import React, { useState, useEffect, useRef } from "react";
import { Dialog, DialogActions, DialogContent, Box, Card } from "@mui/material";
import _ from "lodash";
import ReactQuill from "react-quill";
import { quillModules } from "../components/emailAndText/quillModules";
import { Interweave } from "interweave";
import { useReactToPrint } from "react-to-print";
import { useAuth } from "../contexts/AuthContext";
import { getCurrentUserObject } from "../components/helpers/source";
import "./print.scss";
import MedessistLogo from "../../src/images/VectorMedEssistLogo.png";
import { CssBaseline } from "@mui/material";

export default function AIHandout(props) {
  const { currentUser } = useAuth();

  const { showAIHandoutModal, setShowAIHandoutModal, response } = props;
  const [isEditing, setIsEditing] = useState(false);
  const [formattedResponse, setFormattedResponse] = useState();
  const [responseChanges, setResponseChanges] = useState();
  const [userObject, setUserObject] = useState(null);

  useEffect(() => {
    if (response) {
      const disclaimerText =
        "<p><br></p><p><strong>Disclaimer: </strong>The information provided may include content generated by artificial intelligence (AI) and is for general informational purposes only. It is not a substitute for professional medical advice. Consult with your healthcare provider before making any health-related decisions.</p>";

      var showdown = require("showdown"),
        converter = new showdown.Converter({ tables: true }); //converts markdown to html
      const copy = _.cloneDeep(response);
      let outputString = copy.replace(/\[\d+\](?:,\s*\[\d+\])*/g, ""); //removes sources
      setFormattedResponse(converter.makeHtml(outputString) + disclaimerText);
    }
  }, []);

  useEffect(() => {
    getCurrentUserObject(currentUser.uid).then((res) => setUserObject(res));
  }, [currentUser]);
  const componentRef = useRef();

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  return (
    <>
      <CssBaseline />
      <Dialog
        open={showAIHandoutModal}
        onClose={() => {
          setShowAIHandoutModal(false);
          setIsEditing(false);
          setResponseChanges("");
        }}
        maxWidth={"md"}
      >
        <DialogContent>
          <div className="PrintSection" ref={componentRef}>
            <div
              style={{
                display: "flex",
                alignItems: "center	",
                marginBottom: "1rem",
              }}
            >
              <img src={MedessistLogo} style={{ height: "40px" }} />
              <div style={{ marginLeft: "10px" }}>
                <h2 style={{ marginBottom: "0" }}>
                  {userObject?.firstName} {userObject?.lastName}{" "}
                </h2>
                <h4 style={{ marginTop: "0" }}>{userObject?.profession}</h4>
              </div>
            </div>

            {isEditing ? (
              <ReactQuill
                style={{ marginBottom: "1rem" }}
                theme="snow"
                defaultValue={formattedResponse}
                onChange={(e, delta, source) => {
                  if (source === "user") {
                    setResponseChanges(e);
                  }
                }}
                modules={quillModules}
                // formats={formats}
              />
            ) : (
              <Interweave ref={componentRef} content={formattedResponse} />
            )}
          </div>
        </DialogContent>
        <DialogActions>
          <div style={{ textAlign: "end" }}>
            {isEditing ? (
              <>
                <button
                  className="new-button-style primary"
                  disabled={!responseChanges}
                  onClick={() => {
                    setIsEditing(!isEditing);
                    setFormattedResponse(responseChanges);
                    setResponseChanges("");
                  }}
                >
                  Update
                </button>
                <button
                  className="new-button-style secondary"
                  style={{ marginLeft: "5px" }}
                  onClick={() => {
                    setIsEditing(false);
                    setResponseChanges("");
                  }}
                >
                  Close Editing
                </button>
              </>
            ) : (
              <>
                <button
                  className="new-button-style secondary"
                  onClick={() => setIsEditing(!isEditing)}
                >
                  Edit
                </button>
                <button
                  className="new-button-style primary"
                  style={{ marginLeft: "5px" }}
                  onClick={() => {
                    handlePrint();
                  }}
                >
                  Print
                </button>
              </>
            )}
          </div>
        </DialogActions>
      </Dialog>
    </>
  );
}
