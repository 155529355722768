import React from "react";
import { useNavigate } from "react-router-dom";
import ErrorImage from "../../images/Error404Image.png";
import "./notFound.scss";

export default function NotFound(props) {
  const navigate = useNavigate();

  return (
    <div style={{ textAlign: "center" }}>
      <img src={ErrorImage} className="not-found-image"></img>

      <h1 className="not-found-title">404</h1>
      <h3
        style={{
          fontSize: "20px",
          fontWeight: "bold",
          color: "#61CCCC",
          margin: "0 0 1.5rem 0",
        }}
      >
        Page Not Found
      </h3>
      <button
        onClick={() => {
          navigate("/");
          document.title = "home";
        }}
        className="new-button-style"
      >
        Return Home
      </button>
    </div>
  );
}
