import React, { useState, useRef, useEffect } from "react";
import useCourseEnrollment from "./useCourseEnrollment";
import QuizModal from "./QuizModal";
import { updateCoursesForUser, getCurrentUserObject } from "../helpers/source";
import { rateCourse } from "../helpers/source";
import { Card, CardContent, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import TabContent from "./TabContent/TabContent";
import CourseHeader from "./CourseHeader/CourseHeader";
import resourcesData from "./TabContent/resourcesData";
import VideoAudioSection from "./VideoAudioSection";
import UnlockQuiz from "./UnlockQuiz";
import PostQuiz from "./PostQuiz";
import instructorData from "./TabContent/instructorData";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AutoAwesomeIcon from "@mui/icons-material/AutoAwesome";
import { KofiButton } from "react-kofi-button";
import TabsComponent from "./TabContent/TabsComponent";
import "./courses.scss";

function CoursePage({ courseData, currentUser }) {
  const {
    courseName,
    quizPassingScore,
    courseId,
    instructorName,
    tutorial,
    feedbackDoc,
  } = courseData;

  const [selectedSection, setSelectedSection] = useState("about");
  const [quizOpen, setQuizOpen] = useState(false);
  const [videoWatched, setVideoWatched] = useState(false);
  const [completed, setCompleted] = useState(false);
  const [tutorialCompleted, setTutorialCompleted] = useState(false);
  const [rating, setRating] = useState(0);
  const [submittedRating, setSubmittedRating] = useState(false);
  const [thankYouMessage, setThankYouMessage] = useState("");
  const [markAsWatchedLoading, setMarkAsWatchedLoading] = useState(false);

  const { registration } = courseData;
  const navigate = useNavigate();

  const koFiUsername = instructorData?.[instructorName]?.koFiUsername;

  const {
    enrolled,
    isLoadingEnrollment,
    isEnrollmentFetched,
    handleCourseEnrollment,
  } = useCourseEnrollment(courseData);

  const handleTutorialCompletion = async () => {
    setCompleted(true);

    await updateCoursesForUser({
      userId: currentUser?.uid,
      courseId: courseId,
      videoWatched: true,
      pass: true,
    });
  };

  // check if user had already watched video, if yes, setVideoWatched(true) and make quiz accessible
  useEffect(() => {
    if (currentUser) {
      const checkVideoWatched = async () => {
        const userObject = await getCurrentUserObject(currentUser.uid);
        const courses = userObject?.courses || [];

        if (Object.keys(courses).includes(courseId)) {
          setVideoWatched(courses?.[courseId]?.videoWatched);
        }

        // Check for completed status
        const completedStatus = courses?.[courseId]?.completed;
        if (completedStatus) {
          setCompleted(true);
        }
        if (completedStatus && tutorial) {
          setTutorialCompleted(true);
        }
      };
      checkVideoWatched();
    }
  }, [currentUser?.uid, courseId]);

  // Fetch the rating from the db
  useEffect(() => {
    const fetchRating = async () => {
      if (currentUser) {
        const userObject = await getCurrentUserObject(currentUser?.uid);
        const courses = userObject?.courses || [];

        if (Object.keys(courses).includes(courseId)) {
          const courseRating = courses?.[courseId]?.rating;
          setRating(courseRating || 0);

          //check if user has already submitted a rating
          if (courseRating) {
            setSubmittedRating(true);
          }
        }
      }
    };

    fetchRating();
  }, [currentUser?.uid, courseId]);

  const handleStartQuiz = () => {
    if (videoWatched) {
      setQuizOpen(true);
    }
  };

  const handleClose = () => {
    setQuizOpen(false);
  };

  const handleChangeRating = async (event, newValue) => {
    setRating(newValue);

    if (newValue > 0) {
      await rateCourse({
        userId: currentUser.uid,
        courseId: courseId,
        rating: newValue,
      });

      // Change thank you message based on whether user has already submitted a rating
      if (submittedRating) {
        setThankYouMessage(
          `Your rating for ${courseName} has been updated. Thank you!`
        );
      } else {
        setThankYouMessage(`Thanks for rating ${courseName}!`);
      }

      setSubmittedRating(true);
    }
  };

  const handleUserConfirmation = async () => {
    setMarkAsWatchedLoading(true);
    setTimeout(() => {
      setVideoWatched(true);
      setMarkAsWatchedLoading(false);
    }, 1000);

    await updateCoursesForUser({
      userId: currentUser.uid,
      courseId: courseId,
      videoWatched: true,
    });
  };

  const playerRef = useRef(null);

  // render the enrollment card only when user is not enrolled yet
  if (currentUser && !isEnrollmentFetched) {
    return null;
  }

  const handleSectionClick = (section) => {
    setSelectedSection(section);
  };

  // called when passing the quiz
  const quizPassed = () => {
    setCompleted(true);
    // so users do not miss the certificate achieved card
    window.scrollTo(0, 0);
  };

  const handleBackClick = () => {
    navigate("/courses");
  };

  return (
    <div className="course-container">
      <CourseHeader
        courseName={courseName}
        instructorName={instructorName}
        handleSectionClick={handleSectionClick}
        handleBackClick={handleBackClick}
      />
      {koFiUsername && enrolled && (
        <Accordion
          style={{
            margin: "0.5rem 1rem",
            borderRadius: "15px",
            padding: "5px 0 5px 0",
            textAlign: "center",
            boxShadow: "0 4px 8px 0 rgba(0,0,0,0.2)",
          }}
        >
          <AccordionSummary
            // expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
            style={{ justifyContent: "center" }}
          >
            <Typography style={{ textAlign: "center", width: "100%" }}>
              Enjoyed the course? Support {instructorName} by treating them to a
              coffee! ☕️
            </Typography>
          </AccordionSummary>

          <KofiButton
            username={koFiUsername}
            label={`Support ${instructorName}`}
          />

          <Typography
            style={{
              color: "gray",
              fontSize: "0.8rem",
              textAlign: "center",
              margin: "10px",
            }}
          >
            The presenter has received no financial support for the hours of
            work put into creating this course. Please consider supporting the
            presenter by "buying them a coffee" through their KoFi link above.
            100% of donations are sent directly to the presenter.
          </Typography>
        </Accordion>
      )}

      {/*  if not enrolled yet and there are > 40 users registered for a course, display popular course banner */}
      {!enrolled && registration && registration > 40 && (
        <Typography
          sx={{
            display: "flex",
            justifyContent: "center",
            fontWeight: "bold",
            textAlign: "center",
            mb: "0.5rem",
          }}
        >
          <AutoAwesomeIcon /> Popular Course: This course has {registration}{" "}
          registrations
        </Typography>
      )}
      {completed && (
        <PostQuiz
          rating={rating}
          submittedRating={submittedRating}
          handleChangeRating={handleChangeRating}
          thankYouMessage={thankYouMessage}
          tutorial={tutorial}
          feedbackDoc={feedbackDoc}
        />
      )}
      <VideoAudioSection
        courseData={courseData}
        playerRef={playerRef}
        enrolled={enrolled}
        currentUser={currentUser}
      />
      <Card
        variant="outlined"
        className="card-container-border course-info-container"
      >
        <div className="course-info-header">
          <TabsComponent
            selectedSection={selectedSection}
            setSelectedSection={setSelectedSection}
            resourcesData={resourcesData}
            courseData={courseData}
            tutorial={tutorial}
          />
          {(!completed || tutorial) && (
            <UnlockQuiz
              videoWatched={videoWatched}
              handleStartQuiz={handleStartQuiz}
              handleUserConfirmation={handleUserConfirmation}
              markAsWatchedLoading={markAsWatchedLoading}
              tutorial={tutorial}
              handleTutorialCompletion={handleTutorialCompletion}
              tutorialCompleted={tutorialCompleted}
              setTutorialCompleted={setTutorialCompleted}
              courseData={courseData}
              isEnrollmentFetched={isEnrollmentFetched}
              enrolled={enrolled}
              isLoadingEnrollment={isLoadingEnrollment}
              handleCourseEnrollment={handleCourseEnrollment}
              currentUser={currentUser}
            />
          )}
        </div>
        <CardContent>
          <TabContent
            selectedSection={selectedSection}
            courseData={courseData}
          />
        </CardContent>
      </Card>
      {/* Modal to show the quiz */}
      <QuizModal
        open={quizOpen}
        handleClose={handleClose}
        courseData={courseData}
        passingScore={quizPassingScore}
        onQuizPassed={quizPassed}
      />
    </div>
  );
}

export default CoursePage;
