import React, { useState, useEffect } from "react";
import {
  Dialog,
  DialogContent,
  TextField,
  DialogActions,
  DialogTitle,
  IconButton,
} from "@mui/material";
import { handleSendCustomEmail } from "../helpers/source";
import "./emailAndText.scss";
import "react-quill/dist/quill.snow.css";
import { quillModules } from "./quillModules";
import ReactQuill from "react-quill";
import { useAuth } from "../../contexts/AuthContext";
import { getCurrentUserObject } from "../helpers/source";
import { CssBaseline } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

export default function SendEmailModal(props) {
  const { showSendEmailModal, setShowSendEmailModal, message } = props;

  const { currentUser } = useAuth();

  const [userObject, setUserObject] = useState(null);
  const [emailRecepient, setEmailRecipient] = useState();
  const [emailMessage, setEmailMessage] = useState("");
  const [emailSubject, setEmailSubject] = useState(
    `A message from your pharmacist`
  );

  useEffect(() => {
    getCurrentUserObject(currentUser.uid).then((res) => setUserObject(res));
  }, [currentUser]);

  useEffect(() => {
    if (message) {
      setEmailMessage(message);
    }
  }, [showSendEmailModal]);

  useEffect(() => {
    setEmailSubject(
      `A message from ${userObject?.firstName} ${userObject?.lastName}`
    );
  }, [userObject]);

  const formattedEmailData = {
    customMessage: emailMessage,
    email: emailRecepient,
    firstName: emailMessage,
    subject: emailSubject,
  };

  const handleSendEmail = async () => {
    await handleSendCustomEmail(formattedEmailData);
    setShowSendEmailModal(false);
  };

  return (
    <Dialog
      open={showSendEmailModal}
      backdrop="static"
      onClose={() => {
        setShowSendEmailModal(false);
        setEmailMessage("");
        setEmailRecipient("");
        setEmailSubject(
          `A message from ${userObject?.firstName} ${userObject?.lastName}`
        );
      }}
    >
      <CssBaseline />

      <DialogTitle style={{ textAlign: "center" }}>Send Email</DialogTitle>
      <IconButton
        aria-label="close"
        onClick={() => setShowSendEmailModal(false)}
        sx={{
          position: "absolute",
          right: 8,
          top: 8,
        }}
      >
        <CloseIcon />
      </IconButton>
      <DialogContent>
        <div className="email-send-info">
          <h4>To: </h4>
          <TextField
            placeholder={"Patient Email Address"}
            value={emailRecepient}
            onChange={(e) => setEmailRecipient(e.target.value)}
          ></TextField>

          <h4>Subject: </h4>
          <TextField
            placeholder={"Email Subject"}
            value={emailSubject}
            onChange={(e) => setEmailSubject(e.target.value)}
          ></TextField>
        </div>

        <br />

        <ReactQuill
          theme="snow"
          style={{
            width: "100%",
            height: "300px",
            paddingBottom: "20px",
          }}
          modules={quillModules}
          onChange={(e) => {
            setEmailMessage(e);
          }}
          placeholder="Enter your message here..."
          value={emailMessage}
        />
        <br />
        <br />
        <p>REMINDER: Patients will not be able to reply to your email. </p>
      </DialogContent>
      <DialogActions>
        <button
          className="new-button-style primary"
          onClick={() => {
            handleSendEmail();
          }}
          disabled={
            !emailRecepient?.match(
              /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/
            ) || !emailMessage
          }
        >
          Send
        </button>
      </DialogActions>
    </Dialog>
  );
}
