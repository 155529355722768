import React from "react";
import { IconButton, TextField } from "@mui/material";
import SendIcon from "@mui/icons-material/Send";

export default function NewQueryInput(props) {
  const {
    selectedConversation,
    messageToSend,
    setMessageToSend,
    handleSubmit,
    setSelectedConversation,
    setCurrentQuery,
    loading,
  } = props;

  return (
    <div
      className="ai-message-input"
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "baseline",
        position: "relative",
      }}
    >
      {/* new conversation input */}
      <TextField
        tabIndex={0}
        style={{ width: "100%", height: "auto" }}
        id="outlined-multiline-static"
        multiline
        rows={3}
        placeholder="Type a question"
        value={!selectedConversation?.docId ? messageToSend?.query : ""}
        onChange={(e) => {
          if (!loading) {
            setMessageToSend({
              ...messageToSend,
              chat_history: [],
              query: e.target.value,
            });
            setCurrentQuery(e.target.value);
            setSelectedConversation(messageToSend);
          }
        }}
        onKeyDown={(e) => {
          if (e.key === "Enter" && !loading) {
            handleSubmit(e);
          }
        }}
        autoFocus
      />

      <IconButton
        style={{
          position: "absolute",
          right: "10px",
          top: "50%",
          marginTop: "-21px",
          background: "#3ac0c3",
          borderRadius: "10px",
        }}
        onClick={(e) => {
          if (!loading) {
            handleSubmit(e);
          }
        }}
      >
        <SendIcon />
      </IconButton>
    </div>
  );
}
