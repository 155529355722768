import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";

function StepQuiz({
  quizData,
  onQuizPass,
  isPassed,
  updateProgress,
  handlePreviousStep,
  currentStepIndex,
}) {
  const { t } = useTranslation();
  const [answers, setAnswers] = useState({});
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [showContinue, setShowContinue] = useState(false);
  const [showExplanation, setShowExplanation] = useState(false);

  useEffect(() => {
    // When the quiz loads or the pass status changes, reset the quiz answers
    if (isPassed) {
      // If already passed, fill in the correct answers and lock the quiz
      const correctAnswers = quizData.questions.reduce(
        (acc, question) => ({
          ...acc,
          [question.question]: question.answer,
        }),
        {}
      );
      setAnswers(correctAnswers);
      setIsSubmitted(true);
      setShowContinue(true);
    } else {
      // If not passed, clear previous answers and allow user to answer
      setAnswers({});
      setIsSubmitted(false);
      setShowContinue(false);
      setShowExplanation(false);
    }
  }, [quizData.quizId, isPassed, quizData.questions]);

  const handleAnswerChange = (questionId, option) => {
    // Update answers only if the quiz hasn't been submitted
    if (!isSubmitted) {
      setAnswers({
        ...answers,
        [questionId]: option,
      });
    }
  };

  const handleSubmit = () => {
    // Submit the quiz answers
    setIsSubmitted(true);
    let passed = true;
    // Check each question to see if the user's answer is correct
    quizData.questions.forEach((question) => {
      if (answers[question.question] !== question.answer) {
        passed = false;
      }
    });
    if (passed) {
      // If all answers are correct, allow proceeding to the next step
      setShowContinue(true);
      setShowExplanation(true); // Show explanation when passed
      updateProgress({
        quizId: quizData.quizId,
        passed: true,
      });
    }
  };

  const handleContinue = () => {
    // Move to the next part of the course after passing the quiz
    if (showContinue) {
      onQuizPass();
    }
  };

  const handleRetry = () => {
    // Allow retrying the quiz by resetting the state
    setIsSubmitted(false);
    setShowContinue(false);
    setShowExplanation(false);
    setAnswers({});
  };

  return (
    <div className="centered-container">
      <h1>{quizData.title}</h1>
      {quizData.questions.map((question, index) => (
        <div
          key={index}
          className="question-block"
          style={{ marginBottom: "15px" }}
        >
          <p style={{ fontWeight: "bold" }}>{question.question}</p>
          {question.options.map((option, optionIndex) => (
            <label
              key={optionIndex}
              style={{
                display: "block",
                margin: "5px 0",
                fontWeight: "bold",
                color:
                  isSubmitted &&
                  answers[question.question] === question.answer &&
                  option === question.answer
                    ? "green"
                    : "initial",
              }}
            >
              <input
                type="radio"
                name={`question-${index}`}
                value={option}
                checked={answers[question.question] === option}
                onChange={() => handleAnswerChange(question.question, option)}
                disabled={isSubmitted}
              />
              {option}
            </label>
          ))}

          {/* Optional Extra explanation section to show after the user gets the question right */}
          {showExplanation && question.explanation && (
            <p style={{ color: "blue", marginTop: "10px" }}>
              {question.explanation}
            </p>
          )}

          {/* Optional Explanation Picture */}
          {showExplanation && question.explanationPicture && (
            <img
              src={question.explanationPicture}
              alt="Explanation"
              style={{ marginTop: "10px", maxWidth: "100%" }}
            />
          )}
        </div>
      ))}

      {isSubmitted && !showContinue && (
        <p
          style={{
            color: "red",
            fontSize: "18px",
            fontWeight: "bold",
            textAlign: "center",
            marginBottom: "10px",
          }}
        >
          {t("interactiveCourse.sorryDidNotPass")}
        </p>
      )}

      {isSubmitted && showContinue && (
        <p
          style={{
            color: "green",
            fontSize: "18px",
            fontWeight: "bold",
            textAlign: "center",
            marginBottom: "10px",
          }}
        >
          {t("interactiveCourse.congratulationsPassedQuiz")}
        </p>
      )}

      <div style={{ display: "flex", justifyContent: "center" }}>
        <button
          onClick={handlePreviousStep}
          className="new-button-style secondary"
          style={{ marginRight: "10px" }}
          disabled={currentStepIndex < 1}
        >
          {t("interactiveCourse.back")}
        </button>

        {!isSubmitted ? (
          <button className="new-button-style primary" onClick={handleSubmit}>
            {t("interactiveCourse.submit")}
          </button>
        ) : showContinue ? (
          <button className="new-button-style primary" onClick={handleContinue}>
            {t("interactiveCourse.continue")}
          </button>
        ) : (
          <button className="new-button-style primary" onClick={handleRetry}>
            {t("interactiveCourse.tryAgain")}
          </button>
        )}
      </div>
    </div>
  );
}

export default StepQuiz;
