import React, { useEffect, useState } from "react";
import {
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  ListItemSecondaryAction,
  IconButton,
  Badge,
} from "@mui/material";
import PharmacyIcon from "@mui/icons-material/LocalPharmacy";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useAuth } from "../../../contexts/AuthContext";
import {
  getUpdatingLinkedPharmacies,
  removeUserPharmacy,
} from "../../../features/states/linkedPharmaciesSlice";
import { deleteLinkedPharmacy } from "../../helpers/source";
import { useDispatch } from "react-redux";
import PendingRequestButtonAndModal from "../../../pendingPharmacyRequests/PendingRequestButtonAndModal";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import { getUpdatingPendingPharmacyRequests } from "../../../features/states/pendingPharmacyRequestsSlice";
import ConfirmDeleteLinkedPharmacy from "./ConfirmDeleteLinkedPharmacy";

const LinkedPharmacies = ({ setOpenAddPharmacyModal }) => {
  const { currentUser } = useAuth();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [openPendingRequestModal, setOpenPendingRequestModal] = useState(false);
  const [pharmacy, setPharmacy] = useState(null);
  const [openConfirmDeleteLinkedPharmacy, setOpenConfirmDeleteLinkedPharmacy] =
    useState(false);
  const linkedPharmacies = useSelector((state) => state.linkedPharmacies.value);
  const pendingPharmacyRequests = useSelector(
    (state) => state.pendingPharmacyRequests.value
  );
  useEffect(() => {
    if (currentUser) {
      dispatch(getUpdatingPendingPharmacyRequests(currentUser.uid));
      dispatch(getUpdatingLinkedPharmacies(currentUser.uid));
    }
  }, [dispatch]);
  // function called when user unlinks a pharmacy, remove from user in db
  const handleDeletePharmacy = (pharmacyId) => {
    deleteLinkedPharmacy(currentUser.uid, pharmacyId)
      .then(() => {
        dispatch(removeUserPharmacy(pharmacyId));
        setOpenConfirmDeleteLinkedPharmacy(false);
      })
      .catch((error) => {
        console.error("Error deleting pharmacy", error);
      });
  };
  return (
    <>
      <PendingRequestButtonAndModal
        open={openPendingRequestModal}
        setOpen={setOpenPendingRequestModal}
      />

      <List component="div" disablePadding style={{ width: "100%" }}>
        <div className="header-section">
          <h3>My Linked Pharmacies</h3>
          <Badge
            color="primary"
            badgeContent={pendingPharmacyRequests?.length ?? null}
          >
            <IconButton onClick={() => setOpenPendingRequestModal(true)}>
              <PersonAddIcon />
            </IconButton>
          </Badge>
          {/* <button
            className="link-pharmacy-button"
            onClick={() => {
              setOpenAddPharmacyModal(true);
            }}
          >
            + LINK A PHARMACY
          </button> */}
        </div>

        {/* Check if there are linked pharmacies */}
        <ConfirmDeleteLinkedPharmacy
          open={openConfirmDeleteLinkedPharmacy}
          setOpenConfirmDeleteLinkedPharmacy={
            setOpenConfirmDeleteLinkedPharmacy
          }
          handleDeletePharmacy={handleDeletePharmacy}
          pharmacy={pharmacy}
        />
        {linkedPharmacies?.length > 0 ? (
          linkedPharmacies.map((pharmacy) => (
            <>
              <ListItem
                key={pharmacy.pharmacyName}
                className="pharmacy-list-item"
              >
                <ListItemIcon>
                  <PharmacyIcon style={{ fontSize: "3em" }} />
                </ListItemIcon>
                <ListItemText
                  primary={pharmacy.pharmacyName}
                  secondary={pharmacy.pharmacyPhone}
                />
                <ListItemSecondaryAction>
                  <IconButton
                    edge="end"
                    onClick={() => {
                      setPharmacy(pharmacy);
                      setOpenConfirmDeleteLinkedPharmacy(true);
                    }}
                  >
                    <HighlightOffIcon />
                  </IconButton>
                </ListItemSecondaryAction>
              </ListItem>
            </>
          ))
        ) : (
          // Display a message if there are no linked pharmacies
          <ListItem className="no-pharmacies-linked">
            No pharmacies linked yet.
          </ListItem>
        )}
      </List>
    </>
  );
};

export default LinkedPharmacies;
