const basalInsulinMasterySeries = {
  multipleCreditCourse: true,
  numberOfSections: 3,
  courseId: "basal_insulin_mastery_t2d",
  courseTitle: "Basal Insulin Mastery Series for Management of Type 2 Diabetes",
  courseDescription:
    "Welcome to this interactive 3-module series that covers the mechanism, benefits, and guidelines for long-acting insulin, practical steps for initiating and titrating basal insulins, and an introduction to the new once-weekly insulin Icodec therapy.",
  courseAccreditation: {
    ceus: "Each module has been accredited by The Canadian Council on Continuing Education in Pharmacy (CCCEP).",
    statementOfParticipation:
      "After completing each course you will receive a Statement of Participation.",
  },
  collaborationStatement:
    "This interactive course has been designed in collaboration with LMC Pharmacy. LMC is Canada’s largest specialist care provider in Diabetes & Endocrinology. Diabetes Educator Pharmacists on the team have created this series to provide practical and relevant knowledge to help you in your community practice.",
  instructors: [
    {
      name: "Brian Lam",
      imageUrl:
        "https://firebasestorage.googleapis.com/v0/b/education-hub-848d2.appspot.com/o/educationHub%2Fheadshots%2FBrian.Lam.Photo.jpeg?alt=media&token=fa20261d-8c37-4f0e-91ed-c5b4ccc42115",
      bio: "Brian is a clinical and consultant pharmacist who specializes in diabetes care. He is passionate about helping individuals in managing their chronic conditions and medication therapy. As a testament to being a life-long learner, he is a Certified Diabetes Educator and is certified in hypertension management by Hypertension Canada. Committed to advancing pharmacy practice and care, he prioritizes continuous innovation and education.",
      linkedinUrl: "https://www.linkedin.com/in/brian-lam/",
    },
    {
      name: "Natalie Shing",
      imageUrl:
        "https://firebasestorage.googleapis.com/v0/b/education-hub-848d2.appspot.com/o/educationHub%2Fheadshots%2FNatalie.png?alt=media&token=920e2edd-9bce-4c45-9836-8b5ba15de1a6",
      bio: "Natalie is a dedicated pharmacist currently specializing in diabetes care. With extensive experience in hospital, community and clinic settings, she brings a wealth of knowledge and expertise in managing conditions and resolving medication related challenges. She is continuously committed to advancing diabetes care and pharmaceutical education.",
      linkedinUrl: "https://www.linkedin.com/in/natalie-shing-91a6231aa/",
    },
  ],
  credits: "0.5 CEU",

  modules: [
    {
      title:
        "Module 1: Exploring the Landscape of Basal Insulin Therapy in the Management of Type 2 Diabetes - A Comprehensive Overview",
      subtitle: "Mechanism of Action, Benefits & Guidelines in Type 2 Diabetes",
      watchTime: "25 minutes",
      ceu: "0.5 CEU",
      accreditationNumber: "1751-2024-3781-I-P",
    },
    {
      title:
        "Module 2: Getting Started. Initiating and titrating long-acting basal insulins",
      //  subtitle: "Initiating and Titrating Long-Acting Basal Insulins",
      watchTime: "32 minutes",
      ceu: "1.10 CEU",
      accreditationNumber: "1751-2024-3815-I-P",
    },
    {
      title:
        "Module 3: Crash Course on Once Weekly Insulin, The New Therapy on The Block",
      subtitle: "Crash course on once weekly insulin Icodec",
      watchTime: "36 minutes",
      ceu: "1.0 CEU",
      accreditationNumber: "1751-2024-3838-I-P",
    },
  ],
  sections: [
    {
      credits: "0.5 CEU",
      accreditationNumber: "1751-2024-3781-I-P",
      id: "basal_insulin_mastery_t2d_module_1",
      title:
        "Module 1: Exploring the Landscape of Basal Insulin Therapy in the Management of Type 2 Diabetes: A Comprehensive Overview",
      //subtitle: "Mechanism of Action, Benefits & Guidelines in Type 2 Diabetes",
      progressBarTitle: "Module 1: Long-Acting Insulin Review",
      watchTime: "25 minutes",
      surveyLink:
        "https://docs.google.com/forms/d/e/1FAIpQLSdCfRFe_oxBCImtnsAlMoqcin0ewFuhGWfAzOXonkUU1zFTEw/viewform",
      certificateId: "basal_insulin_mastery_t2d_module_1",
      instructor: "Brian Lam, RPh, CDE",
      learningObjectives: [
        "Describe how basal insulin works and its role in the management of type 2 diabetes.",
        "Recognize the different basal insulins available on the Canadian market and be able to distinguish the differences between each basal insulin.",
        "List the potential benefits and risk of using basal insulins.",
      ],
      steps: [
        {
          type: "video",
          src: "https://vimeo.com/954414968/82515fcba4",
          videoId: "mod_1-vid_1",
          title: "Long-acting Insulin Review",
        },
        {
          type: "quiz",
          quizId: "mod_1-quiz_1",
          questions: [
            {
              question:
                "James Jimmerson is a 58 year old man who has been living with type 2 diabetes for over 10 years. His current diabetes medication regimen includes metformin, empagliflozin and oral semaglutide. Today, he presents to your diabetes care team for more information on a new basal insulin he has been prescribed - insulin degludec U-100 (Tresiba). Confused about why his doctor prescribed another diabetes medication, he asks you for your opinion on why this might be. You take a look at his chart and see that his A1C is 8.6%. What might you tell him?",
              options: [
                "James’ A1C is at target and his doctor thought it would be a good idea to add another medication to ensure he stays at target",
                "James’ A1C is NOT at target despite the use of other anti-hyperglycemic medications and his doctor wants to reduce his risk of developing complications associated with inadequately managed type 2 diabetes through the use of basal insulin",
                "James’ A1C is at target and you are not sure why his doctor initiated this medication",
                "James’ A1C is not at target despite the use of other anti-hyperglycemic medications and his doctor wants to complicate his medication regimen with the use of basal insulin",
              ],
              answer:
                "James’ A1C is NOT at target despite the use of other anti-hyperglycemic medications and his doctor wants to reduce his risk of developing complications associated with inadequately managed type 2 diabetes through the use of basal insulin",
            },
          ],
        },
        {
          type: "video",
          src: "https://vimeo.com/954414949/c3a57efcb6?share=copy",
          videoId: "mod_1-vid_2",
          title: "Long-acting Insulin Review",
        },
        {
          type: "quiz",
          quizId: "mod_1-quiz_2",
          questions: [
            {
              question:
                "James reports that he has only heard about insulin before, but no one had ever explained what it was to him. He asks you to tell him more about insulin, specifically asking about “basal insulins” which he heard his endocrinologist say. Which of the following discussion points below is false?",
              options: [
                "Insulin is a hormone that your pancreas secretes and helps regulate blood sugar levels",
                "Insulin is secreted from the pancreas in a pulsatile manner, with higher levels released during and after meals, and consistent background levels at other times during the day",
                "The role of basal insulin is to mimic the body’s endogenous insulin response to the high blood sugar spikes after meals",
                "The role of basal insulin in managing type 2 diabetes is to provide a steady level of insulin throughout the day, mimicking the background pulsatile insulin secretion seen in individuals without type 2 diabetes",
              ],
              answer:
                "The role of basal insulin is to mimic the body’s endogenous insulin response to the high blood sugar spikes after meals",
            },
          ],
        },
        {
          type: "video",
          src: "https://vimeo.com/954414909/58d4b66102",
          videoId: "mod_1-vid_3",
          title: "Long-acting Insulin Review",
        },
        {
          type: "quiz",
          quizId: "mod_1-quiz_3",
          questions: [
            {
              question:
                "James is extremely impressed with the amount of information you have provided him. Before he goes to sit down and wait for his medications to be filled, he asks one more question. He asks about what risks are associated with using insulin? You tell him…",
              options: [
                "Hypoglycemia",
                "Weight gain",
                "Injection site issues",
                "All of the above",
              ],
              answer: "All of the above",
            },
          ],
        },
        {
          type: "video",
          src: "https://vimeo.com/954414928/e8833f492c?share=copy",
          videoId: "mod_1-vid_4",
          title: "Long-acting Insulin Review",
        },
        {
          type: "section-done-multi-ceu",
          sectionIndex: 1,
          sectionId: "basal_insulin_mastery_t2d_module_1",
        },
      ],
      status: "available",
    },
    {
      credits: "1.10 CEU",
      accreditationNumber: "1751-2024-3815-I-P",
      id: "basal_insulin_mastery_t2d_module_2",
      title:
        "Module 2: Getting started - Initiating and titrating long-acting basal insulins",
      //  subtitle: "Initiating and titrating long-acting basal insulins",
      progressBarTitle: "Module 2: Getting Started",
      watchTime: "32 minutes",
      surveyLink:
        "https://docs.google.com/forms/d/e/1FAIpQLSdeEwOKDQsIF1wDrNowVmt3Ez8R2g3zlExX2-uOMTrT9ROhNA/viewform?usp=sharing",
      certificateId: "basal_insulin_mastery_t2d_module_2",
      instructor: "Natalie Shing, RPh, CDE",
      learningObjectives: [
        "Explain how to initiate the discussion on insulin use and administration.",
        "Titrate and optimize the dose of basal insulin.",
        "Understand options to manage side effects of insulin.",
        "Evaluate glucose measurements to manage the basal insulin regimen, other medications, and lifestyle.",
      ],
      steps: [
        {
          type: "video",
          src: "https://vimeo.com/970987783/d149767ea7",
          videoId: "mod_2-vid_1",
          title: "Initiating Long-acting Basal Insulins",
        },
        {
          type: "quiz",
          quizId: "mod_2-quiz_1",
          questions: [
            {
              question:
                "Which of the following is not considered proper insulin injection technique?",
              options: [
                "A) Rotating the injection area/sites",
                "B) Only priming with each new pen",
                "C) Using a new needle with each injection",
                "D) Holding the dose button until the dose window indicates '0' and releasing immediately",
                "E) B only",
                "F) B and D",
              ],
              answer: "F) B and D",
            },
          ],
        },
        {
          type: "video",
          src: "https://vimeo.com/970987319/fae06ccc68",
          videoId: "mod_2-vid_2",
          title: "Injection Techniques Explained",
        },
        {
          type: "free-answer-mcq",
          quizId: "mod_2-quiz_2",
          questions: [
            {
              question:
                "Let’s hear from you! What do you think is more important to manage first: hypoglycemia OR hyperglycemia? Why?",
              options: ["Hypoglycemia", "Hyperglycemia"],
              explanation: "Thanks for sharing your opinion!",
              //  answer: "Hypoglycemia",
            },
          ],
        },
        {
          type: "video",
          src: "https://vimeo.com/970987423/52bf39de6d",
          videoId: "mod_2-vid_3",
          title: "Managing Hypoglycemia and Hyperglycemia",
        },
        {
          type: "quiz",
          quizId: "mod_2-quiz_3",
          title: "Case Study Part A",
          questions: [
            {
              question:
                "F.K. is a 63-year-old male who was recently diagnosed with diabetes...Which of the following options would be the most appropriate to initiate at this time?",
              options: [
                "Insulin glargine (Lantus) 10 units once daily at bedtime.",
                "Insulin detemir (Levemir) 0.1–0.2 units/kg once daily at bedtime.",
                "Insulin degludec (Tresiba) 10 units once daily in the morning.",
                "Insulin glargine 300U (Toujeo) 0.1-0.2 units/kg once daily at bedtime.",
              ],
              answer:
                "Insulin degludec (Tresiba) 10 units once daily in the morning.",
            },
          ],
        },
        {
          type: "video",
          src: "https://vimeo.com/970987539/db2ea07efa",
          videoId: "mod_2-vid_4",
          title: "Case Study Analysis Part A",
        },
        {
          type: "quiz",
          quizId: "mod_2-quiz_4",
          title: "Case Study Part B",
          questions: [
            {
              question:
                "Two weeks later, you call F.K. to follow up and inquire about his experience...What is the most appropriate advice to give him at this time?",
              options: [
                "Increase the dose of insulin degludec (Tresiba) by 1 unit once daily at bedtime until FBG are < 7",
                "Increase the dose of insulin degludec (Tresiba) by 2 units once daily at bedtime until FBG are <7",
                "Increase the dose of insulin degludec (Tresiba) by 2 units once every 3-4 days until FBG are < 7.",
                "Do not titrate due to hypoglycemia risk. Suggest dietary modifications...",
                "Add a short-acting insulin (bolus) to F.K.'s regimen...",
              ],
              answer:
                "Increase the dose of insulin degludec (Tresiba) by 2 units once every 3-4 days until FBG are < 7.",
            },
          ],
        },
        {
          type: "video",
          src: "https://vimeo.com/970987595/81ef99afb3",
          videoId: "mod_2-vid_5",
          title: "Case Study Analysis Part B",
        },

        {
          type: "quiz",
          quizId: "mod_2-quiz_5",
          title: "Case Study Part C",
          questions: [
            {
              question:
                "Two months later, you follow up with F.K. regarding his insulin therapy...What are the possible factors contributing to his hypoglycemic episodes?",
              options: [
                "Increased physical activity from walking for 1 hour daily",
                "Alcohol consumption",
                "Dietary changes leading to reduced carbohydrate intake",
                "A and C",
              ],
              answer: "A and C",
            },
          ],
        },
        {
          type: "video",
          src: "https://vimeo.com/970987628/5739dd9495",
          videoId: "mod_2-vid_5",
          title: "Case Study Analysis Part C",
        },
        {
          type: "quiz",
          quizId: "mod_2-quiz_6",
          title: "Case Study Part D",
          questions: [
            {
              question:
                "What would you advise F.K in terms of management and prevention of future hypoglycemic episodes?",
              options: [
                "Reduce basal insulin by 10%",
                "Reduce exercise to 15 minutes per day and stop following the dietician’s meal plan",
                "Have access to fast acting sugar at all times",
                "Bring a snack when he goes on his walk",
                "A, C and D",
              ],
              answer: "A, C and D",
            },
          ],
        },
        {
          type: "video",
          src: "https://vimeo.com/970987671/e70f3fed97",
          videoId: "mod_2-vid_6",
          title: "Case Study Analysis Part C",
        },
        {
          type: "quiz",
          quizId: "mod_2-quiz_7",
          title: "Case Study Part E",
          questions: [
            {
              question:
                "In addition to his daily routine, F.K. also drops off and picks up his grandchildren...What should you advise F.K regarding his management of hypoglycemia?",
              options: [
                "There are no concerns. F. K is treating managing hypoglycemia appropriately",
                "Advise F.K. not to treat hypoglycemia with chocolate",
                "F.K. must re test after 15 minutes to ensure his glucose levels are above 4 mmol/L before starting to drive after treating a low blood sugar",
                "Advise F.K that after a hypoglycemic episode, it may take up to 40 minutes for his brain to fully recover...",
                "D only",
                "B and D",
                "B, C and D",
              ],
              answer: "B and D",
            },
          ],
        },

        {
          type: "video",
          src: "https://vimeo.com/970987707/a5fbf9c3b0",
          videoId: "mod_2-vid_7",
          title: "Case Study Analysis Part D",
        },

        {
          type: "quiz",
          quizId: "mod_2-quiz_8",
          title: "Case Study Part F",
          questions: [
            {
              question:
                "Six months have passed and F.K., has been stable on 18U of insulin degludec...What is the best option you can recommend at this time?",
              options: [
                "Advise F.K the only option is to modify his lifestyle",
                "Recommend addition of bolus insulin to his regimen",
                "Recommend addition of a GLP-1 RA to his regimen",
                "Recommend increasing basal insulin",
              ],
              answer: "Recommend addition of a GLP-1 RA to his regimen",
            },
          ],
        },

        {
          type: "video",
          src: "https://vimeo.com/970987753/ca87869c58",
          videoId: "mod_2-vid_8",
          title: "Case Study Analysis Part E",
        },

        {
          type: "section-done-multi-ceu",
          sectionIndex: 2,
          sectionId: "basal_insulin_mastery_t2d_module_2",
        },
      ],
      // status: "available-internally",
      status: "available",
    },
    {
      credits: "1.0 CEU",
      accreditationNumber: "1751-2024-3838-I-P",
      id: "basal_insulin_mastery_t2d_module_3",
      title:
        "Module 3: Crash Course on Once Weekly Insulin, The New Therapy on The Block",
      progressBarTitle: "Module 3: The New Therapy on the Block!",
      watchTime: "36 minutes",
      surveyLink:
        "https://docs.google.com/forms/d/e/1FAIpQLSf8EvcXGQUeYtgAlzkHyhP1CC9lDVziNowFZl2wiyImTNrTcA/viewform?usp=sharing",
      certificateId: "basal_insulin_mastery_t2d_module_3",
      instructor: "Brian Lam, RPh, CDE",
      learningObjectives: [
        "Describe the mechanism and pharmacokinetics of once weekly insulin icodec.",
        "Identify who once weekly insulin is appropriate for.",
        "List the potential benefits and risks of using once weekly insulin.",
        "Explain how to initiate an individual on once weekly insulin icodec and how to titrate their dose to glycemic targets.",
        "Discuss the efficacy and safety of insulin icodec in insulin-naive individuals and individuals who were previously on other basal insulin analogues.",
      ],
      steps: [
        {
          type: "video",
          src: "https://vimeo.com/1002925046/d177264b0c",
          videoId: "mod_3-vid_1",
          title:
            "Crash Course on Once Weekly Insulin Icodec - Introduction to Q1",
        },
        {
          type: "long-answer",
          questionId: "long-answer-1",
          question:
            "Who do you think would benefit from once weekly insulin? & Why?",
          title: "Thought session!",
        },
        {
          type: "video",
          src: "https://vimeo.com/1002923441/20725b631b",
          videoId: "mod_3-vid_2",
          title:
            "Crash Course on Once Weekly Insulin Icodec - Introduction to Q1",
        },
        {
          type: "quiz",
          quizId: "mod_3-quiz_1",
          questions: [
            {
              question:
                "George is a 50 year old male living with Type 2 Diabetes. He is currently taking several medications to manage his diabetes including: Tirzepatide 7.5mg weekly, Empagliflozin 25mg weekly, and Metformin 1000mg twice daily. George’s recent HbA1c result came back higher at 8.7%. Given his worsening glycemic management, his endocrinologist recommended he titrate up Tirzepatide further. Unfortunately, Georges reports 7.5mg weekly is the maximum dose that he can tolerate. His endocrinologist instead recommends he start the new once weekly insulin icodec and George agrees…What do you think is an appropriate starting dose for George? And why?",
              options: [
                "10U once daily",
                "70U once weekly",
                "70U once daily",
                "100U once weekly",
              ],
              answer: "70U once weekly",
            },
          ],
        },
        {
          type: "video",
          src: "https://vimeo.com/1002923717/e9353bdccf",
          videoId: "mod_3-vid_3",
          title:
            "Crash Course on Once Weekly Insulin Icodec - Q2 Answer and Q3",
        },
        {
          type: "quiz",
          quizId: "mod_3-quiz_2",
          questions: [
            {
              question:
                "George is a 50 year old male living with Type 2 Diabetes. He is currently taking several medications to manage his diabetes including: Tirzepatide 7.5mg weekly, Empagliflozin 25mg weekly, Metformin 1000mg twice daily, and 25U of insulin glargine U100 daily. George’s recent HbA1c result came back higher at 8.7%. Given his worsening glycemic management, his endocrinologist recommended he titrate up Tirzepatide further. Unfortunately, Georges reports 7.5mg weekly is the maximum dose that he can tolerate. His endocrinologist instead recommends he start the new once weekly insulin icodec and George agrees…Assuming NO loading/addition to the first dose, what do you think is an appropriate starting dose for George? And why?",
              options: [
                "100U once weekly",
                "170U once weekly",
                "180U once weekly",
                "200U once weekly",
              ],
              answer: "180U once weekly",
            },
          ],
        },
        {
          type: "video",
          src: "https://vimeo.com/1002923755/d740da07b9",
          videoId: "mod_3-vid_4",
          title:
            "Crash Course on Once Weekly Insulin Icodec - Q3 Answer and Q4",
        },
        {
          type: "quiz",
          quizId: "mod_3-quiz_3",
          questions: [
            {
              question:
                "George is a 50 year old male living with Type 2 Diabetes. He is currently taking several medications to manage his diabetes including: Tirzepatide 7.5mg weekly, Empagliflozin 25mg weekly, Metformin 1000mg twice daily, and 25U of insulin glargine U100 daily. George’s recent HbA1c result came back higher at 8.7%. Given his worsening glycemic management, his endocrinologist recommended he titrate up Tirzepatide further. Unfortunately, Georges reports 7.5mg weekly is the maximum dose that he can tolerate. His endocrinologist instead recommends he start the new once weekly insulin icodec and George agrees…Assuming that an additional dose of +50% was appropriate for the first dose, what dose of insulin icodec should George take on week 1 and week 2?",
              options: [
                "Week 1: 260U once weekly, Week 2: 180U once weekly",
                "Week 2: 270U once weekly, Week 2: 175U once weekly",
                "Week 3: 260U once weekly, Week 2: 150U once weekly",
                "Week 4: 270U once weekly, Week 2: 150U once weekly",
              ],
              answer: "Week 1: 260U once weekly, Week 2: 180U once weekly",
            },
          ],
        },
        {
          type: "video",
          src: "https://vimeo.com/1002923788/c61b02961e",
          videoId: "mod_3-vid_5",
          title:
            "Crash Course on Once Weekly Insulin Icodec - Q4 Answer and Q5",
        },
        {
          type: "quiz",
          quizId: "mod_3-quiz_4",
          questions: [
            {
              question:
                "George is a 50 year old male living with type 2 diabetes. He recently started on insulin icodec 3 weeks ago for better glycemic management by his endocrinologist. His last weekly dose was 170U. Today (July 3) he is supposed to take his 3rd dose of insulin icodec. Reading the instruction sheets that were provided to him, it says that he should be titrating his insulin icodec dose depending on his mean FBG. His FBG readings over the last week are as follows: July 1 = 7.9 mmol/L, July 2 = 8.2 mmol/L, July 3 = 7.5 mmol/L. What dose of insulin icodec should George take today?",
              options: [
                "150U once weekly",
                "170U once weekly",
                "180U once weekly",
                "190U once weekly",
              ],
              answer: "190U once weekly",
            },
          ],
        },
        {
          type: "video",
          src: "https://vimeo.com/1002923931/d88475c9b4",
          videoId: "mod_3-vid_6",
          title: "Crash Course on Once Weekly Insulin Icodec - Q5 and Q6",
        },
        {
          type: "quiz",
          quizId: "mod_3-quiz_5",
          questions: [
            {
              question:
                "Harleen normally takes her dose of insulin icodec once weekly every Monday. Last week, she lost her last insulin icodec pen on vacation and won’t be able to pick up new medication supplies until Thursday. If Harleen picks up her medication supply on Thursday and takes the dose on Thursday, she will be able to resume her normal weekly scheduled dose on Monday.",
              options: ["True", "False"],
              answer: "True",
            },
          ],
        },
        {
          type: "video",
          src: "https://vimeo.com/1002924059/abf3f89838",
          videoId: "mod_3-vid_7",
          title:
            "Crash Course on Once Weekly Insulin Icodec - Q6 Answer and Q7",
        },
        {
          type: "quiz",
          quizId: "mod_3-quiz_6",
          questions: [
            {
              question:
                "Sonia normally takes her dose of insulin icodec once weekly every Monday. Last week, she lost her last insulin icodec pen on vacation and won’t be able to pick up new medication supplies until Friday. If Sonia picks up her medication supply on Friday and takes the dose on Friday, she will be able to resume her normal weekly scheduled dose on Monday.",
              options: ["True", "False"],
              answer: "False",
            },
          ],
        },
        {
          type: "video",
          src: "https://vimeo.com/1002924593/29ae587d1f",
          videoId: "mod_3-vid_8",
          title:
            "Crash Course on Once Weekly Insulin Icodec - Q7 Answer to End",
        },
        {
          type: "section-done-multi-ceu",
          sectionIndex: 3,
          sectionId: "basal_insulin_mastery_t2d_module_3",
        },
      ],
      status: "available",
    },
  ],
  sponsoredBy:
    "The course has been supported by an unrestricted educational grant from Novo Nordisk Canada.",
};

export default basalInsulinMasterySeries;
