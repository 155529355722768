import { useState, useEffect } from "react";
import {
  Typography,
  Box,
  FormControl,
  Radio,
  RadioGroup,
  FormControlLabel,
} from "@mui/material";
import { updateCoursesForUser } from "../../helpers/source";
import { useAuth } from "../../../contexts/AuthContext";
import { shuffle } from "lodash";
import ReviewQuiz from "./Review";
import { useTranslation } from "react-i18next";
import "./QuizDisplay.scss";

function Quiz({ courseData, onQuizPassed }) {
  const { t } = useTranslation();
  const { currentUser } = useAuth();
  const [currentQuestion, setCurrentQuestion] = useState(null);
  const [selectedAnswer, setSelectedAnswer] = useState(null);
  const [score, setScore] = useState(0);
  const [questions, setQuestions] = useState([]);
  const [quizStarted, setQuizStarted] = useState(false);
  const [quizPassed, setQuizPassed] = useState(false);
  const [userAnswers, setUserAnswers] = useState([]);
  const [showReview, setShowReview] = useState(false);

  const {
    courseName,
    quiz,
    quizPassingScore,
    courseId,
    courseLength,
    tutorial,
  } = courseData;

  useEffect(() => {
    const shuffledQuiz = shuffle(quiz).map((question) => {
      const shuffledAnswers = shuffle(question.answers);
      return { ...question, answers: shuffledAnswers };
    });
    setQuestions(shuffledQuiz);
  }, [quiz]);

  useEffect(() => {
    if (currentQuestion !== null && currentQuestion < questions.length) {
      const currentAnswer = questions[currentQuestion].answers.find(
        (answer) => answer === selectedAnswer
      );
      const isCorrect =
        currentAnswer === questions[currentQuestion].correctAnswer;
      if (isCorrect) {
        setScore((prev) => prev + 1);
      }
    }
  }, [selectedAnswer, currentQuestion, questions]);

  const handleAnswerSelect = (event) => {
    setSelectedAnswer(event.target.value);
  };

  const handleShowReview = () => {
    setShowReview(true);
  };

  const handleStartQuiz = () => {
    setCurrentQuestion(0);
    setQuizStarted(true);
    setQuizPassed(false);
  };

  const handleSubmit = () => {
    setUserAnswers((prev) => [...prev, selectedAnswer]);
    setCurrentQuestion((prev) => prev + 1);
    setSelectedAnswer(null);

    if (currentQuestion === questions.length - 1) {
      const pass = score >= quizPassingScore;
      updateCoursesForUser({
        userId: currentUser.uid,
        courseId,
        courseName,
        score,
        pass,
        // add courseLength only if not tutorial course
        ...(!tutorial && { courseLength }),
      });
      setQuizPassed(pass);
      if (pass) {
        onQuizPassed();
      }
    }
  };

  if (!quizStarted) {
    return (
      <Box className="quiz-display">
        <Typography variant="h4" align="center" gutterBottom>
          {t("quiz.welcome")}
        </Typography>
        <Typography variant="body1" align="center" gutterBottom>
          {t("quiz.instructions", {
            questionCount: questions.length,
            passingPercentage: Math.round(
              (quizPassingScore / questions.length) * 100
            ),
          })}
        </Typography>
        <button
          className="new-button-style primary teal-hover"
          onClick={handleStartQuiz}
          style={{ marginTop: "1rem" }}
        >
          {t("quiz.startQuiz")}
        </button>
      </Box>
    );
  }

  if (currentQuestion === questions.length && !showReview) {
    return (
      <Box className="quiz-display">
        <Typography variant="h4" align="center" gutterBottom>
          {t("quiz.quizFinished")}
        </Typography>
        <Typography variant="h6" align="center" gutterBottom>
          {t("quiz.yourScore", { score: score, total: questions.length })}
        </Typography>
        {quizPassed ? (
          <>
            <Typography variant="h6" align="center" sx={{ color: "green" }}>
              {t("quiz.congratulations")}
            </Typography>
            <button
              onClick={handleShowReview}
              className="new-button-style primary teal-hover"
            >
              {t("quiz.reviewAnswers")}
            </button>
          </>
        ) : (
          <Typography variant="h6" align="center" sx={{ color: "red" }}>
            {t("quiz.notPassed")}
          </Typography>
        )}
      </Box>
    );
  }
  if (showReview) {
    return (
      <ReviewQuiz
        questions={questions}
        userAnswers={userAnswers}
        course={courseData}
        currentUser={currentUser}
      />
    );
  }
  return (
    <Box className="quiz-display">
      <Typography variant="h6" sx={{ mt: 2 }} gutterBottom>
        {questions[currentQuestion].question}
      </Typography>
      <FormControl component="fieldset">
        <RadioGroup value={selectedAnswer} onChange={handleAnswerSelect}>
          {questions[currentQuestion].answers.map((answer) => (
            <FormControlLabel
              key={answer}
              value={answer}
              control={<Radio />}
              label={answer}
              sx={{
                mb: 1,
                "& .MuiRadio-root": { color: "primary.main" },
                textAlign: "left",
              }}
            />
          ))}
        </RadioGroup>
      </FormControl>
      <button
        className="new-button-style primary"
        disabled={!selectedAnswer}
        onClick={handleSubmit}
        style={{ marginTop: "10px" }}
      >
        {currentQuestion === questions.length - 1
          ? t("quiz.finish")
          : t("quiz.next")}
      </button>
    </Box>
  );
}

export default Quiz;
