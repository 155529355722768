import { createSlice } from "@reduxjs/toolkit";
import { onSnapshot, doc } from "firebase/firestore";
import { db } from "../../lib/firebase/config";
export const myPinSlice = createSlice({
  name: "myPin",
  initialState: {
    value: {
      myPin: "",
    },
  },
  reducers: {
    setMyPin: (state, action) => {
      state.value = action.payload;
    },
  },
});
export const getUpdatingMyPin = (userId) => async (dispatch) => {
  const userDocRef = doc(db, "users", userId);
  const unsubscribe = onSnapshot(userDocRef, (doc) => {
    if (doc.exists()) {
      // Document exists, update data
      const updatedUserData = doc.data().myPin;
      dispatch(setMyPin(updatedUserData));
      // Dispatch an action or update local state with updatedUserData
    } else {
      // Document doesn't exist
      console.log("User document no longer exists");
    }
  });
  return unsubscribe;
};

export const { setMyPin } = myPinSlice.actions;

export default myPinSlice.reducer;
