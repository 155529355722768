import { isAPharmacist } from "../helpers/helperFunctions";

export const coursesAsResources = [
  {
    title: "Deprescribing 101: A Practical Session For Pharmacists",
    link: "https://hub.medessist.com/courses/deprescribing-101",
    group: "Courses",
    icon: "course",
    otherKeywords: "deprescribing",
  },
  {
    title: "7 Steps to Make Minor Ailments Easy & Profitable",
    link: "https://hub.medessist.com/courses/7-steps-minor-ailments",
    group: "Courses",
    icon: "course",
  },
  {
    title: "Marketing 101: Digital Marketing for Community Pharmacies (Part 1)",
    link: "https://hub.medessist.com/courses/digital-marketing-part_1",
    group: "Courses",
    icon: "course",
  },
  {
    title:
      "Antimicrobial Stewardship In Primary Care...and How To Review Antimicrobial Therapy",
    link: "https://hub.medessist.com/courses/AMS",
    group: "Courses",
    icon: "course",
    otherKeywords: "antibiotic",
  },
];

export const travelHealthResourceList = [
  {
    title: "CDC Yellow Book (CDC)",
    link: "https://wwwnc.cdc.gov/travel/yellowbook/2020/table-of-contents",
    icon: "website",
    typeOfResource: "provider",
  },
  {
    title:
      "Travel vaccinations and Vaccination recommendatons by destination (Health Canada)",
    link: "https://travel.gc.ca/travelling/health-safety/vaccines",
    icon: "website",
    typeOfResource: "provider",
  },
  {
    title: "Canadian Immunization Guide (Health Canada)",
    link: "https://www.canada.ca/en/public-health/services/canadian-immunization-guide.html",
    icon: "website",
    typeOfResource: "provider",
  },
  {
    title: "CATMAT Statements & Recommendations  (CATMAT)",
    link: "https://www.canada.ca/en/public-health/services/catmat.html",
    icon: "website",
    typeOfResource: "provider",
  },
];

export const paxlovidPatientResources = [
  {
    title: "Your Guide to Taking Paxlovid (Pfizer)",
    link: "https://firebasestorage.googleapis.com/v0/b/medessist-ver2.appspot.com/o/Files%2FResources%2FPatient%20Guide%20to%20Paxlovid.pdf?alt=media&token=a042e191-c5b0-49ca-aef7-b0e33728fa12",
    typeOfResource: "patient",
    group: "Patient Resources",
    icon: "poster",
  },
  {
    title: "COVID-19: Treatment may be right for you (Pfizer)",
    link: "https://firebasestorage.googleapis.com/v0/b/medessist-ver2.appspot.com/o/Files%2FResources%2FCOVID%20Treatment%20checklist.pdf?alt=media&token=cfd5ff53-006d-4ca0-baae-6e49e2b30b7d",
    typeOfResource: "patient",
    group: "Patient Resources",
    icon: "poster",
  },
  {
    title:
      "Therapeutic Management of Adult Patients with COVID-19 (Science Table)",
    link: "https://covid19-sciencetable.ca/wp-content/uploads/2022/02/Clinical-Practice-Guidelines_Update_20220223.pdf",
    group: "Provider Resources",
    icon: "poster",
    typeOfResource: "provider",
  },
  {
    title:
      "Paxlovid - What Prescribers and Pharmacists Need to Know (Science Table)",
    link: "https://covid19-sciencetable.ca/wp-content/uploads/2022/06/NirmatrelvirRitonavir-Paxlovid-What-Prescribers-and-Pharmacists-Need-to-Know-with-Appendix_20220606.pdf",
    group: "Provider Resources",
    icon: "poster",
    typeOfResource: "provider",
  },
  {
    title:
      "(Ontario) Evidence-Based Recommendations on the Use of Nirmatrelvir/ Ritonavir (Paxlovid) for Adults in Ontario (Science Table)",
    link: "https://covid19-sciencetable.ca/wp-content/uploads/2022/02/Evidence-Based-Recommendations-on-the-Use-of-Nirmatrelvir-Ritonavir-Paxlovid-for-Adults-in-Ontario_published_20220223.pdf",
    group: "Provider Resources",
    typeOfResource: "provider",
  },
  {
    title: "Dosing and Patient Selection Guide (Pfizer)",
    link: "https://firebasestorage.googleapis.com/v0/b/medessist-ver2.appspot.com/o/Files%2FResources%2FPfizer%20Dosing%20and%20Patient%20Selection%20Guide.pdf?alt=media&token=fc4d45eb-627f-4d55-88e0-e8732f946f1d",
    group: "Provider Resources",
    icon: "poster",
    typeOfResource: "provider",
  },
  {
    title:
      "Guidance for the prescription of nirmatrelvir/ritonavir (Paxlovid)(Centre for Effective Practice)",
    link: "https://tools.cep.health/tool/covid-19/#guidance-for-the-prescription-of-nirmatrelvir-ritonavir-paxlovidtm",

    group: "Provider Resources",
    typeOfResource: "provider",

    icon: "poster",
  },
  // 	// pfizer says resources expire after a year, so can be removed oct 19/2024 (or earlier if applicable)
  {
    title: "Expiration Date Extention Notice for Paxlovid (Pfizer Canada)",
    link: "https://firebasestorage.googleapis.com/v0/b/medessist-ver2.appspot.com/o/Files%2FResources%2FPaxlovid-Expiry.pdf?alt=media&token=b4fc4c42-616c-45e8-b7fa-d599da8c7499&_gl=1*1qfhqzz*_ga*MTUyNzUxNDI3NC4xNjk2MTY3OTUx*_ga_CW55HF8NVT*MTY5NzczMTI1OC41My4xLjE2OTc3MzEzOTQuMzEuMC4w",
    group: "Provider Resources",
    icon: "poster",
    typeOfResource: "provider",
  },
  // 	// pfizer says resources expire after a year, so can be removed oct 19/2024 (or earlier if applicable)
  {
    title:
      "Involve - A look at the importance of the pharmacists' role in COVID-19 outpatient therapies (Pfizer Canada)",
    link: "https://firebasestorage.googleapis.com/v0/b/medessist-ver2.appspot.com/o/Files%2FResources%2FINVOLVE-course-info.pdf?alt=media&token=0a409980-d33a-43d6-a927-b4b59888d221&_gl=1*wn2qbv*_ga*MTUyNzUxNDI3NC4xNjk2MTY3OTUx*_ga_CW55HF8NVT*MTY5NzczMTI1OC41My4xLjE2OTc3MzQ2OTYuMzEuMC4w",
    group: "Provider Resources",
    icon: "poster",
    typeOfResource: "provider",
  },
  {
    title: "Paxlovid (Pfizer Canada)",
    link: "https://covid-vaccine.canada.ca/info/pdf/paxlovid-pm-en.pdf",
    group: "Monograph",
    typeOfResource: "provider",
  },
];

export const covidVaccineForms = [
  {
    title: "What is a bivalent vaccine? video (FDA)",
    link: "https://www.youtube.com/watch?v=yxrTK737V6c",
    typeOfResource: "patient",
    group: "Patient Resources",
    icon: "video",
  },
  {
    title: " Moderna Spikevax Dosing & Administration PDF (Moderna Canada)",
    link: "https://firebasestorage.googleapis.com/v0/b/medessist-ver2.appspot.com/o/Files%2FSpikevax_Bivalent_DASH_EN.pdf?alt=media&token=f408a869-fe60-4418-bf41-d5e4ed064454",
    group: "Provider Resources",
    icon: "poster",
    typeOfResource: "provider",
  },
  // {/* // pfizer says resources expire after a year, so can be removed oct 19/2024 (or earlier if applicable) */}
  {
    title: "Pfizer XBB.1.5 Dosage and Storage Guide (Pfizer Canada)",
    link: "https://firebasestorage.googleapis.com/v0/b/medessist-ver2.appspot.com/o/Files%2FResources%2FPfizer-COVID-19-Vaccine_XBB_Quick-Reference-Guide.pdf?alt=media&token=004f3f46-3955-4d01-bf66-c11368de4142&_gl=1*y9pauw*_ga*MTUyNzUxNDI3NC4xNjk2MTY3OTUx*_ga_CW55HF8NVT*MTY5NzczMTI1OC41My4xLjE2OTc3MzI4NzkuNjAuMC4w",
    group: "Provider Resources",
    icon: "poster",
    typeOfResource: "provider",
  },
];

export const patientPneumococcalResources = [
  {
    title: "50 or Over? Pneumococcal Handout (Pfizer)",
    link: "https://firebasestorage.googleapis.com/v0/b/medessist-ver2.appspot.com/o/Files%2FResources%2FPrevnar20PatientBrochure.pdf?alt=media&token=fb9dc98b-cf49-4207-8232-bd54eb06946f",
    group: "Patient Resources",
    icon: "poster",
    typeOfResource: "patient",
  },
  {
    title: "Pneumococcal vaccine: Canadian Immunization Guide (Health Canada)",
    link: "https://www.canada.ca/en/public-health/services/publications/healthy-living/canadian-immunization-guide-part-4-active-vaccines/page-16-pneumococcal-vaccine.html",
    group: "Provider Resources",
    icon: "website",
    typeOfResource: "provider",
  },
  {
    title:
      "Public health level recommendations on the use of pneumococcal vaccines in adults (NACI)",
    link: "https://www.canada.ca/en/public-health/services/immunization/national-advisory-committee-on-immunization-naci/public-health-level-recommendations-use-pneumococcal-vaccines-adults-including-use-15-valent-20-valent-conjugate-vaccines.html",
    group: "Provider Resources",
    icon: "website",
    typeOfResource: "provider",
  },
  {
    title: "Prevnar 20 NACI Leaflet (Pfizer)",
    link: "https://firebasestorage.googleapis.com/v0/b/medessist-ver2.appspot.com/o/Files%2FResources%2FPrevnar20HCPLeaflet.pdf?alt=media&token=62b1f322-4f45-4923-8518-2530126b4bc9",
    group: "Provider Resources",
    icon: "poster",
    typeOfResource: "provider",
  },
  {
    title: "Healthcare Professional Leaflet (Pfizer)",
    link: "https://firebasestorage.googleapis.com/v0/b/medessist-ver2.appspot.com/o/Files%2FResources%2FPrevnar20_NACI%20Leaflet-links%20active.pdf?alt=media&token=836cfaf3-ecb0-4482-a06d-cc6c6021b969",
    group: "Provider Resources",
    icon: "poster",
    typeOfResource: "provider",
  },
];

export const HPVPatientResources = [
  {
    title: "Brochure for Cancers Caused by HPV - PDF (SOGC)",
    link: "https://www.hpvinfo.ca/wp-content/uploads/2019/12/SOGC-HPVBrochure-EN-f-web-1.pdf",
    typeOfResource: "patient",
    icon: "poster",
  },
  {
    title: "HPV Prevention - Website (hpvinfo.ca)",
    link: "https://www.hpvinfo.ca/prevention/",
    typeOfResource: "patient",
    icon: "website",
  },
  {
    title:
      "Preventing Against HPV and Related Cancers - 5 Minute Video (Canadian Health and Family)",
    link: "https://www.healthandfamily.ca/show/protecting-against-hpv-and-related-cancers/",
    typeOfResource: "patient",
    icon: "website",
  },
  {
    title: "Commonly Asked Questions About HPV - PDF (immunize.org)",
    link: "https://www.immunize.org/catg.d/p4207.pdf",
    typeOfResource: "patient",
    icon: "poster",
  },

  {
    title: "HPV In Teens - PDF (immunize.org)",
    link: "https://www.immunize.org/catg.d/p4250.pdf",
    typeOfResource: "patient",
    icon: "poster",
  },
  {
    title: "HPV In Adults - PDF (immunize.org)",
    link: "https://www.immunize.org/catg.d/p4251.pdf",
    typeOfResource: "patient",
    icon: "poster",
  },
];

export const HPVProviderResources = [
  {
    title: "The Pharmacist's Role in Prevention of HPV-Related Cancers",
    link: "https://www.ncbi.nlm.nih.gov/pmc/articles/PMC8282915/",
    typeOfResource: "provider",
  },
  {
    title: "Summary of HPV Recommendations (NACI)",
    link: "https://nccid.ca/wp-content/uploads/sites/2/2017/10/hpv_vaccination1.pdf",
    icon: "poster",
    typeOfResource: "provider",
  },
  {
    title: "Gardasil-9 Monograph (Merck Canada)",
    link: "https://www.merck.ca/static/pdf/GARDASIL_9-PM_E.pdf",
    icon: "poster",
    typeOfResource: "provider",
  },
  {
    title: "Cervarix Monograph (GSK Canada)",
    link: "https://ca.gsk.com/media/589880/cervarix.pdf",
    typeOfResource: "provider",
  },
];

export const shinglesPatientResources = [
  {
    title: "Did you know? Shingles Handout - Image (Immunize Canada)",
    link: "https://immunize.ca/sites/default/files/Resource%20and%20Product%20Uploads%20(PDFs)/Products%20and%20Resources/Shingles/Infographic/2014/shingles2014_infographic_e.png",
    icon: "poster",
    typeOfResource: "patient",
  },
  {
    title: "Your Best Shot – Shingles Vaccines Video (US Department of Health)",
    link: "https://www.youtube.com/watch?v=l-HhmH8gC9k&ab_channel=U.S.DepartmentofHealthandHumanServices",
    icon: "video",
    typeOfResource: "patient",
  },
  {
    title: "Shingrix Patient Information PDF (GSK Canada)",
    link: "https://ca.gsk.com/media/6257/shingrix-pi-en.pdf",
    icon: "poster",
    typeOfResource: "patient",
  },
];

export const shinglesGuidelines = [
  {
    title:
      "Herpes Zoster (Shingles) Vaccine: Canadian Immunization Guide (Health Canada)",
    link: "https://www.canada.ca/en/public-health/services/publications/healthy-living/canadian-immunization-guide-part-4-active-vaccines/page-8-herpes-zoster-(shingles)-vaccine.html",
    typeOfResource: "provider",
  },
  {
    title: "Summary of NACI updates on Herpes Zoster PDF (NACI)",
    link: "https://www.canada.ca/content/dam/phac-aspc/documents/services/reports-publications/canada-communicable-disease-report-ccdr/monthly-issue/2018-44/issue-9-september-6-2018/ccdrv44i09a06-eng.pdf",
    typeOfResource: "provider",
  },
  {
    title: "NACI Guideline on Herpes Zoster PDF (NACI)",
    link: "https://www.canada.ca/content/dam/phac-aspc/documents/services/publications/healthy-living/updated-recommendations-use-herpes-zoster-vaccines-eng.pdf",
    typeOfResource: "provider",
  },
];

export const shinglesMonograph = [
  {
    title: "Shingrix Monograph PDF (GSK Canada)",
    link: "https://ca.gsk.com/media/6259/shingrix-pm-en.pdf",
    typeOfResource: "provider",
  },
  {
    title: "Zostavax II Monograph PDF (Merck Canada)",
    link: "https://pdf.hres.ca/dpd_pm/00042265.PDF",
    typeOfResource: "provider",
  },
];

export const miscellaneousResourceList = (profession) => {
  return [
    {
      title: "SwitchRX - The online medication switching tool",
      link: "https://www.switchrx.com/",
      group: "Mental Health",
      otherKeywords: "antidepressants, antipsychotics",
      typeOfResource: "provider",
    },
    {
      title: "Center for Effective Practice - Clinical Tools and Resources",
      link: "https://cep.health/tools/",
      group: "General Resources",
      typeOfResource: "provider",
    },
    {
      title: "Merck Manuals",
      link: "https://www.merckmanuals.com/en-ca/professional",
      group: "General Resources",
      typeOfResource: "provider",
    },
    {
      title: "Choosing Wisely Canada",
      link: "https://choosingwiselycanada.org",
      group: "General Resources",
      typeOfResource: "provider",
    },
    {
      title: "MyHealth Alberta",
      link: "https://myhealth.alberta.ca/",
      group: "General Resources",
      typeOfResource: "provider",
    },
    {
      title: "Health Link BC",
      link: "https://www.healthlinkbc.ca/",
      group: "General Resources",
      typeOfResource: "provider",
    },

    {
      title: "Antimicrobial Stewardship (University Health Network)",
      link: "https://www.antimicrobialstewardship.com/",
      group: "Infectious Disease",
      otherKeywords: "antibiotic",
      typeOfResource: "provider",
    },
    {
      title: "Bugs & Drugs",
      link: "https://www.bugsanddrugs.org/",
      group: "Infectious Disease",
      otherKeywords: "antibiotic",
      typeOfResource: "provider",
    },
    {
      title:
        "Health Canada - Sexually transmitted and blood-borne infections: Guides for health professionals",
      link: "https://www.canada.ca/en/public-health/services/infectious-diseases/sexual-health-sexually-transmitted-infections/canadian-guidelines.html",
      typeOfResource: "provider",
      group: "Infectious Disease",
    },
    {
      title: "Thrombosis Canada Patient Information Sheets",
      link: "https://thrombosiscanada.ca/patients_and_caregivers/about_thrombosis/Patient_Info_sheets",
      group: "Diabetes and Cardiovascular Health",
      typeOfResource: "provider",
    },
    {
      title: "Louisville Kidney Drug Book for adult and pediatric renal dosing",
      link: "https://kdpnet.kdp.louisville.edu/",
      group: "Renal",
      otherKeywords: "kidney",
      typeOfResource: "provider",
    },
    {
      title: "Ontario Renal Network - KidneyWise",
      link: "https://www.ontariorenalnetwork.ca/en/kidney-care-resources/clinical-tools/primary-care-tools/kidneywise",
      group: "Renal",
      otherKeywords: "kidney",
      typeOfResource: "provider",
    },
    {
      title: "Diabetes Canada Guidelines",
      link: "https://guidelines.diabetes.ca/",
      group: "Diabetes and Cardiovascular Health",
      typeOfResource: "provider",
    },
    {
      title: "Canadian Cardiovascular Society Guidelines",
      link: "https://ccs.ca/guidelines-and-clinical-practice-update-library/",
      group: "Diabetes and Cardiovascular Health",
      typeOfResource: "provider",
    },
    {
      title: "Hypertension Canada",
      link: "https://hypertension.ca/",
      group: "Diabetes and Cardiovascular Health",
      typeOfResource: "provider",
    },
    {
      title: "Probiotic Chart",
      link: "http://www.probioticchart.ca/?utm_source=intro_pg&utm_medium=civ&utm_campaign=CDN_CHART",
      group: "OTC and Natural Health Products",
      typeOfResource: "provider",
    },
    {
      title:
        "Memorial Sloane Kettering Cancer Care: About Herbs, Botanicals & Other Products",
      link: "https://www.mskcc.org/cancer-care/diagnosis-treatment/symptom-management/integrative-medicine/herbs/search",
      group: "OTC and Natural Health Products",
      typeOfResource: "provider",
    },
    {
      title: "Health Canada - Licensed Natural Health Products Database",
      link: "https://www.canada.ca/en/health-canada/services/drugs-health-products/natural-non-prescription/applications-submissions/product-licensing/licensed-natural-health-products-database.html",
      group: "OTC and Natural Health Products",
      typeOfResource: "provider",
    },
    {
      title: "MDCalc - Medical Calculations",
      link: "https://www.mdcalc.com/",
      group: "Tools",
      otherKeywords: "bmi",
      typeOfResource: "provider",
    },
    {
      title: "Deprescribing Guidelines and Algorithms",
      link: "https://deprescribing.org/resources/deprescribing-guidelines-algorithms/",
      group: "Tools",
      typeOfResource: "provider",
    },
    {
      title: "Sick Kids - About Kids Health",
      link: "https://www.aboutkidshealth.ca/",
      group: "Pediatric",
      typeOfResource: "provider",
    },
    {
      title: "Canadian Paediatric Society",
      link: "https://cps.ca/",
      group: "Pediatric",
      typeOfResource: "provider",
    },
    {
      title:
        "Society of Obstetricians and Gynacologists of Canada - Pregnancy Info",
      link: "https://www.pregnancyinfo.ca/#smooth-scroll-top",
      group: "Pregnancy",
      typeOfResource: "provider",
    },
    {
      title: "Health Canada - The Sensible Guide to a Healthy Pregnancy",
      link: "https://www.canada.ca/content/dam/phac-aspc/documents/services/health-promotion/healthy-pregnancy/64-03-16-1758-Sensible%20Guide%20to%20Healthy%20Pregnancy-EN-Web-final-v3.pdf",
      group: "Pregnancy",
      typeOfResource: "provider",
    },
    {
      title: "MotherToBaby",
      link: "https://mothertobaby.org/healthcare-professionals/",
      group: "Pregnancy",
      typeOfResource: "provider",
    },
    {
      title:
        "Society of Obstetricians and Gynacologists of Canada - Menopause and U",
      link: "https://www.menopauseandu.ca/",
      group: "Women's Health",
      typeOfResource: "provider",
    },
    {
      title: "Canadian Menopause Society",
      link: "https://www.sigmamenopause.com/consumers/publications",
      group: "Women's Health",
      typeOfResource: "provider",
    },
    {
      title: "Canadian Headache Society - Clinical Guidelines",
      link: "https://www.headachesociety.ca/clinicalguidance",
      group: "Pain",
      typeOfResource: "provider",
    },
    {
      title: "Migraine Canada",
      link: "https://migrainecanada.org",
      group: "Pain",
      typeOfResource: "provider",
    },
    {
      title: "National Pain Centre Guidelines (McMaster University)",
      link: "https://npc.healthsci.mcmaster.ca/guidelines/",
      group: "Pain",
      typeOfResource: "provider",
    },
    {
      title: "Rheum Tutor",
      link: "https://www.rheumtutor.com/",
      group: "Pain",
      typeOfResource: "provider",
    },
    {
      title: "American College of Rheumatology",
      link: "https://rheumatology.org",
      group: "Pain",
      typeOfResource: "provider",
    },
    {
      title: "Canadian Lung Association - Clinical Guidelines",
      link: "https://www.lung.ca/health-professionals/clinical-tools-and-resources",
      group: "Respiratory Conditions",
      typeOfResource: "provider",
    },
    {
      title: "Canadian Thoracic Society",
      link: "https://cts-sct.ca/guideline-library/",
      group: "Respiratory Conditions",
      typeOfResource: "provider",
    },
    {
      title: "Global Initiative for Asthma (GINA) Reports",
      link: "https://ginasthma.org/reports/",
      group: "Respiratory Conditions",
      typeOfResource: "provider",
    },
    {
      title:
        "Global Initiative for Chronic Obstructive Lung Disease (GOLD) Reports",
      link: "https://goldcopd.org/2023-gold-report-2/",
      group: "Respiratory Conditions",
      typeOfResource: "provider",
    },
    {
      title: "Canadian Dermatology Association",
      link: "https://dermatology.ca/",
      group: "Dermatological",
      typeOfResource: "provider",
    },
    {
      title: "DermNet",
      link: "https://dermnetnz.org/",
      group: "Dermatological",
      typeOfResource: "provider",
    },
    {
      title: "Eczema Society of Canada",
      link: "https://eczemahelp.ca/eczema-resources/",
      group: "Dermatological",
      typeOfResource: "provider",
    },
    {
      title: "The Canadian Continence Foundation - Patient Resources",
      link: "https://www.canadiancontinence.ca/",
      group: "Sexual Health and Genitourinary Disorders",
      typeOfResource: "provider",
    },
    {
      title: "Society of Obstetricians and Gynacologists of Canada - Sex and U",
      link: "https://www.sexandu.ca/",
      group: "Sexual Health and Genitourinary Disorders",
      typeOfResource: "provider",
    },
    {
      title: "Trans Care BC  - Transgender Care Toolkit",
      link: "http://www.phsa.ca/transcarebc/Documents/HealthProf/Primary-Care-Toolkit.pdf",
      group: "LGBTQ",
      typeOfResource: "provider",
    },
    {
      title: "Rainbow Health Ontario",
      link: "https://www.rainbowhealthontario.ca/",
      group: "LGBTQ",
      typeOfResource: "provider",
    },
    ...(isAPharmacist(profession)
      ? [
          {
            title: "StudentRX - University Insurance Plan Information (CA)",
            link: "http://www.studentrx.ca/",
            group: "Billing and Insurance",
            typeOfResource: "provider",
          },
        ]
      : []),
  ];
};
export const resourcesList = (profession) => {
  return [
    {
      resourceType: "Courses",
      linkArrays: coursesAsResources,
    },
    {
      resourceType: "General Resources",
      linkArrays: miscellaneousResourceList(profession),
    },
    {
      resourceType: "Devices",
    },
    {
      resourceType: "COVID-19 Vaccines",
      linkArrays: covidVaccineForms,
    },
    {
      resourceType: "COVID-19 Oral Treatment",
      linkArrays: paxlovidPatientResources,
    },
    {
      resourceType: "Travel Health",
      linkArrays: travelHealthResourceList,
    },
    {
      resourceType: "Pneumococcal Prevention",
      linkArrays: patientPneumococcalResources,
    },
  ];
};
