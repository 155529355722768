import { Popover, Typography } from "@mui/material";
import { React, useState } from "react";
// import AIConversations from "./AIConversations";
import FlareIcon from "@mui/icons-material/Flare";
import "../ai/AI.scss";
import AIConversations from "./AIConversations";
export default function AIButton({ handleOpenAIButton, openAI }) {
  const handleClose = () => {
    handleOpenAIButton();
  };

  return (
    <div
      style={{
        position: "absolute",
        right: "6em",
        bottom: "1.3em",
        zIndex: "99999999999999",
      }}
    >
      <button className="ai-button" onClick={handleOpenAIButton}>
        <span class="icon">
          <FlareIcon />
        </span>
        <span class="text">MedEssist AI</span>
      </button>

      <Popover
        tabIndex={0}
        open={openAI}
        onClose={handleClose}
        anchorReference="anchorPosition"
        anchorPosition={{ top: 0, left: 30000 }}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        disableAutoFocus
        disableEnforceFocus
        disableRestoreFocus
      >
        <Typography sx={{ p: 2 }}>
          <AIConversations openAI={openAI} />
        </Typography>
      </Popover>
    </div>
  );
}
