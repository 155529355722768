import React from "react";
import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormHelperText,
} from "@mui/material";

function ProvinceSelector({ error, setProvince, province, country }) {
  const handleChange = (event) => {
    setProvince(event.target.value);
  };

  const fieldStyle = {
    marginTop: 10,
  };

  const provinces = [
    "Alberta",
    "British Columbia",
    "Manitoba",
    "New Brunswick",
    "Newfoundland and Labrador",
    "Northwest Territories",
    "Nova Scotia",
    "Nunavut",
    "Ontario",
    "Prince Edward Island",
    "Quebec",
    "Saskatchewan",
    "Yukon",
  ];
  const states = [
    "Alabama",
    "Alaska",
    "Arizona",
    "Arkansas",
    "California",
    "Colorado",
    "Connecticut",
    "Delaware",
    "Florida",
    "Georgia",
    "Hawaii",
    "Idaho",
    "Illinois",
    "Indiana",
    "Iowa",
    "Kansas",
    "Kentucky",
    "Louisiana",
    "Maine",
    "Maryland",
    "Massachusetts",
    "Michigan",
    "Minnesota",
    "Mississippi",
    "Missouri",
    "Montana",
    "Nebraska",
    "Nevada",
    "New Hampshire",
    "New Jersey",
    "New Mexico",
    "New York",
    "North Carolina",
    "North Dakota",
    "Ohio",
    "Oklahoma",
    "Oregon",
    "Pennsylvania",
    "Rhode Island",
    "South Carolina",
    "South Dakota",
    "Tennessee",
    "Texas",
    "Utah",
    "Vermont",
    "Virginia",
    "Washington",
    "West Virginia",
    "Wisconsin",
    "Wyoming",
  ];
  return (
    <FormControl fullWidth style={fieldStyle} error={!province && error}>
      <InputLabel id="province-label">
        {country === "United States" ? "State" : "Province"}
      </InputLabel>
      <Select
        labelId="province-label"
        id="province-select"
        value={province}
        onChange={handleChange}
        label={country === "United States" ? "State" : "Province"}
      >
        <MenuItem value="">
          <em>None</em>
        </MenuItem>
        {country === "United States"
          ? states.map((state) => (
              <MenuItem key={state} value={state}>
                {state}
              </MenuItem>
            ))
          : provinces.map((province) => (
              <MenuItem key={province} value={province}>
                {province}
              </MenuItem>
            ))}
      </Select>
      {!province && error ? (
        <FormHelperText>This field is required.</FormHelperText>
      ) : null}
    </FormControl>
  );
}

export default ProvinceSelector;
