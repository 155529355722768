import React from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";

const TabsComponent = ({
  selectedSection,
  setSelectedSection,
  resourcesData,
  courseData,
  tutorial,
}) => {
  return (
    <Tabs
      value={selectedSection}
      onChange={(event, newValue) => setSelectedSection(newValue)}
      variant="scrollable"
      scrollButtons="auto"
      sx={{ maxWidth: "fit-content", overflowX: "auto" }}
      className="course-navbar"
    >
      <Tab label="About" value="about" sx={{ marginRight: "10px" }} />
      {!tutorial && <Tab label="Presenter" value="instructor" />}
      {resourcesData[courseData.courseName] &&
        resourcesData[courseData.courseName].length > 0 && (
          <Tab
            label="Resources"
            value="resources"
            sx={{ marginLeft: "10px" }}
          />
        )}
    </Tabs>
  );
};

export default TabsComponent;
