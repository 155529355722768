import React, { useState } from "react";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { IconButton, Menu, MenuItem } from "@mui/material";
import SendEmailModal from "../emailAndText/SendEmailModal";
import SendTextModal from "../emailAndText/SendTextModal";
import QRModal from "./QRModal";
import { actionCounter } from "../helpers/source";

export default function MobileCopyAndEmailResourceButton(props) {
  const { linkObj, resourceType } = props;

  const [showSendEmailModal, setShowSendEmailModal] = useState(false);
  const [showSendTextModal, setShowSendTextModal] = useState(false);
  const [anchorEl, setAnchorEl] = useState();
  const [showQRModal, setShowQRModal] = useState(false);
  const [qrLink, setqrLink] = useState();
  const handleDropdownOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };
  return (
    <div className="email-text-buttons-mobile">
      <QRModal
        setShowQRModal={setShowQRModal}
        showQRMOodal={showQRModal}
        link={qrLink}
      />
      <SendEmailModal
        showSendEmailModal={showSendEmailModal}
        setShowSendEmailModal={setShowSendEmailModal}
        message={`
        <a href=${linkObj?.link} rel="noopener noreferrer" target="_blank">${linkObj?.title}</a>`}
      />{" "}
      <SendTextModal
        showSendTextModal={showSendTextModal}
        setShowSendTextModal={setShowSendTextModal}
        message={linkObj?.link}
      />
      <IconButton
        aria-label="more"
        aria-controls="profile-menu"
        aria-haspopup="true"
        onClick={(e) => handleDropdownOpen(e)}
      >
        <MoreVertIcon />
      </IconButton>
      <Menu
        id="profile-menu"
        keepMounted
        anchorEl={anchorEl}
        open={anchorEl}
        onClose={() => setAnchorEl()}
      >
        <MenuItem
          onClick={() => {
            setShowSendEmailModal(true);
            setAnchorEl();
            if (
              linkObj?.title.includes("Aerochamber") &&
              resourceType === "Devices"
            ) {
              actionCounter("trudell", "aerochamberSendEmailClicked");
            }
          }}
        >
          Email Link
        </MenuItem>
        <MenuItem
          onClick={() => {
            setShowSendTextModal(true);
            setAnchorEl();
            if (
              linkObj?.title.includes("Aerochamber") &&
              resourceType === "Devices"
            ) {
              actionCounter("trudell", "aerochamberSendTextClicked");
            }
          }}
        >
          Text Link
        </MenuItem>
        <MenuItem
          onClick={() => {
            setShowQRModal(true);
            setAnchorEl();
            setqrLink(linkObj?.link);
          }}
        >
          QR Code
        </MenuItem>
      </Menu>
    </div>
  );
}
