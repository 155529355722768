import React, { useState, useEffect } from "react";
import { ClickAwayListener } from "@mui/material";
import { Box } from "@mui/material";
import VisibilityIcon from "@mui/icons-material/Visibility";
import DOMPurify from "dompurify";

export default function SourcesTextPopup(props) {
  const { sources, selectedSources } = props;

  const [allSourceTextFromTheLink, setAllSourceTextFromTheLink] = useState();
  const [open, setOpen] = useState(false);

  // gets all the sourceText from the url of the selectedSources

  useEffect(() => {
    const allSourcesText = [];

    sources.forEach((sourceObj) => {
      if (sourceObj?.source === selectedSources?.source) {
        allSourcesText.push(sourceObj?.source_text);
      }
      setAllSourceTextFromTheLink(allSourcesText);
    });
  }, [selectedSources, sources]);

  const handleClick = () => {
    setOpen((prev) => !prev);
  };

  const handleClickAway = () => {
    setOpen(false);
  };
  return (
    <span
      style={{
        zIndex: "99999999999999",
      }}
    >
      <ClickAwayListener onClickAway={handleClickAway}>
        <span
          sx={{
            display: "contents",
          }}
        >
          <VisibilityIcon onClick={handleClick} style={{ color: "grey" }} />
          {open ? (
            <Box
              sx={{
                background: "#fff7e0f5",
                height: "30vh",
                padding: "1rem",
                overflow: "scroll",
                overflowX: "hidden",
                borderRadius: "20px",
                margin: "10px",
              }}
            >
              {allSourceTextFromTheLink?.length > 0 &&
                allSourceTextFromTheLink.map((sourceText, index) => (
                  <>
                    ...
                    <span
                      dangerouslySetInnerHTML={{
                        __html: DOMPurify.sanitize(
                          sourceText.replace(":\n", "")
                        ),
                      }}
                    />
                    ...
                    {allSourceTextFromTheLink?.length > 1 &&
                      index + 1 !== allSourceTextFromTheLink?.length && <hr />}
                  </>
                ))}
            </Box>
          ) : null}
        </span>
      </ClickAwayListener>
    </span>
  );
}
