const learningResources = {
  // "How to Fish": [
  //   {
  //     type: "blog",
  //     title: "The Ultimate Guide to Fishing",
  //     link: "https://www.blog.com/fishing_guide",
  //   },
  //   {
  //     type: "poster",
  //     title: "Fishing Techniques Poster",
  //     link: "https://www.resources.com/fishing_poster.pdf",
  //   },
  // ],
  // "Pharmacy Course": [
  //   {
  //     type: "blog",
  //     title: "Understanding Pharmacy: A Comprehensive Guide",
  //     link: "https://www.blog.com/pharmacy_guide",
  //   },
  //   {
  //     type: "ebook",
  //     title: "Pharmacy Basics",
  //     link: "https://www.resources.com/pharmacy_basics.pdf",
  //   },
  // ],
  "Deprescribing 101": [
    {
      type: "website",
      title: "BEERS List",
      link: "https://www.ismp-canada.org/beers_list/#l=tab2",
    },
    {
      type: "website",
      title: "Anticholinergic Burden",
      link: "https://www.acbcalc.com/",
    },
    {
      type: "journal",
      title: "Drug Burden Index",
      link: "https://pubmed.ncbi.nlm.nih.gov/27909739/",
    },
    {
      type: "journal",
      title: "STOPP/START",
      link: "https://www.ncbi.nlm.nih.gov/pmc/articles/PMC4339726/",
    },
    {
      type: "website",
      title: "Choosing Wisely",
      link: "https://choosingwiselycanada.org/",
    },
    {
      type: "website",
      title: "Deprescribing.org",
      link: "https://deprescribing.org/",
    },
    {
      type: "website",
      title: "Taper MD",
      link: "https://tapermd.com/tapering-resources/",
    },
    {
      type: "website",
      title: "Medstopper.com",
      link: "https://medstopper.com/",
    },
    {
      type: "journal",
      title: "Deprescribing Patient Handout - JAMA Internal Med",
      link: "https://jamanetwork.com/journals/jamainternalmedicine/fullarticle/2805583",
    },
    {
      type: "website",
      title: "NSW Therapeutic Advisory Group Inc.",
      link: "https://nswtag.org.au/deprescribing-tools",
    },
    {
      type: "website",
      title: "Primary Health Tasmania",
      link: "https://www.primaryhealthtas.com.au/resources/deprescribing-resources/",
    },
  ],
};

export default learningResources;
