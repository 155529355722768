import React, { useEffect, useState, useRef } from "react";
import {
  Dialog,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
  Hidden,
  Typography,
} from "@mui/material";
import { jsPDF } from "jspdf";
import html2canvas from "html2canvas";
import CourseCertificate from "../certificate/CourseCertificate";
import ModuleParticipationCertificate from "../certificate/ModuleParticipationCertificate";
import { useTranslation } from "react-i18next";

export default function CertificateDialog({
  openCertModal,
  closeCertificateModal,
  userObject,
  course,
  multiCreditCourseDetails,
}) {
  const [selectedCourse, setSelectedCourse] = useState(null);
  const certificateRef = useRef(null);
  const { t } = useTranslation();

  useEffect(() => {
    setSelectedCourse(course);
  }, [course]);

  const downloadCertificate = async (certificateRef) => {
    const element = certificateRef.current;
    const canvas = await html2canvas(element, {
      allowTaint: true,
      useCORS: true,
      scale: 5,
    });
    const data = canvas.toDataURL("image/png");
    const pdf = new jsPDF("l", "pt", [canvas.width, canvas.height]);

    const width = pdf.internal.pageSize.getWidth();
    const height = pdf.internal.pageSize.getHeight();
    const imgWidth = width;
    const imgHeight = height;
    const imgPosX = 0;
    const imgPosY = 0;
    pdf.addImage(data, "PNG", imgPosX, imgPosY, imgWidth, imgHeight);
    pdf.save(selectedCourse.courseName);
  };

  const isMultiCreditCourse = selectedCourse?.isThisAModuleThatGetsACredit;

  return (
    <Dialog
      open={openCertModal}
      onClose={closeCertificateModal}
      fullWidth
      maxWidth="lg"
    >
      <DialogContent
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <DialogContentText>
          {isMultiCreditCourse ? (
            <ModuleParticipationCertificate
              name={`${userObject?.firstName} ${userObject?.lastName}`}
              licenseNumber={userObject?.licenseNumber}
              module={selectedCourse}
              certificateRef={certificateRef}
              completedDate={new Date(selectedCourse.date)}
            />
          ) : (
            <CourseCertificate
              name={`${userObject?.firstName} ${userObject?.lastName}`}
              course={selectedCourse}
              certificateRef={certificateRef}
              licenseNumber={userObject?.licenseNumber}
            />
          )}
        </DialogContentText>
      </DialogContent>
      <Hidden mdUp>
        <Typography sx={{ textAlign: "center", padding: 2 }}>
          {t("postQuiz.certificateInfo")}
        </Typography>
      </Hidden>

      <DialogActions
        sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}
      >
        <Button onClick={() => downloadCertificate(certificateRef)}>
          {t("courseCertificate.downloadCertificate")}
        </Button>
        <Button onClick={closeCertificateModal} autoFocus>
          {t("courseCertificate.close")}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
