import React, { useState } from "react";
import { Card, CardContent } from "@mui/material";
import HeadphonesIcon from "@mui/icons-material/Headphones";
import PodcastsLinks from "../podcasts/PodcastsLinks";

function VideoAudioSection({ courseData, playerRef, enrolled, currentUser }) {
  const { courseName, vimeoId, searchieId, audioLink, loomURL } = courseData;
  const [displayAudio, setDisplayAudio] = useState(false);

  const videoStyle = {
    opacity: enrolled ? 1 : 0.5,
    pointerEvents: enrolled && currentUser ? "auto" : "none",
  };

  return (
    <div style={videoStyle}>
      <Card variant="outlined" className="card-container-border">
        <CardContent>
          {vimeoId && (
            <div style={{ height: "400px" }}>
              <iframe
                ref={playerRef}
                src={`https://player.vimeo.com/video/${vimeoId}`}
                style={{
                  width: "100%",
                  height: "100%",
                  border: "0",
                }}
                frameBorder="0"
                allow="autoplay; fullscreen; picture-in-picture"
                allowFullScreen
                title="Video"
              />
            </div>
          )}
          {/* For Videos hosted on loom */}
          {loomURL && (
            <div style={{ height: "400px" }}>
              <iframe
                ref={playerRef}
                src={`${loomURL}`}
                style={{
                  width: "100%",
                  height: "100%",
                  border: "0",
                }}
                frameBorder="0"
                allow="autoplay; fullscreen; picture-in-picture"
                allowFullScreen
                title="Video"
              />
            </div>
          )}
          {searchieId && (
            <div className="vimeo-container">
              <iframe
                id={`searchie-${searchieId}`}
                src={`https://app.searchie.io/file/${searchieId}/embed`}
                frameBorder="0"
                allowFullScreen
                title={`Introducing ${courseName}`}
                className="vimeo-embed"
              />
            </div>
          )}
          {audioLink && (
            <div className="audio-section">
              <button
                className="listen-audio-button"
                onClick={() => setDisplayAudio(true)}
              >
                <HeadphonesIcon sx={{ marginRight: "8px" }} />{" "}
                <span style={{ color: "gray" }}>Listen to Audio Instead</span>
              </button>
              {displayAudio && (
                <>
                  <iframe
                    src={audioLink}
                    title="Podcast"
                    className="audio-container"
                  />
                  <div style={{ marginTop: "1rem" }}>
                    <PodcastsLinks />
                  </div>
                </>
              )}
            </div>
          )}
        </CardContent>
      </Card>
    </div>
  );
}

export default VideoAudioSection;
