import React from "react";
import {
  Card,
  CardContent,
  Typography,
  CircularProgress,
  Divider,
} from "@mui/material";
import { List, ListItem, ListItemIcon, ListItemText } from "@mui/material";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { useNavigate } from "react-router-dom";
import AutoAwesomeIcon from "@mui/icons-material/AutoAwesome";
import { useTranslation } from "react-i18next";

function EnrollmentCard({
  courseData,
  isEnrollmentFetched,
  enrolled,
  isLoadingEnrollment,
  handleCourseEnrollment,
}) {
  const { t } = useTranslation();
  const { courseName, description, learningObjectives, registration } =
    courseData;
  const navigate = useNavigate();
  const handleBackClick = () => {
    navigate("/courses");
  };

  if (!isEnrollmentFetched) {
    return null;
  }

  if (!isEnrollmentFetched) {
    return null;
  }

  return (
    <Card
      variant="outlined"
      sx={{
        maxWidth: 600,
        margin: "auto",
        mt: 5,
        p: 3,
        borderRadius: 10,
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <CardContent style={{ width: "90%" }}>
        <Typography
          variant="h5"
          component="div"
          gutterBottom
          sx={{ textAlign: "left", fontWeight: "bold", mb: 2 }}
        >
          {courseName}
        </Typography>
        <Typography variant="body1" gutterBottom>
          {description}
        </Typography>
        <Divider variant="middle" />
        {learningObjectives && (
          <>
            <Typography
              variant="h6"
              component="h3"
              gutterBottom
              sx={{
                marginTop: "20px",
                fontWeight: "bold",
                fontSize: "1.2rem",
              }}
            >
              {t("enrollmentCard.learningObjectives")}
            </Typography>
            <List
              sx={{
                padding: 0,
                margin: 0,
                "& .MuiListItem-root": {
                  paddingTop: "4px",
                  paddingBottom: "4px",
                  fontSize: "0.8rem",
                },
              }}
            >
              {learningObjectives.map((objective, index) => (
                <ListItem key={index}>
                  <ListItemIcon>
                    <CheckCircleIcon color="success" />
                  </ListItemIcon>
                  <ListItemText primary={objective} />
                </ListItem>
              ))}
            </List>
          </>
        )}

        <div className="enroll-button-container">
          <button
            className="new-button-style teal-hover"
            style={{
              marginBottom: "0",
              minWidth: "40%",
              marginBottom: window.innerWidth < 400 ? "1rem" : "",
            }}
            onClick={handleBackClick}
          >
            {t("enrollmentCard.backToCourses")}
          </button>
          <button
            className="new-button-style primary enroll-button"
            style={{ minWidth: "40%" }}
            disabled={isLoadingEnrollment || enrolled}
            onClick={handleCourseEnrollment}
          >
            {isLoadingEnrollment ? (
              <CircularProgress size={14} />
            ) : enrolled ? (
              t("enrollmentCard.enrolled")
            ) : (
              t("enrollmentCard.enrollNow")
            )}
          </button>
        </div>
        {registration && registration > 35 && (
          <Typography
            sx={{
              display: "flex",
              justifyContent: "center",
              fontWeight: "bold",
              textAlign: "center",
              marginTop: "0.5rem",
            }}
          >
            <AutoAwesomeIcon />{" "}
            {t("enrollmentCard.popularCourse", { registration })}
          </Typography>
        )}
      </CardContent>
    </Card>
  );
}

export default EnrollmentCard;
