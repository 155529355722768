import React from "react";
import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormHelperText,
} from "@mui/material";

function CountrySelector({ error, setCountry, country }) {
  const handleChange = (event) => {
    setCountry(event.target.value);
  };

  const fieldStyle = {
    marginTop: 10,
  };

  const countrys = ["Canada", "United States"];

  return (
    <FormControl fullWidth style={fieldStyle} error={!country && error}>
      <InputLabel id="country-label">Country</InputLabel>
      <Select
        labelId="country-label"
        id="country-select"
        value={country}
        onChange={handleChange}
        label="country"
      >
        <MenuItem value="">
          <em>None</em>
        </MenuItem>
        {countrys.map((country) => (
          <MenuItem key={country} value={country}>
            {country}
          </MenuItem>
        ))}
      </Select>
      {!country && error ? (
        <FormHelperText>This field is required.</FormHelperText>
      ) : null}
    </FormControl>
  );
}

export default CountrySelector;
