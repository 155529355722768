import React, { useState, useEffect } from "react";
import {
  Autocomplete,
  TextField,
  Dialog,
  DialogTitle,
  DialogActions,
  Typography,
  CircularProgress,
  Box,
  IconButton,
} from "@mui/material";
import { fetchMedEssistData, linkPharmacyToUser } from "../../api/medessistApi";
import CloseIcon from "@mui/icons-material/Close";
import { getUpdatingUserObject } from "../../features/states/userSlice";
import { useDispatch, useSelector } from "react-redux";
import { useAuth } from "../../contexts/AuthContext";
import axios from "axios";
export default function PharmacyAutocompleteDialog({
  open,
  setOpen,
  handleAddPharmacy,
  userId,
}) {
  const [searchValue, setSearchValue] = useState("");
  const [pharmacies, setPharmacies] = useState([]);
  const [selectedPharmacy, setSelectedPharmacy] = useState(null);
  const [loading, setLoading] = useState(false);
  const [autoCompleteOpen, setAutoCompleteOpen] = useState(false);
  const { currentUser } = useAuth();

  const dispatch = useDispatch();
  const user = useSelector((state) => state.user.value);

  useEffect(() => {
    dispatch(getUpdatingUserObject(currentUser.uid));
  }, [dispatch]);
  useEffect(() => {
    setLoading(true);
    fetchMedEssistData()
      .then((data) => {
        // do not include pharmacy names that have demo, delete, or is a test account
        // const nonDemoPharmacies = data?.filter(
        //   (pharmacy) =>
        //     pharmacy.pharmacyName &&
        //     !pharmacy?.pharmacyName.toLowerCase().includes("demo") &&
        //     !pharmacy?.pharmacyName.toLowerCase().includes("delete") &&
        //     (!pharmacy?.pharmacyName.includes("Test") ||
        //       pharmacy?.pharmacyName.toLowerCase().includes("testing"))
        // );
        // setPharmacies(nonDemoPharmacies);
        setPharmacies(data);
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  const handleSubmit = async (selectedPharmacy) => {
    const { pharmacyId, pharmacyPhone, pharmacyName } = selectedPharmacy;
    const { email } = user;
    await axios
      .post(
        "https://northamerica-northeast1-education-hub-848d2.cloudfunctions.net/api/api/link-pharmacy",
        { email, pharmacyId, pharmacyPhone, pharmacyName }
      )
      .then((res) => console.log(res));
  };

  const handleCloseAutocomplete = () => {
    setOpen(false);
    setSearchValue("");
    setSelectedPharmacy(null);
  };

  return (
    <Dialog
      open={open}
      onClose={handleCloseAutocomplete}
      fullWidth
      maxWidth="sm"
    >
      <DialogTitle sx={{ textAlign: "center" }}>
        Link a Pharmacy
        <IconButton
          sx={{ position: "absolute", right: 0, mt: -1.5 }}
          onClick={handleCloseAutocomplete}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <Typography
        style={{ padding: "0.25em 1em" }}
        variant="body2"
        color="textSecondary"
        align="center"
      >
        Linking your Education account with your pharmacy's Medessist account
        will allow the pharmacy to recognize the courses you have completed and
        may open up more functionality for you.
      </Typography>
      {loading ? (
        <div
          style={{ display: "block", textAlign: "center", marginTop: "1rem" }}
        >
          <CircularProgress size={20} sx={{ marginBottom: "-3px" }} />{" "}
          Loading...
        </div>
      ) : (
        <Autocomplete
          open={autoCompleteOpen}
          options={pharmacies}
          getOptionLabel={(option) =>
            `${option.pharmacyName} - ${option.pharmacyPhone}`
          }
          /* how to filter the autocomplete options shown (able to use pharmacyName or phone)*/
          filterOptions={(options, params) => {
            const filtered = options.filter(
              (option) =>
                (option.pharmacyName &&
                  option.pharmacyName
                    .toLowerCase()
                    .includes(params.inputValue.toLowerCase())) ||
                (option.pharmacyPhone &&
                  option.pharmacyPhone.includes(params.inputValue))
            );
            /* list only 5 choices */
            // return filtered.slice(0, 5);
            return filtered;
          }}
          renderOption={(props, option) => (
            <Box component="li" {...props} key={option.pharmacyId}>
              {option.pharmacyName} - {option.pharmacyPhone}
            </Box>
          )}
          inputValue={searchValue}
          onInputChange={(event, newInputValue) => {
            setSearchValue(newInputValue);
            // list should display only when there is a search
            if (newInputValue.length === 0) {
              if (autoCompleteOpen) {
                setAutoCompleteOpen(false);
              }
            } else {
              if (!autoCompleteOpen) {
                setAutoCompleteOpen(true);
              }
            }
          }}
          onClose={() => setAutoCompleteOpen(false)}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Search for a pharmacy by name or phone number"
              variant="outlined"
              disabled={loading}
              sx={{ width: "90%" }}
              InputProps={{
                ...params.InputProps,
              }}
            />
          )}
          onChange={(event, value) => {
            setSelectedPharmacy(value);
          }}
          popupIcon={""}
          sx={{ display: "flex", justifyContent: "center" }}
        />
      )}
      <DialogActions sx={{ mt: 2, mb: 1, mr: 1 }}>
        <button
          className="new-button-style primary"
          onClick={() => handleSubmit(selectedPharmacy)}
          disabled={!selectedPharmacy}
        >
          Submit
        </button>
      </DialogActions>
    </Dialog>
  );
}
