import React, { useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  TextField,
  DialogActions,
} from "@mui/material";
import { useNavigate } from "react-router-dom";

function LicenseModal({ open, handleSave }) {
  const [licenseNumber, setLicenseNumber] = useState("");
  const navigate = useNavigate();

  const onSave = () => {
    if (licenseNumber.trim() !== "") {
      handleSave(licenseNumber);
    } else {
      alert("Please enter your license number to proceed.");
    }
  };

  // Redirects the user to the courses page when the dialog is closed
  const handleClose = () => {
    navigate("/courses");
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose} //navigate back to courses when clicking away from modal
      aria-labelledby="form-dialog-title"
    >
      <DialogTitle id="form-dialog-title">
        Accreditation Requirement
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          To enroll in this accredited course, please enter your license number.
        </DialogContentText>
        <TextField
          autoFocus
          margin="dense"
          id="license"
          label="License Number"
          type="text"
          fullWidth
          variant="standard"
          value={licenseNumber}
          onChange={(e) => setLicenseNumber(e.target.value)}
        />
      </DialogContent>
      <DialogActions>
        <button
          className="new-button-style secondary"
          // This button also uses handleClose to ensure consistent navigation
          onClick={handleClose}
        >
          Back to Courses
        </button>
        <button className="new-button-style primary" onClick={onSave}>
          Save
        </button>
      </DialogActions>
    </Dialog>
  );
}

export default LicenseModal;
