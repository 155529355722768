import React, { useState } from "react";
import CourseHeader from "./CourseHeader/CourseHeader";
import UnlockQuiz from "./UnlockQuiz";
import resourcesData from "./TabContent/resourcesData";
import { Card, CardContent, CardMedia } from "@mui/material";
import useCourseEnrollment from "./useCourseEnrollment";
import { useAuth } from "../../contexts/AuthContext";
import TabsComponent from "./TabContent/TabsComponent";
import TabContent from "./TabContent/TabContent";

function ExternalCoursePage({ courseData }) {
  const { currentUser } = useAuth();
  const [selectedSection, setSelectedSection] = useState("about");
  const { enrolled, isLoadingEnrollment, handleCourseEnrollment } =
    useCourseEnrollment(courseData);

  return (
    <div className="course-container">
      <CourseHeader
        courseName={courseData.courseName}
        instructorName={courseData.instructorName}
      />

      <Card
        variant="outlined"
        className="card-container-border course-info-container"
      >
        {/* Placeholder Image Section */}
        <Card
          variant="outlined"
          className="media-container"
          style={{ margin: "20px 0" }}
        >
          <CardMedia
            component="img"
            image={courseData?.placeholderImage}
            title={courseData?.courseName}
            style={{
              height: "auto",
              width: "100%",
              margin: "auto",
            }}
          />
        </Card>

        <div className="course-info-header">
          <TabsComponent
            selectedSection={selectedSection}
            setSelectedSection={setSelectedSection}
            resourcesData={resourcesData}
            courseData={courseData}
            tutorial={false}
          />
          <UnlockQuiz
            enrolled={enrolled}
            handleCourseEnrollment={handleCourseEnrollment}
            currentUser={currentUser}
            isExternal={true}
            externalLink={courseData.externalLink}
            isLoadingEnrollment={isLoadingEnrollment}
            courseId={courseData.courseId}
          />
        </div>
        <CardContent>
          <TabContent
            selectedSection={selectedSection}
            courseData={courseData}
          />
        </CardContent>
      </Card>
    </div>
  );
}

export default ExternalCoursePage;
