import React, { useState } from "react";
import { Grid, Paper } from "@mui/material";
import InputLabel from "@mui/material/InputLabel";
import OutlinedInput from "@mui/material/OutlinedInput";
import FormControl from "@mui/material/FormControl";
import { useAuth } from "../../contexts/AuthContext";
import { useTranslation } from "react-i18next";

function ForgotPassword() {
  const [email, setEmail] = useState("");
  const { resetPassword } = useAuth();
  const [error, setError] = useState("");
  const [message, setMessage] = useState("");
  const { t } = useTranslation();

  async function handleSubmit(e) {
    e.preventDefault();

    try {
      setMessage("");
      setError("");
      await resetPassword(email);
      setMessage(t("forgotPassword.checkInbox"));
    } catch {
      setError(t("forgotPassword.failedReset"));
    }
  }

  const paperStyle = {
    padding: 20,
    height: "30vh",
    width: 280,
    margin: "20px auto",
  };

  return (
    <Grid>
      <Paper elevation={10} style={paperStyle}>
        <Grid align="center">
          <h2>{t("forgotPassword.title")}</h2>
        </Grid>
        <form onSubmit={handleSubmit}>
          {error} {message}
          <FormControl sx={{ mt: 2, width: "100%" }} variant="outlined">
            <InputLabel htmlFor="email">
              {t("forgotPassword.emailLabel")}
            </InputLabel>
            <OutlinedInput
              id="email"
              type="email"
              label={t("forgotPassword.emailLabel")}
              value={email}
              onChange={(event) => setEmail(event.target.value)}
              placeholder={t("forgotPassword.emailPlaceholder")}
              fullWidth
              required
            />
          </FormControl>
          <button
            className="new-button-style primary teal-hover"
            style={{
              width: "100%",
              height: "50px",
              fontSize: "18px",
              margin: "20px 0px",
            }}
            variant="contained"
            fullWidth
          >
            {t("forgotPassword.resetPasswordButton")}
          </button>
        </form>
      </Paper>
    </Grid>
  );
}

export default ForgotPassword;
