import React from "react";
import { TextField } from "@mui/material";
import ChooseSourceTypeFilter from "./ChooseSourceTypeFilter";
import EllipsisPending from "./EllipsisPending";

export default function FollowUpQuestionTextInput(props) {
  const {
    handleSubmit,
    conversation,
    setCurrentQuery,
    messageToSend,
    setAnswerCompleteFlag,
    answerCompleteFlag,
    setMessageToSend,
    selectedConversation,
    loading,
  } = props;
  return (
    <>
      {loading ? (
        <EllipsisPending />
      ) : (
        <div>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              marginBottom: "5px	",
            }}
          >
            <div
              className="marketing-description"
              style={{
                fontSize: "16px",
                color: "#6a6a6a",
              }}
            >
              Ask a follow up question:
            </div>
            <div>
              <ChooseSourceTypeFilter
                setMessageToSend={setMessageToSend}
                messageToSend={messageToSend}
                backgroundColor={"#edd178"}
              />
            </div>
          </div>
          <TextField
            style={{ width: "100%", height: "auto" }}
            id="outlined-multiline-static"
            multiline
            rows={3}
            placeholder="Ask a follow up question"
            value={
              selectedConversation?.docId === conversation?.docId
                ? messageToSend?.query
                : ""
            }
            onChange={(e) => {
              if (!loading) {
                if (answerCompleteFlag) {
                  setAnswerCompleteFlag(false);
                }
                setMessageToSend({
                  ...messageToSend,
                  query: e.target.value,
                  chat_history: [...selectedConversation?.chat_history],
                });
                setCurrentQuery(e.target.value);
              }
            }}
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                handleSubmit(e);
              }
            }}
          />
        </div>
      )}
    </>
  );
}
