import React, { useState } from "react";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import {
  Grid,
  Paper,
  Typography,
  TextField,
  IconButton,
  FormControl,
  InputLabel,
  InputAdornment,
  OutlinedInput,
  CircularProgress,
  Link,
} from "@mui/material";
import { useAuth } from "../../contexts/AuthContext";
import { useTranslation } from "react-i18next";

function SignIn({ handleCreateAccountClick, navigateAfterSignInOrSignUp }) {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [error, setError] = useState(false);
  const [errorText, setErrorText] = useState("");
  const [loading, setLoading] = useState(false);
  const { signIn } = useAuth();
  const { t } = useTranslation();

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleSignIn = async (e) => {
    e.preventDefault();
    setErrorText("");
    setError(false);
    try {
      if (!email || !password) {
        setError(true);
        return;
      }
      setLoading(true);
      await new Promise((resolve) => setTimeout(resolve, 500));
      await signIn(email, password);
      navigateAfterSignInOrSignUp();
    } catch (err) {
      setLoading(false);
      switch (err.code) {
        case "auth/wrong-password":
          setError(true);
          setErrorText(t("signIn.incorrectPassword"));
          break;
        case "auth/user-not-found":
          setError(true);
          setErrorText(t("signIn.userNotFound"));
          break;
        default:
          console.log("Error:", err.message);
          setError(true);
          setErrorText(t("signIn.unexpectedError"));
          break;
      }
    }
  };

  const paperStyle = { padding: "30px 20px", margin: "20px auto" };

  return (
    <Grid>
      <Paper elevation={10} style={paperStyle}>
        <Grid align="center">
          <h2>{t("signIn.signInTitle")}</h2>
        </Grid>
        <form onSubmit={handleSignIn}>
          {errorText && <Typography color="error">{errorText}</Typography>}
          <FormControl sx={{ mt: 2, width: "100%" }} variant="outlined">
            <InputLabel
              htmlFor="email"
              shrink
              sx={{ backgroundColor: "white" }}
            >
              {t("signIn.emailLabel")}
            </InputLabel>
            <TextField
              error={!email && error}
              fullWidth
              label={t("signIn.emailLabel")}
              placeholder={t("signIn.emailPlaceholder")}
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              helperText={error && !email ? t("signIn.errorRequiredField") : ""}
            />
          </FormControl>

          <FormControl sx={{ mt: 2, width: "100%" }} variant="outlined">
            <InputLabel
              htmlFor="outlined-adornment-password"
              shrink
              sx={{ backgroundColor: "white" }}
            >
              {t("signIn.passwordLabel")}
            </InputLabel>
            <OutlinedInput
              id="outlined-adornment-password"
              type={showPassword ? "text" : "password"}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    edge="end"
                  >
                    {showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              }
              label={t("signIn.passwordLabel")}
              value={password}
              placeholder={t("signIn.passwordPlaceholder")}
              onChange={(e) => setPassword(e.target.value)}
              fullWidth
              error={!password && error}
              helperText={
                error && !password ? t("signIn.errorRequiredField") : ""
              }
            />
          </FormControl>
          <div
            style={{
              marginTop: "0.5em",
              fontWeight: "bold",
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            <Link style={{ color: "#3ac0c4" }} href="/passwordreset">
              {t("signIn.forgotPassword")}
            </Link>
          </div>

          <button
            className="new-button-style primary teal-hover"
            style={{
              width: "100%",
              height: "50px",
              fontSize: "18px",
              margin: "20px 0px",
            }}
            fullWidth
            onClick={handleSignIn}
            disabled={loading}
          >
            {loading ? (
              <CircularProgress size={24} />
            ) : (
              t("signIn.signInButton")
            )}
          </button>
        </form>
        <div
          style={{
            fontWeight: "bold",
            margin: "auto",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            cursor: "pointer",
          }}
          onClick={handleCreateAccountClick}
        >
          {t("signIn.notRegistered")}{" "}
          <span style={{ color: "#3ac0c4", marginLeft: "5px" }}>
            {t("signIn.createAccount")}
          </span>
        </div>
      </Paper>
    </Grid>
  );
}

export default SignIn;
