import React from "react";
import { getAccountData } from "../helpers/source";

export default function DownloadEmailCSV() {
  function getHumanReadableDate() {
    const currentDate = new Date();
    const options = {
      weekday: "short",
      month: "short",
      day: "numeric",
      year: "numeric",
    };
    return currentDate.toLocaleDateString(undefined, options);
  }
  const convertAndDownloadCSV = async () => {
    const accounts = await getAccountData();
    const accountsWithHeader = [
      ["Email", "First Name", "Last Name"], // CSV Headers
      ...accounts.map((account) => [
        account.email || "",
        account.firstName || "",
        account.lastName || "",
      ]),
    ];

    // this is done so csv renders in a column instead of all in 1 row
    const csvString = accountsWithHeader.map((row) => row.join(",")).join("\n");

    const blob = new Blob([csvString], { type: "text/csv" });
    const url = URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute(
      "download",
      `${getHumanReadableDate()}_Ehub-All-Registrant-Emails.csv`
    );
    document.body.appendChild(link);
    link.click();

    URL.revokeObjectURL(url);
    document.body.removeChild(link);
  };
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
      }}
    >
      Education Hub Registrant Emails
      <button
        className="new-button-style primary"
        onClick={convertAndDownloadCSV}
      >
        Download .csv
      </button>
    </div>
  );
}
