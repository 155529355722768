import { createSlice } from "@reduxjs/toolkit";
import { onSnapshot, doc } from "firebase/firestore";
import { db } from "../../lib/firebase/config";
export const pendingPharmacyRequestsSlice = createSlice({
  name: "pendingPharmacyRequests",
  initialState: {
    value: {
      pendingPharmacyRequests: [],
    },
  },
  reducers: {
    setPendingPharmacyRequests: (state, action) => {
      // Redux Toolkit allows us to write "mutating" logic in reducers. It
      // doesn't actually mutate the state because it uses the Immer library,
      // which detects changes to a "draft state" and produces a brand new
      // immutable state based off those changes.
      // Also, no return statement is required from these functions.
      state.value = action.payload;
    },
    removePendingPharmacyRequestSliced: (state, action) => {
      const pharmacyIdToRemove = action.payload;
      state.value = state.value.filter(
        (request) => request.pharmacyId !== pharmacyIdToRemove
      );
    },
  },
});

export const getUpdatingPendingPharmacyRequests =
  (userId) => async (dispatch) => {
    const userDocRef = doc(db, "users", userId);
    const unsubscribe = onSnapshot(userDocRef, (doc) => {
      if (doc.exists()) {
        // Document exists, update data
        const updatedUserData = doc.data().pendingPharmacyRequests;
        dispatch(setPendingPharmacyRequests(updatedUserData));
        // Dispatch an action or update local state with updatedUserData
      } else {
        // Document doesn't exist
        console.log("User document no longer exists");
      }
    });
    return unsubscribe;
  };
export const {
  setPendingPharmacyRequests,
  removePendingPharmacyRequestSliced,
} = pendingPharmacyRequestsSlice.actions;

export default pendingPharmacyRequestsSlice.reducer;
