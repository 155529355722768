import React from "react";
import { TextField } from "@mui/material";

const ReusableTextField = ({
  label,
  placeholder,
  value,
  error = false,
  helperText = "",
  onChange,
  handleKeyDown,
}) => {
  return (
    <TextField
      error={error}
      fullWidth
      label={label}
      placeholder={placeholder}
      value={value}
      onChange={onChange}
      helperText={helperText}
      onKeyDown={(e) => {
        if (e.key === "Enter" && handleKeyDown) {
          handleKeyDown();
        }
      }}
    />
  );
};

export default ReusableTextField;
