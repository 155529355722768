import React from "react";
import { Dialog, DialogContent } from "@mui/material";
import QRCode from "qrcode.react";

export default function QRModal(props) {
  const { link, setShowQRModal, showQRMOodal } = props;
  return (
    <Dialog
      open={showQRMOodal}
      onClose={() => {
        setShowQRModal(false);
      }}
    >
      <DialogContent>
        <QRCode size="300" style={{ maxHeight: "3.5cm" }} value={link} />
      </DialogContent>
    </Dialog>
  );
}
