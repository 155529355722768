import React, { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import { useAuth } from "../contexts/AuthContext";
import {
  Button,
  Avatar,
  Menu,
  MenuItem,
  IconButton,
  Badge,
  Typography,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import LoginIcon from "@mui/icons-material/Login";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { SecondaryButton } from "./styled-components/Buttons";
import PendingRequestButtonAndModal from "../pendingPharmacyRequests/PendingRequestButtonAndModal";
import { useDispatch, useSelector } from "react-redux";
import { getUpdatingPendingPharmacyRequests } from "../features/states/pendingPharmacyRequestsSlice";
import useUserRedux from "../hooks/useUserRedux";

export default function LoggedInHeader() {
  const [anchorEl, setAnchorEl] = useState(null);
  const user = useUserRedux();

  const dispatch = useDispatch();
  const { currentUser, logOut } = useAuth();
  const location = useLocation();
  const { t } = useTranslation();

  const [openPendingRequestModal, setOpenPendingRequestModal] = useState(false);

  useEffect(() => {
    dispatch(getUpdatingPendingPharmacyRequests(currentUser?.uid));
  }, [dispatch]);
  const pendingPharmacyRequests = useSelector(
    (state) => state.pendingPharmacyRequests.value
  );

  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = () => {
    logOut()
      .then(() => {
        window.location.reload(false);
        console.log("Successfully logged out");
      })
      .catch(() => {
        console.log("Failed to log out");
      });
  };

  useEffect(() => {
    if (pendingPharmacyRequests?.length > 0) {
      setOpenPendingRequestModal(true);
    }
  }, [pendingPharmacyRequests?.length]);

  return (
    <>
      <div
        style={{
          display: "flex",
          justifyContent: "flex-end",
          marginBottom: "1em",
          position: "fixed",
          right: "0",
          top: "0",
          zIndex: "100",
          borderRadius: "0 0 15px 15px",
          background: "white",
          padding: "10px",
        }}
      >
        {currentUser ? (
          <div style={{ display: "flex", alignItems: "center" }}>
            <PendingRequestButtonAndModal
              open={openPendingRequestModal}
              setOpen={setOpenPendingRequestModal}
            />
            <Badge
              component={Link}
              to={"/profile"}
              color="primary"
              badgeContent={pendingPharmacyRequests?.length ?? null}
            >
              <Avatar
                sx={{
                  width: 40,
                  height: 40,
                  mb: 1,
                  ml: 2,
                  mr: 1,
                  border: "1px solid white",
                  boxShadow: "0px 0px 10px rgba(0,0,0,0.1)",
                }}
                src={user?.profilePicture}
              ></Avatar>
            </Badge>

            <Typography
              style={{
                fontWeight: "bold",
                color: "inherit",
                textDecoration: "none",
              }}
              component={Link}
              to={"/profile"}
            >
              {user?.firstName} {user?.lastName}
            </Typography>
            <div>
              <IconButton
                id="basic-button"
                aria-controls={open ? "basic-menu" : undefined}
                aria-haspopup="true"
                aria-expanded={open ? "true" : undefined}
                onClick={handleClick}
              >
                <ExpandMoreIcon />
              </IconButton>
              <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                  "aria-labelledby": "basic-button",
                }}
              >
                <MenuItem
                  component={Link}
                  to={"/profile"}
                  onClick={handleClose}
                >
                  {t("nav.profile")}
                </MenuItem>
                {/* <MenuItem onClick={handleClose}>My Account</MenuItem> */}
                <MenuItem
                  onClick={() => {
                    handleLogout();
                    handleClose();
                  }}
                >
                  {t("nav.logout")}
                </MenuItem>
              </Menu>
            </div>
          </div>
        ) : (
          location.pathname !== "/login" && (
            <>
              <Button
                sx={{
                  textTransform: "none",
                  color: "black",
                  borderRadius: "20px",
                  marginRight: "1em",
                }}
                startIcon={<LoginIcon sx={{ color: "black" }} />}
                component={Link}
                to="/login"
                state={{ from: location }}
              >
                {t("nav.login")}
              </Button>
              <SecondaryButton
                component={Link}
                to="/login?signup=true"
                state={{ from: location }}
              >
                Get Started - It's free!
              </SecondaryButton>
            </>
          )
        )}
      </div>
    </>
  );
}
