import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import Tooltip from "@mui/material/Tooltip";

function StepQuizGraded({
  quizData,
  handleNextStep,
  isPassed,
  updateProgress,
  handlePreviousStep,
  currentStepIndex,
  canAccessEvaluation,
  handleQuizPass,
  setScorePercentage,
  scorePercentage,
}) {
  const { t } = useTranslation();
  const [userAnswers, setUserAnswers] = useState({});
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [showContinue, setShowContinue] = useState(false);

  // Effect to initialize the component state based on whether the quiz is already passed
  useEffect(() => {
    if (isPassed) {
      setIsSubmitted(true);
      setShowContinue(true);
    } else {
      setUserAnswers({});
      setIsSubmitted(false);
      setShowContinue(false);
    }
  }, [quizData.quizId, quizData.questions, isPassed]);

  // Handle answer selection
  const handleAnswerChange = (questionId, option) => {
    if (!isSubmitted) {
      setUserAnswers({
        ...userAnswers,
        [questionId]: option,
      });
    }
  };

  // Handle quiz submission
  const handleSubmit = () => {
    setIsSubmitted(true);
    let correctAnswers = 0;

    quizData.questions.forEach((question) => {
      if (userAnswers[question.question] === question.answer) {
        correctAnswers += 1;
      }
    });

    const totalQuestions = quizData.questions.length;
    const calculatedScorePercentage = Math.floor(
      (correctAnswers / totalQuestions) * 100
    );
    setScorePercentage(calculatedScorePercentage);

    if (calculatedScorePercentage >= 70) {
      setShowContinue(true);
      updateProgress({
        quizId: quizData.quizId,
        passed: true,
      });
      handleQuizPass(quizData.quizId, calculatedScorePercentage);
    } else {
      updateProgress({
        quizId: quizData.quizId,
        passed: false,
      });
    }
  };

  // Handle retrying the quiz
  const handleRetry = () => {
    setIsSubmitted(false);
    setShowContinue(false);
    setUserAnswers({});
  };

  return (
    <div className="centered-container" style={{ padding: "20px" }}>
      <h1 style={{ textAlign: "center" }}>{quizData.quizTitle}</h1>
      {canAccessEvaluation && (
        <h4 style={{ textAlign: "center" }}>{quizData.description}</h4>
      )}
      {!canAccessEvaluation ? (
        <p
          style={{
            color: "red",
            fontSize: "18px",
            fontWeight: "bold",
            textAlign: "center",
            marginBottom: "10px",
          }}
        >
          {t("interactiveCourse.completeAllModules")}
        </p>
      ) : (
        <>
          {quizData.questions.map((question, index) => (
            <div
              key={index}
              className="question-block"
              style={{
                marginBottom: "15px",
                padding: "10px",
                border: "1px solid #ddd",
                borderRadius: "8px",
                backgroundColor: "#f9f9f9",
              }}
            >
              <p style={{ fontWeight: "bold" }}>
                {index + 1}. {question.question}
              </p>
              {question.options.map((option, optionIndex) => {
                let backgroundColor = "transparent";

                if (isSubmitted) {
                  if (userAnswers[question.question] === option) {
                    if (option === question.answer) {
                      backgroundColor = "#d4edda"; // Green for correct answers
                    } else {
                      backgroundColor = "#f8d7da"; // Red for incorrect answers
                    }
                  } else if (isPassed && option === question.answer) {
                    backgroundColor = "#d4edda"; // Green for correct answers when passed
                  }
                }

                return (
                  <label
                    key={optionIndex}
                    style={{
                      display: "block",
                      margin: "5px 0",
                      cursor: "pointer",
                      padding: "5px",
                      borderRadius: "5px",
                      backgroundColor,
                    }}
                  >
                    <input
                      type="radio"
                      name={`question-${index}`}
                      value={option}
                      checked={userAnswers[question.question] === option}
                      onChange={() =>
                        handleAnswerChange(question.question, option)
                      }
                      disabled={isSubmitted || isPassed}
                      style={{ marginRight: "10px" }}
                    />
                    {option}
                  </label>
                );
              })}
            </div>
          ))}

          {isSubmitted && (
            <p
              style={{
                fontSize: "18px",
                fontWeight: "bold",
                textAlign: "center",
                marginBottom: "10px",
                color: isPassed || scorePercentage >= 70 ? "green" : "red",
              }}
            >
              {isPassed || scorePercentage >= 70
                ? t("interactiveCourse.congratulationsPassedQuiz")
                : t("interactiveCourse.sorryDidNotPass")}
              : {scorePercentage}%
            </p>
          )}

          <div
            style={{ display: "flex", justifyContent: "center", gap: "10px" }}
          >
            <button
              onClick={handlePreviousStep}
              className="new-button-style secondary"
              style={{
                marginRight: "10px",
                padding: "10px 20px",
                cursor: "pointer",
              }}
              disabled={currentStepIndex < 1}
            >
              {t("interactiveCourse.back")}
            </button>

            {!isSubmitted ? (
              <button
                className="new-button-style primary"
                onClick={handleSubmit}
                style={{ padding: "10px 20px", cursor: "pointer" }}
              >
                {t("interactiveCourse.submit")}
              </button>
            ) : showContinue ? (
              <button
                className="new-button-style primary"
                onClick={handleNextStep}
                style={{ padding: "10px 20px", cursor: "pointer" }}
              >
                {t("interactiveCourse.continue")}
              </button>
            ) : (
              <Tooltip title={t("interactiveCourse.need70ToPass")}>
                <button
                  className="new-button-style primary"
                  onClick={handleRetry}
                  style={{ padding: "10px 20px", cursor: "pointer" }}
                >
                  {t("interactiveCourse.tryAgain")}
                </button>
              </Tooltip>
            )}
          </div>
        </>
      )}
    </div>
  );
}

export default StepQuizGraded;
