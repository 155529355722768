import React, { useState, useEffect } from "react";
import {
  Card,
  Typography,
  Avatar,
  List,
  Box,
  Snackbar,
  Alert,
  TextField,
  CircularProgress,
} from "@mui/material";
import { useDispatch } from "react-redux";
import { useAuth } from "../../contexts/AuthContext";
import { useTranslation } from "react-i18next";
import {
  updatePracticeTypeForUser,
  updateUserName,
  uploadProfilePicture,
} from "../helpers/source";
import ManageAccount from "./user-profile-sections/ManageAccount";
import { useSelector } from "react-redux";
import { getUpdatingLicenseNumber } from "../../features/states/licenseNumberSlice";
import { setLicenseNumber } from "../../features/states/licenseNumberSlice";
import { setUserSingleField } from "../../features/states/userSlice";
import { updateUserLicenseNumber } from "../helpers/source";
import useUserRedux from "../../hooks/useUserRedux";
import ProfessionPracticeTypeCompany from "../ProfessionPracticeTypeCompany";

function AccountSettingsModal({ userObject, setOpenReauthenticate }) {
  const { currentUser } = useAuth();
  const user = useUserRedux();
  const dispatch = useDispatch();
  const [openManageAccount, setOpenManageAccount] = useState(false);
  const [isUploading, setIsUploading] = useState(false);
  const [toastOpen, setToastOpen] = useState(false);
  const [editFirstName, setEditFirstName] = useState("");
  const [editLastName, setEditLastName] = useState("");
  const { t } = useTranslation();
  const licenseNumber = useSelector((state) => state.licenseNumber.value);

  const { firstName, lastName, profilePicture } = user;
  // get the user's data from db

  useEffect(() => {
    dispatch(getUpdatingLicenseNumber(currentUser.uid));
  }, [dispatch]);
  const handlePracticeTypeSelector = ({
    practiceType,
    company,
    profession,
  }) => {
    //update only values that are not undefined (value was given)
    const updateData = {
      uid: user.uid,
      ...(practiceType !== undefined && { practiceType }),
      ...(profession !== undefined && { profession }),
      ...(company !== undefined && { company }),
    };

    updatePracticeTypeForUser(updateData)
      .then(() => {
        setToastOpen(true); // Open the toast notification
      })
      .catch((error) => {
        console.error("Error updating practice type", error);
      });
  };

  const handleProfilePictureChange = async (event) => {
    const file = event.target.files[0];
    if (!file) return;
    setIsUploading(true);
    try {
      await uploadProfilePicture(currentUser.uid, file);
    } catch (error) {
      console.error("Error updating profile picture:", error); // Log any errors
    } finally {
      console.log("Finished uploading and updating profile picture"); // Log after everything is done
    }
    setIsUploading(false);
  };

  // Function called when the user clicks on the "Delete Account" button
  const handleDeleteAccount = () => {
    // confirm user wants to delete
    if (window.confirm(t("userProfile.deleteAccountConfirmation"))) {
      // open reauthenticate dialog
      setOpenReauthenticate(true);
    }
  };

  const handleSavingPersonalInfo = async () => {
    // Check if any name has changed before sending update request
    if (editFirstName !== firstName || editLastName !== lastName) {
      try {
        await updateUserName({
          userId: currentUser.uid,
          firstName: firstName,
          lastName: lastName,
        });
        setToastOpen(true); // Open the toast notification for successful name update
      } catch (error) {
        console.error("Error updating user's name", error);
      }
    }

    // Check if the license number has changed before updating
    if (licenseNumber !== userObject.licenseNumber) {
      try {
        await updateUserLicenseNumber({
          userId: currentUser.uid,
          licenseNumber,
        });
        setToastOpen(true); // Open the toast notification for successful license number update
      } catch (error) {
        console.error("Error updating user's license number", error);
      }
    }
  };

  return (
    <Card
      sx={{
        display: "flex",
        flexDirection: "column",
        border: 0,
        borderRadius: "1rem",
        boxShadow: "none",
        padding: "1rem",
        flex: 1,
        marginRight: "0.5rem",
        marginLeft: "0.5rem",
        maxWidth: "40%",
      }}
    >
      <Box className="inner-avatar-box">
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
            width: "100%",
            maxWidth: "600px",
            padding: "20px",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            {isUploading ? (
              <CircularProgress />
            ) : (
              <Avatar
                sx={{ width: 100, height: 100, m: 1 }}
                src={profilePicture}
              >
                {!profilePicture && (
                  <h1>{firstName?.charAt(0).toUpperCase()}</h1>
                )}
              </Avatar>
            )}
            <input
              accept="image/*"
              id="profile-picture-upload"
              type="file"
              onChange={handleProfilePictureChange}
              style={{ display: "none" }}
            />
            <label
              htmlFor="profile-picture-upload"
              style={{ cursor: "pointer" }}
            >
              <Typography variant="caption" component="span">
                {t("userProfile.uploadPhoto")}
              </Typography>
            </label>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              marginLeft: "20px",
              flexGrow: 1,
              maxWidth: "400px",
            }}
          >
            <TextField
              size="small"
              label={t("userProfile.firstName")}
              variant="standard"
              value={firstName ?? ""}
              onChange={(e) =>
                dispatch(
                  setUserSingleField({
                    fieldName: "firstName",
                    payload: e.target.value,
                  })
                )
              }
              style={{ marginBottom: "10px" }}
            />
            <TextField
              size="small"
              label="Last Name"
              variant="standard"
              value={lastName ?? ""}
              onChange={(e) =>
                dispatch(
                  setUserSingleField({
                    fieldName: "lastName",
                    payload: e.target.value,
                  })
                )
              }
              style={{ marginBottom: "10px" }}
            />
            <TextField
              size="small"
              label="License Number"
              variant="standard"
              value={licenseNumber}
              onChange={(e) => dispatch(setLicenseNumber(e.target.value))}
              style={{ marginBottom: "10px" }}
            />
            <button
              className="new-button-style secondary"
              style={{ minWidth: "60px" }}
              onClick={handleSavingPersonalInfo}
            >
              Save
            </button>
          </div>
        </div>
      </Box>

      <ProfessionPracticeTypeCompany
        handlePracticeTypeSelector={handlePracticeTypeSelector}
      />
      <List>
        {/* Account Settings */}
        <ManageAccount
          openManageAccount={openManageAccount}
          setOpenManageAccount={setOpenManageAccount}
          handleDeleteAccount={handleDeleteAccount}
        />

        <Snackbar
          open={toastOpen}
          autoHideDuration={6000}
          onClose={() => setToastOpen(false)}
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        >
          <Alert onClose={() => setToastOpen(false)} severity="success">
            {t("userProfile.saved")}
          </Alert>
        </Snackbar>
      </List>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          marginTop: 2,
        }}
      ></Box>
    </Card>
  );
}

export default AccountSettingsModal;
