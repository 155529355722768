import React from "react";
import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormHelperText,
  TextField,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { debounce } from "lodash";

function PracticeTypeSelector({
  practiceType,
  setPracticeType,
  company,
  setCompany,
  setOtherCompany,
  otherCompany,
  profession,
  setProfession,
  error,
  country,
  handlePracticeTypeSelector = () => {},
}) {
  const { t } = useTranslation();

  const handlePracticeTypeChange = (event) => {
    setPracticeType(event.target.value);
    if (event.target.value !== "Corporate") {
      setCompany("");
      setOtherCompany("");
    }
    if (handlePracticeTypeSelector) {
      handlePracticeTypeSelector({ practiceType: event.target.value });
    }
  };

  const handleCompanyChange = (event) => {
    setCompany(event.target.value);
    if (event.target.value !== "Other") {
      setOtherCompany("");
    }
    if (handlePracticeTypeSelector) {
      handlePracticeTypeSelector({ company: event.target.value });
    }
  };

  const debouncedHandlePracticeTypeSelector = debounce((value) => {
    handlePracticeTypeSelector({ company: `Other: ${value}` });
  }, 2000);

  const handleOtherCompanyChange = (event) => {
    setOtherCompany(event.target.value);
    if (handlePracticeTypeSelector) {
      debouncedHandlePracticeTypeSelector(event.target.value);
    }
  };

  const handleProfessionChange = (event) => {
    const selectedProfession = event.target.value;
    setProfession(selectedProfession);
    if (event.target.value !== "Pharmacist") {
      setCompany("");
      setOtherCompany("");
    }

    if (handlePracticeTypeSelector) {
      handlePracticeTypeSelector({ profession: event.target.value });
    }
  };

  const pharmacistOptions = [
    "communityPharmacyCorporate",
    "communityPharmacyIndependent",
    "familyHealthTeam",
    "hospitalPharmacy",
    "pharmaceuticalIndustry",
    "academiaResearch",
    "governmentInsurance",
    "healthcareConsulting",
    "student",
    "other",
  ];

  const otherProfessionOptions = [
    "community",
    "hospital",
    "publicHealth",
    "academia",
    "consulting",
    "other",
  ];

  const getPracticeTypeOptions = () => {
    return profession === "Pharmacist"
      ? pharmacistOptions
      : otherProfessionOptions;
  };

  return (
    <div>
      <FormControl sx={{ mt: 2, width: "100%" }} variant="outlined">
        <InputLabel id="profession-type">
          {t("professionSelector.label")}
        </InputLabel>
        <Select
          labelId="profession-type"
          value={profession}
          onChange={(e) => handleProfessionChange(e)}
          label={t("professionSelector.label")}
          style={{ marginBottom: "10px" }}
        >
          <MenuItem value={"Pharmacist"}>
            {t("professionSelector.pharmacist")}
          </MenuItem>
          <MenuItem value={"Pharmacy Technician"}>
            {t("professionSelector.pharmacyTechnician")}
          </MenuItem>
          <MenuItem value={"Nurse Practitioner"}>
            {t("professionSelector.nursePractitioner")}
          </MenuItem>
          <MenuItem value={"Registered Nurse"}>
            {t("professionSelector.registeredNurse")}
          </MenuItem>
          <MenuItem value={"Physician"}>
            {t("professionSelector.physician")}
          </MenuItem>
          <MenuItem value={"Physician Assistant"}>
            {t("professionSelector.physicianAssistant")}
          </MenuItem>
        </Select>
      </FormControl>

      {/* Conditional rendering: Show practice type dropdown */}
      {profession !== "Student" && (
        <FormControl
          sx={{ mt: 1, width: "100%" }}
          variant="outlined"
          error={!practiceType && error}
        >
          <InputLabel id="practice-type">
            {t("practiceTypeSelector.label")}
          </InputLabel>
          <Select
            labelId="practice-type"
            id="practice-type-select"
            value={practiceType}
            onChange={(e) => handlePracticeTypeChange(e)}
            label={t("practiceTypeSelector.label")}
          >
            {getPracticeTypeOptions().map((type) => (
              <MenuItem key={type} value={type}>
                {t(`practiceTypeSelector.${type}`)}
              </MenuItem>
            ))}
          </Select>
          {!practiceType && error && (
            <FormHelperText>
              {t("practiceTypeSelector.fieldRequired")}
            </FormHelperText>
          )}
        </FormControl>
      )}

      {/* if user answered Corporate, display dropdown to ask what company they work at */}
      {practiceType === "communityPharmacyCorporate" && (
        <FormControl sx={{ mt: 2, width: "100%" }} variant="outlined">
          <InputLabel id="company-type">
            {t("companySelector.label")}
          </InputLabel>
          <Select
            labelId="company-type"
            id="company-select"
            value={company}
            onChange={(e) => handleCompanyChange(e)}
            label={t("companySelector.label")}
          >
            <MenuItem value={"Rexall"}>Rexall</MenuItem>
            <MenuItem value={"Shoppers Drug Mart"}>Shoppers Drug Mart</MenuItem>
            <MenuItem value={"Costco"}>Costco</MenuItem>
            <MenuItem value={"Walmart"}>Walmart</MenuItem>
            <MenuItem value={"Metro"}>Metro</MenuItem>
            <MenuItem value={"Sobeys"}>Sobeys</MenuItem>
            <MenuItem value={"London Drugs"}>London Drugs</MenuItem>
            <MenuItem value={"Uniprix"}>Uniprix</MenuItem>
            <MenuItem value={"Jean Coutu"}>Jean Coutu</MenuItem>
            <MenuItem value={"Other"}>{t("companySelector.other")}</MenuItem>
          </Select>
        </FormControl>
      )}

      {/* if user says "other", ask them to specify */}
      {company === "Other" && (
        <TextField
          sx={{ mt: 2, width: "100%" }}
          label={t("companySelector.specify")}
          variant="outlined"
          value={otherCompany}
          onChange={handleOtherCompanyChange}
        />
      )}
    </div>
  );
}

export default PracticeTypeSelector;
