import React from "react";
import { Typography } from "@mui/material";
import "./CourseHeader.scss";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import instructorData from "../TabContent/instructorData";
import { useTranslation } from "react-i18next";

function CourseHeader({
  courseName,
  instructorName,
  handleSectionClick,
  handleBackClick,
}) {
  const instructorInfo = instructorData?.[instructorName];
  const { t } = useTranslation();

  return (
    <div className="course-header-container">
      <div className="course-header-title">
        <Typography
          variant={{ xs: "h3", sm: "h1" }}
          component="h1"
          className="course-name"
        >
          {courseName}
        </Typography>
        <div className="instructor-container">
          <Typography
            variant="body2"
            component="p"
            className="instructor-name"
            onClick={() => handleSectionClick("instructor")}
          >
            {instructorName}
            {instructorInfo && instructorInfo.designation
              ? `, ${instructorInfo.designation}`
              : ""}
          </Typography>
        </div>
      </div>
      <button className="back-to-courses" onClick={handleBackClick}>
        <ChevronLeftIcon sx={{ marginTop: "2px" }} />{" "}
        {t("courseHeader.backToCourses")}
      </button>
    </div>
  );
}

export default CourseHeader;
