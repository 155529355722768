import React, { useState, useEffect } from "react";
import "./courses.scss";
import useCourseEnrollment from "./useCourseEnrollment";
import QuizModal from "./QuizModal";
import { updateCoursesForUser, getCurrentUserObject } from "../helpers/source";
import { rateCourse } from "../helpers/source";
import EnrollmentCard from "./EnrollmentCard";
import { useNavigate } from "react-router-dom";
import { Tab, Tabs, Card, CardContent } from "@mui/material";
import TabContent from "./TabContent/TabContent";
import CourseHeader from "./CourseHeader/CourseHeader";
import resourcesData from "./TabContent/resourcesData";
import VideoAudioSection from "./VideoAudioSection";
import PostQuiz from "./PostQuiz";
import UnlockQuiz from "./UnlockQuiz";

/* this component is for courses that have their videos hosted on SEARCHIE (Jean's Courses)
main difference from CoursePage:
- vimeo and searchie diff format for embedding
- searchie does not support the 'ended' event that is used to detect when a VIMEO ended,
so we need to ask user to confirm is the video was watched */

function SearchieCoursePage({ courseData, currentUser }) {
  const { courseName, quizPassingScore, courseId, instructorName } = courseData;

  const [quizOpen, setQuizOpen] = useState(false);
  const [videoWatched, setVideoWatched] = useState(false);
  const [markAsWatchedLoading, setMarkAsWatchedLoading] = useState(false);
  const [completed, setCompleted] = useState(false);
  const [rating, setRating] = useState(0);
  const [submittedRating, setSubmittedRating] = useState(false);
  const [thankYouMessage, setThankYouMessage] = useState("");
  const [selectedSection, setSelectedSection] = useState("about");
  const navigate = useNavigate();

  const {
    enrolled,
    isLoadingEnrollment,
    isEnrollmentFetched,
    handleCourseEnrollment,
  } = useCourseEnrollment(courseData);

  // check if user had already watched video, if yes, setVideoWatched(true) and make quiz accessible
  useEffect(() => {
    const checkVideoWatched = async () => {
      const userObject = await getCurrentUserObject(currentUser.uid);
      const courses = userObject?.courses || [];

      if (Object.keys(courses).includes(courseId)) {
        setVideoWatched(courses?.[courseId]?.videoWatched);
      }
      // Check for completed status
      const completedStatus = courses?.[courseId]?.completed;
      if (completedStatus) {
        setCompleted(true);
      }
    };

    checkVideoWatched();
  }, [currentUser.uid, courseId]);

  // Fetch the rating from the db
  useEffect(() => {
    const fetchRating = async () => {
      const userObject = await getCurrentUserObject(currentUser.uid);
      const courses = userObject?.courses || [];

      if (Object.keys(courses).includes(courseId)) {
        const courseRating = courses?.[courseId]?.rating;
        setRating(courseRating || 0);

        //check if user has already submitted a rating
        if (courseRating) {
          setSubmittedRating(true);
        }
      }
    };

    fetchRating();
  }, [currentUser.uid, courseId]);

  const handleUserConfirmation = async () => {
    setMarkAsWatchedLoading(true);
    setTimeout(() => {
      setVideoWatched(true);
      setMarkAsWatchedLoading(false);
    }, 1000);

    await updateCoursesForUser({
      userId: currentUser.uid,
      courseId: courseId,
      videoWatched: true,
    });
  };

  function handleStartQuiz() {
    if (videoWatched) {
      setQuizOpen(true);
    }
  }

  function handleClose() {
    setQuizOpen(false);
  }

  const handleChangeRating = async (event, newValue) => {
    setRating(newValue);

    if (newValue > 0) {
      await rateCourse({
        userId: currentUser.uid,
        courseId: courseId,
        rating: newValue,
      });

      // Change thank you message based on whether user has already submitted a rating
      if (submittedRating) {
        setThankYouMessage(
          `Your rating for ${courseName} has been updated. Thank you!`
        );
      } else {
        setThankYouMessage(`Thanks for rating ${courseName}!`);
      }

      setSubmittedRating(true);
    }
  };

  const handleBackClick = () => {
    navigate("/courses");
  };

  const handleSectionClick = (section) => {
    setSelectedSection(section);
  };

  // called when passing the quiz
  const quizPassed = () => {
    setCompleted(true);
    // so users do not miss the certificate achieved card
    window.scrollTo(0, 0);
  };

  // render the enrollment card only when user is not enrolled yet
  if (!isEnrollmentFetched) {
    return null;
  }
  // if enrollment data is fetched and the user is not enrolled, render enrollment card
  else if (isEnrollmentFetched && !enrolled) {
    return (
      <EnrollmentCard
        courseData={courseData}
        isEnrollmentFetched={isEnrollmentFetched}
        enrolled={enrolled}
        isLoadingEnrollment={isLoadingEnrollment}
        handleCourseEnrollment={handleCourseEnrollment}
      />
    );
  }

  // Render course once user is enrolled
  return (
    <div className="course-container">
      <CourseHeader
        courseName={courseName}
        instructorName={instructorName}
        handleSectionClick={handleSectionClick}
        handleBackClick={handleBackClick}
      />
      {completed && (
        <PostQuiz
          rating={rating}
          submittedRating={submittedRating}
          handleChangeRating={handleChangeRating}
          thankYouMessage={thankYouMessage}
        />
      )}
      <VideoAudioSection courseData={courseData} />
      <Card
        variant="outlined"
        className="card-container-border course-info-container"
      >
        <div className="course-info-header">
          <Tabs
            value={selectedSection}
            onChange={(event, newValue) => setSelectedSection(newValue)}
            variant="scrollable"
            scrollButtons="auto"
            sx={{ maxWidth: "fit-content", overflowX: "auto" }}
            className="course-navbar"
          >
            <Tab label="About" value="about" sx={{ marginRight: "10px" }} />
            <Tab label="Presenter" value="instructor" />
            {resourcesData[courseData.courseName] &&
              resourcesData[courseData.courseName].length > 0 && (
                <Tab
                  label="Resources"
                  value="resources"
                  sx={{ marginLeft: "10px" }}
                />
              )}
          </Tabs>
          {!completed && (
            <UnlockQuiz
              videoWatched={videoWatched}
              handleStartQuiz={handleStartQuiz}
              handleUserConfirmation={handleUserConfirmation}
              markAsWatchedLoading={markAsWatchedLoading}
            />
          )}
        </div>
        <CardContent>
          {/* tabs nav bar to select section to view */}
          <TabContent
            selectedSection={selectedSection}
            courseData={courseData}
            videoWatched={videoWatched}
            completed={completed}
            rating={rating}
            submittedRating={submittedRating}
            handleStartQuiz={handleStartQuiz}
            handleChangeRating={handleChangeRating}
            isLoadingEnrollment={isLoadingEnrollment}
            thankYouMessage={thankYouMessage}
            handleUserConfirmation={handleUserConfirmation}
            markAsWatchedLoading={markAsWatchedLoading}
          />
        </CardContent>
      </Card>

      {/* Modal to show the quiz */}
      <QuizModal
        open={quizOpen}
        handleClose={handleClose}
        courseData={courseData}
        passingScore={quizPassingScore}
        onQuizPassed={quizPassed}
      />
    </div>
  );
}

export default SearchieCoursePage;
