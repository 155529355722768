import React, { useState } from "react";
import { Chip, Tooltip, Snackbar } from "@mui/material";
import Markdown from "react-markdown";
import remarkGfm from "remark-gfm";
import _ from "lodash";
import { handleSourceClickMetric } from "./SourcesAccordion";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import AIHandout from "./AIHandout";
import SubjectIcon from "@mui/icons-material/Subject";

export default function ResponseAI(props) {
  const {
    response,
    sources,
    conversation,
    messageReceived,
    index,
    selectedConversation,
    setSelectedConversation,
  } = props;

  const [showCopySnackbar, setShowCopySnackbar] = useState(false);
  const [showAIHandoutModal, setShowAIHandoutModal] = useState(false);
  let matchedSourceWithChipNumber = [];

  // this searches though the text to find where the sources and filters out the one ones that have duplicate URLS
  const getFilteredText = (word) => {
    const inlineTextSources = word.match(/\[\d+\](?:,\s*\[\d+\])*/g);
    if (inlineTextSources) {
      let modifiedWord = word;

      inlineTextSources.forEach((stringOfSourceNumbers) => {
        const sourceNums = stringOfSourceNumbers
          .replace(/[\[\]]/g, "")
          .split(",")
          .map((num) => parseInt(num.trim()));

        const sourcesOfInlineText = sourceNums
          .map((inlineSource) =>
            sources?.find((source) => source?.source_num === inlineSource)
          )
          .filter(
            (matchedSource, index, arr) =>
              matchedSource &&
              arr.findIndex(
                (existingMatch) =>
                  existingMatch?.source === matchedSource?.source
              ) === index
          );

        const replacementText = sourcesOfInlineText
          .map((source) => `[${source?.source_num}]`)
          .join(", ");

        modifiedWord = modifiedWord.replace(
          stringOfSourceNumbers,
          replacementText
        );
      });

      return modifiedWord;
    }
    return word;
  };

  const formatter = (word) => {
    let filteredDuplicates = getFilteredText(word);

    const inlineSources = filteredDuplicates.match(/\[\d+\]/g);
    // // if there's any source numbers inside this word
    if (inlineSources) {
      let chipNum = 1;
      let matchedSource;

      inlineSources.forEach((inLineSource) => {
        const sourceNums = inLineSource
          .replace(/[\[\]]/g, "")
          .split(",")
          .map((num) => parseInt(num.trim()));

        // finds the source information that matches the inline source number
        matchedSource = sources?.find(
          (source) => source?.source_num === sourceNums[0]
        );

        // checks if the matched source link already exists in the matched array
        const notDuplicate = matchedSourceWithChipNumber?.every(
          (existingMatch) =>
            existingMatch?.source?.source !== matchedSource?.source
        );

        // pushed the source into the matchedArrayArray, which matches the label(chipNum) with the source so it can be displayed properly
        if (matchedSource && notDuplicate) {
          matchedSourceWithChipNumber.push({
            chipNum: chipNum,
            source: matchedSource,
          });
          chipNum++;
        }
        if (matchedSource?.source) {
          // converts the source Number into it's equivalent href in markdown
          filteredDuplicates = filteredDuplicates.replace(
            `[${sourceNums}]`,
            `<${matchedSource?.source}>`
          );
        } else {
          filteredDuplicates = filteredDuplicates.replace(
            /\[\d*\],|\[\d*\]/g,
            " "
          ); //used for when we are getting the response, but don't have access to the sources yet
        }
      });
    }

    return filteredDuplicates.replace(/<br>/g, "&nbsp;");
  };

  const CustomLink = ({ href }) => {
    const matchedSource = matchedSourceWithChipNumber?.find(
      (sourceObj) => sourceObj?.source?.source === href
    );
    return (
      <Chip
        component="a"
        href={href}
        target="_blank"
        label={matchedSource?.chipNum}
        clickable
        onClick={() => {
          if (index !== null) {
            handleSourceClickMetric(
              matchedSource?.source?.source_num,
              index,
              conversation,
              setSelectedConversation,
              selectedConversation
            );
          }
        }}
      />
    );
  };

  function removeMarkdown(markdownText) {
    // Remove headers
    let plainText = markdownText.replace(/^# .*/gm, "");

    // Remove lists
    plainText = plainText.replace(/^\s*[\*\-\+]\s+/gm, "");

    // Remove inline formatting
    plainText = plainText.replace(/([*]{1,2})(.*?)\1/g, "$2");

    return plainText;
  }
  return (
    <div className="AI-response">
      <AIHandout
        showAIHandoutModal={showAIHandoutModal}
        setShowAIHandoutModal={setShowAIHandoutModal}
        response={response}
      />
      {response ? (
        <>
          <Markdown
            components={{
              a: CustomLink, //converts links from markdown to html/javascript
            }}
            remarkPlugins={[remarkGfm]} //plugin that allows for things like tables
          >
            {formatter(response)}
          </Markdown>

          <Snackbar
            anchorOrigin={{ vertical: "top", horizontal: "center" }}
            open={showCopySnackbar}
            message="Copied!"
          />
          <div style={{ display: "flex" }}>
            <Chip
              icon={<ContentCopyIcon fontSize="10px" />}
              sx={{
                fontWeight: "bold",
                fontSize: "15px",
                marginRight: "3px",
                marginTop: "10px",
              }}
              label="Copy Text"
              onClick={(event) => {
                event.stopPropagation();
                setShowCopySnackbar(true);
                setTimeout(() => {
                  setShowCopySnackbar(false);
                }, 800);
                navigator.clipboard.writeText(
                  removeMarkdown(formatter(response))
                );
              }}
            />
            <div className="ai-handout-button">
              <Chip
                className="ai-handout-button"
                icon={<SubjectIcon fontSize="10px" />}
                sx={{ fontWeight: "bold", fontSize: "15px", marginTop: "10px" }}
                onClick={() => {
                  setShowAIHandoutModal(true);
                }}
                label="Create handout"
              />
            </div>
          </div>
        </>
      ) : (
        messageReceived?.pending && (
          <Markdown
            remarkPlugins={[remarkGfm]}
            components={{
              br: "br",
              a: CustomLink, //converts links from markdown to html/javascript
            }}
          >
            {formatter(messageReceived?.pending)}
          </Markdown>
        )
      )}
    </div>
  );
}
