import React, { useEffect } from "react";
import { useState } from "react";
import { Modal, TextField } from "@mui/material";
import { handleSendCustomEmail } from "../helpers/source";
import { useAuth } from "../../contexts/AuthContext";
import { getCurrentUserObject } from "../helpers/source";
import { useTranslation } from "react-i18next";

export default function EmailModal({ open, handleClose }) {
  const { t } = useTranslation();
  const { currentUser } = useAuth();
  const [userObject, setUserObject] = useState({});

  useEffect(() => {
    if (currentUser) {
      const fetchData = async () => {
        const user = await getCurrentUserObject(currentUser.uid);
        setUserObject(user);
      };

      fetchData();
    }
  }, [currentUser]);

  const [email, setEmail] = useState("");

  const handleSubmit = async (e) => {
    e.preventDefault();
    // Perform your submission logic here, such as sending the data to an API or updating state

    if (email && email !== "") {
      const data = {
        customMessage: `<div style="text-align: center;">Please add this email for ${userObject.firstName}  ${userObject.lastName}  to MedEssist's Private Spotify Listeners: ${email}</div>`,
        email: "bita@medessist.com",
        firstName: "test",
        lastName: "test",
        appointmentId: "",
        pharmacyPhone: "test",
        pharmacyId: "",
        registration: "test",
        pharmacyName: "Spotify Podcast Access Request",
      };
      await handleSendCustomEmail(data);
      alert(
        `Your Request to access MedEssist's Spotify Podcasts has been received! Please allow our team 1-2 business days to add your email address and you will receive an email confirming you have been added`
      );
    }
    handleClose();
  };

  return (
    <Modal
      open={open}
      onClose={() => {
        handleClose();
        setEmail("");
      }}
    >
      <div className="quiz-container" style={{ fontFamily: "Nunito Sans" }}>
        <h2>{t("emailModal.header")}</h2>
        <p dangerouslySetInnerHTML={{ __html: t("emailModal.instructions") }} />
        <form onSubmit={handleSubmit}>
          <TextField
            label={t("emailModal.emailLabel")}
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            fullWidth
            margin="normal"
            variant="outlined"
          />
          <button className="new-button-style primary" type="submit">
            {t("emailModal.submit")}
          </button>
        </form>
      </div>
    </Modal>
  );
}
