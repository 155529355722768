import { create } from "zustand";

const useAuthenticatedUser = create((set, get) => ({
  authenticated: false,
  timeoutId: null, // Keep track of the timeout ID

  setAuthenticated: (isAuthenticated) => {
    const { timeoutId } = get();

    // If setting to true, start the timer for 5 minutes
    if (isAuthenticated) {
      // Clear any previous timer if it exists
      if (timeoutId) {
        clearTimeout(timeoutId);
      }

      // Start a new timer
      const newTimeoutId = setTimeout(() => {
        set({ authenticated: false, timeoutId: null });
        console.log("User logged out due to inactivity.");
      }, 5 * 60 * 1000); // 5 minutes

      // Update the store with the new timeout ID
      set({ authenticated: true, timeoutId: newTimeoutId });
    } else {
      // If setting to false, clear the existing timer if there is one
      if (timeoutId) {
        clearTimeout(timeoutId);
        console.log("Timer cleared.");
      }
      set({ authenticated: false, timeoutId: null });
    }
  },

  // Cleanup method if needed for manual reset or testing purposes
  clearAuthTimeout: () => {
    const { timeoutId } = get();
    if (timeoutId) {
      clearTimeout(timeoutId);
      set({ timeoutId: null });
    }
  },
}));

export { useAuthenticatedUser };
