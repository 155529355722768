import { db } from "../../lib/firebase/config";
import { onSnapshot, doc } from "firebase/firestore";
import { createSlice } from "@reduxjs/toolkit";

export const doNotShowInitPopUpSlice = createSlice({
  name: "doNotShowInitPopUp",
  initialState: {
    value: false,
  },
  reducers: {
    setDoNotShowInitPopUp: (state, action) => {
      state.value = action.payload;
    },
  },
});
export const { setDoNotShowInitPopUp } = doNotShowInitPopUpSlice.actions;
export default doNotShowInitPopUpSlice.reducer;
