import React from "react";
import { Typography, Rating, Card, CardContent } from "@mui/material";
import WorkspacePremiumIcon from "@mui/icons-material/WorkspacePremium";
import { useTranslation } from "react-i18next";

const PostQuiz = (props) => {
  const { t } = useTranslation();

  const {
    rating,
    submittedRating,
    handleChangeRating,
    thankYouMessage,
    tutorial,
    feedbackDoc,
  } = props;

  if (tutorial) {
    return null;
  }

  return (
    <Card
      variant="outlined"
      className="card-container-border"
      sx={{ padding: "10px 0 0 0", marginBottom: "1rem" }}
    >
      <CardContent>
        <Typography variant="h5" component="h2" className="certificate-header">
          <WorkspacePremiumIcon sx={{ mr: 1 }} />
          <span style={{ marginTop: "5px" }}>
            {t("postQuiz.certificateAchieved")}
          </span>
        </Typography>
        <Typography
          variant="body2"
          component="p"
          style={{ color: "gray", textAlign: "center" }}
        >
          {t("postQuiz.certificateInfo")}
        </Typography>
        <Typography
          variant="body2"
          component="p"
          style={{
            color: "gray",
            textAlign: "center",
            marginTop: "10px",
          }}
        >
          {submittedRating
            ? t("postQuiz.yourCourseRating")
            : t("postQuiz.rateCourse")}
        </Typography>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            marginBottom: "10px",
          }}
        >
          <Rating
            name="course-rating"
            value={rating}
            onChange={handleChangeRating}
          />
        </div>
        {submittedRating && (
          <Typography
            variant="body2"
            component="p"
            style={{
              color: "gray",
              textAlign: "center",
              marginTop: "10px",
            }}
          >
            {t("postQuiz.thankYouMessage", { thankYouMessage })}
          </Typography>
        )}
        {submittedRating && feedbackDoc && (
          <Typography
            variant="body2"
            component="p"
            style={{
              color: "gray",
              textAlign: "center",
              marginTop: "10px",
            }}
          >
            {t("postQuiz.feedbackInvitation")}{" "}
            <a href={feedbackDoc} target="_blank" rel="noopener noreferrer">
              {t("postQuiz.clickToAnswerQuestions")}
            </a>
          </Typography>
        )}
      </CardContent>
    </Card>
  );
};

export default PostQuiz;
