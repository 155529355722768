import React, { useState } from "react";
import PinChange from "../components/pin/PinChange";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Button,
  MobileStepper,
  Typography,
  Box,
} from "@mui/material";
import ArrowBack from "@mui/icons-material/ArrowBack";
import ArrowForward from "@mui/icons-material/ArrowForward";
import { updateDontShowPopUp } from "../components/helpers/source";
import hubOnboardingImageOne from "../images/onboarding/HubOnboarding_1.png";
import hubOnboardingImageTwo from "../images/onboarding/HubOnboarding_2.png";
import hubOnboardingImageThree from "../images/onboarding/HubOnboarding_3.png";
import hubOnboardingImageFour from "../images/onboarding/HubOnboarding_4.png";

export default function InitialLinkingPharmacySetUpModal({
  open,
  setShowInitialLinkingPharmacySetUpModal,
  userObject,
}) {
  const [activeStep, setActiveStep] = useState(0);

  // Function to handle advancing the step from the <PinChange /> component
  const handlePinChangeComplete = () => {
    handleNext(); // Move to the next step when the PIN is created
  };

  // Content for each slide (steps) — now handlePinChangeComplete is accessible
  const steps = [
    {
      title: "Welcome to MedEssist Hub",
      content: (
        <>
          Your ultimate practice companion, equipped with education, clinical
          resources, and AI support!
          <img
            src={hubOnboardingImageOne}
            alt="Welcome"
            style={{
              maxWidth: "200px", // Set a maximum width
              width: "100%", // Ensure it is responsive
              height: "auto",
              display: "block",
              margin: "16px auto 0 auto", // Center the image
            }}
          />
        </>
      ),
    },
    {
      title: "Looking for HCP Authentication?",
      content: (
        <>
          If your workplace uses MedEssist Dashboard, you'll need to create a
          myPIN
          <img
            src={hubOnboardingImageTwo}
            alt="HCP"
            style={{
              maxWidth: "200px", // Set a maximum width
              width: "100%", // Ensure it is responsive
              height: "auto",
              display: "block",
              margin: "16px auto 0 auto", // Center the image
            }}
          />
        </>
      ),
    },
    {
      title: "Create myPIN",
      content: (
        <>
          Create a secret PIN to use to access patient data or sign documents at
          your linked work locations
          <img
            src={hubOnboardingImageThree}
            alt="Lock"
            style={{
              maxWidth: "200px", // Set a maximum width
              width: "100%", // Ensure it is responsive
              height: "auto",
              display: "block",
              margin: "16px auto 0 auto", // Center the image
            }}
          />
          {/* Pass handlePinChangeComplete as a prop to PinChange */}
          <PinChange
            noVerificationNeeded={true}
            onPinChangeComplete={handlePinChangeComplete}
          />
          You can change this PIN at any time under Manage Account on your Hub
          Profile
        </>
      ),
    },
    {
      title: "myPIN created!",
      content: (
        <>
          To continue setting up HCP, follow these steps:
          <ol>
            <li>
              Login to your{" "}
              <a target="_blank" href="https://app.medessist.com/login">
                MedEssist Dashboard
              </a>
            </li>
            <li>Click HCP Login</li>
            <li>Click Add New HCP</li>
            <li>
              Enter <b>MedEssist Hub Email and myPIN</b>
            </li>
          </ol>
          After completing these steps, select your name from the dropdown,
          enter your myPIN and login!
        </>
      ),
    },
    {
      title: "Congratulations on creating your account!",
      content: (
        <>
          Head to the Workshops and CEs section and check out the tutorial to
          make the most of your account
          <img
            src={hubOnboardingImageFour}
            alt="Lock"
            style={{
              maxWidth: "200px", // Set a maximum width
              width: "100%", // Ensure it is responsive
              height: "auto",
              display: "block",
              margin: "16px auto 0 auto", // Center the image
            }}
          />
        </>
      ),
    },
  ];

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleSkip = () => {
    setActiveStep(4); // Skip to the last step
  };

  const handleFinish = () => {
    updateDontShowPopUp(userObject?.uid);
    setShowInitialLinkingPharmacySetUpModal(false);
  };

  const isLastStep = activeStep === steps.length - 1;

  return (
    <Dialog
      open={open}
      onClose={() => setShowInitialLinkingPharmacySetUpModal(false)}
      fullWidth
    >
      <DialogTitle>{steps[activeStep].title}</DialogTitle>
      <DialogContent>
        <Typography variant="body1" fontSize="18px" color="textSecondary">
          {steps[activeStep].content}
        </Typography>
      </DialogContent>

      {/* Stepper controls */}
      <Box sx={{ display: "flex", alignItems: "center", p: 2 }}>
        {/* Left-aligned Skip button */}
        <Box sx={{ flex: 1 }}>
          {activeStep === 1 && (
            <Button
              size="small"
              variant="text"
              onClick={handleSkip}
              sx={{ color: "black" }}
            >
              Skip
            </Button>
          )}
        </Box>

        {/* Centered Stepper */}
        <Box sx={{ flex: 2, display: "flex", justifyContent: "center" }}>
          <MobileStepper
            variant="dots"
            steps={steps.length}
            position="static"
            activeStep={activeStep}
            sx={{
              "& .MuiMobileStepper-dotActive": {
                backgroundColor: "#229D98", // Active dot color
              },
            }}
            backButton={
              <Button
                size="small"
                onClick={handleBack}
                disabled={activeStep === 0}
                sx={{ color: "black" }}
              >
                <ArrowBack /> {/* Left arrow icon */}
                Back
              </Button>
            }
            nextButton={
              <Button
                sx={{ color: "#229D98" }}
                size="small"
                onClick={handleNext}
                disabled={isLastStep || activeStep === 2}
              >
                Next
                <ArrowForward /> {/* Right arrow icon */}
              </Button>
            }
          />
        </Box>

        {/* Right-aligned Finish button */}
        <Box sx={{ flex: 1, display: "flex", justifyContent: "flex-end" }}>
          {isLastStep && (
            <Button
              size="small"
              variant="text"
              color="primary"
              onClick={handleFinish}
              sx={{ color: "teal" }}
            >
              Finish
            </Button>
          )}
        </Box>
      </Box>
    </Dialog>
  );
}
