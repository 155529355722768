import React, { useState, useEffect } from "react";
import {
  Dialog,
  DialogContent,
  TextField,
  DialogActions,
  DialogTitle,
  IconButton,
} from "@mui/material";
import { handleSendNotificationSMS } from "../helpers/source";
import { getCurrentUserObject } from "../helpers/source";
import "./emailAndText.scss";
import { useAuth } from "../../contexts/AuthContext";
import { CssBaseline } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

export default function SendTextModal(props) {
  const { showSendTextModal, setShowSendTextModal, message } = props;

  const { currentUser } = useAuth();

  const [mobileNumber, setMobileNumber] = useState();
  const [textMessage, setTextMessage] = useState("");
  const [userObject, setUserObject] = useState(null);

  const messageFooter = ` - ${userObject?.firstName} ${userObject?.lastName}. Do not reply. Thank you!`;

  useEffect(() => {
    if (message) {
      setTextMessage(message);
    }
  }, [showSendTextModal]);

  useEffect(() => {
    getCurrentUserObject(currentUser.uid).then((res) => setUserObject(res));
  }, [currentUser]);

  const handleSendText = async () => {
    const phoneNumberRegex = /^[0-9\s-()+]+$/; // Regex to allow digits, spaces, hyphens, and parentheses
    if (mobileNumber && mobileNumber !== "") {
      if (mobileNumber.trim() === "") {
        alert("Please enter a phone number");
      } else if (!mobileNumber.match(phoneNumberRegex)) {
        alert("Phone number contains invalid characters");
      } else if (mobileNumber.length !== 10) {
        alert("Phone number must be between 10 digits");
      } else {
        await handleSendNotificationSMS(
          mobileNumber,
          textMessage + messageFooter
        );
        setShowSendTextModal(false);
      }
    }

    setShowSendTextModal(false);
  };

  return (
    <Dialog
      open={showSendTextModal}
      backdrop="static"
      onClose={() => {
        setShowSendTextModal(false);
        setTextMessage("");
        setMobileNumber("");
      }}
    >
      <CssBaseline />
      <DialogTitle style={{ textAlign: "center" }}>Send Text</DialogTitle>
      <IconButton
        aria-label="close"
        onClick={() => setShowSendTextModal(false)}
        sx={{
          position: "absolute",
          right: 8,
          top: 8,
        }}
      >
        <CloseIcon />
      </IconButton>
      <DialogContent>
        <div style={{ display: "flex", justifyContent: "center" }}>
          <h4>To: </h4> &nbsp;
          <TextField
            style={{ width: "60%", height: "auto" }}
            type="tel"
            placeholder={"Patient Phone Number"}
            value={mobileNumber}
            onChange={(e) => setMobileNumber(e.target.value)}
          ></TextField>
        </div>
        <br />
        <div style={{ textAlign: "initial" }}>
          Characters Remaining:{" "}
          {152 - (textMessage?.length + messageFooter?.length)}
        </div>
        <TextField
          style={{ width: "100%", height: "auto" }}
          multiline
          rows={3}
          placeholder="Enter your message here..."
          value={textMessage}
          onChange={(e) => setTextMessage(e.target.value)}
          maxLength={152 - messageFooter?.length}
        ></TextField>
        <div>Message includes {messageFooter}</div>
      </DialogContent>
      <DialogActions>
        <button
          className="new-button-style primary"
          onClick={() => handleSendText()}
          disabled={
            !textMessage ||
            !mobileNumber ||
            !mobileNumber.match(/[\d]/g) ||
            mobileNumber.match(/[\d]/g).length !== 10
          }
        >
          Send
        </button>
      </DialogActions>
    </Dialog>
  );
}
