import React from "react";
import CoursesPreview from "./courses/CoursesPreview";
import BlogPromoBanner from "./blog/BlogPromoBanner";

export default function Dashboard() {
  return (
    <>
      <CoursesPreview />
      {/* <BlogPromoBanner /> */}
    </>
  );
}
