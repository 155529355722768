import React from "react";

export default function EllipsisPending() {
  return (
    <>
      <div
        style={{
          color: "#3ac0c3",
          fontSize: "2em",
          marginTop: "-1em",
          marginLeft: "10px",
        }}
        className="ellipsis-anim"
      >
        <span>.</span>
        <span>.</span>
        <span>.</span>
      </div>
    </>
  );
}
