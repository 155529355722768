import React from "react";
import { useTranslation } from "react-i18next";

export const TermsAndConditions = () => {
  const { t } = useTranslation();

  return (
    <>
      <div style={{ fontWeight: "bold" }}>
        {t("termsAndConditions.acceptanceTitle")}
      </div>
      <p style={{ marginTop: "5px" }}>
        {t("termsAndConditions.acceptanceContent")}
      </p>
      <div style={{ fontWeight: "bold" }}>
        {t("termsAndConditions.servicesTitle")}
      </div>
      <p style={{ marginTop: "5px" }}>
        {t("termsAndConditions.servicesContent")}
      </p>
      <div style={{ fontWeight: "bold" }}>
        {t("termsAndConditions.accessUseTitle")}
      </div>
      <p style={{ marginTop: "5px" }}>
        {t("termsAndConditions.accessUseContent")}
      </p>
      <div style={{ fontWeight: "bold" }}>
        {t("termsAndConditions.thirdPartyContentTitle")}
      </div>
      <p style={{ marginTop: "5px" }}>
        {t("termsAndConditions.thirdPartyContentContent")}
      </p>
      <div style={{ fontWeight: "bold" }}>
        {t("termsAndConditions.privacyTitle")}
      </div>
      <p style={{ marginTop: "5px" }}>
        {t("termsAndConditions.privacyContent")}
      </p>
      <div style={{ fontWeight: "bold" }}>
        {t("termsAndConditions.intellectualPropertyTitle")}
      </div>
      <p style={{ marginTop: "5px" }}>
        {t("termsAndConditions.intellectualPropertyContent")}
      </p>
      <div style={{ fontWeight: "bold" }}>
        {t("termsAndConditions.liabilityTitle")}
      </div>
      <p style={{ marginTop: "5px" }}>
        {t("termsAndConditions.liabilityContent")}
      </p>
      <div style={{ fontWeight: "bold" }}>
        {t("termsAndConditions.refundsCancellationsTitle")}
      </div>
      <p style={{ marginTop: "5px" }}>
        {t("termsAndConditions.refundsCancellationsContent")}
      </p>
      <div style={{ fontWeight: "bold" }}>
        {t("termsAndConditions.disputeResolutionTitle")}
      </div>
      <p style={{ marginTop: "5px" }}>
        {t("termsAndConditions.disputeResolutionContent")}
      </p>
      {/*  MARKETING EMAILS */}
      <div style={{ fontWeight: "bold" }}>
        {t("termsAndConditions.marketingTitle")}
      </div>
      <p style={{ marginTop: "5px" }}>
        {t("termsAndConditions.marketingContent")}
      </p>
      <div style={{ fontWeight: "bold" }}>
        {t("termsAndConditions.contactTitle")}
      </div>
      <p style={{ marginTop: "5px" }}>
        {t("termsAndConditions.contactContent")}
      </p>
    </>
  );
};

export default TermsAndConditions;
