import React, { useState } from "react";
import List from "@mui/material/List";
import { Tabs, Tab } from "@mui/material";

const aerochamberDeviceList = [
  {
    name: "Patient Resources",
    video: [
      {
        title:
          "Aerochamber - Importance of Using Your Inhaler with a Spacer (2:20 min video)",
        link: "https://www.youtube.com/watch?v=W-GWAKR55HQ",
        icon: "video",
        typeOfResource: "patient",
      },
      {
        title:
          "Aerochamber - How to use the AeroChamber Plus* Flow-Vu* with a ComfortSeal* Mask [child] (1:49 min video)",
        link: "https://www.youtube.com/watch?v=uGkbreu169Q",
        icon: "video",
        typeOfResource: "patient",
      },
      {
        title:
          "Aerochamber - How to use the AeroChamber Plus* Flow-Vu* Chamber with a Mouthpiece [child] (2:10 min video)",
        link: "https://www.youtube.com/watch?v=e6rAIxVnin4",
        icon: "video",
        typeOfResource: "patient",
      },
      {
        title:
          "Aerochamber - How to use the AeroChamber Plus* Flow-Vu* Chamber with Mouthpiece [adult] (2:09 min video)",
        link: "https://www.youtube.com/watch?v=kBndCXS8KLw",
        icon: "video",
        typeOfResource: "patient",
      },
      {
        title:
          "Aerochamber - How to Use the AeroChamber Plus* Flow-Vu* AntiStatic Valved Holding Chamber with a Adult Large Mask [adult] (1:51 min video)",
        link: "https://www.youtube.com/watch?v=rsfpEFpV2SE",
        icon: "video",
        typeOfResource: "patient",
      },
      {
        title:
          "Aerochamber - How to Clean Your AeroChamber Plus* Flow-Vu* Chamber (1:58 min video)",
        link: "https://www.youtube.com/watch?v=wX4nVHNKf0o",
        icon: "video",
        typeOfResource: "patient",
      },

      {
        title:
          "Aerochamber - How to use, store, and clean AeroChamber2go (1:34 min video)",
        link: "https://www.youtube.com/watch?v=wX4nVHNKf0o",
        icon: "video",
        typeOfResource: "patient",
      },
      {
        title: "Aerochamber - Aerochamber-2-go Counselling Sheet ",
        link: "https://firebasestorage.googleapis.com/v0/b/medessist-ver2.appspot.com/o/Files%2FResources%2FAerochamber2Go%20Counselling.pdf?alt=media&token=0465c6a3-17f2-41ef-8ffc-edb01c2b5651",
        icon: "poster",
        typeOfResource: "patient",
      },
    ],
  },
  {
    name: "Provider Resources",
    video: [
      {
        title:
          "Aerochamber - Always Use a Spacer With Your Metered Dose Inhaler (2 min video)",
        link: "https://www.youtube.com/watch?v=xJ5p3xFA-64",

        icon: "video",
        typeOfResource: "provider",
      },
      {
        title:
          "Aerochamber - Improved outcomes with AeroChamber Plus* Flow-Vu* Chamber",
        link: "https://www.youtube.com/watch?v=wH5tjUOphHQ",
        icon: "video",
        typeOfResource: "provider",
      },
      {
        title:
          "Aerochamber - Importance and Use of the Flow Vu Inspriatory Indicator",
        link: "https://www.youtube.com/watch?v=CcAh6KyJWQQ",
        icon: "video",
        typeOfResource: "provider",
      },
      {
        title: "Aerochamber - Why use Aerochamber-2-go ",
        link: "https://www.youtube.com/watch?v=orX-p3bxhMs",
        icon: "video",
        typeOfResource: "provider",
      },
      // this is on both the patient and provider resources, as requested by trudell
      {
        title: "Aerochamber - Aerochamber-2-go Counselling Sheet ",
        link: "https://firebasestorage.googleapis.com/v0/b/medessist-ver2.appspot.com/o/Files%2FResources%2FAerochamber2Go%20Counselling.pdf?alt=media&token=0465c6a3-17f2-41ef-8ffc-edb01c2b5651",
        icon: "poster",
        typeOfResource: "provider",
      },
      {
        title: "Aerochamber - Aerochamber Reimbursement information ",
        link: "https://firebasestorage.googleapis.com/v0/b/medessist-ver2.appspot.com/o/Files%2FResources%2FAerochamber%20Reimbursement.pdf?alt=media&token=3d9b0a0a-735e-4f4f-bfbc-d67b0981e1d6",
        icon: "document",
        typeOfResource: "provider",
      },

      {
        title: "Aerochamber - Importance oF VHC for every MDI ",
        link: "https://firebasestorage.googleapis.com/v0/b/medessist-ver2.appspot.com/o/Files%2FResources%2FImportance%20of%20VHC%20use%20with%20every%20MDI.pdf?alt=media&token=4ec088a2-0fa0-484f-9db6-d74b3d753a35",
        icon: "document",
        typeOfResource: "provider",
      },
      {
        title: "Aerochamber - MDI with VHC vs DPI Lung Deposition ",
        link: "https://firebasestorage.googleapis.com/v0/b/medessist-ver2.appspot.com/o/Files%2FResources%2FMDI%20with%20VHC%20vs%20DPI%20Lung%20Deposition.pdf?alt=media&token=08cf668b-3d54-4d98-99fc-25d256b8bef3",
        icon: "document",
        typeOfResource: "provider",
      },
    ],
  },
];

const inhalerDeviceList = [
  {
    name: "Breezhaler",
    video: [
      {
        title:
          "Deutsche Atemwegsliga - Breezhaler® with Glycopyrronium Bromide (English)(3 min video)",
        link: "https://youtu.be/LZbKYUpYxK4",
        icon: "video",
        typeOfResource: "patient",
      },

      {
        title:
          "MedEssist - MedEssist Guide to Using Breezehale (2.5 min video)",
        link: "https://www.youtube.com/watch?v=XzgJLMeWozE",
        icon: "video",
        typeOfResource: "patient",
      },
    ],
  },
  {
    name: "Diskus",
    video: [
      {
        title:
          "Canadian Lung Association - How to use your Diskus (1 min video video)",
        link: "https://youtu.be/5yBCane4jeI",
        icon: "video",
        typeOfResource: "patient",
      },
      {
        title:
          "Boston Children's Hospital - How to use a Diskus Inhaler (2 min video video)",
        link: "https://www.youtube.com/watch?v=mfiShjE9P-Q",
        icon: "video",
        typeOfResource: "patient",
      },
    ],
  },
  {
    name: "Ellipta",
    video: [
      {
        title:
          "MedEssist - MedEssist Guide to using the Ellipta Inhaler (2 min video video)",
        link: "https://www.youtube.com/watch?v=2mPl6kNOWcA",
        icon: "video",
        typeOfResource: "patient",
      },
      {
        title:
          "Canadian Lung Association - How to use your Ellipta (1.5 min video) ",
        link: "https://www.youtube.com/watch?v=t_vTBm3pVPY",
        icon: "video",
        typeOfResource: "patient",
      },
    ],
  },
  {
    name: "Genuair",
    video: [
      {
        title:
          "The Lung Association - How to use a Genuair Inhaler (1.5 min video)",
        link: "https://youtu.be/_jV0dSA3GwE",
        icon: "video",
        typeOfResource: "patient",
      },
      {
        title:
          "MedEssist - MedEssist Guide to Using the Genuair Inhaler (2.5 min video)",
        link: "https://www.youtube.com/watch?v=XzgJLMeWozE",
        icon: "video",
        typeOfResource: "patient",
      },
    ],
  },
  {
    name: "Metered Dose Inhalers",
    video: [
      {
        title: "MedEssist - MedEssist Guide to MDI inhalers",
        link: "https://www.youtube.com/watch?v=DwekJbqzec8",
        videoLength: "2 min video",
        icon: "video",
        typeOfResource: "patient",
      },
      {
        title:
          "Nucleus Medical Media - How to Use a Metered-Dose Inhaler (3 min video)",
        link: "https://youtu.be/gFGM-8X98zs",
        icon: "video",
        typeOfResource: "patient",
      },
      {
        title:
          "Nucleus Medical Media - How to Use a Metered Dose Inhaler with a Spacer (4 min video)",
        link: "https://youtu.be/cjyf7lWnEBI",
        icon: "video",
        typeOfResource: "patient",
      },
    ],
  },
  {
    name: "Respimat",
    video: [
      {
        title:
          "MedEssist - MedEssist guide to the Respimat Inhaler (3 min video)",
        link: "https://www.youtube.com/watch?v=BFT6b61xYdU",
        icon: "video",
        typeOfResource: "patient",
      },
      {
        title: "Atrius Health - Using a Respimat Inhaler (4 min video)",
        link: "https://www.youtube.com/watch?v=BFT6b61xYdU",
        icon: "video",
        typeOfResource: "patient",
      },
    ],
  },
  {
    name: "Turbuhaler",
    video: [
      {
        title: "MedEssist - MedEssist Guide to the Turbuhaler (2 min video)",
        link: "https://www.youtube.com/watch?v=sf-CTimsxZg",
        icon: "video",
        typeOfResource: "patient",
      },
      {
        title:
          "NPS MedicineWise - Asthma treatment: how to use a Turbuhaler (1 min video)",
        link: "https://www.youtube.com/watch?v=FhmYs-XGvvQ",
        icon: "video",
        typeOfResource: "patient",
      },
    ],
  },
];

const injectionDeviceList = [
  {
    name: "Epipen",
    video: [
      {
        title:
          "EpiPen Canada - How to use EpiPen on Someone Else (3 min video)",
        link: "https://youtu.be/uBvdO9a9NTQ",
        icon: "video",
        typeOfResource: "patient",
      },
      {
        title: "EpiPen Canada - How to use EpiPen on Yourself (3 min video)",
        link: "https://youtu.be/9DKwnlyoGWc",
        icon: "video",
        typeOfResource: "patient",
      },
      {
        title:
          "Allergy & Anaphylaxis Australia - How to give an EpiPen (1.5 min video)",
        link: "https://www.youtube.com/watch?v=ROqxy9Gm4OM",
        icon: "video",
        typeOfResource: "patient",
      },
    ],
  },
  {
    name: "Subcutaneous Injections",
    video: [
      {
        title:
          "Prism Health - How to Admin videoister a Subcutaneous Injection – Using an Auto-injector Pen (3 min video)",
        link: "https://www.youtube.com/watch?v=oqt4cAa_w_U",
        icon: "video",
        typeOfResource: "patient",
      },
      {
        title:
          "Prism Health - How to Admin videoister a Subcutaneous Injection – Using a Vial (4.5 min video)",
        link: "https://www.youtube.com/watch?v=jt7SYhALtPk",
        icon: "video",
        typeOfResource: "patient",
      },
      {
        title: "Tan Tock Seng Hospital - Pen Insulin Injection (3.5 min video)",
        link: "https://youtu.be/ZofLlDgUSgw",
        icon: "video",
        typeOfResource: "patient",
      },
      {
        title:
          "Nucleus Media - Diabetes: How to Use an Insulin Pen (7 min video)",
        link: "https://www.youtube.com/watch?v=XTXzTDUf6rc",
        icon: "video",
        typeOfResource: "patient",
      },
    ],
  },
];

const naloxoneDeviceList = [
  {
    name: "Naloxone",
    video: [
      {
        title:
          "Kelley-Ross Group - Naloxone Training Video- Narcan® Nasal Spray (1.5 min video)",
        link: "https://www.youtube.com/watch?v=xa7X00_QKWk",
        icon: "video",
        typeOfResource: "patient",
      },
      {
        title:
          "Kelley-Ross Group - Naloxone Training Video-Injectable Naloxone (3 min video)",
        link: "https://www.youtube.com/watch?v=_ojGrGchyGc",
        icon: "video",
        typeOfResource: "patient",
      },
      {
        title: "State Maryland - Naloxone Training Video (1 min video)",
        link: "https://www.youtube.com/watch?v=p9hYzykHs_o",
        icon: "video",
        typeOfResource: "patient",
      },
    ],
  },
];

const otherDeviceList = [
  {
    name: "Eye and Ear drops",
    video: [
      {
        title: "MedEssist - MedEssist Guide to Using Eye Drops (1.5 min video)",
        link: "https://www.youtube.com/watch?v=Z2J1lNKBvoA",
        icon: "video",
        typeOfResource: "patient",
      },
      {
        title: "MedEssist- MedEssist Guide to Using Ear Drops (2 min video)",
        link: "https://www.youtube.com/watch?v=0mO3QBePg_0",
        icon: "video",
        typeOfResource: "patient",
      },
      {
        title: "Apotex - How to Use Ear Drops Properly (2 min video)",
        link: "htts://www.youtube.com/watch?v=65uAZr7TsAE",
        icon: "video",
        typeOfResource: "patient",
      },
    ],
  },
  {
    name: "Nasal Spray",
    video: [
      {
        title:
          "MedEssist - MedEssist Guide to Using Nasal Sprays (1.5 min video)",
        link: "https://www.youtube.com/watch?v=gpdvabH-kGA",
        icon: "video",
        typeOfResource: "patient",
      },
    ],
  },
  {
    name: "Nitroglycerin Spray",
    video: [
      {
        title:
          "MedEssist - MedEssist Guide to Using Nitroglycerin Spray (2 min video)",
        link: "https://youtu.be/5UtU1bSxFXE",
        icon: "video",
        typeOfResource: "patient",
      },
    ],
  },
];

export const allDevicesArray = [
  ...aerochamberDeviceList,
  ...inhalerDeviceList,
  ...injectionDeviceList,
  ...naloxoneDeviceList,
  ...otherDeviceList,
];

const deviceList = [
  {
    category: "AEROCHAMBER",
    array: aerochamberDeviceList,
  },
  {
    category: "INHALERS",
    array: inhalerDeviceList,
  },
  {
    category: "INJECTION DEVICES",
    array: injectionDeviceList,
  },
  {
    category: "NALOXONE",
    array: naloxoneDeviceList,
  },
  {
    category: "OTHER",
    array: otherDeviceList,
  },
];

export default function DeviceResources(props) {
  const { displayListItem } = props;
  const [resourceToDisplay, setResourceToDisplay] = useState({
    category: "AEROCHAMBER",
    array: aerochamberDeviceList,
  });

  const displayVideoList = () => {
    if (resourceToDisplay) {
      return resourceToDisplay?.array.map((links, index) => (
        <div key={index}>
          <h4>{links.name}</h4>

          <List>
            {links?.video.map((video, index) => displayListItem(video, index))}
          </List>
        </div>
      ));
    }
  };

  return (
    <div>
      <Tabs
        sx={{
          "& .MuiTabs-flexContainer": {
            flexWrap: "wrap",
            justifyContent: "inherit",
          },
        }}
        aria-label="basic tabs example"
        value={resourceToDisplay?.category}
      >
        {deviceList.map((resourceObject, index) => (
          <Tab
            key={index}
            label={resourceObject.category}
            value={resourceObject.category}
            onClick={() => {
              setResourceToDisplay(resourceObject);
            }}
          />
        ))}
      </Tabs>
      <div style={{ padding: "10px" }}>{displayVideoList()}</div>
    </div>
  );
}
