import React, { useState } from "react";
import {
  FormControl,
  InputLabel,
  OutlinedInput,
  InputAdornment,
  IconButton,
} from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";

const PasswordInput = ({
  label,
  placeholder,
  value,
  onChange,
  error,
  helperText,
  handleKeyDown,
}) => {
  const [showPassword, setShowPassword] = useState(false);

  const handleClickShowPassword = () => {
    setShowPassword((prev) => !prev);
  };

  return (
    <FormControl sx={{ mt: 2, width: "100%" }} variant="outlined">
      <InputLabel>{label}</InputLabel>
      <OutlinedInput
        id="outlined-adornment-password"
        type={showPassword ? "text" : "password"}
        value={value}
        onChange={onChange}
        placeholder={placeholder}
        fullWidth
        error={error}
        endAdornment={
          <InputAdornment position="end">
            <IconButton
              aria-label="toggle password visibility"
              onClick={handleClickShowPassword}
              edge="end"
            >
              {showPassword ? <VisibilityOff /> : <Visibility />}
            </IconButton>
          </InputAdornment>
        }
        label={label}
        onKeyDown={(e) => {
          if (e.key === "Enter" && handleKeyDown) {
            handleKeyDown();
          }
        }}
      />
      {error && helperText && <p style={{ color: "red" }}>{helperText}</p>}
    </FormControl>
  );
};

export default PasswordInput;
