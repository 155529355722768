import React from "react";
import {
  List,
  ListItem,
  ListItemText,
  Collapse,
  Typography,
} from "@mui/material";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
export default function ManageAccount({
  openManageAccount,
  setOpenManageAccount,
  handleDeleteAccount,
}) {
  const { t } = useTranslation();

  return (
    <>
      <ListItem button onClick={() => setOpenManageAccount(!openManageAccount)}>
        <ListItemText primary={t("accountSettings.accountSettings")} />
        {openManageAccount ? <ExpandLessIcon /> : <ExpandMoreIcon />}
      </ListItem>
      <Collapse in={openManageAccount} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          {/*  allow user to delete pharmacy account */}
          <ListItem>
            <Typography
              variant="body2"
              color="textSecondary"
              style={{ cursor: "pointer", textDecoration: "underline" }}
              component={Link}
              to={"/pinChange"}
            >
              Change myPIN
            </Typography>
          </ListItem>
          <ListItem>
            <Typography
              variant="body2"
              color="textSecondary"
              style={{ cursor: "pointer", textDecoration: "underline" }}
              onClick={handleDeleteAccount}
            >
              Delete My Account
            </Typography>
          </ListItem>
        </List>
      </Collapse>
    </>
  );
}
