import React, { useState } from "react";
import LoadingIcon from "../../images/loading.svg";
import "./blog.scss";
import { useTranslation } from "react-i18next";

const BlogList = () => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(true);

  const hideLoader = () => {
    setLoading(false);
  };

  return (
    <div
      style={{ display: "flex", alignItems: "center", flexDirection: "column" }}
    >
      <h1>{t("blogList.title")}</h1>
      {loading ? (
        <img className="loader" src={LoadingIcon} alt="Loading" />
      ) : (
        ""
      )}
      <iframe
        className="blog-frame"
        src="https://www.medessist.com/education-blog"
        onLoad={hideLoader}
      />
    </div>
  );
};

export default BlogList;
