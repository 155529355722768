import React from "react";
import { Modal, IconButton } from "@mui/material";
import { Close } from "@mui/icons-material";
import Quiz from "./Quizzes/Quiz";

function QuizModal({
  open,
  handleClose,
  courseData,
  passingScore,
  onQuizPassed,
}) {
  return (
    <Modal
      open={open}
      backdrop="static"
      // onClose={handleClose}
      sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}
    >
      <div className="quiz-container">
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{ position: "absolute", top: 0, right: 0, zIndex: 1000 }}
        >
          <Close />
        </IconButton>
        <Quiz
          courseData={courseData}
          passingScore={passingScore}
          onQuizPassed={onQuizPassed}
        />
      </div>
    </Modal>
  );
}

export default QuizModal;
