import React, { useState, useEffect } from "react";
import {
  Card,
  CardContent,
  CardMedia,
  Typography,
  Avatar,
  Box,
  IconButton,
  Menu,
  MenuItem,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { getCourse } from "../helpers/source";
import Rating from "@mui/material/Rating";
import instructorData from "./TabContent/instructorData";
import { useTranslation } from "react-i18next";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import QuizReviewModal from "../user-profiles/QuizReviewModal";
import CertificateDialog from "../user-profiles/CertificateDialog";
import { useSelector } from "react-redux";
import "./course-card.scss";
import { getAllCourses } from "../helpers/source";
import useCompletedCourses from "../../hooks/useCompletedCourses";
export default function CourseCard(props) {
  const {
    instructorName,
    courseName,
    description,
    category,
    imageURL,
    courseId,
    tutorial,
    languages,
    isExternal,
  } = props;
  const navigate = useNavigate();
  const user = useSelector((state) => state.user.value);
  const { getMetaDataOfCompletedCourse } = useCompletedCourses();
  const metaDataOfCompletedCourse = getMetaDataOfCompletedCourse(courseId);

  const { t } = useTranslation();

  // state to hold average rating
  const [averageRating, setAverageRating] = useState(0);

  // state to hold rating banner message
  const [ratingBannerMessage, setRatingBannerMessage] = useState("");
  const [anchorEl, setAnchorEl] = useState({});
  const [selectedCourse, setSelectedCourse] = useState("");
  const [openCertModal, setOpenCertModal] = useState(false);
  const [openQuizReview, setOpenQuizReview] = useState(false);
  const [allCoursesData, setAllCoursesData] = useState([]);

  const handleDropdownOpen = (event, id) => {
    event.stopPropagation();
    setAnchorEl({ ...anchorEl, [id]: event.currentTarget });
  };
  const handleDropdownClose = (event) => {
    event.stopPropagation();
    setAnchorEl({});
  };
  const closeCertificateModal = () => {
    setOpenCertModal(false);
  };
  const handleCoursesCertificate = (course) => {
    // setSelectedCourse(course);
    setOpenCertModal(true);
  };
  useEffect(() => {
    const fetchCourses = async () => {
      const coursesData = await getAllCourses();
      setAllCoursesData(coursesData);
    };
    fetchCourses();
  }, []);

  useEffect(() => {
    const fetchCourseData = async () => {
      const courseData = await getCourse(courseId);
      if (courseData) {
        const ratings = courseData?.ratings || {};
        const ratingsArray = Object.values(ratings);

        let totalRating = 0;
        let fiveStarRatingsCount = 0;

        for (let i = 0; i < ratingsArray.length; i++) {
          totalRating += ratingsArray[i];
          if (ratingsArray[i] === 5) {
            fiveStarRatingsCount++;
          }
        }

        const avgRating = totalRating / ratingsArray.length;

        setAverageRating(avgRating);

        // set rating banner message if 5-star ratings are 10 or more
        if (fiveStarRatingsCount >= 10) {
          setRatingBannerMessage("10+ 5 Star Ratings!");
        }
      }
    };

    fetchCourseData();
  }, [courseId]);

  const handleClick = () => {
    navigate(`${courseId}?source=internal`);
  };

  const instructorInfo = instructorData[instructorName];

  return (
    <>
      <QuizReviewModal
        openQuizReview={openQuizReview}
        setOpenQuizReview={setOpenQuizReview}
        courses={allCoursesData}
        selectedCourse={selectedCourse}
      />

      {/* Dialog to view and download Certificates */}
      <CertificateDialog
        openCertModal={openCertModal}
        closeCertificateModal={closeCertificateModal}
        userObject={user}
        course={props}
      />
      <Card
        className="course-card"
        onClick={handleClick}
        sx={{
          height: "400px",
          display: "flex",
          flexDirection: "column",
          borderRadius: 2,
        }}
      >
        <Box
          className="course-card__image-wrapper"
          sx={{ minHeight: tutorial ? "175px" : "118px" }}
        >
          <CardMedia
            className="course-card__image"
            component="img"
            image={imageURL}
            title={courseName}
            loading="lazy"
          />
        </Box>
        {ratingBannerMessage && (
          <div className="course-card__rating-banner">
            {ratingBannerMessage}
          </div>
        )}

        <CardContent
          sx={{ flexGrow: 1, display: "flex", flexDirection: "column" }}
        >
          <div>
            {tutorial ? (
              <>
                <br />
                <br />
              </>
            ) : (
              <>
                {languages && languages.length > 1 && (
                  <Typography
                    sx={{
                      color: "gray",
                      fontSize: "smaller",
                      mt: "1rem",
                      ml: "1rem",
                    }}
                  >
                    {t("courseCard.alsoAvailableIn")}
                  </Typography>
                )}
                {/*  no ratings for external courses */}
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  {!isExternal && (
                    <Rating value={averageRating} readOnly precision={0.5} />
                  )}
                  {metaDataOfCompletedCourse && (
                    <>
                      <IconButton
                        aria-label="more"
                        aria-controls={`course-menu-${courseId}`}
                        aria-haspopup="true"
                        onClick={(e) => {
                          handleDropdownOpen(e, courseId);
                        }}
                        edge="end"
                        sx={{ ml: 1 }}
                      >
                        <MoreVertIcon />
                      </IconButton>
                      <Menu
                        id={`course-menu-${courseId}`}
                        keepMounted
                        anchorEl={anchorEl[courseId]}
                        open={Boolean(anchorEl[courseId])}
                        onClose={handleDropdownClose}
                      >
                        <MenuItem
                          onClick={(e) => {
                            e.stopPropagation();
                            handleCoursesCertificate(props);
                            handleDropdownClose();
                          }}
                        >
                          Certificate
                        </MenuItem>
                        <MenuItem
                          onClick={(e) => {
                            e.stopPropagation();
                            setSelectedCourse(props);
                            setOpenQuizReview(true);
                            handleDropdownClose();
                          }}
                        >
                          {t("reviewQuiz.reviewTitle")}
                        </MenuItem>
                      </Menu>
                    </>
                  )}
                </div>
                {/* Display "Also available in..." message for languages */}
                <Typography
                  className="course-card__category"
                  variant="subtitle2"
                >
                  {category}
                </Typography>
              </>
            )}

            <Typography
              className="course-card__title"
              variant={tutorial ? "h5" : "h6"}
              style={{
                fontWeight: "bold",
                textOverflow: "ellipsis",
              }}
            >
              {courseName}
            </Typography>
            <Typography
              className="course-card__description"
              variant={tutorial ? "body1" : "body2"}
            >
              {description}
            </Typography>
          </div>

          {!tutorial && (
            <Box
              className="course-card__instructor-container"
              sx={{ display: "flex", alignItems: "center" }}
            >
              <Avatar
                src={instructorInfo?.avatar}
                alt={instructorName}
                className="course-card__avatar"
                style={{ marginRight: "1rem" }}
              />
              <Typography
                className="course-card__instructor-name"
                variant="subtitle1"
              >
                {instructorName}
              </Typography>
            </Box>
          )}
        </CardContent>
      </Card>
    </>
  );
}
