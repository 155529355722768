import React, { useEffect, useState } from "react";
import { useAuth } from "../contexts/AuthContext";
import { useTranslation } from "react-i18next";
import { Grid, Paper, Typography, CircularProgress } from "@mui/material";
import { useNavigate } from "react-router-dom";
import ReusableTextField from "../components/auth/form-components/ReusableTextField";
import PasswordInput from "../components/auth/form-components/PasswordInput";
import { useAuthenticatedUser } from "../zustand/store";
import { useLocation } from "react-router-dom";
import { linkPharmacy } from "../components/helpers/source";
import { useSnackbar } from "../contexts/SnackbarProvider";
export default function MedEssistSignOn() {
  const { currentUser, logOut, signIn } = useAuth();
  const { showSnackbar } = useSnackbar();
  const location = useLocation();
  // Use URLSearchParams to get the query parameters
  const searchParams = new URLSearchParams(location.search);
  const pharmacyId = searchParams.get("pharmacyId");
  const pharmacyName = searchParams.get("pharmacyName");
  const pharmacyPhone = searchParams.get("pharmacyPhone");

  const { setAuthenticated } = useAuthenticatedUser((state) => ({
    setAuthenticated: state.setAuthenticated,
  }));
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [error, setError] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const [errorText, setErrorText] = useState("");
  const paperStyle = {
    width: window.innerWidth > 450 ? "500px" : "fit-content",
    padding: "30px 20px",
    margin: "20px auto",
  };

  useEffect(() => {
    if (currentUser) {
      logOut()
        .then(() => {
          window.location.reload(false);
          console.log("Successfully logged out");
        })
        .catch(() => {
          console.log("Failed to log out");
        });
    }
  }, []);
  const handleSignIn = async (e) => {
    setErrorText("");
    setError(false);
    try {
      if (!email || !password) {
        setError(true);
        return;
      }
      setLoading(true);
      await new Promise((resolve) => setTimeout(resolve, 500));
      const userCredential = await signIn(email, password);
      if (pharmacyId && pharmacyName && pharmacyPhone) {
        await linkPharmacy(userCredential.user.uid, {
          pharmacyId,
          pharmacyName,
          pharmacyPhone,
        });
      }
      showSnackbar(`Successful log in, create/change PIN below`);
      setAuthenticated(true);
      navigate("/pinChange");
    } catch (err) {
      setLoading(false);
      switch (err.code) {
        case "auth/wrong-password":
          setError(true);
          setErrorText(t("signIn.incorrectPassword"));
          break;
        case "auth/user-not-found":
          setError(true);
          setErrorText(t("signIn.userNotFound"));
          break;
        default:
          console.log("Error:", err.message);
          setError(true);
          setErrorText(t("signIn.unexpectedError"));
          break;
      }
    }
  };
  return (
    <Grid>
      <Paper elevation={10} style={paperStyle}>
        <Grid align="center">
          <h2>{`Sign in to create/change PIN`}</h2>
        </Grid>
        {errorText && <Typography color="error">{errorText}</Typography>}
        <ReusableTextField
          label={t("signIn.emailLabel")}
          placeholder={t("signIn.emailPlaceholder")}
          value={email}
          error={!email && error}
          helperText={error && !email ? t("signIn.errorRequiredField") : ""}
          onChange={(e) => setEmail(e.target.value)}
          handleKeyDown={handleSignIn}
        />
        <PasswordInput
          label={t("signIn.passwordLabel")}
          placeholder={t("signIn.passwordPlaceholder")}
          value={password}
          error={!password && error}
          helperText={error && !password ? t("signIn.errorRequiredField") : ""}
          onChange={(e) => setPassword(e.target.value)}
          handleKeyDown={handleSignIn}
        />
        <button
          className="new-button-style primary teal-hover"
          style={{
            width: "100%",
            height: "50px",
            fontSize: "18px",
            margin: "20px 0px",
          }}
          fullWidth
          onClick={handleSignIn}
          disabled={loading}
        >
          {loading ? <CircularProgress size={24} /> : "Sign In"}
        </button>
      </Paper>
    </Grid>
  );
}
