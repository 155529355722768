import React from "react";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import {
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Avatar,
  Card,
  CardContent,
  Typography,
} from "@mui/material";
import instructorData from "./instructorData";
import resourcesData from "./resourcesData";
import DescriptionIcon from "@mui/icons-material/Description";
import ArticleIcon from "@mui/icons-material/Article";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import PublicIcon from "@mui/icons-material/Public";
import MenuBookIcon from "@mui/icons-material/MenuBook";
import SchoolIcon from "@mui/icons-material/School";
import DOMPurify from "dompurify";
import { useTranslation } from "react-i18next";
import PlayCircleIcon from "@mui/icons-material/PlayCircle";

export const resourceIcons = {
  blog: <ArticleIcon />,
  poster: <PictureAsPdfIcon />,
  ebook: <DescriptionIcon />,
  website: <PublicIcon />,
  journal: <MenuBookIcon />,
  video: <PlayCircleIcon />,
  course: <SchoolIcon />,
};

function TabContent({ selectedSection, courseData }) {
  const { t } = useTranslation();
  const { courseName, instructorName } = courseData;

  const renderAboutSection = () => {
    return (
      <Card variant="outlined">
        <CardContent>
          <Typography variant="h5" component="h2" sx={{ marginBottom: "15px" }}>
            {t("tabContent.aboutCourse")}
          </Typography>
          <Typography variant="body2" component="p">
            {courseData.description}
          </Typography>

          {courseData.learningObjectives && (
            <>
              <Typography
                variant="h6"
                component="h3"
                gutterBottom
                sx={{
                  marginTop: "20px",
                  fontWeight: "bold",
                  fontSize: "1.2rem",
                }}
              >
                {t("tabContent.learningObjectives")}
              </Typography>
              <List
                sx={{
                  padding: 0,
                  margin: 0,
                  "& .MuiListItem-root": {
                    paddingTop: "4px",
                    paddingBottom: "4px",
                    fontSize: "0.8rem",
                  },
                }}
              >
                {courseData.learningObjectives.map((objective, index) => (
                  <ListItem key={index}>
                    <ListItemIcon>
                      <CheckCircleIcon color="success" />
                    </ListItemIcon>
                    <ListItemText primary={objective} />
                  </ListItem>
                ))}
              </List>
            </>
          )}
        </CardContent>
      </Card>
    );
  };

  const renderResourcesByCourseName = () => {
    switch (courseName) {
      case "Deprescribing 101: A Practical Session For Pharmacists":
        return (
          <List>
            {resourcesData[courseName].map((resource, index) => (
              <ListItem key={index}>
                <ListItemIcon>{resourceIcons[resource.type]}</ListItemIcon>
                <ListItemText>
                  <a
                    href={resource.link}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {resource.title}
                  </a>{" "}
                  (Resource Type: {resource.type})
                </ListItemText>
              </ListItem>
            ))}
          </List>
        );
      default:
        return <p>{t("tabContent.noAdditionalResources")}</p>;
    }
  };

  const renderResourcesSection = () => {
    return (
      <Card variant="outlined">
        <CardContent>
          <Typography variant="h5" component="h2" sx={{ marginBottom: "15px" }}>
            {t("tabContent.resources")}
          </Typography>
          {renderResourcesByCourseName()}
        </CardContent>
      </Card>
    );
  };

  //Instructor section renders avatar, blurb, and designation for instructor based on instructor name
  const renderInstructorSection = () => {
    const instructorInfo = instructorData[instructorName];
    const sanitizedBlurb = DOMPurify.sanitize(instructorInfo?.blurb, {
      ADD_ATTR: ["target"],
    });

    return (
      <Card variant="outlined">
        <CardContent>
          <Typography variant="h5" component="h2" sx={{ marginBottom: "15px" }}>
            {t("tabContent.aboutPresenter")}
          </Typography>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              marginBottom: "15px",
            }}
          >
            <Avatar
              src={instructorInfo?.avatar}
              sx={{
                width: 100,
                height: 100,
                boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
              }}
            />
          </div>
          <Typography variant="body2" component="p" className="instructor-name">
            {instructorName}
          </Typography>
          <Typography
            variant="body2"
            component="p"
            className="instructor-designation"
            mb="15px"
          >
            {instructorInfo?.designation}
          </Typography>
          <div dangerouslySetInnerHTML={{ __html: sanitizedBlurb }} />
        </CardContent>
      </Card>
    );
  };

  return (
    <div className="course-content">
      {selectedSection === "about" && renderAboutSection()}
      {selectedSection === "instructor" && renderInstructorSection()}
      {selectedSection === "resources" && renderResourcesSection()}
    </div>
  );
}

export default TabContent;
