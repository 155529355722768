import React, { useState } from "react";
import { Select, FormControl, MenuItem, ListSubheader } from "@mui/material";
import { resourcesList } from "./resourceList";
import learningResources from "../courses/TabContent/resourcesData";
import "./resources.scss";
export default function ResourceListMobile(props) {
  const {
    linksToShow,
    setLinksToShow,
    searchQuery,
    setSearchQuery,
    userProfession,
		setFiltersTurnedOn
  } = props;
  const courseResources = learningResources && Object.keys(learningResources);

  return (
    <div className="resource-list-mobile">
      <FormControl
        sx={{
          width: " 90%",
          backgroundColor: "#CAE8E7",
          borderRadius: "20px",
          textAlign: "center",
        }}
      >
        <Select value={linksToShow.resourceType} sx={{ borderRadius: "20px" }}>
          <ListSubheader>Resources</ListSubheader>

          {resourcesList(userProfession)?.map((resource, index) => (
            <MenuItem
              key={index}
              value={resource.resourceType}
              onClick={() => {
                setSearchQuery("");
                setLinksToShow(resource);
								setFiltersTurnedOn([])
              }}
            >
              {resource?.resourceType}
            </MenuItem>
          ))}
          <ListSubheader>Course Resources</ListSubheader>
          {learningResources &&
            courseResources.map((courseResource, index) => (
              <MenuItem
                key={index}
                value={courseResource}
                onClick={() => {
                  setSearchQuery("");
									setFiltersTurnedOn([])
                  setLinksToShow({
                    resourceType: courseResource,
                    linkArrays: learningResources[courseResource],
                  });
                }}
              >
                {courseResource}
              </MenuItem>
            ))}
        </Select>
      </FormControl>
    </div>
  );
}
