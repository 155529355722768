import { addUserPharmacy } from "../../features/states/linkedPharmaciesSlice";
import { removePendingPharmacyRequestSliced } from "../../features/states/pendingPharmacyRequestsSlice";
import { linkPharmacy, deletePharmacyLinkRequest } from "./source";
export const isAPharmacist = (profession) => {
  if (!profession || profession === "Pharmacist") {
    return true;
  } else return false;
};

export const handleAcceptRequest = async (pharmacyObject, user, dispatch) => {
  await linkPharmacy(user.uid, pharmacyObject)
    .then(() => {
      dispatch(addUserPharmacy(pharmacyObject));
    })
    .catch((error) => {
      console.error("Error adding pharmacy", error);
    });
};

export const handleDeleteRequest = async (pharmacyId, user, dispatch) => {
  await deletePharmacyLinkRequest(user.uid, pharmacyId)
    .then(() => {
      dispatch(removePendingPharmacyRequestSliced(pharmacyId));
    })
    .catch((error) => {
      console.error("Error deleting request", error);
    });
};
