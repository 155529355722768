import { allDevicesArray } from "./DeviceResources";
import { resourcesList } from "./resourceList";
import learningResources from "../courses/TabContent/resourcesData";

export const getAllResources = (userProfession) => {
  let allResourcesArray = [];

  // first goes through our hard-coded resources and pushes the arrays into arraysToSearchThrough
  resourcesList(userProfession)?.forEach((category) =>
    [category?.linkArrays].forEach((linkArray) => {
      linkArray &&
        linkArray.forEach((link) => {
          allResourcesArray.push(link);
        });
    })
  );

  // searching the course specific resources
  learningResources &&
    Object.values(learningResources).map((linkArrays) =>
      linkArrays.forEach((linkArray) =>
        allResourcesArray.push({ ...linkArray })
      )
    );

  // searches the arrays in devices
  allDevicesArray.map((category) =>
    category.video.forEach((video) => {
      if (video && video?.icon) {
        allResourcesArray.push({ ...video });
      } else {
        allResourcesArray.push({ ...video, icon: "video" });
      }
    })
  );

  return allResourcesArray;
};

            // linkGroup?.typeOfResource === "patient" ? "#CAE8E7" : "#faf1c278",

export const getResourceTypeColour = (typeOfResource) => {
  switch (typeOfResource) {
    case "patient":
      return "#CAE8E7";
    // case "tutorial":
    //   return "rgb(255, 246, 246)";
    // case "custom":
    //   return "rgb(243, 243, 243)";
    default:
      return "#f2e9b978";
  }
};
