export const accountsTrialingAI = [
	"3LH6XJD80SM1AD499ZbqAdQ5tRz2",
	"04VrLq6Jp1U7b9UEOo6I2DwR6k23",
	"mm0Ft22M5GQ4srTdrit7csK3C7A3",
	"1J726u1Eh2goUiM5IwoMawLAinE3",
	"k2GIuytEluZion1OgvgipTIiOKA2",
	"cIs1sA5V77Q1ZyD5FxwXMUT002t1",
	"szuCQ5AlV9ONyhTYIure6IT51yg1",
	"ydTv3LLjCAfznLTaIZOMQBTJYoU2",
	"fbBz2KtxT2TLXJ4auyOqX9LfxUk1",
	"gLPBrLSkPcNv5llopfFpDq9eXD32",
	"bXobkVRWD9ZpKzaRSPpAbJTYqoA2",
	"LdWS9OKJTiTmyhNiEGJWHMNnyI03",
	"o8Gh3oUbHDU05PuS1v3vqOtkF273",
	"eQebqVuI1KMTTHdamiYXIXRBv672",
	"FqTWyvQ6W3NLklvfDjyU9wmXiqo2",
	"RGo9u1M8wDauEcMydTSi4Fqnmck1",
	"NUDchPVtwsfJnq5Iz53cuCryYzt1",
	"zSslSdPLC6OYc7CmavlbeT83wUB2",
	"d695ww0to2Q7X47PR9PkpQK24vo1",
	"PD9Jd9Pv5JQQcr8VWvdGUEtqkwA2",
	"lPwT5SImVLYlMldTAnkN8wswuI62", 
	"ihXGFYQidLg9PORi1zs3HTevPEF2", 
	"7UWgDD4TDud6XuGZEcpEdTwUfxy1", 
	"E0h6GPgn7vZUa6Dfyf1diDiReZu2", 
	"LjIJgMHMk6X7nX4d6VzN6j83USi2"
]