import React, { useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  DialogActions,
  Button,
  Typography,
  CircularProgress,
  Alert,
} from "@mui/material";
import { useAuth } from "../../contexts/AuthContext";

export default function ReauthenticateUser({
  open,
  onClose,
  handleDeleteAfterReauth,
}) {
  const { currentUser, reauthenticateUser } = useAuth();

  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");

  const handleSubmit = () => {
    const userEmail = currentUser.email;

    if (currentUser && userEmail) {
      setLoading(true);
      setError("");

      // call the reauthenticateUser function with the user's email and password
      // if reauthentication is successful, clear the password, close the dialog, and call handleDeleteAfterReauth()
      reauthenticateUser(userEmail, password)
        .then(() => {
          setPassword("");
          onClose();
          handleDeleteAfterReauth(userEmail, password);
        })
        .catch((error) => {
          console.error("Error re-authenticating user", error);
          setError("Wrong password. Please try again.");
        })
        .finally(() => {
          setLoading(false);
        });
    } else {
      console.error("Current user or email is not available");
    }
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Confirm Account Deletion</DialogTitle>
      <DialogContent>
        <Typography variant="body1">
          To ensure your account's security, please reenter your password to
          confirm that you want to delete your account. This action cannot be
          undone.
        </Typography>
        {/* Display an error message if there is one (eg wrong password) */}
        {error && (
          <Alert severity="error" style={{ marginTop: "16px" }}>
            {error}
          </Alert>
        )}
        {/* Input field for the password */}
        <TextField
          autoFocus
          label="Password"
          type="password"
          fullWidth
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          style={{ marginTop: "16px" }}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} disabled={loading}>
          Cancel
        </Button>
        <Button onClick={handleSubmit} color="secondary" disabled={loading}>
          Confirm Deletion
          {/* Show a loading spinner if the form is being submitted */}
          {loading && (
            <CircularProgress size={24} style={{ marginLeft: "10px" }} />
          )}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
