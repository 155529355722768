import React from "react";
import { Box, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import logo from "../../images/LogoWithOutline.png";

const ModuleParticipationCertificate = ({
  name,
  module,
  licenseNumber,
  certificateRef,
  completedDate,
}) => {
  const isFrenchCourse = (course) => {
    return course.courseId.includes("-fr");
  };

  const { t } = useTranslation();

  const completedDateStr = new Date(completedDate).toLocaleDateString("en-US", {
    year: "numeric",
    month: "long",
    day: "numeric",
  });

  const expirationDate = new Date(completedDate);
  expirationDate.setFullYear(expirationDate.getFullYear() + 1);
  const expirationDateStr = expirationDate.toLocaleDateString("en-US", {
    year: "numeric",
    month: "long",
    day: "numeric",
  });

  if (isFrenchCourse(module)) {
    //Statement of Participation for FRENCH course
    return (
      <Box
        className="certificate-outer-container"
        style={{ height: "720px" }}
        ref={certificateRef}
      >
        <img
          src="https://live.staticflickr.com/65535/53453876866_e5d1bbd7a8_o.png"
          alt="logo"
          style={{ display: "block", width: "100%", height: "100%" }}
        />
        <Box className="inner-container" id="certificate">
          {/* Certificate Title */}
          <Typography
            className="name"
            style={{
              marginTop: "3em",
              textTransform: "uppercase",
              color: "#EEB741",
              marginBottom: "5px",
            }}
          >
            DÉCLARATION DE PARTICIPATION
          </Typography>
          {/* Certificate Holder */}
          <Typography
            variant="h3"
            className="subtitle"
            style={{ marginBottom: "5px" }}
          >
            Ceci est pour reconnaître que
          </Typography>
          <Typography variant="h1" className="name user-name">
            {name}
          </Typography>
          <Typography variant="h6" className="license-number">
            Numéro de membre : {licenseNumber || ""}
          </Typography>

          {/* Course Completion Information */}
          <Typography variant="h4" className="subtitle" marginBottom={0.5}>
            A réussi le module
          </Typography>
          <Typography variant="h2" className="course">
            {module?.moduleName}
          </Typography>
          <Typography variant="h3" className="sub-course">
            Du cours: {module?.courseName}
          </Typography>
          {/* Completion Date */}
          <Typography
            variant="h4"
            className="date"
            style={{
              textTransform: "uppercase",
              fontWeight: "bold",
              color: "#ababab",
            }}
          >
            Date : {completedDateStr}
          </Typography>
          <div style={{ width: "100%" }}>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                margin: "20px 25px 5px 100px",
              }}
            >
              <div>
                <Typography variant="body2" component="p" gutterBottom>
                  Cette activité de formation continue est accréditée par
                  l’Ordre des pharmaciens du Québec <br />
                  {/*             Activité accréditée : {completedDateStr} <br />
                  Activité expire : {expirationDateStr} <br /> */}
                  Accréditation pour {module.creditsEarned} <br />
                  Type d'accréditation: Nouvelle activité d'apprentissage <br />
                  No d'accréditation : {module.accreditationNumber}
                </Typography>
              </div>
            </Box>
          </div>
          <Box className="medessist-container">
            <img src={logo} alt="MedEssist Logo" className="medessist-logo" />
            <Typography
              variant="h6"
              className="medessist"
              style={{ fontSize: "11px" }}
            >
              {t("courseCertificate.poweredBy")}{" "}
              <a
                href="http://www.hub.medessist.com"
                target="_blank"
                rel="noopener noreferrer"
                style={{ color: "inherit", textDecoration: "none" }}
              >
                www.hub.medessist.com
              </a>
            </Typography>
          </Box>
        </Box>
      </Box>
    );
  } else
    return (
      <Box
        className="certificate-outer-container"
        style={{ height: "720px" }}
        ref={certificateRef}
      >
        <img
          src="https://live.staticflickr.com/65535/53453876866_e5d1bbd7a8_o.png"
          alt="logo"
          style={{ display: "block", width: "100%", height: "100%" }}
        />
        <Box className="inner-container" id="certificate">
          {/* Certificate Title */}
          <Typography
            className="name"
            style={{
              marginTop: "3em",
              textTransform: "uppercase",
              color: "#EEB741",
              marginBottom: "5px",
            }}
          >
            STATEMENT OF PARTICIPATION
          </Typography>
          {/* Certificate Holder */}
          <Typography
            variant="h3"
            className="subtitle"
            style={{ marginBottom: "5px" }}
          >
            This is to acknowledge that
          </Typography>
          <Typography variant="h1" className="name user-name">
            {name}
          </Typography>
          <Typography variant="h6" className="license-number">
            License number {licenseNumber || ""}
          </Typography>
          {/* Course Completion Information */}
          <Typography variant="h3" className="subtitle">
            Has successfully completed
          </Typography>
          <Typography
            variant="h2"
            className="course"
            style={{
              wordBreak: "break-word",
              maxWidth: "85%",
              margin: "10px auto",
              textAlign: "center",
            }}
          >
            {module?.moduleName}
          </Typography>
          <Typography variant="p" className="sub-course">
            From the course: {module?.courseName}
          </Typography>
          {/* Completion Date */}
          <Typography
            variant="h4"
            className="date"
            style={{
              textTransform: "uppercase",
              fontWeight: "bold",
              color: "#ababab",
            }}
          >
            {t("courseCertificate.dated")} {completedDateStr}
          </Typography>

          <div style={{ width: "100%" }}>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                margin: "10px 25px 5px 100px",
              }}
            >
              <img
                src="https://firebasestorage.googleapis.com/v0/b/education-hub-848d2.appspot.com/o/educationHub%2FCCCEP%2FCCCEP%20logo%20ACCREDITED%20colour.jpg?alt=media&token=bed29524-0990-4042-b5bb-1bf0bb03c489"
                style={{ marginRight: "5px", height: "4rem" }}
                alt="cccep logo"
              />
              <div>
                <Typography variant="body2" component="p" gutterBottom>
                  CCCEP No. {module.accreditationNumber} <br />
                  {/*                 Activity Accredited {completedDateStr} <br />
                  Activity Expires {expirationDateStr} <br /> */}
                  Accreditation for {module.creditsEarned} <br />
                  Accreditation Type: New Learning Activity
                </Typography>
              </div>
            </Box>
          </div>
          <Box className="medessist-container">
            <img src={logo} alt="MedEssist Logo" className="medessist-logo" />
            <Typography
              variant="h6"
              className="medessist"
              style={{ fontSize: "16px" }}
            >
              {t("courseCertificate.poweredBy")}{" "}
              <a
                href="http://www.hub.medessist.com"
                target="_blank"
                rel="noopener noreferrer"
                style={{ color: "inherit", textDecoration: "none" }}
              >
                www.hub.medessist.com
              </a>
            </Typography>
          </Box>
        </Box>
      </Box>
    );
};

export default ModuleParticipationCertificate;
