import React from "react";
import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  TextField,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import useUserRedux from "../hooks/useUserRedux";
import { Autocomplete } from "@mui/material";
function ProfessionPracticeTypeCompany(props) {
  const user = useUserRedux();
  const { handlePracticeTypeSelector = () => {} } = props;
  const { profession, practiceType, company } = user;
  const { t } = useTranslation();
  const handlePracticeTypeChange = (event) => {
    handlePracticeTypeSelector({ practiceType: event.target.value });
  };

  const handleCompanyChange = (event, newValue) => {
    handlePracticeTypeSelector({ company: newValue });
  };

  const handleProfessionChange = (event) => {
    handlePracticeTypeSelector({ profession: event.target.value });
  };

  const pharmacistOptions = [
    "communityPharmacyCorporate",
    "communityPharmacyIndependent",
    "familyHealthTeam",
    "hospitalPharmacy",
    "pharmaceuticalIndustry",
    "academiaResearch",
    "governmentInsurance",
    "healthcareConsulting",
    "other",
  ];

  const otherProfessionOptions = [
    "community",
    "hospital",
    "publicHealth",
    "academia",
    "consulting",
    "other",
  ];
  const companyOptions = [
    "Rexall",
    "Shoppers Drug Mart",
    "Costco",
    "Walmart",
    "Metro",
    "Sobeys",
    "London Drugs",
    "Uniprix",
    "Jean Coutu",
  ];
  const getPracticeTypeOptions = () => {
    return profession === "Pharmacist"
      ? pharmacistOptions
      : otherProfessionOptions;
  };

  return (
    <div>
      <FormControl sx={{ mt: 2, width: "100%" }} variant="outlined">
        <InputLabel id="profession-type">
          {t("professionSelector.label")}
        </InputLabel>
        <Select
          labelId="profession-type"
          value={profession}
          onChange={(e) => handleProfessionChange(e)}
          label={t("professionSelector.label")}
          style={{ marginBottom: "10px" }}
        >
          <MenuItem value={"Pharmacist"}>
            {t("professionSelector.pharmacist")}
          </MenuItem>
          <MenuItem value={"Nurse Practitioner"}>
            {t("professionSelector.nursePractitioner")}
          </MenuItem>
          <MenuItem value={"Registered Nurse"}>
            {t("professionSelector.registeredNurse")}
          </MenuItem>
          <MenuItem value={"Physician"}>
            {t("professionSelector.physician")}
          </MenuItem>
          <MenuItem value={"Physician Assistant"}>
            {t("professionSelector.physicianAssistant")}
          </MenuItem>
        </Select>
      </FormControl>

      {/* Conditional rendering: Show practice type dropdown */}

      <FormControl sx={{ mt: 1, width: "100%" }} variant="outlined">
        <InputLabel id="practice-type">
          {t("practiceTypeSelector.label")}
        </InputLabel>
        <Select
          labelId="practice-type"
          id="practice-type-select"
          value={practiceType}
          onChange={(e) => handlePracticeTypeChange(e)}
          label={t("practiceTypeSelector.label")}
        >
          {getPracticeTypeOptions().map((type) => (
            <MenuItem key={type} value={type}>
              {t(`practiceTypeSelector.${type}`)}
            </MenuItem>
          ))}
        </Select>
      </FormControl>

      {/* if user answered Corporate, display dropdown to ask what company they work at */}
      {practiceType === "communityPharmacyCorporate" && (
        <FormControl sx={{ mt: 2, width: "100%" }} variant="outlined">
          <Autocomplete
            id="company-select"
            options={companyOptions}
            value={company}
            onChange={handleCompanyChange}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Which corporate do you primarily work at?"
                variant="outlined"
              />
            )}
          />
        </FormControl>
      )}
      {/* if user says "other", ask them to specify */}
    </div>
  );
}

export default ProfessionPracticeTypeCompany;
