import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useAuth } from "../contexts/AuthContext";
import { getUpdatingUserObject } from "../features/states/userSlice";

const useUserRedux = () => {
  const dispatch = useDispatch();
  const { currentUser } = useAuth();

  useEffect(() => {
    if (currentUser?.uid) {
      dispatch(getUpdatingUserObject(currentUser.uid));
    }
  }, [dispatch, currentUser]);

  const user = useSelector((state) => state.user.value);

  return user;
};

export default useUserRedux;
