import React, { useState } from "react";
import { Chip, Tooltip, Snackbar } from "@mui/material";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";

export default function CopyTextChip(props) {
  const { copyText, tooltip } = props;
  const [showCopySnackbar, setShowCopySnackbar] = useState(false);

  return (
    <>
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        open={showCopySnackbar}
        message="Copied!"
      />
      <Tooltip title={tooltip || "Copy Text"}>
        <Chip
          icon={<ContentCopyIcon fontSize="10px" />}
          style={{
            width: "40px",
            height: "30px",
            paddingLeft: "15px",
            margin: "5px",
          }}
          onClick={(event) => {
            event.stopPropagation();

            setShowCopySnackbar(true);
            setTimeout(() => {
              setShowCopySnackbar(false);
            }, 800);
            navigator.clipboard.writeText(copyText);
          }}
        />
      </Tooltip>
    </>
  );
}
