import React, { useState, useEffect } from "react";
import CompletedCourses from "./user-profile-sections/CompletedCourses";
import InProgressCourses from "./user-profile-sections/InProgressCourses";
import { getAllCourses } from "../helpers/source";
import { useTranslation } from "react-i18next";

export default function YourCourseListToggle({
  completedCourses,
  inProgressCourses,
  multiCreditCourseDetails,
  userObject,
}) {
  const [showCompletedCourses, setShowCompletedCourses] = useState(true);
  const [allCoursesData, setAllCoursesData] = useState([]);
  const { t } = useTranslation();

  useEffect(() => {
    const fetchCourses = async () => {
      const coursesData = await getAllCourses();
      setAllCoursesData(coursesData);
    };
    fetchCourses();
  }, []);

  return (
    <>
      <div className="courseToggleButtonContainer">
        <button
          className={`courseToggleButton ${
            showCompletedCourses
              ? "completedCourseButton"
              : "inProgressCourseButton"
          }`}
          onClick={() => setShowCompletedCourses(true)}
        >
          {t("coursesPreview.completedCourses")}
        </button>
        <button
          className={`courseToggleButton ${
            !showCompletedCourses
              ? "completedCourseButton"
              : "inProgressCourseButton"
          }`}
          onClick={() => setShowCompletedCourses(false)}
        >
          {t("coursesPreview.inProgress")}
        </button>
      </div>

      {showCompletedCourses ? (
        <CompletedCourses
          completedCourses={completedCourses}
          userObject={userObject}
          allCoursesData={allCoursesData}
          multiCreditCourseDetails={multiCreditCourseDetails}
        />
      ) : (
        <InProgressCourses
          inProgressCourses={inProgressCourses}
          userObject={userObject}
          allCoursesData={allCoursesData}
        />
      )}
    </>
  );
}
