import { useSelector } from "react-redux";
import { useMemo } from "react";
const useCompletedCourses = () => {
  const user = useSelector((state) => state.user.value);
  const { courses } = user;

  const coursesArray = Object.values(courses);
  const completedCourses = useMemo(() => {
    return coursesArray.filter(
      (course) => course?.completed && course?.courseId !== "tutorial"
    );
  }, [courses]);
  const completedBasalInsulinCourse = (course) => {
    if (course.completedSections?.basal_insulin_mastery_t2d_module_3) {
      return true;
    }
    return false;
  };
  const inProgress = coursesArray.filter(
    (course) => !completedBasalInsulinCourse(course) && !course.completed
  );

  const getMetaDataOfCompletedCourse = (courseId) => {
    return completedCourses.find(
      (queriedCourse) => queriedCourse?.courseId === courseId
    );
  };

  return {
    completedCourses,
    inProgress,
    completedBasalInsulinCourse,
    getMetaDataOfCompletedCourse,
  };
};

export default useCompletedCourses;
