import React, { useState } from "react";
import { resourcesList } from "./resourceList";
import learningResources from "../courses/TabContent/resourcesData";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import "./resources.scss";

import {
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from "@mui/material";

export default function ResourcesSideBar(props) {
  const {
    linksToShow,
    setLinksToShow,
    searchQuery,
    setSearchQuery,
    userProfession,
    setFiltersTurnedOn,
  } = props;

  const [showGeneralResources, setShowGeneralResources] = useState(true);
  const [showCourseResources, setShowCourseResources] = useState(true);
  const courseResources = learningResources && Object.keys(learningResources);

  const displayListItem = (resource, index) => {
    const isSelectedResource =
      (linksToShow?.resourceType === resource?.resourceType ||
        linksToShow?.resourceType === resource) &&
      !searchQuery;
    return (
      <ListItem
        className="resource-side-bar-item"
        sx={{
          backgroundColor: isSelectedResource && "#51AFAA",
          color: isSelectedResource && "white",
          borderRadius: isSelectedResource && "20px",
          "& .MuiListItemButton-root:hover": {
            background: !isSelectedResource && "#CAE8E7",
            color: !isSelectedResource && "black",
          },
        }}
        key={index}
        disablePadding
      >
        <ListItemButton
          className="resource-side-bar-item"
          onClick={() => {
            setSearchQuery("");

            if (typeof resource === "string") {
              setLinksToShow({
                resourceType: resource,
                linkArrays: learningResources[resource],
              });
              setFiltersTurnedOn([]);
            } else {
              setLinksToShow(resource);
              setFiltersTurnedOn([]);
            }
          }}
        >
          <ListItemText
            primary={
              typeof resource === "string" ? resource : resource?.resourceType
            }
          />
        </ListItemButton>
      </ListItem>
    );
  };

  const displayHeaderListButton = (setShow, show, title) => {
    return (
      <ListItem disablePadding>
        <ListItemButton
          // sx =
          onClick={() => {
            setShow(!show);
          }}
        >
          <ListItemText primary={title} />
          <ListItemIcon>
            {show ? <ExpandLessIcon /> : <ExpandMoreIcon />}
          </ListItemIcon>
        </ListItemButton>
      </ListItem>
    );
  };
  return (
    <>
      <div className="resource-side-bar" style={{ width: "350px" }}>
        <List>
          {displayHeaderListButton(
            setShowGeneralResources,
            showGeneralResources,
            "Resources"
          )}

          <List sx={{ pl: 4 }}>
            {showGeneralResources &&
              resourcesList(userProfession)?.map((resource, index) =>
                displayListItem(resource, index)
              )}
          </List>
          {displayHeaderListButton(
            setShowCourseResources,
            showCourseResources,
            "Course Resources"
          )}

          <List sx={{ pl: 4 }}>
            {showCourseResources &&
              learningResources &&
              courseResources.map((courseResource, index) =>
                displayListItem(courseResource, index)
              )}
          </List>
        </List>
      </div>
    </>
  );
}
