import React from "react";
import { useState, useEffect } from "react";
import { Typography, Dialog } from "@mui/material";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";

export default function QuizReviewModal({
  openQuizReview,
  setOpenQuizReview,
  courses,
  selectedCourse,
}) {
  const [selectedReview, setSelectedReview] = useState();

  useEffect(() => {
    courses &&
      setSelectedReview(
        courses.filter((x) => x.courseId === selectedCourse.courseId)
      );
  }, [selectedCourse, courses]);

  return (
    <Dialog
      open={openQuizReview}
      onClose={() => setOpenQuizReview(false)}
      fullWidth
      maxWidth="xl"
      sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}
    >
      <div style={{ margin: "2rem" }}>
        <b>Review Answers</b> <br />
        <br />
        {selectedReview?.length > 0
          ? selectedReview[0]?.quiz &&
            selectedReview[0]?.quiz.map((question, index) => {
              // check if answer correct
              return (
                <div key={index}>
                  <Typography variant="body1">
                    {index + 1}. {question.question}
                    {question.answers &&
                      question.answers.map((x) => {
                        const isCorrect = x === question.correctAnswer;

                        return (
                          <Typography
                            variant="body2"
                            sx={{
                              // color: isCorrect ? "green" : "black",
                              marginLeft: "2rem",
                              fontWeight: isCorrect && "bold",
                            }}
                          >
                            {isCorrect && (
                              <CheckCircleOutlineIcon
                                sx={{
                                  color: "green",
                                  verticalAlign: "middle",
                                  fontSize: "1.1rem",
                                }}
                              />
                            )}{" "}
                            {x}
                          </Typography>
                        );
                      })}
                  </Typography>
                  <br />
                </div>
              );
            })
          : "We're sorry, but the quiz you are trying to review is not available."}
      </div>
    </Dialog>
  );
}
