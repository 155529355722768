import React, { useState } from "react";
import {
  Typography,
  CssBaseline,
  TextField,
  Snackbar,
  Dialog,
  DialogTitle,
  DialogContent,
} from "@mui/material";
import { handleSendCustomEmail } from "../helpers/source";
import "./Teach.scss";

function TeachOnMedEssist() {
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [linkedin, setLinkedin] = useState("");
  const [message, setMessage] = useState("");
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [errors, setErrors] = useState({
    //required fields are first, last name, email and message (NOT linkedin profile)
    firstName: false,
    lastName: false,
    email: false,
    message: false,
  });
  const [profession, setProfession] = useState("");
  const [openTeach, setOpenTeach] = useState(false);

  //performs the error handling for required fields
  const errorHandlingForForm = () => {
    const newErrors = {
      firstName: firstName === "",
      lastName: lastName === "",
      email: email === "",
      message: message === "",
      profession: profession === "",
    };

    setErrors(newErrors);
    if (
      newErrors.firstName ||
      newErrors.lastName ||
      newErrors.email ||
      newErrors.message
    ) {
      //return false if theres an error somewhere
      return false;
    }
    //return true if no errors
    return true;
  };

  //submit the form
  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!errorHandlingForForm()) {
      //end form submission if not true, indicating there's an error
      return;
    }
    //send email to us with the form details they filled out so we get their request submission
    const data = {
      customMessage: `<div>First Name: ${firstName}<br>Last Name: ${lastName}<br>Email: ${email}<br>LinkedIn: ${linkedin}<br>Message: ${message}. Profession: ${profession}</div>`,
      firstName,
      lastName,
      email: "bita@medessist.com",
      appointmentId: "",
      pharmacyPhone: "Education Hub",
      pharmacyId: "",
      registration: "Education Hub",
      pharmacyName: "MedEssist Education - Creator Request",
      subject: "Education Hub - Creator Request",
    };

    handleSendCustomEmail(data)
      .then(() => {
        //show confirmation snack bar
        setShowConfirmation(true);
        //reset form fields
        setFirstName("");
        setLastName("");
        setEmail("");
        setLinkedin("");
        setMessage("");
      })
      .catch((error) => {
        console.error("Error sending interest submission", error);
      });
  };

  return (
    <>
      <CssBaseline />
      <Dialog
        component="form"
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        open={openTeach}
        onClose={() => {
          setOpenTeach(false);
        }}
        onSubmit={handleSubmit}
      >
        <div className="teach-form">
          <DialogTitle>Teach With Us</DialogTitle>
          <DialogContent style={{ display: "flex", flexDirection: "column" }}>
            {/* First Name field */}
            <TextField
              label="First Name"
              value={firstName}
              onChange={(e) => setFirstName(e.target.value)}
              variant="outlined"
              sx={{ marginTop: 2 }}
              error={errors.firstName}
              helperText={errors.firstName && "First name is required"}
            />
            {/* Last name field */}
            <TextField
              label="Last Name"
              value={lastName}
              onChange={(e) => setLastName(e.target.value)}
              variant="outlined"
              sx={{ marginTop: 2 }}
              error={errors.lastName}
              helperText={errors.lastName && "Last name is required"}
            />
            {/* Profession field */}
            <TextField
              label="Profession and Area of Expertise"
              value={profession}
              onChange={(e) => setProfession(e.target.value)}
              variant="outlined"
              sx={{ marginTop: 2 }}
              error={errors.profession}
              helperText={errors.profession && "Profession is required"}
            />
            {/* Email Field */}
            <TextField
              label="Email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              variant="outlined"
              sx={{ marginTop: 2 }}
              error={errors.email}
              helperText={errors.email && "Email is required"}
            />
            {/*  Optional Linkedin Field */}
            <TextField
              label="LinkedIn Profile (optional)"
              value={linkedin}
              onChange={(e) => setLinkedin(e.target.value)}
              variant="outlined"
              sx={{ marginTop: 2 }}
            />
            {/*  Message field */}
            <TextField
              label="What topic are you interested in teaching?"
              multiline
              rows={4}
              value={message}
              onChange={(e) => setMessage(e.target.value)}
              variant="outlined"
              sx={{ marginTop: 2 }}
              error={errors.message}
              helperText={errors.message && "Message is required"}
            />

            {/* submit button */}
            <button
              className="new-button-style primary teal-hover"
              style={{ marginTop: "2rem" }}
            >
              Submit Interest
            </button>
          </DialogContent>
        </div>
      </Dialog>

      <div>
        <Typography variant="h5" gutterBottom>
          <b>Teach on MedEssist Hub</b>
        </Typography>

        <Typography variant="body1" sx={{ marginTop: 2 }}>
          As a platform dedicated to advancing professional healthcare
          education, MedEssist Hub welcomes others to share their expertise in
          the field. If you are someone with valuable knowledge to share with
          the healthcare community, we invite you to share your teachings on our
          platform. We provide comprehensive support, from course development to
          video production.
          <br /> <br />
        </Typography>
        <button
          type="button"
          className="new-button-style primary"
          style={{ width: "150px" }}
          onClick={() => setOpenTeach(true)}
        >
          Teach with us!
        </button>
      </div>
      {/* snackbar to show user confirmation that submission was sent */}
      <Snackbar
        open={showConfirmation}
        autoHideDuration={6000}
        onClose={() => setShowConfirmation(false)}
        message="Thank you for submitting your interest in contributing to the Education Hub!"
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      />
    </>
  );
}

export default TeachOnMedEssist;
