import React, { useEffect, useState, useRef } from "react";
import NewQueryInput from "./NewQueryInput";
import ChooseSourceTypeFilter from "./ChooseSourceTypeFilter";
import ResponseAI from "./ResponseAI";
import SourcesAccordion from "./SourcesAccordion";
import FollowUpQuestionTextInput from "./FollowUpQuestionTextInput";
import HistoryIcon from "@mui/icons-material/History";
import {
  addNewQuery,
  getCurrentUserObject,
  getAIPosts,
  addToAIQuery,
} from "../components/helpers/source";
import { useAuth } from "../contexts/AuthContext";
import FeedbackAI from "./FeedbackAI";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Chip,
} from "@mui/material";
import { fetchEventSource } from "@microsoft/fetch-event-source";
import CopyTextChip from "./CopyTextChip";

export default function AIConversations({ openAI }) {
  const { currentUser } = useAuth();
  const [userObject, setUserObject] = useState(null);
  const [currentQuery, setCurrentQuery] = useState("");
  const [loading, setLoading] = useState(false);
  const [selectedConversation, setSelectedConversation] = useState();
  const [allConversations, setAllConversations] = useState([]);
  const [sources, setSources] = useState();
  const [messageReceived, setMessageReceived] = useState("");
  const [answerCompleteFlag, setAnswerCompleteFlag] = useState(false);
  const [messageToSend, setMessageToSend] = useState({
    query: "",
    chat_history: [], //if it's a follow up, it will be in the form of [[question1, answer1], [question2, answer2]]. for new query it's an empty array
    response_mode: "default", //this is for sources...if you want to get only sources (and no text) it will be "no_text"
    api_key: process.env.REACT_APP_PENDIUM_HEALTH_KEY,
    filters: { source_type: { $in: ["guidelines", "drugs", "general"] } },
  });
  let ctrl = new AbortController();
  const scrollRef = useRef(null);

  useEffect(() => {
    getCurrentUserObject(currentUser.uid).then((res) => setUserObject(res));
  }, [currentUser]);

  useEffect(() => {
    if (userObject?.uid) {
      getAIPosts(setAllConversations, userObject?.uid);
    }
  }, [userObject?.uid]);

  // this is used if they exit the ai while it is still getting the response. it will trigger an end, and saves to db
  useEffect(() => {
    if (messageReceived && !answerCompleteFlag && !openAI) {
      setAnswerCompleteFlag(true); //this will trigger the functions in the useeffect to add to DB
      setMessageToSend({ ...messageToSend, query: "" });
      ctrl.abort();
      ctrl = new AbortController();
    }
  }, [openAI]);

  useEffect(() => {
    // thsee are triggered once we recieve DONE from the websocket
    if (answerCompleteFlag && messageReceived.pending) {
      const finalMessage = messageReceived?.pending?.replace(
        new RegExp("\\b" + "DONE" + "\\b", "gi"),
        ""
      );

      // resets query object
      setMessageToSend({
        ...messageToSend,
        chat_history: convertObjectToArray(),
        response_mode: "default",
        filters: { source_type: { $in: ["guidelines", "drugs", "general"] } },
      });

      const sendToDB = async () => {
        // adds response to DB
        await addResponse(finalMessage);
      };
      sendToDB()
        .then(() => {
          setMessageReceived("");
          setSources([]);
          setLoading(false);
          // keep track of number of AI searches sent each month
          // updateMonthlyMetrics(user.userId, "aiSearches", 1);
        })
        .catch((err) => console.log(err));
    }
  }, [answerCompleteFlag]);

  // // firebase does not support nested arrays, but the AI requires chat_history in nested array. need to convert firebase chat_history (array of objects)into array of array to be submitted to websocket
  const convertObjectToArray = () => {
    let chatHistoryInArray = [];
    selectedConversation?.chat_history.map((questionAndAnswerPair) => {
      chatHistoryInArray.push([
        questionAndAnswerPair?.question,
        questionAndAnswerPair?.answer,
      ]);
    });
    return chatHistoryInArray;
  };

  const handleSubmit = async (e) => {
    setLoading(true);

    if (currentQuery.replace(/(\r\n|\n|\r)/gm, "") && !loading) {
      e.preventDefault();
      setAnswerCompleteFlag(false);
      if (messageToSend?.chat_history?.length === 0) {
        //if it's an initial query (and not a follow up)

        sendQueryToAPI(messageToSend);
        // socketRef.current.send(JSON.stringify(messageToSend));
        const fetchedQueryID = await addNewQuery(
          userObject.uid,
          messageToSend
          // userObject.pharmacyName
        );
        setSelectedConversation({ ...messageToSend, docId: fetchedQueryID });
        scrollRef.current.scrollIntoView({ behavior: "smooth" }); //scolls to response div
      } else {
        // if it's a follow up
        sendQueryToAPI({
          ...messageToSend,
          chat_history: convertObjectToArray(),
        });
      }
    }
  };

  const sendQueryToAPI = (message) => {
    fetchEventSource("https://pendiumdev.com/stream", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(message),
      signal: ctrl.signal,
      openWhenHidden: true,

      onopen(res) {
        if (res.ok && res.status === 200) {
          console.log("Connection Established");
        } else if (
          res.status >= 400 &&
          res.status < 500 &&
          res.status !== 429
        ) {
          console.log("Client Side Failure", res);
        }
      },

      onmessage(event) {
        const data = event.data;
        if (data === "DONE") {
          setAnswerCompleteFlag(true); //this will trigger the functions in the useeffect to add to DB
          setMessageToSend({ ...messageToSend, query: "" });
        } else if (data.includes("source_documents")) {
          let sources = JSON.parse(event.data).source_documents;
          setSources(sources);
        } else {
          // console.log(event.data);
          setMessageReceived((state) => ({
            ...state,
            pending: (state.pending ?? "") + event.data.replace(/\\n/g, "\n"), //replaces newline values
          }));
        }
      },
      onclose() {
        console.log("Connection closed");
      },
      onerror(err) {
        alert("An error has occurred. Please try again later");
        ctrl.abort();
        ctrl = new AbortController();
      },
    });
  };

  const addResponse = async (response) => {
    // adds the new question, answer to the AI query
    await addToAIQuery(
      {
        chat_history: [
          ...selectedConversation?.chat_history,
          {
            question: currentQuery,
            answer: response,
            sources: sources || [],
            timestamp: new Date(),
          },
        ],
      },
      selectedConversation?.docId
    );

    // adds the question, answer, sources to state
    setSelectedConversation({
      ...selectedConversation,
      chat_history: [
        ...selectedConversation?.chat_history,
        { question: currentQuery, answer: response, sources: sources },
      ],
    });
  };

  return (
    <div className="ai-container">
      <div className="ai-content">
        <div className="marketing-intro">
          <div style={{ display: "flex" }}>
            <h2>
              <b>MedEssist AI</b>{" "}
            </h2>
            <Chip
              label="Beta"
              variant="outlined"
              color="primary"
              style={{
                borderColor: "gray",
                color: "gray",
                margin: "0 0.5rem",
                alignSelf: "center",
              }}
            />
          </div>
          <div>
            <span
              style={{
                fontSize: "12px",
                marginLeft: "0.5em",
                marginRight: "1em",
              }}
            >
              developed in partnership with{" "}
            </span>
            <a
              href="https://pendium.health/"
              target="_blank"
              rel="noopener noreferrer"
            >
              {" "}
              <img
                style={{ width: "130px" }}
                src={
                  "https://pendium.health/wp-content/uploads/2023/08/pendium-health-logo-3x.png"
                }
              />{" "}
            </a>
          </div>
        </div>

        <div className="marketing-description">
          <h5>Ask and receive answers to any drug related question</h5>
          <ChooseSourceTypeFilter
            messageToSend={messageToSend}
            setMessageToSend={setMessageToSend}
          />
        </div>

        <NewQueryInput
          selectedConversation={selectedConversation}
          setSelectedConversation={setSelectedConversation}
          messageToSend={messageToSend}
          setMessageToSend={setMessageToSend}
          handleSubmit={handleSubmit}
          setCurrentQuery={setCurrentQuery}
          loading={loading}
        />
        <div style={{ fontSize: "smaller", color: "gray" }}>
          <b>Disclaimer</b>: This information is exclusively intended for
          healthcare providers and for general informational purposes only. It
          is not a substitute for professional medical advice, diagnosis, or
          treatment. Healthcare providers should exercise their clinical
          judgment when making decisions based on this information.
        </div>

        <div className="ai-message-output">
          {/* previous conversations */}
          <div
            className="chat-history"
            hidden={!allConversations || allConversations?.length < 1}
            style={{ paddingBottom: "1em" }}
          >
            <h4 style={{ textAlign: "left", fontSize: "1.2em" }}>
              <HistoryIcon /> Chat History
            </h4>
            {allConversations.map((conversation) => (
              <Accordion
                ref={scrollRef}
                className="chat-history-block"
                style={{
                  borderTopLeftRadius: "20px",
                  borderTopRightRadius: "20px",
                  borderBottomLeftRadius: "20px",
                  borderBottomRightRadius: "20px",
                }}
                key={conversation?.docId}
                expanded={selectedConversation?.docId === conversation?.docId}
                sx={{
                  textAlign: "left",
                  borderRadius: "20px",
                  fontSize: "1em",
                  marginTop: "1em",
                  marginBottom: "1em",
                  boxShadow: "none",
                  position: "inherit",
                  ...(selectedConversation?.docId === conversation?.docId && {
                    backgroundColor: "#FFF1C0",
                  }),
                  ...(selectedConversation?.docId !== conversation?.docId && {
                    backgroundColor: "#DAF4F3",
                  }),
                }}
              >
                <AccordionSummary
                  sx={{ padding: "1em" }}
                  onClick={() => {
                    if (!loading) {
                      if (selectedConversation?.docId !== conversation?.docId) {
                        setMessageToSend({ ...messageToSend, query: "" });
                        setSelectedConversation(conversation);
                      } else {
                        setSelectedConversation(null);
                      }
                    }
                  }}
                >
                  <b
                    style={{
                      color:
                        selectedConversation?.docId === conversation?.docId
                          ? "#947820"
                          : "#2b8481",
                    }}
                  >
                    {conversation?.initialQuery}
                    {selectedConversation?.docId === conversation?.docId && (
                      <CopyTextChip
                        tooltip={"Copy question"}
                        copyText={conversation?.initialQuery}
                      />
                    )}
                  </b>
                </AccordionSummary>
                <AccordionDetails>
                  {/* <button
                    onClick={() => {
                      console.log(conversation?.docId);
                      deleteQuery(conversation?.docId);
                    }}
                  >
                    delete
                  </button> */}
                  <>
                    {conversation?.chat_history?.map((message, index) => (
                      <div key={index}>
                        {index !== 0 && (
                          <div style={{ color: "#947820", fontWeight: "bold" }}>
                            <br />
                            {message?.question}
                            <CopyTextChip
                              tooltip={"Copy question"}
                              copyText={message?.question}
                            />
                          </div>
                        )}
                        <br />

                        <span>
                          <ResponseAI
                            response={message?.answer || null}
                            sources={
                              message?.sources ? message?.sources : sources
                            }
                            conversation={conversation}
                            messageReceived={messageReceived}
                            index={index}
                            selectedConversation={selectedConversation}
                            setSelectedConversation={setSelectedConversation}
                          />

                          <FeedbackAI
                            conversation={conversation}
                            message={message}
                            index={index}
                            setSelectedConversation={setSelectedConversation}
                            selectedConversation={selectedConversation}
                          />
                        </span>
                        {message?.sources?.length > 0 && (
                          <SourcesAccordion
                            message={message}
                            conversation={conversation}
                            index={index}
                            setSelectedConversation={setSelectedConversation}
                            selectedConversation={selectedConversation}
                          />
                        )}
                      </div>
                    ))}
                    <FollowUpQuestionTextInput
                      handleSubmit={handleSubmit}
                      conversation={conversation}
                      selectedConversation={selectedConversation}
                      setCurrentQuery={setCurrentQuery}
                      messageToSend={messageToSend}
                      setAnswerCompleteFlag={setAnswerCompleteFlag}
                      answerCompleteFlag={answerCompleteFlag}
                      setMessageToSend={setMessageToSend}
                      loading={loading}
                    />
                  </>
                  <ResponseAI
                    sources={sources}
                    conversation={conversation}
                    messageReceived={messageReceived}
                    index={null}
                    selectedConversation={selectedConversation}
                    setSelectedConversation={setSelectedConversation}
                  />
                </AccordionDetails>
              </Accordion>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}
