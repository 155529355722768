import React, { useState } from "react";
import ListItemButton from "@mui/material/ListItemButton";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { Snackbar, Tooltip } from "@mui/material";
import SendEmailModal from "../emailAndText/SendEmailModal";
import SendTextModal from "../emailAndText/SendTextModal";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import PhoneIcon from "@mui/icons-material/Phone";
import "./resources.scss";
import { actionCounter } from "../helpers/source";
import QRButtonResources from "./QRButtonResources";

export default function CopyAndEmailResourcesButtons(props) {
  const { linkObj, resourceType } = props;

  const [showCopySnackbar, setShowCopySnackbar] = useState(false);
  const [showSendEmailModal, setShowSendEmailModal] = useState(false);
  const [showSendTextModal, setShowSendTextModal] = useState(false);
	
  return (
    <div className="email-text-buttons">
      <SendEmailModal
        showSendEmailModal={showSendEmailModal}
        setShowSendEmailModal={setShowSendEmailModal}
        // message={linkObj?.link}
        message={`
        <a href=${linkObj?.link} rel="noopener noreferrer" target="_blank">${linkObj?.title}</a>`}
      />{" "}
      <SendTextModal
        showSendTextModal={showSendTextModal}
        setShowSendTextModal={setShowSendTextModal}
        message={linkObj?.link}
      />
      <div style={{ display: "flex" }}>
        <ListItemButton
          onClick={() => {
            setShowCopySnackbar(true);
            setTimeout(() => {
              setShowCopySnackbar(false);
            }, 800);
            navigator.clipboard.writeText(linkObj?.link);
          }}
        >
          <Tooltip title="Copy Link">
            <ContentCopyIcon />
          </Tooltip>

          <Snackbar
            anchorOrigin={{ vertical: "top", horizontal: "center" }}
            open={showCopySnackbar}
            message="Copied!"
          />
        </ListItemButton>
        <ListItemButton
          onClick={() => {
            setShowSendEmailModal(true);
            if (
              linkObj?.title.includes("Aerochamber") &&
              resourceType === "Devices"
            ) {
              actionCounter("trudell", "aerochamberSendEmailClicked");
            }
          }}
        >
          <Tooltip title="Email Link">
            <MailOutlineIcon />
          </Tooltip>
        </ListItemButton>
        <ListItemButton
          onClick={() => {
            setShowSendTextModal(true);
            if (
              linkObj?.title.includes("Aerochamber") &&
              resourceType === "Devices"
            ) {
              actionCounter("trudell", "aerochamberSendTextClicked");
            }
          }}
        >
          <Tooltip title="Text Link">
            <PhoneIcon />
          </Tooltip>
        </ListItemButton>
        <QRButtonResources link={linkObj?.link} />
      </div>
    </div>
  );
}
