import React, { useState, useEffect } from "react";
import { CircularProgress, Rating, Typography } from "@mui/material";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import {
  markSectionAsComplete,
  updateCoursesForUser,
  getCurrentUserObject,
  rateCourse,
} from "../../helpers/source";
import { useTranslation } from "react-i18next";

const SectionDone = ({
  currentStepObject,
  handlePreviousStep,
  handleNextStep,
  currentUser,
  courseId,
  setCompletedSections,
  completedSections,
  numberOfSections,
  hardCodedCourseContent,
}) => {
  const { t } = useTranslation();
  const [isCompleting, setIsCompleting] = useState(false); // Tracks if the completion process is ongoing
  const [isCompleted, setIsCompleted] = useState(false); // Indicates if the current section is marked as completed from before
  const [areAllSectionsCompleted, setAllSectionsCompleted] = useState(false); // Indicates if all sections of the course are completed
  const [rating, setRating] = useState(0);
  const [submittedRating, setSubmittedRating] = useState(false);
  const [thankYouMessage, setThankYouMessage] = useState("");

  useEffect(() => {
    const sectionId = currentStepObject?.sectionId;
    if (!completedSections?.[sectionId]) {
      // If not already marked as completed
      markSectionComplete(sectionId);
    } else {
      setIsCompleted(true); // Set completion state if already completed
    }

    // Check if all sections are completed
    if (
      completedSections &&
      Object.keys(completedSections)?.length === numberOfSections
    ) {
      setAllSectionsCompleted(true);
    }

    // Fetch the rating from the db
    const fetchRating = async () => {
      if (currentUser) {
        const userObject = await getCurrentUserObject(currentUser?.uid);
        const courses = userObject?.courses || [];

        if (Object.keys(courses).includes(courseId)) {
          const courseRating = courses?.[courseId]?.rating;
          setRating(courseRating || 0);

          //check if user has already submitted a rating
          if (courseRating) {
            setSubmittedRating(true);
          }
        }
      }
    };

    fetchRating();
  }, [
    currentStepObject,
    completedSections,
    currentUser,
    courseId,
    numberOfSections,
  ]);

  const markSectionComplete = async (sectionId) => {
    setIsCompleting(true); // Start the completion process
    try {
      await markSectionAsComplete({
        userId: currentUser?.uid,
        courseId,
        sectionId,
      });
      const updatedCompletedSections = {
        ...completedSections,
        [sectionId]: true,
      };
      setCompletedSections(updatedCompletedSections); // Update state to include the new completion

      // Check if all sections are completed
      if (Object.keys(updatedCompletedSections).length === numberOfSections) {
        setAllSectionsCompleted(true);
        //if all section completed, mark as completed in db in user collection
        await updateCoursesForUser({
          userId: currentUser?.uid,
          courseId: courseId,
          videoWatched: true,
          pass: true,
        });
      }
      setIsCompleted(true); // Mark current section as completed
    } finally {
      setIsCompleting(false); // End the completion process
    }
  };

  const handleChangeRating = async (event, newValue) => {
    setRating(newValue);

    if (newValue > 0) {
      await rateCourse({
        userId: currentUser.uid,
        courseId: courseId,
        rating: newValue,
      });

      // Change thank you message based on whether user has already submitted a rating
      if (submittedRating) {
        setThankYouMessage(t("interactiveCourse.ratingUpdated"));
      } else {
        setThankYouMessage(t("interactiveCourse.ratingThanks"));
      }

      setSubmittedRating(true);
    }
  };

  //last section is the last module OR evaluation if it has one
  const isLastSection =
    currentStepObject?.sectionId === `module-${numberOfSections}` ||
    currentStepObject?.sectionId === `evaluation`;

  return (
    <div
      className="centered-container"
      style={{
        textAlign: "center",
        padding: 20,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <h2
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <CheckCircleIcon
          style={{ color: "green", fontSize: 40, margin: "5px" }}
        />{" "}
        {t("interactiveCourse.congratulations")}
      </h2>
      <p>
        {isCompleting
          ? t("interactiveCourse.finalizingProgress")
          : isCompleted && !areAllSectionsCompleted
          ? t("interactiveCourse.moduleCompleted")
          : t("interactiveCourse.finishedModule")}
      </p>
      {areAllSectionsCompleted && t("interactiveCourse.allModulesCompleted")}
      <br />

      {isCompleting && <CircularProgress />}
      <br />

      {/* Rating section */}
      {areAllSectionsCompleted && (
        <div style={{ marginBottom: "20px", textAlign: "center" }}>
          <Typography variant="h6">
            {t("interactiveCourse.rateCourse")}
          </Typography>
          <Rating
            name="course-rating"
            value={rating}
            onChange={handleChangeRating}
          />
          {thankYouMessage && (
            <Typography
              variant="body2"
              style={{ marginTop: "10px", color: "green" }}
            >
              {thankYouMessage}
            </Typography>
          )}
        </div>
      )}

      <div style={{ display: "flex", justifyContent: "center", gap: "10px" }}>
        <button
          onClick={handlePreviousStep}
          className="new-button-style secondary"
        >
          {t("interactiveCourse.back")}
        </button>
        {/* hide next module button when on last section */}
        {!areAllSectionsCompleted && !isLastSection && (
          <button onClick={handleNextStep} className="new-button-style primary">
            {t("interactiveCourse.startNextModule")}
          </button>
        )}
        {areAllSectionsCompleted && (
          <button
            className="new-button-style primary"
            onClick={() =>
              window.open(hardCodedCourseContent.surveyLink, "_blank")
            }
          >
            {t("interactiveCourse.completeSurvey")}{" "}
          </button>
        )}
      </div>
    </div>
  );
};

export default SectionDone;
