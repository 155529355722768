import { createSlice } from "@reduxjs/toolkit";
import { onSnapshot, doc } from "firebase/firestore";
import { db } from "../../lib/firebase/config";

export const licenseNumberSlice = createSlice({
  name: "licenseNumber",
  initialState: {
    value: {
      licenseNumber: "",
    },
  },
  reducers: {
    setLicenseNumber: (state, action) => {
      state.value = action.payload;
    },
  },
});
export const getUpdatingLicenseNumber = (userId) => async (dispatch) => {
  const userDocRef = doc(db, "users", userId);
  const unsubscribe = onSnapshot(userDocRef, (doc) => {
    if (doc.exists()) {
      // Document exists, update data
      const updatedUserData = doc.data().licenseNumber;
      dispatch(setLicenseNumber(updatedUserData));
      // Dispatch an action or update local state with updatedUserData
    } else {
      // Document doesn't exist
      console.log("User document no longer exists");
    }
  });
  return unsubscribe;
};

export const { setLicenseNumber } = licenseNumberSlice.actions;

export default licenseNumberSlice.reducer;
