import { useState, useEffect } from "react";
import { useAuth } from "../../contexts/AuthContext";
import { getCurrentUserObject } from "../helpers/source";
import { updateCoursesForUser } from "../helpers/source";
import { addToTrackRegistration } from "../helpers/source";
import { useNavigate, useLocation } from "react-router-dom";

/* 
 This is a custom hook that handles the shared logic in
 both SearchieCoursePage and CoursePage components for Course Enrollment

 it checks to see if the user is enrolled in a course by looking at the user object

 also, if you enroll in a course, it updates the user object 
 
 the component returns an object containing the state variables and the handleCourseEnrollment function
 and handleSignInToEnroll function
 */

const useCourseEnrollment = (courseData) => {
  const navigate = useNavigate();
  const location = useLocation();

  const { currentUser } = useAuth();

  const {
    courseId,
    courseName,
    instructorName,
    multipleCreditCourse = false,
  } = courseData;

  const [enrolled, setEnrolled] = useState(null);
  const [isLoadingEnrollment, setIsLoadingEnrollment] = useState(false);
  const [isEnrollmentFetched, setIsEnrollmentFetched] = useState(false);

  useEffect(() => {
    if (currentUser && courseId) {
      getCurrentUserObject(currentUser.uid).then((res) => {
        setEnrolled(
          res?.courses[courseId]?.enrolled
            ? res?.courses[courseId]?.enrolled
            : false
        );
        setIsEnrollmentFetched(true);
      });
    }
  }, [currentUser, courseId]);

  const handleCourseEnrollment = async (source) => {
    if (currentUser) {
      setIsLoadingEnrollment(true);

      await updateCoursesForUser({
        userId: currentUser.uid,
        courseId,
        courseName,
        instructorName,
        enrolled: true,
        multipleCreditCourse,
        source: source || "direct",
      });

      addToTrackRegistration(courseId);
      setTimeout(() => {
        setEnrolled(true);
        setIsLoadingEnrollment(false);
      }, 1000);
    }
  };

  const handleSignInToEnroll = (source) => {
    const module = new URLSearchParams(location.search).get("module");
    const step = new URLSearchParams(location.search).get("step");
    // Navigate to login page with module, step, and source parameters
    navigate(
      `/login?module=${module}${step ? `&step=${step}` : ""}${
        source ? `&source=${source}` : ""
      }`,
      {
        state: { from: location, source },
      }
    );
  };

  return {
    enrolled,
    isLoadingEnrollment,
    isEnrollmentFetched,
    handleCourseEnrollment,
    handleSignInToEnroll,
  };
};

export default useCourseEnrollment;
