import { db } from "../../lib/firebase/config";
import { onSnapshot, doc } from "firebase/firestore";
import { createSlice } from "@reduxjs/toolkit";
export const linkedPharmaciesSlice = createSlice({
  name: "linkedPharmacies",
  initialState: {
    value: {
      linkedPharmacies: [],
    },
  },
  reducers: {
    setLinkedPharmacies: (state, action) => {
      state.value = action.payload;
    },
    addUserPharmacy: (state, action) => {
      state.value.push(action.payload);
    },
    removeUserPharmacy: (state, action) => {
      const pharmacyIdToRemove = action.payload;
      state.value = state.value.filter(
        (request) => request.pharmacyId !== pharmacyIdToRemove
      );
    },
  },
});

// this is a thunk function, and it's used to carry out async functions that the redux reducers
// can't do. since onSnapshot is how we fetch real time changes (ie. async fn), we put this updating
// data function in a separate function then it is called in a useEffect whenever it is dispatched or mounted
export const getUpdatingLinkedPharmacies = (userId) => async (dispatch) => {
  const userDocRef = doc(db, "users", userId);
  const unsubscribe = onSnapshot(userDocRef, (doc) => {
    if (doc.exists()) {
      // Document exists, update data
      const updatedUserData = doc.data().linkedPharmacies;
      dispatch(setLinkedPharmacies(updatedUserData));
      // Dispatch an action or update local state with updatedUserData
    } else {
      // Document doesn't exist
      console.log("User document no longer exists");
    }
  });
  return unsubscribe;
};
export const { setLinkedPharmacies, addUserPharmacy, removeUserPharmacy } =
  linkedPharmaciesSlice.actions;
export default linkedPharmaciesSlice.reducer;
