import React, { useEffect } from "react";
import { Typography, Box } from "@mui/material";
import { getAllResources } from "./resourceFunctions";

export default function SearchResourceList(props) {
  const {
    searchQuery,
    displayListItem,
    userProfession,
    filtersTurnedOn,
    linksWithSearchItem,
    setLinksWithSearchItem,
  } = props;

  const filteredArrays = (allResourceArrays) => {
    return allResourceArrays.filter((linkItem) =>
      filtersTurnedOn.includes(linkItem?.typeOfResource)
    );
  };

  const allResourcesArray = getAllResources(userProfession);

  useEffect(() => {
		const allResourceArrays = getAllResources(userProfession);

    let arraysToSearchThrough = [];
    let postiveSearchList = [];
    //if theres any filter on, it will search through those with filters, otherwise, all arrays
    if (filtersTurnedOn.length > 0) {
      arraysToSearchThrough = filteredArrays(allResourcesArray);
    } else {
      arraysToSearchThrough = allResourcesArray;
    }

    //if theres any filter on, it will search through those with filters, otherwise, all arrays
    if (filtersTurnedOn.length > 0) {
      arraysToSearchThrough = filteredArrays(allResourceArrays);
    } else {
      arraysToSearchThrough = allResourceArrays;
    }

    // goes through arraysToSearchThrough to find if any of them have the search item
    arraysToSearchThrough.forEach((link) => {
      if (
        link?.title?.toLowerCase().includes(searchQuery.toLowerCase()) ||
        link?.group?.toLowerCase().includes(searchQuery.toLowerCase()) ||
        link?.otherKeywords?.includes(searchQuery.toLowerCase())
      ) {
        postiveSearchList.push(link);
      }
    });

    setLinksWithSearchItem(postiveSearchList);
  }, [searchQuery]);

  return (
    <div>
      {linksWithSearchItem.length > 0 ? (
        linksWithSearchItem
          .sort((a, b) => a.title.localeCompare(b.title))
          .map((linkObj, index) => displayListItem(linkObj, index))
      ) : (
        <div>
          {" "}
          <Box sx={{ mt: 4, textAlign: "center" }}>
            <Typography variant="p">
              {searchQuery
                ? `No results found for "${searchQuery}".`
                : `No results found`}
            </Typography>
          </Box>
        </div>
      )}
    </div>
  );
}
