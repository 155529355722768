import { Button } from "@mui/material";
import { styled } from "@mui/material/styles";

export const PrimaryButton = styled(Button)(({ theme }) => ({
  textTransform: "none",
  padding: "10px 1em 10px 1em",
  transition: "all 0.4s",
  borderRadius: "20px",
  minWidth: "120px",
  maxWidth: "200px",
  fontWeight: "600",
  background: "#229d98",
  color: "white",
  "&:hover": {
    background: "#46c4bf",
  },

  [theme.breakpoints.down("md")]: {
    margin: "auto",
  },
}));

export const SecondaryButton = styled(Button)({
  textTransform: "none",
  padding: "10px 1em 10px 1em",
  transition: "all 0.4s",
  borderRadius: "20px",
  minWidth: "120px",
  maxWidth: "200px",
  fontWeight: "600",
  background: "#FFF1C0",
  color: "black",
  "&:hover": {
    background: "#ffde69",
  },
});
