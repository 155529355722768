import React from "react";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";

export default function ChooseSourceTypeFilter(props) {
  const { setMessageToSend, messageToSend, backgroundColor } = props;

  const availableOptions = [
    {
      label: "All",
      value: "All",
      // value: { $in: ["guidelines", "drugs", "general"] },
    },
    {
      label: "Drug Monographs",
      value: "drugs",
    },
    {
      label: "Practice Guidelines",
      value: "guidelines",
    },
    {
      label: "General Knowledge",
      value: "general",
    },
    {
      label: "Sources Only",
      value: "Sources Only",
    },
  ];
  return (
    <div>
      <Select
        variant="standard"
				disableUnderline
        sx={{
          backgroundColor: backgroundColor ? backgroundColor : "#DAF4F3",
          padding: "20px",
          height: "2.5rem",
          borderRadius: "20px",
          borderBottom: "none",

        }}
        onChange={(e) => {
          if (e.target.value === "Sources Only") {
            setMessageToSend({
              ...messageToSend,
              filters: {
                source_type: { $in: ["guidelines", "drugs", "general"] },
              },
              response_mode: "no_text",
            });
          } else if (e.target.value === "All") {
            setMessageToSend({
              ...messageToSend,
              filters: {
                source_type: { $in: ["guidelines", "drugs", "general"] },
              },
              response_mode: "default",
            });
          } else {
            setMessageToSend({
              ...messageToSend,
              filters: {
                source_type: e.target.value,
              },
              response_mode: "default",
            });
          }
        }}
        value={
          messageToSend?.response_mode === "no_text"
            ? "Sources Only"
            : typeof messageToSend?.filters?.source_type == "string"
            ? messageToSend?.filters?.source_type
            : "All"
        }
      >
        {availableOptions.map((option) => (
          <MenuItem value={option.value}>{option.label}</MenuItem>
        ))}
      </Select>
    </div>
  );
}
