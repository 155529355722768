import { configureStore } from "@reduxjs/toolkit";
import userSlice from "../features/states/userSlice";
import pendingPharmacyRequestsSlice from "../features/states/pendingPharmacyRequestsSlice";
import linkedPharmaciesSlice from "../features/states/linkedPharmaciesSlice";
import myPinSlice from "../features/states/myPinSlice";
import licenseNumberSlice from "../features/states/licenseNumberSlice";
import doNotShowInitPopUpSlice from "../features/states/doNotShowInitPopUpSlice";
export default configureStore({
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        // Ignore these action types
        ignoredActions: ["user/setUser"],
        // Ignore these paths in the state
        ignoredPaths: ["user.value.courses"],
      },
    }),
  reducer: {
    user: userSlice,
    pendingPharmacyRequests: pendingPharmacyRequestsSlice,
    linkedPharmacies: linkedPharmaciesSlice,
    myPin: myPinSlice,
    licenseNumber: licenseNumberSlice,
    doNotShowInitPopUp: doNotShowInitPopUpSlice,
  },
});
