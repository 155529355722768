import React from "react";

export default function PageTitleHeader(props) {
  const { pageTitle } = props;
  return (
    <>
      <div
        style={{
          width: "100%",
          padding: "1em",
          margin: "1em 0 1em 0",
          borderRadius: "10px",
          background: "#DAF4F3",
          fontWeight: "bold",
        }}
      >
        {pageTitle}
      </div>
    </>
  );
}
