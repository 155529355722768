import React, { useState } from "react";
import _ from "lodash";
import FormControlLabel from "@mui/material/FormControlLabel";
import { Modal, Box, Checkbox, TextField } from "@mui/material";
import { addToAIQuery } from "../components/helpers/source";

export default function ExtraFeedbackModal(props) {
  const {
    showExpandFeedBackModal,
    setShowExpandFeedbackModal,
    conversation,
    message,
    index,
    setSelectedConversation,
    selectedConversation,
  } = props;

  const [feedbackAnswers, setFeedbackAnswers] = useState([]);
  const [otherFeedbackAnswer, setOtherFeedbackAnswer] = useState([]);
  const unhelpfulReasons = [
    "Inaccurate information",
    "Inaccurate sources",
    "Outdated",
    "Too lengthy",
    "Formatting",
    "Missing Guidelines",
  ];

  return (
    <Modal
      open={showExpandFeedBackModal}
      backdrop="static"
      onClose={() => {
        setShowExpandFeedbackModal(false);
        setOtherFeedbackAnswer([]);
        setFeedbackAnswers([]);
      }}
    >
      <Box className="emailAndTextContainer">
        <h3>What was wrong with this answer?</h3>
        <div style={{ display: "grid", gridTemplateColumns: "1fr 1fr" }}>
          {unhelpfulReasons.map((reason) => (
            <FormControlLabel
              key={reason}
              control={
                <Checkbox
                  onClick={(e) => {
                    const feedbackCopy = _.cloneDeep(feedbackAnswers);

                    if (e.target.checked) {
                      feedbackCopy.push(reason);
                      setFeedbackAnswers(feedbackCopy);
                    } else {
                      const filteredAnswers = feedbackCopy.filter(
                        (existingAnswers) => existingAnswers !== reason
                      );
                      setFeedbackAnswers(filteredAnswers);
                    }
                  }}
                />
              }
              label={reason}
            />
          ))}
        </div>
        <br />
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            marginBottom: "1rem",
          }}
        >
          Other:{" "}
          <TextField
            sx={{ width: "60%" }}
            onChange={(e) => {
              setOtherFeedbackAnswer([e.target.value]);
            }}
          />
        </div>
        <button
          disabled={
            feedbackAnswers.length === 0 && otherFeedbackAnswer.length === 0
          }
          className="new-button-style primary"
          onClick={(e) => {
            const chatHistoryCopy = _.cloneDeep(conversation?.chat_history);
            chatHistoryCopy[index]["poorFeedbackReasons"] = [
              ...feedbackAnswers,
              ...otherFeedbackAnswer,
            ];
            addToAIQuery(
              { chat_history: chatHistoryCopy },
              conversation?.docId
            );

            setSelectedConversation({
              ...selectedConversation,
              chat_history: chatHistoryCopy,
            });
            setShowExpandFeedbackModal(false);
          }}
        >
          Submit{" "}
        </button>
      </Box>
    </Modal>
  );
}
