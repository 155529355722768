import React, { useEffect, useRef, useState } from "react";
import Vimeo from "@vimeo/player";
import Tooltip from "@mui/material/Tooltip";
import { useTranslation } from "react-i18next";

function VimeoPlayer({
  src,
  handleVideoCompletion,
  handlePreviousStep,
  currentStepObject,
  isVideoCompleted,
  onTimeUpdate,
}) {
  const { t } = useTranslation();
  const playerRef = useRef(null);
  const [player, setPlayer] = useState(null);
  const [loading, setLoading] = useState(true);
  const [showContinue, setShowContinue] = useState(isVideoCompleted);

  useEffect(() => {
    if (playerRef.current && !player) {
      // Only initialize the player if it hasn't been initialized yet
      const newPlayer = new Vimeo(playerRef.current, {
        url: src,
        width: 640,
        byline: false,
        portrait: false,
        title: false,
      });

      newPlayer.on("loaded", () => {
        setLoading(false);
      });

      newPlayer.on("ended", () => {
        setShowContinue(true);
        // Automatically mark as complete when video ends
        handleVideoCompletion(currentStepObject?.videoId);
      });

      // Listen for time updates and pass the current time to the parent
      newPlayer.on("timeupdate", (data) => {
        // onTimeUpdate happens when a user posts a question to forums
        if (onTimeUpdate) {
          onTimeUpdate(data.seconds); // current time in seconds
        }
      });

      setPlayer(newPlayer);
    }

    return () => {
      if (player) {
        player.off("loaded");
        player.off("ended");
        player.off("timeupdate");
        player.destroy();
      }
    };
  }, [src, player]);

  const handleContinue = () => {
    setShowContinue(false);
    handleVideoCompletion(currentStepObject?.videoId);
  };

  return (
    <div className="centered-container">
      {loading && <p>{t("interactiveCourse.loadingVideo")}</p>}
      <div ref={playerRef} className="vimeo-video-wrapper" />
      <div
        className="button-container"
        style={{ marginTop: "20px", textAlign: "center" }}
      >
        <button
          onClick={handlePreviousStep}
          className="new-button-style secondary"
          style={{ marginRight: "10px" }}
        >
          {t("interactiveCourse.back")}
        </button>

        <Tooltip
          title={showContinue ? "" : t("interactiveCourse.watchEntireVideo")}
        >
          <button
            disabled={!showContinue}
            className="new-button-style primary"
            onClick={handleContinue}
          >
            {t("interactiveCourse.continue")}
          </button>
        </Tooltip>
      </div>
    </div>
  );
}

export default VimeoPlayer;
