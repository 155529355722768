import React from "react";
import { useNavigate } from "react-router-dom";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useTranslation } from "react-i18next";

const BackButton = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const handleGoBack = () => {
    navigate("/");
  };

  return (
    <div
      onClick={handleGoBack}
      style={{
        display: "flex",
        justifyContent: "flex-end",
        alignItems: "flex-start",
        width: "100%",
        cursor: "pointer",
        marginBottom: "1rem",
      }}
    >
      <ArrowBackIcon />
      {t("backButton.back")}
    </div>
  );
};

export default BackButton;
