import React, { useEffect, useState } from "react";
import "./courses.scss";
import CoursePage from "./CoursePage";
import SearchieCoursePage from "./SearchieCoursePage";
import ExternalCoursePage from "./ExternalCoursePage";
import InteractiveCoursePage from "./InteractiveCoursePage";
import { getCourse } from "../helpers/source";
import { useParams, useNavigate } from "react-router-dom";
import { useAuth } from "../../contexts/AuthContext";

function CourseFromDB() {
  const { courseId } = useParams();
  const [courseData, setCourseData] = useState(null);
  const { currentUser } = useAuth();

  const navigate = useNavigate();

  // fetch course data from db
  useEffect(() => {
    getCourse(courseId).then((res) => {
      if (res === null) {
        navigate("/notfound/404");
      } else {
        setCourseData(res);
      }
    });
  }, [courseId]);

  if (!courseData) {
    return <div>Loading...</div>; // Render loading state while data is being fetched
  }

  // Check if the course is hosted externally
  if (courseData.isExternal) {
    return (
      <ExternalCoursePage courseData={courseData} currentUser={currentUser} />
    );
  }

  // Check if searchieId exists to render searchie component
  if (courseData.searchieId) {
    return (
      <SearchieCoursePage courseData={courseData} currentUser={currentUser} />
    );
  }

  // Check if interactive course
  if (courseData.interactiveCourse) {
    return (
      <InteractiveCoursePage
        courseData={courseData}
        currentUser={currentUser}
      />
    );
  }

  // default to rendering the CoursePage component (regular vimeo course)
  return <CoursePage courseData={courseData} currentUser={currentUser} />;
}

export default CourseFromDB;
