import React from "react";
import { useState } from "react";
import EmailModal from "./EmailModal";
import "./podcastsLinks.scss";
import { useTranslation } from "react-i18next";
import applePodcasts from "./podcast icons/applepodcasts.png";
import googlePodcasts from "./podcast icons/google-podcasts-icon.png";
import rssFeedIcon from "./podcast icons/rssfeed.png";
import spotifyIcon from "./podcast icons/spotify.png";

export default function PodcastsLinks() {
  const { t } = useTranslation();
  const [openModal, setOpenModal] = useState(false);
  const [showOptions, setShowOptions] = useState(false);
  const handleCloseModal = () => {
    setOpenModal(false);
  };
  const handleOpenModal = () => {
    setOpenModal(true);
  };
  const linkStyles = { textDecoration: "none", fontSize: "smaller" };

  const iconStyles = { width: "30px", marginRight: "10px" };

  const universalPodcastLink =
    "https://podcasts.helloaudio.fm/subscribe/f74ca3cd-ddee-4e92-9886-72d8c797e113/r3L8HOi0qu";

  const handleSubscribeClick = () => {
    showOptions ? setShowOptions(false) : setShowOptions(true);
  };

  return (
    <div
      className="podcast-subscribe-button"
      onClick={() => handleSubscribeClick()}
    >
      <EmailModal open={openModal} handleClose={handleCloseModal} />
      <div style={{ width: "100%" }}>
        {t("podcastsLinks.subscribePodcast")} <br />
      </div>
      <div
        style={{
          opacity: !showOptions ? "0" : "1",
          transition: "all .2s",
          height: !showOptions ? "0" : "195px",
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          visibility: !showOptions ? "hidden" : "visible",
        }}
      >
        <a style={linkStyles} href={universalPodcastLink} target="_blank">
          <div
            className="platform-button"
            style={{
              marginTop: "10px",
            }}
          >
            <img style={iconStyles} src={applePodcasts} /> Apple Podcasts
          </div>
        </a>
        <a style={linkStyles} href={universalPodcastLink} target="_blank">
          <div className="platform-button">
            <img style={iconStyles} src={googlePodcasts} /> Google Podcasts
          </div>
        </a>
        <a style={linkStyles} href={universalPodcastLink} target="_blank">
          <div className="platform-button">
            <img style={iconStyles} src={rssFeedIcon} /> RSS Feed
          </div>
        </a>
        <div
          className="platform-button"
          style={{
            fontSize: "smaller",
          }}
          onClick={handleOpenModal}
        >
          <img style={iconStyles} src={spotifyIcon} /> Spotify
        </div>
      </div>
    </div>
  );
}
