const glpCourseEng = {
  numberOfSections: 4,
  courseId: "managing-t2d-using-glp1ra",
  courseTitle: "Managing Type 2 Diabetes with GLP-1 RA",
  courseDescription:
    "Welcome to the MedEssist Practical Points for Pharmacists Series! This 4-module series covers Managing Type 2 Diabetes using GLP1-RAs.",
  courseAccreditation: {
    accreditationNumber: "CCCEP Accreditation #: 1751-2024-3752-I-P",
    ceus: "CEUs: 1.50",
    statementOfParticipation:
      "After completing this course, you will receive a Statement of Participation.",
  },
  collaborationStatement:
    "This interactive course has been designed in collaboration with LMC Pharmacy. LMC is Canada’s largest specialist care provider in Diabetes & Endocrinology. Diabetes Educator Pharmacists on the team have created this series to provide practical and relevant knowledge to help you in your community practice.",
  learningObjectives: [
    "Describe the GLP-1 RAs available in Canada, including their mechanism of action, benefits and place in therapy of Type 2 Diabetes.",
    "Demonstrate GLP-1 RA treatment initiation, including efficient injection training.",
    "Identify GLP-1 RA side effects and management to help with adherence to treatment.",
    "Explain GLP-1 RA ongoing monitoring and therapy optimization.",
    "Recognize opportunities for pharmacists to provide GLP-1 RA consultation and monitoring at key timelines in treatment.",
  ],
  instructors: [
    {
      name: "Brian Lam",
      imageUrl:
        "https://firebasestorage.googleapis.com/v0/b/education-hub-848d2.appspot.com/o/educationHub%2Fheadshots%2FBrian.Lam.Photo.jpeg?alt=media&token=fa20261d-8c37-4f0e-91ed-c5b4ccc42115",
      bio: "Brian is a clinical and consultant pharmacist who specializes in diabetes care. He is passionate about helping individuals in managing their chronic conditions and medication therapy. As a testament to being a life-long learner, he is a Certified Diabetes Educator and is certified in hypertension management by Hypertension Canada. Committed to advancing pharmacy practice and care, he prioritizes continuous innovation and education.",
      linkedinUrl: "https://www.linkedin.com/in/brian-lam/",
    },
    {
      name: "Natalie Shing",
      imageUrl:
        "https://firebasestorage.googleapis.com/v0/b/education-hub-848d2.appspot.com/o/educationHub%2Fheadshots%2FNatalie.png?alt=media&token=920e2edd-9bce-4c45-9836-8b5ba15de1a6",
      bio: "Natalie is a dedicated pharmacist currently specializing in diabetes care. With extensive experience in hospital, community and clinic settings, she brings a wealth of knowledge and expertise in managing conditions and resolving medication related challenges. She is continuously committed to advancing diabetes care and pharmaceutical education.",
      linkedinUrl: "https://www.linkedin.com/in/natalie-shing-91a6231aa/",
    },
    {
      name: "Negeen Foroughian",
      imageUrl:
        "https://firebasestorage.googleapis.com/v0/b/education-hub-848d2.appspot.com/o/educationHub%2Fheadshots%2FNegeen.png?alt=media&token=330363b9-c495-4fcb-b63d-37f32f3c2228",
      bio: "Negeen is a clinical pharmacist and Certified Diabetes Educator. She is driven by her passion for patient care and expertise in diabetes management. She finds fulfillment in engaging in diverse opportunities such as research and mentoring future pharmacists.",
      linkedinUrl: "https://www.linkedin.com/in/negeen-f-42b27621a/",
    },
  ],

  modules: [
    {
      title: "Module 1: GLP-1 RA Review",
      subtitle: "Mechanism of Action, Benefits & Guidelines in Type 2 Diabetes",
      watchTime: "11 minutes",
    },
    {
      title: "Module 2: Great Start!",
      subtitle:
        "Pointers to Efficiently Train People with Type 2 Diabetes on GLP-1 RAs",
      watchTime: "23 minutes",
    },
    {
      title: "Module 3: Staying on Track",
      subtitle:
        "Pharmacist Monitoring & Recommendations for Post-Start Side Effects",
      watchTime: "23 minutes",
    },
    {
      title: "Module 4: You’re a Super Pharmacist!",
      subtitle: "Pharmacist Treatment Optimization",
      watchTime: "26 minutes",
    },
  ],
  surveyLink:
    "https://docs.google.com/forms/d/1Y4dGUljUH3EpR9GDGYYNwwTqBHhxcN-QyvN0uOE0MyA/edit?ts=66295757",
  sponsoredBy:
    "The course has been supported by an unrestricted educational grant from Novo Nordisk Canada.",
  sections: [
    /* SECTION 1 */
    {
      id: "module-1",
      title: "Module 1 - GLP-1 RA Review",
      description:
        "Mechanism of Action, Benefits & Guidelines in Type 2 Diabetes",
      instructor: "Brian Lam, RPh, CDE",
      agenda: [
        "What are GLP-1 RAs?",
        "Mechanism of Action of GLP-1 RAs",
        "What Is Available In The Canadian Market – Medications & Differences",
        "GLP-1 RA Benefits, Place In Therapy & Preferential Use Scenarios In Type 2 Diabetes (Diabetes Canada Guidelines)",
        "Benefits for Pharmacists to Conduct Ongoing GLP-1 RA Consultations & Monitoring",
      ],
      watchTime: "11 minutes",
      steps: [
        /* VIDEO PART 1 */
        {
          type: "video",
          src: "https://vimeo.com/941481529/6d5a3815b0",
          videoId: "mod_1-vid_1",
          title: "glp1ra-module-1-trimmed-part-1",
        },

        /* QUIZ 1 */
        {
          type: "quiz",
          quizId: "mod_1-quiz-1",
          questions: [
            {
              question:
                "GLP-1 RAs primarily exert their effects by binding to receptors in all of the following areas of the body, EXCEPT:",
              options: ["Pancreas", "Liver", "Brain", "GI tract", "Muscle"],
              answer: "Muscle",
            },
          ],
        },
        /* VIDEO PART 2 */
        {
          type: "video",
          src: "https://vimeo.com/941483140/4898929e62",
          videoId: "mod_1-vid_2",
        },

        /*  QUIZ 2 */
        {
          type: "quiz",
          quizId: "mod_1-quiz-2",
          questions: [
            {
              question: "Which GLP-1 RA is administered on a daily basis?",
              options: [
                "1. Subcutaneous Dulaglutide",
                "2. Subcutaneous Liraglutide",
                "3. Subcutaneous Semaglutide",
                "4. Oral Semaglutide",
                "5. There is more than one correct answer",
              ],
              answer: "5. There is more than one correct answer",
            },
          ],
        },

        /* VIDEO PART 3 */
        {
          type: "video",
          src: "https://vimeo.com/941486196/d4e14534af",
          videoId: "mod_1-vid_3",
        },

        /*  QUIZ 3 */
        {
          type: "quiz",
          quizId: "mod_1-quiz-3",
          questions: [
            {
              question:
                "What are situations where the addition of GLP-1 RAs is shown to have strong evidence to lower the risk of major adverse cardiovascular events (MACE)?",
              options: [
                "If the individual has Atherosclerotic Cardiovascular Disease, or ASCVD",
                "If the individual has Chronic Kidney Disease, or CKD",
                "If the person is an adult over 60 with 2 or more risk factors. Risk factors include: tobacco use, dyslipidemia, hypertension, and/or central/abdominal obesity",
                "All of the above",
              ],
              answer: "All of the above",
            },
          ],
        },

        /* VIDEO PART 4 */
        {
          type: "video",
          src: "https://vimeo.com/941487152/a6bb57961d",
          videoId: "mod_1-vid_4",
        },

        //DONE
        {
          type: "section-done",
          sectionIndex: 1,
          sectionId: "module-1",
        },
      ],
    },

    /* SECTION 2 */
    {
      id: "module-2",
      title: "Module 2 - Great Start!",
      description:
        "Pointers to Efficiently Train People with Type 2 Diabetes on GLP-1 RAs",
      instructor: "Brian Lam, RPh, CDE and Negeen Foroughian, RPh, CDE",
      agenda: [
        "Considerations When Filling GLP-1 RA Prescriptions",
        "GLP-1 RA Start Training Information – Dosing, Side Effects, Expectations",
        "Sample Outline for Efficient Training",
        "Demonstration of GLP-1 RA Injection Training",
        "Additional Pharmacy Items to Support GLP-1 RA Usage",
      ],
      watchTime: "23 minutes",
      steps: [
        /* VIDEO 1 */
        {
          type: "video",
          src: "https://vimeo.com/941726953/fbeae2fb04",
          videoId: "mod_2-vid_1",
        },
        /* QUIZ 1*/
        {
          type: "quiz",
          quizId: "mod_1-quiz_1",
          questions: [
            {
              question:
                "If an individual with type 2 diabetes is starting a GLP1-RA and they are taking a DPP4i, the DPP4i should be discontinued.",
              options: ["True", "False"],
              answer: "True",
            },
          ],
          title: "DPP4i and GLP1-RA Combination Therapy Quiz",
        },
        /* VIDEO 2 */
        {
          type: "video",
          src: "https://vimeo.com/941726768/20d4dffcc8",
          videoId: "mod_2-vid_2",
        },
        /* QUIZ 2 */
        {
          type: "quiz-checkboxes",
          quizId: "mod_2-quiz_2",
          questions: [
            {
              question:
                "Below are the instructions for taking oral semaglutide. Identify which of the following steps are correct (There may be more than one):",
              options: [
                "Ingest the tablet whole – do not crush or chew the tablet",
                "Ingest the tablet whole or crushed",
                "Take the medication first thing in the morning at least 30 minutes before other food and medications, including levothyroxine",
                "Take the medication first thing in the morning at least 30 minutes before food. Taking it alongside other medications is okay",
                "Take the medication with at least a full glass of water to ensure the tablet travels to the stomach",
                "Take the medication with no more than a couple of sips (max 4oz or 120mL) of plain water",
              ],
              answer: [
                "Ingest the tablet whole – do not crush or chew the tablet",
                "Take the medication first thing in the morning at least 30 minutes before other food and medications, including levothyroxine",
                "Take the medication with no more than a couple of sips (max 4oz or 120mL) of plain water",
              ],
            },
          ],
          title: "Oral Semaglutide Administration Instructions Quiz",
        },
        /* VIDEO 3 */
        {
          type: "video",
          src: "https://vimeo.com/941726810/a28b2a0550",
          videoId: "mod_2-vid_3",
        },
        /* QUIZ 3 */
        {
          type: "quiz",
          quizId: "mod_2-quiz_3",
          questions: [
            {
              question:
                "When starting GLP-1 RAs, there are various side effects that are common but usually temporary. Which of the following side effects listed below are not typical of GLP-1 RAs?",
              options: [
                "Acid reflux",
                "Gastritis",
                "Nausea",
                "Diarrhea",
                "Constipation",
              ],
              answer: "Gastritis",
            },
          ],
          title: "GLP-1 RA Side Effects Quiz",
        },
        /* VIDEO 4 */
        {
          type: "video",
          src: "https://vimeo.com/941726819/61741c7525",
          videoId: "mod_2-vid_4",
        },
        /* QUIZ 4 */
        {
          type: "quiz",
          quizId: "mod_2-quiz_4",
          questions: [
            {
              question:
                "GI side effects from GLP-1 RAs can be prevented or mitigated by various non-pharmacological strategies listed below. Which of the following non-pharmacological strategies is not appropriate to recommend:",
              options: [
                "Eating smaller meals",
                "Stopping eating when no longer hungry",
                "Avoiding spicy foods",
                "Avoiding fatty/oily foods",
                "Eating larger meals that have lots of fiber",
              ],
              answer: "Eating larger meals that have lots of fiber",
            },
          ],
          title: "Managing GI Side Effects from GLP-1 RAs Quiz",
        },

        /* VIDEO 5 */
        {
          type: "video",
          src: "https://vimeo.com/941726866/4536a14d6b",
          videoId: "mod_2-vid_5",
        },
        //DONE
        {
          type: "section-done",
          sectionIndex: 2,
          sectionId: "module-2",
        },
      ],
    },
    {
      id: "module-3",
      title: "Module 3 - Staying on Track",
      description:
        "Pharmacist Monitoring & Recommendations for Post-Start Side Effects",
      instructor: "Negeen Foroughian RPh, CDE",
      agenda: [
        "Monitoring and management of gastrointestinal (GI) side effects",
        "Monitoring and management of injection-site reactions",
        "Monitoring and management of hypoglycemia (When the person is taking concurrent medications affiliated with hypoglycemia)",
        "Pharmacist framework for follow ups after GLP-1 RA initiation",
      ],
      watchTime: "23 minutes",

      steps: [
        {
          type: "video",
          src: "https://vimeo.com/941917723/cc3901ad5d?share=copy",
          videoId: "mod_3-vid_1",
        },
        {
          type: "quiz",
          quizId: "mod_3-quiz_1",
          questions: [
            {
              question:
                "What should we monitor for and help manage following GLP-1 RA initiation?",
              options: [
                "GI side effects",
                "Hypoglycemia (If there are agents affiliated with hypoglycemia on board)",
                "Injection site reactions, if using a SC GLP-1 RA",
                "All of the above",
              ],
              answer: "All of the above",
            },
          ],
          title: "Monitoring and Management Post GLP-1 RA Initiation",
        },
        {
          type: "video",
          src: "https://vimeo.com/941917663/593d5fedb4?share=copy",
          videoId: "mod_3-vid_2",
        },
        {
          type: "quiz",
          quizId: "mod_3-quiz_2",
          questions: [
            {
              question:
                "Below is a list of reasons why pharmacists should follow up with individuals after initiating GLP-1 RA treatment. Which one of the following reasons is not appropriate?",
              options: [
                "Monitor and help manage potential GI side effects",
                "Assess administration technique – for injection or oral GLP- RAs",
                "Assess readiness to increase from initial to maintenance dose, if applicable",
                "Assess whether other diabetes medications need to be adjusted to prevent hypoglycemia",
                "Assess if individuals are considering purchasing over the counter medications or supplements from you",
                "As part of a medication therapy management protocol",
              ],
              answer:
                "Assess if individuals are considering purchasing over the counter medications or supplements from you",
            },
          ],
          title: "Pharmacist Follow-Up Post GLP-1 RA Initiation",
        },
        {
          type: "video",
          src: "https://vimeo.com/941917691/8e24d9ffe6?share=copy",
          videoId: "mod_3-vid_3",
        },
        {
          type: "section-done",
          sectionIndex: 3,
          sectionId: "module-3",
        },
      ],
    },
    {
      id: "module-4",
      title: "Module 4 - You’re a Super Pharmacist!",
      description: "Pharmacist Treatment Optimization",
      instructor: "Natalie Shing RPh",
      agenda: [
        "Treatment Optimization – Considerations when filling GLP-1RA prescriptions",
        "Treatment Optimization – Dose titration",
        "Treatment Optimization – What to do if individuals miss doses",
        "Treatment Optimization – Monitoring to prevent adverse effects",
        "Treatment Optimization – Monitoring for efficacy",
        "Treatment Optimization – Discontinuation considerations",
        "Ongoing Pharmacist Medication Therapy Management",
      ],
      watchTime: "26 minutes",
      steps: [
        {
          type: "video",
          src: "https://vimeo.com/941918011/52361ecb84?share=copy",
          videoId: "mod_4-vid_1",
        },
        {
          type: "quiz",
          quizId: "mod_4-quiz_1",
          questions: [
            {
              question:
                "If an individual is supposed to titrate their GLP-1 RA dose up, but they are not tolerating their current dose, what would you recommend?",
              options: [
                "Consider maintaining current dose despite the titration schedule",
                "Recommend OTC medications and/or lifestyle modifications for symptom management",
                "Reassess if a dose increase is appropriate at the next scheduled dose",
                "All of the above",
              ],
              answer: "All of the above",
            },
          ],
          title: "Dose Titration Management",
        },
        {
          type: "video",
          src: "https://vimeo.com/941917856/b3f15228f8?share=copy",
          videoId: "mod_4-vid_2",
        },
        {
          type: "quiz",
          quizId: "mod_4-quiz_2",
          questions: [
            {
              question:
                "An individual tells you that they were away and have missed 5 consecutive weeks of their Semaglutide SC 1 mg weekly, prescribed for the management of type 2 diabetes. What is your recommendation in this situation?",
              options: [
                "Double the next scheduled dose to compensate for the missed doses",
                "Resume Semaglutide at 1mg SC weekly",
                "Restart Semaglutide at 0.5mg SC weekly x 4 weeks, then increase to 1mg",
                "Restart Semaglutide SC from the beginning, with a full escalation i.e., 0.25mg SC weekly x 4 weeks; then, if warranted, increase to 0.5mg SC weekly x 4 weeks; then, if warranted, increase to 1mg SC weekly",
              ],
              answer:
                "Restart Semaglutide SC from the beginning, with a full escalation i.e., 0.25mg SC weekly x 4 weeks; then, if warranted, increase to 0.5mg SC weekly x 4 weeks; then, if warranted, increase to 1mg SC weekly",
            },
          ],
          title: "Managing Missed Doses of Semaglutide",
        },
        {
          type: "video",
          src: "https://vimeo.com/941917882/3953ce4ff3",
          videoId: "mod_4-vid_3",
        },
        {
          type: "quiz",
          quizId: "mod_4-quiz_3",
          questions: [
            {
              question:
                "True or False: People taking GLP-1 RA medications should receive a dilated eye exam every 3-4 years. And if they experience any visual changes, they should inform their prescriber.",
              options: ["True", "False"],
              answer: "False",
            },
          ],
          title: "Eye Health in GLP-1 RA Therapy",
        },
        {
          type: "video",
          src: "https://vimeo.com/941917914/4889a8adeb",
          videoId: "mod_4-vid_4",
        },

        {
          type: "quiz",
          quizId: "sq-SQ_1",
          questions: [
            {
              question:
                "Which GLP-1RA comes in 2 different administration route options?",
              options: ["Dulaglutide", "Liraglutide", "Semaglutide"],
              answer: "Semaglutide",
            },
          ],
          title: "GLP-1 RA Administration Routes",
        },
        {
          type: "video",
          src: "https://vimeo.com/941917954/122951828f",
          videoId: "sq-vid_1",
        },
        {
          type: "quiz",
          quizId: "sq-SQ_2",
          questions: [
            {
              question:
                "If an individual with type 2 diabetes is starting a GLP1-RA and they are taking a DPP4i, the DPP4i should be discontinued.",
              options: ["True", "False"],
              answer: "True",
            },
          ],
          title: "DPP4i and GLP-1 RA Combination",
        },
        {
          type: "video",
          src: "https://vimeo.com/941917966/cb7cb0f5b3",
          videoId: "sq-vid_2",
        },
        {
          type: "quiz",
          quizId: "sq-SQ_3",
          questions: [
            {
              question:
                "Why should pharmacists follow up with individuals after initiating GLP-1 RA treatment?",
              options: [
                "Monitor and help manage potential GI side effects",
                "Assess administration technique – for injection or oral GLP- RAs",
                "Assess readiness to increase from initial to maintenance dose, if applicable",
                "Assess whether other diabetes medications need to be adjusted to prevent hypoglycemia",
                "As part of a medication therapy management protocol",
                "All of the above",
              ],
              answer: "All of the above",
            },
          ],
          title: "Pharmacist's Role in GLP-1 RA Management",
        },
        {
          type: "video",
          src: "https://vimeo.com/941917983/c8956b76a5",
          videoId: "sq-vid_3",
        },

        {
          type: "quiz",
          quizId: "sq-SQ_4",
          questions: [
            {
              question:
                "If an individual is supposed to titrate their GLP-1 RA dose up, but they are not tolerating their current dose, what would you recommend?",
              options: [
                "Consider maintaining current dose despite the titration schedule",
                "Recommend OTC medications and/or lifestyle modifications for symptom management",
                "Reassess if a dose increase is appropriate at the next scheduled dose",
                "All of the above",
              ],
              answer: "All of the above",
            },
          ],
          title: "Dose Management in GLP-1 RA Therapy",
        },

        {
          type: "video",
          src: "https://vimeo.com/941917999/864f3da0ea",
          videoId: "sq-vid_4",
        },
        {
          type: "section-done",
          sectionIndex: 4,
          sectionId: "module-4",
        },
      ],
    },
  ],
};

export default glpCourseEng;
