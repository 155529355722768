import React, { useState } from "react";
import QrCode2Icon from "@mui/icons-material/QrCode2";
import { ClickAwayListener, Card, ListItemButton, Popper } from "@mui/material";
import QRCode from "qrcode.react";

export default function QRButtonResources(props) {
  const { link } = props;
  const [open, setOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setOpen((prev) => !prev);
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };
  const handleClickAway = () => {
    setOpen(false);
    setAnchorEl(null);
  };
  const id = open ? "simple-popper" : undefined;

  return (
    <div>
      <ClickAwayListener onClickAway={handleClickAway}>
        <span
          sx={{
            display: "contents",
          }}
        >
          <ListItemButton onClick={(e) => handleClick(e)}>
            <QrCode2Icon />
          </ListItemButton>
          <Popper
            sx={{ zIndex: "50000" }}
            id={id}
            open={open}
            anchorEl={anchorEl}
          >
            <Card style={{ borderRadius: "20px" }}>
              <QRCode
                size="300"
                style={{ maxHeight: "3.5cm", margin: "1rem" }}
                value={link}
              />
            </Card>
          </Popper>
        </span>
      </ClickAwayListener>
    </div>
  );
}
