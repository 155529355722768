const glpCourseFr = {
  numberOfSections: 5,
  courseId: "managing-t2d-using-glp1ra-fr",
  courseTitle: `Gestion du diabète de type 2 à l'aide des AR du GLP-1`,
  courseDescription: `Bienvenue dans la série MedEssist Practical Points pour les Pharmaciens ! Cette série se réfère à quatre modules qui rendent compte de la gestion du diabète de type 2 en utilisant l’agoniste du GLP1-RAs en abrégé.`,
  courseAccreditation: {
    accreditationNumber: "13880",
    ceus: "1 heure (1 UFC)",
    statementOfParticipation:
      "Après avoir terminé ce cours, vous recevrez une déclaration de participation.",
  },
  learningObjectives: [
    "Décrire les AR du GLP-1 disponibles au Canada, y compris leur mode d'action, leurs avantages et leur place dans le traitement du diabète de type 2.",
    "Démontrer l’initiation du traitement des AR du GLP-1, y compris les principes de la technique d’injection.",
    "Identifier les effets secondaires des AR du GLP-1 afin d’améliorer l’adhésion aux médicaments.",
    "Expliquer la surveillance continue des AR du GLP-1 et l’optimisation du traitement.",
    "Reconnaître des opportunités pour les pharmaciens de fournir des consultations et une surveillance des AR du GLP-1 à des moments clés du traitement.",
  ],
  collaborationStatement:
    "Ce cours interactif a été développé en collaboration avec LMC Pharmacy. LMC est le plus grand fournisseur de soins spécialisés en diabète et endocrinologie au Canada. Les pharmaciens de notre équipe spécialisée dans l'éducation au diabète ont créé cette série pour fournir des connaissances pratiques et pertinentes pour vous aider dans votre pratique communautaire.",

  instructors: [
    {
      name: "Kayla Castonguay, RPh, PharmD, CDE, CGP",
      imageUrl: `https://firebasestorage.googleapis.com/v0/b/education-hub-848d2.appspot.com/o/educationHub%2Fheadshots%2FIMG_9399.jpeg?alt=media&token=4109b1a8-a24e-4276-9f88-49c3d4fef6d5`,
      bio: `Kayla est une pharmacienne clinicienne et possède les certifications de spécialiste en pharmacothérapie et d'éducatrice en diabète. Son dévouement et sa passion de la profession la pousser à la fourniture de soins exceptionnels aux patients. En outre, elle prend plaisir à encadrer les étudiants en pharmacie et à leur transmettre avec enthousiasme son expertise en pharmacothérapie.`,
      linkedinUrl: "https://www.linkedin.com/in/kayla-castonguay-383563a4/",
    },
  ],
  surveyLink:
    "https://docs.google.com/forms/d/17sH5W4VXafA8z_fPKDjvjf5QEuoKiW-QJccEkiQhzYQ/edit",

  modules: [
    {
      title: "Module 1 : Révision des AR du GLP-1",
      subtitle:
        "Mécanisme d'action, avantages et lignes directrices dans le diabète de type 2",
      watchTime: "12 minutes",
    },
    {
      title: "Module 2 : Excellent début",
      subtitle:
        "Conseils pour la formation efficace des personnes atteintes de diabète de type 2 sur les AR du GLP-1",
      watchTime: "13 minutes",
    },
    {
      title: "Module 3 : Rester sur la Bonne Voie",
      subtitle:
        "Surveillance par le pharmacien et recommandations concernant les effets secondaires",
      watchTime: "12 minutes",
    },
    {
      title: "Module 4 : Vous êtes un Super Pharmacien !",
      subtitle: "Optimisation du traitement par le pharmacien",
      watchTime: "12 minutes",
    },
  ],

  sponsoredBy:
    "Ce cours a été soutenu par une subvention éducative non restreinte de Novo Nordisk Canada.",
  sections: [
    /* SECTION 1 */
    {
      id: "module-1",
      title: "Module 1 : Révision des AR du GLP-1",
      description:
        "Mécanisme d'action, avantages et lignes directrices dans le diabète de type 2",
      instructor: "Kayla Castonguay, RPh, PharmD, CDE, CGP",
      agenda: [
        "Que sont les AR du GLP-1?",
        "Mécanisme d'action des AR du GLP-1",
        "Ce qui est disponible sur le marché Canadien– Médicaments & Différences",
        "Avantages des AR du GLP-1, place dans le traitement et scénarios d'utilisation préférentielle dans le diabète de type 2 (lignes directrices de Diabetes Canada)",
        "Avantages pour les pharmaciens d'effectuer des consultations et une surveillance continue des AR du GLP-1",
      ],
      watchTime: "12 minutes",
      steps: [
        {
          type: "video",
          src: "https://vimeo.com/941731062/1374089941",
          videoId: "mod_1-vid_fr",
          title: "AR du GLP-1 Module 1 Français",
        },
        {
          type: "section-done",
          sectionIndex: 1,
          sectionId: "module-1",
        },
      ],
    },
    /* SECTION 2 */
    {
      id: "module-2",
      title: "Module 2 : Excellent début",
      description:
        "Conseils pour la formation efficace des personnes atteintes de diabète de type 2 sur les AR du GLP-1",
      instructor: "Kayla Castonguay, RPh, PharmD, CDE, CGP",
      agenda: [
        "Considérations avant de remplir les ordonnances des AR du GLP-1",
        "Guide d’entraînement des AR du GLP-1 – Posologie, effets indésirables, attentes",
        "Exemple de plan pour la formation efficace",
        "Démonstration de formation sur l'injection des AR du GLP-1",
        "Articles de pharmacie supplémentaires pour prendre en charge l'utilisation des AR du GLP-1",
      ],
      watchTime: "Durée de visionnage : 13 minutes",
      steps: [
        {
          type: "video",
          src: "https://vimeo.com/941718573/acee34bfef?share=copy",
          videoId: "mod_2-vid_fr",
          title: "AR du GLP-1 Module 2 Français",
        },
        {
          type: "section-done",
          sectionIndex: 2,
          sectionId: "module-2",
        },
      ],
    },

    /* SECTION 3 */
    {
      id: "module-3",
      title: "Module 3 : Rester sur la Bonne Voie",
      description:
        "Surveillance par le pharmacien et recommandations concernant les effets secondaires",
      instructor: "Kayla Castonguay, RPh, PharmD, CDE, CGP",
      agenda: [
        "Surveillance et gestion des effets secondaires gastro-intestinaux (GI)",
        "Surveillance et gestion des réactions au site d'injection",
        "Surveillance et gestion de l'hypoglycémie (lorsque la personne prend simultanément des médicaments associés à l'hypoglycémie)",
        "Cadre du pharmacien pour les suivis après l'initiation d’une AR du GLP-1",
      ],
      watchTime: "Durée de visionnage : 12 minutes",
      steps: [
        {
          type: "video",
          src: "https://vimeo.com/941718618/eb0a933a19?share=copy",
          videoId: "mod_3-vid_fr",
          title: "AR du GLP-1 Module 3 Français",
        },
        //DONE
        {
          type: "section-done",
          sectionIndex: 3,
          sectionId: "module-3",
        },
      ],
    },

    /* SECTION 4 */
    {
      id: "module-4",
      title: "Module 4 : Vous êtes un Super Pharmacien !",
      description: "Optimisation du traitement par le pharmacien",
      instructor: "Kayla Castonguay, RPh, PharmD, CDE, CGP",
      agenda: [
        "Optimisation du traitement – Considérations lors du remplissage des ordonnances de AR du GLP-1",
        "Optimisation du traitement – Titrage de la dose",
        "Optimisation du traitement – Que faire si les individus oublient des doses",
        "Optimisation du traitement – Surveillance pour prévenir les effets indésirables",
        "Optimisation du traitement – Surveillance de l'efficacité",
        "Optimisation du traitement – Considérations relatives à l'arrêt",
        "Gestion continue de la thérapie médicamenteuse par le pharmacien",
      ],
      watchTime: "Durée de visionnage : 12 minutes",
      steps: [
        {
          type: "video",
          src: "https://vimeo.com/941718654/5bb2100841?share=copy",
          videoId: "mod_4-vid_fr",
          title: "AR du GLP-1 Module 4 Français",
        },
        //DONE
        {
          type: "section-done",
          sectionIndex: 4,
          sectionId: "module-4",
        },
      ],
    },

    /* SECTION 5 - Evaluation */
    {
      id: "evaluation",
      title: "Évaluation",
      description: "Évaluation de la compréhension",
      instructor: "Divers",
      agenda: ["Questions d'évaluation des modules précédents"],
      steps: [
        /* QUIZ */
        {
          quizTitle: "Questionnaire",
          type: "graded-quiz",
          quizId: "evaluation",
          description:
            "Vous devez obtenir 70 % pour réussir le quiz de ce cours",
          questions: [
            {
              question:
                "Les AR du GLP-1 se lient aux récepteurs dans quelles zones du corps ?",
              options: [
                "Pancréas",
                "Foie",
                "Cerveau",
                "Tractus gastro-intestinal",
                "Tout ce qui précède",
              ],
              answer: "Tout ce qui précède",
            },
            {
              question:
                "Quel AR du GLP-1 est disponible en 2 options de voies d'administration différentes ?",
              options: ["Dulaglutide", "Liraglutide", "Sémaglutide"],
              answer: "Sémaglutide",
            },
            {
              question:
                "Quelles sont les situations dans lesquelles l’ajout d’AR du GLP-1 présente des preuves solides pour réduire le risque d’événements cardiovasculaires indésirables majeurs (ÉCVM) ?",
              options: [
                "Si le patient souffre d'une maladie cardiovasculaire athéroscléreuse",
                "Si le patient souffre d'une maladie rénale chronique",
                "Si la personne est un adulte de plus de 60 ans présentant 2 facteurs de risque ou plus. Les facteurs de risque comprennent : le tabagisme,dyslipidémie, hypertension et/ou obésité centrale/abdominale",
                "Tout ce qui précède",
              ],
              answer: "Tout ce qui précède",
            },
            {
              question:
                "Si une personne atteinte de diabète de type 2 commence un AR du GLP1 et prend un iDPP4, l’iDPP4 doit être interrompu.",
              options: ["Vrai", "Faux"],
              answer: "Vrai",
            },
            {
              question: "Comment faut-il prendre le sémaglutide oral ?",
              options: [
                "Entier – ne pas écraser ni mâcher le comprimé",
                "Au moins 30 minutes avant les autres aliments et médicaments ; y compris la lévothyroxine, si vous utilisez",
                "Avec pas plus de quelques gorgées (maximum 4 oz ou 120 ml) d'eau claire",
                "Tout ce qui précède",
              ],
              answer: "Tout ce qui précède",
            },
            {
              question:
                "Au début du traitement avec les AR du GLP-1, les effets secondaires gastro-intestinaux sont fréquents mais généralement temporaires.",
              options: ["Vrai", "Faux"],
              answer: "Vrai",
            },
            {
              question:
                "Les effets secondaires gastro-intestinaux des AR du GLP-1 peuvent être évités ou atténués en",
              options: [
                "Manger des repas plus petits",
                "Arrêter de manger quand on n'a plus faim",
                "Éviter les aliments épicés",
                "Tout ce qui précède",
              ],
              answer: "Tout ce qui précède",
            },
            {
              question:
                "Que devrions-nous surveiller et aider à gérer après l’initiation de l’AR du GLP-1?",
              options: [
                "Effets secondaires gastro-intestinaux",
                "Hypoglycémie (s'il y a à bord des agents affiliés à l'hypoglycémie)",
                "Réactions au site d'injection, si vous utilisez un SC GLP-1 RA",
                "Tout ce qui précède",
              ],
              answer: "Tout ce qui précède",
            },
            {
              question:
                "Pourquoi les pharmaciens devraient-ils effectuer un suivi auprès des individus après avoir commencé un AR du GLP-1 ?",
              options: [
                "Surveiller et aider gérer les effets secondaires gastro-intestinaux potentiels",
                "Évaluer la technique d’administration – pour injection ou AR du GLP-1 par voie orale",
                "Évaluer l'état de préparation à passer de la dose initiale à la dose d'entretien, le cas échéant",
                "Évaluer si d'autres médicaments contre le diabète doivent être ajustés pour prévenir l'hypoglycémie",
                "Dans le cadre d'un protocole de gestion de la pharmacothérapie",
                "Tout ce qui précède",
              ],
              answer: "Tout ce qui précède",
            },
            {
              question:
                "Si un individu est censé augmenter sa dose des AR du GLP-1, mais qu’il ne tolère pas sa dose actuelle, que recommanderiez-vous ?",
              options: [
                "Envisagez de maintenir la dose actuelle malgré le calendrier de titration",
                "Recommander des médicaments en vente libre et/ou des modifications du mode de vie pour la gestion des symptômes",
                "Réévaluer si une augmentation de dose est appropriée à la prochaine dose programmée",
                "Tout ce qui précède",
              ],
              answer: "Tout ce qui précède",
            },
            {
              question:
                "Une personne vous dit qu'elle était absente et qu'elle a manqué 5 semaines consécutives de son sémaglutide SC 1 mg par semaine, prescrit pour la prise en charge du diabète de type 2. Quelle est votre recommandation dans cette situation ?",
              options: [
                "Doublez la prochaine dose programmée pour compenser les doses manquées",
                "Continuer Sémaglutide à 1 mg SC par semaine",
                "Redémarrer le sémaglutide à 0,5 mg SC par semaine x 4 semaines, puis augmenter à 1 mg",
                "Recommencer le titrage de semaglutide SC dès le début, avec une escalade complète c'est à dire 0,25 mg SC par semaine x 4 semaines ; puis augmenter à 0,5 mg SC par semaine x 4 semaines ; puis, si cela est justifié, augmenter à 1 mg SC par semaine",
              ],
              answer:
                "Recommencer le titrage de semaglutide SC dès le début, avec une escalade complète c'est à dire 0,25 mg SC par semaine x 4 semaines ; puis augmenter à 0,5 mg SC par semaine x 4 semaines ; puis, si cela est justifié, augmenter à 1 mg SC par semaine",
            },
            {
              question:
                "Les personnes prenant des médicaments AR du GLP-1 devraient recevoir un examen de la vue dilatée tous les 1 à 2 ans. Et s’ils ressentent des changements visuels, ils doivent en informer leur médecin.",
              options: ["Vrai", "Faux"],
              answer: "Faux",
            },
          ],
        },
        //DONE
        {
          type: "section-done",
          sectionIndex: 5,
          sectionId: "evaluation",
        },
      ],
    },
  ],
};

export default glpCourseFr;
