import React, { useEffect, useState } from "react";
import { useAuth } from "../../contexts/AuthContext";
import { reauthenticateWithCredential, EmailAuthProvider } from "firebase/auth";
import {
  TextField,
  Box,
  Typography,
  Button,
  Card,
  Snackbar,
  Alert,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { setMyPin } from "../../features/states/myPinSlice";
import { updateUserPin } from "../helpers/source";
import { getUpdatingMyPin } from "../../features/states/myPinSlice";
import { useAuthenticatedUser } from "../../zustand/store";
export default function PinChange({
  noVerificationNeeded = false,
  onPinChangeComplete,
}) {
  const dispatch = useDispatch();
  const { currentUser } = useAuth();
  const myPin = useSelector((state) => state.myPin.value);
  const [password, setPassword] = useState("");
  const [error, setError] = useState(null);
  const [directionMessage, setDirectionMessage] = useState(false);
  const [toastOpen, setToastOpen] = useState(false);
  // const [authenticated, setAuthenticated] = useState(false);
  const { authenticated, setAuthenticated } = useAuthenticatedUser();

  useEffect(() => {
    setAuthenticated(noVerificationNeeded);
  }, []);
  const handleReauthenticate = async () => {
    try {
      // Create a credential with the user's email and password
      const credential = EmailAuthProvider.credential(
        currentUser.email,
        password
      );
      // Reauthenticate the user with the credential
      await reauthenticateWithCredential(currentUser, credential);
      // User successfully reauthenticated, proceed to secure page
      console.log("User reauthenticated successfully");
      setError(false);
      setAuthenticated(true);
      // Redirect or grant access to the secure page
    } catch (error) {
      console.error("Error reauthenticating user:", error);
      setError(error.message);
    }
  };
  const handleSaveChanges = async () => {
    // Update PIN if it has been entered
    if (myPin?.length >= 4) {
      updateUserPin({ userId: currentUser.uid, pin: myPin });
      setDirectionMessage(
        "Congratulations on creating a myPIN, you can now login to MedEssist dashboard and add yourself as a new HCP"
      );
      setToastOpen(true);
      if (onPinChangeComplete) {
        onPinChangeComplete();
      }
    } else {
      alert("PIN must be at least 4 digits.");
      dispatch(getUpdatingMyPin(currentUser.uid));
      return;
    }
  };
  return (
    <>
      <Snackbar
        open={toastOpen}
        autoHideDuration={6000}
        onClose={() => setToastOpen(false)}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert onClose={() => setToastOpen(false)} severity="success">
          Pin Successfully Changed!
        </Alert>
      </Snackbar>
      <Card
        className="user-profile-card"
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            maxWidth: 400,
            margin: "auto",
          }}
        >
          {!authenticated && (
            <>
              <Typography>
                Enter your Hub Password for myPIN Change Access
              </Typography>
              <TextField
                label="Password"
                type="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    handleReauthenticate();
                  }
                }}
                // style={{ width: "300px" }}
              />
              <Button onClick={handleReauthenticate}>Reauthenticate</Button>
            </>
          )}
          {error && <p>Error: {error}</p>}
          {authenticated && (
            <>
              <TextField
                label="Passcode (4-6 digits)"
                variant="outlined"
                value={myPin}
                type="password"
                onChange={(e) => dispatch(setMyPin(e.target.value))}
                inputProps={{ maxLength: 6 }}
                style={{ marginBottom: "10px", minWidth: "15em" }}
              />
              <Button onClick={handleSaveChanges}>Save</Button>
              {/* {directionMessage} */}
            </>
          )}
        </Box>
      </Card>
    </>
  );
}
