import React from "react";
import Box from "@mui/material/Box";
import InputBase from "@mui/material/InputBase";
import SearchIcon from "@mui/icons-material/Search";
import "../SearchBar/SearchBar.scss";

function SearchBar({ searchQuery, setSearchQuery, placeholderText }) {
  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
  };

  return (
    <Box className="search-bar">
      {/* <SearchIcon /> */}
      <InputBase
        placeholder={placeholderText}
        inputProps={{ "aria-label": "search" }}
        value={searchQuery}
        onChange={handleSearchChange}
      />
    </Box>
  );
}

export default SearchBar;
