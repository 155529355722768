import React, { useState } from "react";
import {
  ListItem,
  ListItemText,
  TextField,
  Button,
  InputAdornment,
  IconButton,
} from "@mui/material";
import { useAuth } from "../contexts/AuthContext";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { setMyPin } from "../features/states/myPinSlice";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { updateUserPin } from "../components/helpers/source";
import { getUpdatingMyPin } from "../features/states/myPinSlice";
export default function PendingRequestPinChangeEntry({ setToastOpen }) {
  const { currentUser } = useAuth();
  const [showPassword, setShowPassword] = useState(false);
  const myPin = useSelector((state) => state.myPin.value);
  const dispatch = useDispatch();
  const handleChange = (e) => {
    dispatch(setMyPin(e.target.value));
  };
  const handleTogglePasswordVisibility = () => {
    setShowPassword((prevShowPassword) => !prevShowPassword);
  };
  const handleSavePin = () => {
    if (myPin?.length >= 4) {
      updateUserPin({
        userId: currentUser.uid,
        pin: myPin,
      });
      setToastOpen(true);
    } else {
      alert("PIN must be 4-6 digits.");
      dispatch(getUpdatingMyPin(currentUser.uid));
    }
  };
  return (
    <>
      <ListItem>
        <ListItemText
          primary={
            "You have been invited to join a MedEssist Dashboard workspace! Create a secret PIN to use to access patient data or sign documents at your linked work locations. You can change this PIN at any time under Manage Account on your Hub profile."
          }
        />
      </ListItem>
      <ListItem>
        <ListItemText
          primary={
            <TextField
              label="PIN"
              variant="standard"
              type={showPassword ? "text" : "password"}
              value={myPin}
              onChange={handleChange}
              onKeyDown={(event) => {
                if (event.key === "Enter") {
                  handleSavePin();
                }
              }}
              inputProps={{ maxLength: 6 }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleTogglePasswordVisibility}
                      edge="end"
                    >
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              style={{ marginBottom: "10px", minWidth: "15em" }}
            />
          }
          secondary={
            <Button
              onClick={() => {
                handleSavePin();
              }}
            >
              Save Pin
            </Button>
          }
        />
      </ListItem>
    </>
  );
}
