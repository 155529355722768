import React, { useEffect, useState } from "react";
import { Accordion, AccordionDetails, AccordionSummary } from "@mui/material";
import ExpandMore from "@mui/icons-material/ExpandMore";
import _ from "lodash";
import Chip from "@mui/material/Chip";
import SourcesTextPopup from "./SourcesTextPopup";
import { addToAIQuery } from "../components/helpers/source";

export const handleSourceClickMetric = (
  sourceNumber,
  index,
  conversation,
  setSelectedConversation,
  selectedConversation
) => {
  // counts the total number of source clicks + the number each source was clicked
  const chatHistoryCopy = _.cloneDeep(conversation?.chat_history);

  const chatLogIndex = chatHistoryCopy[index];
  let totalSourceClicks = chatLogIndex["totalSourceClick"];
  let sourceClicked = chatLogIndex["sourceClicked"];

  chatHistoryCopy[index]["totalSourceClick"] = totalSourceClicks
    ? totalSourceClicks + 1
    : 1;

  if (!sourceClicked) {
    chatHistoryCopy[index]["sourceClicked"] = {
      [sourceNumber]: 1,
    };
  } else {
    sourceClicked[sourceNumber]
      ? (chatHistoryCopy[index]["sourceClicked"][sourceNumber] =
          sourceClicked[sourceNumber] + 1)
      : (chatHistoryCopy[index]["sourceClicked"][sourceNumber] = 1);
  }
  addToAIQuery({ chat_history: chatHistoryCopy }, conversation?.docId);
  setSelectedConversation({
    ...selectedConversation,
    chat_history: chatHistoryCopy,
  });
};

export default function SourcesAccordion(props) {
  const {
    conversation,
    message,
    index,
    setSelectedConversation,
    selectedConversation,
  } = props;

  const [matchedInlineSources, setMatchedInlineSources] = useState([]);
  const [sourcesNotInline, setSourcesNotInline] = useState([]);

  useEffect(() => {
    const inlineSources = message?.answer.match(/\[\d+(,\s*\d+)*\]/g) || [];

    let matchedSourceWithChipNumber = [];
    let nonInlineSources = [];

    if (inlineSources) {
      let chipNum = 1;
      let matchedSource;
      // modified from ResponseAI to match the sources with the chip #
      inlineSources.forEach((inLineSource) => {
        const sourceNums = inLineSource
          .replace(/[\[\]]/g, "")
          .split(",")
          .map((num) => parseInt(num.trim()));

        // finds the source information that matches the inline source number
        matchedSource = message?.sources?.find(
          (source) => source?.source_num === sourceNums[0]
        );

        // checks if the matched source link already exists in the matched array
        const notDuplicate = matchedSourceWithChipNumber?.every(
          (existingMatch) =>
            existingMatch?.source?.source !== matchedSource?.source
        );

        // pushed the source into the matchedArrayArray, which matches the label(chipNum) with the source so it can be displayed properly
        if (matchedSource && notDuplicate) {
          matchedSourceWithChipNumber.push({
            chipNum: chipNum,
            source: matchedSource,
          });
          chipNum++;
        }
      });

      // gets the sources that are not inline
      message?.sources &&
        message?.sources.forEach((sourceObj) => {
          if (
            !inlineSources?.includes(`[${sourceObj?.source_num}]`) ||
            !inlineSources?.length
          ) {
            if (
              // filters out all the sources already in the nonInlineSources array
              nonInlineSources?.every(
                (arraySource) => arraySource?.source !== sourceObj?.source
              ) &&
              // filters out all the sources already in the matchedSourceWithChipNumber array
              matchedSourceWithChipNumber?.every(
                (existingMatch) =>
                  existingMatch?.source?.source !== sourceObj?.source
              )
            ) {
              nonInlineSources.push(sourceObj);
            }
          }
        });

      setSourcesNotInline(nonInlineSources);
      setMatchedInlineSources(matchedSourceWithChipNumber);
    }
  }, [message.answer]);

  return (
    <Accordion
      sx={{
        background: "transparent",
        border: "none",
        boxShadow: "none",
        marginTop: "1em",
        marginBottom: "1em",
      }}
			className="ai-sources"
    >
      <AccordionSummary
        sx={{
          color: "#836815",
          fontWeight: "500",
          width: "100%",
          background: "#edd178",
          padding: "10px",
          borderRadius: "15px",
          height: "24px",
        }}
        expandIcon={<ExpandMore sx={{ color: "#836815" }} />}
        aria-controls="panel1a-content"
        id="panel1a-header"
      >
        Read more
      </AccordionSummary>
      <AccordionDetails sx={{ padding: "1em" }}>
        <ul style={{ padding: "0", fontSize: "smaller" }}>
          {matchedInlineSources?.length > 0 &&
            matchedInlineSources.map((sourceChipObj) => (
              <li key={sourceChipObj?.source?.source_num}>
                <Chip label={sourceChipObj?.chipNum} />
                <a
                  style={{ margin: "0 3px", color: "#836814" }}
                  href={sourceChipObj.source?.source}
                  target="_blank"
                  rel="noopener noreferrer"
                  onClick={() => {
                    handleSourceClickMetric(
                      sourceChipObj?.source?.source_num,
                      index,
                      conversation,
                      setSelectedConversation,
                      selectedConversation
                    );
                  }}
                >
                  {`${
                    sourceChipObj?.source.publisher
                  } - ${sourceChipObj?.source.title?.replace(/_/g, " ")}`}
                </a>
                <SourcesTextPopup
                  sources={message?.sources}
                  selectedSources={sourceChipObj?.source}
                />
              </li>
            ))}

          {sourcesNotInline?.length > 0 &&
            sourcesNotInline.map((sourceObj, i) => (
              <li key={sourceObj?.source_num}>
                <Chip label={i + matchedInlineSources?.length + 1} />
                <a
                  style={{ color: "#836814" }}
                  href={sourceObj?.source}
                  target="_blank"
                  rel="noopener noreferrer"
                  onClick={() => {
                    handleSourceClickMetric(
                      sourceObj?.source_num,
                      index,
                      conversation,
                      setSelectedConversation,
                      selectedConversation
                    );
                  }}
                >
                  {`${sourceObj.publisher} - ${sourceObj.title.replace(
                    /_/g,
                    " "
                  )}`}
                </a>
              </li>
            ))}
        </ul>
      </AccordionDetails>
    </Accordion>
  );
}
