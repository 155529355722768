const instructorData = {
  "Brian Lam, Natalie Shing and Negeen Foroughian": {
    blurb: "",
    designation: "Pharmaciss",
    avatar:
      "https://firebasestorage.googleapis.com/v0/b/education-hub-848d2.appspot.com/o/educationHub%2FCCCEP%2FLMC_logo-highres-171-x-172.png?alt=media&token=7a658347-761c-4be7-8be5-2b8d1490883d",
  },
  "Brian Lam and Natalie Shing": {
    blurb: "",
    designation: "Pharmacist",
    avatar:
      "https://firebasestorage.googleapis.com/v0/b/education-hub-848d2.appspot.com/o/educationHub%2FCCCEP%2FLMC_logo-highres-171-x-172.png?alt=media&token=7a658347-761c-4be7-8be5-2b8d1490883d",
  },
  "Stacey D'Angelo": {
    blurb: `Stacey is a <strong>Pharmacist</strong> and <strong>Founder of Your Simple Health</strong>, where she helps people optimize, streamline, and reduce medication use. Her current work specializes in deprescribing, which she has been doing in various practice settings – like long-term care and specialty compounding pharmacy – since she became a pharmacist over a decade ago. She brings together her knowledge of medication, entrepreneurial spirit, and a passion for holistic care to this innovative practice. Connect with her on <a href="https://www.linkedin.com/company/your-simple-health/" target="_blank" rel="noreferrer">LinkedIn</a>, <a href="https://www.instagram.com/yoursimplehealth/" target="_blank" rel="noreferrer">Instagram</a>, <a href="https://www.facebook.com/profile.php?id=100085572370229" target="_blank" rel="noreferrer">Facebook</a>, and <a href="https://www.tiktok.com/@simplehealthpharmacist" target="_blank" rel="noreferrer">TikTok</a>. Visit her <a href="https://www.simplehealthpharmacist.com/providers" target="_blank" rel="noreferrer">website</a> for more.`,
    designation: "Pharmacist",
    avatar:
      "https://media.licdn.com/dms/image/C4D03AQH9RBPZePqltA/profile-displayphoto-shrink_800_800/0/1649163449462?e=2147483647&v=beta&t=kNE4-LPvX2i8t84VOrfKi3PcuFiWFg8xMiCMdgFGXfc",
    koFiUsername: "staceydangelo",
  },
  "Roger Tam": {
    designation: "Clinical Pharmacist",
    avatar:
      "https://firebasestorage.googleapis.com/v0/b/education-hub-848d2.appspot.com/o/educationHub%2Fheadshots%2FRoger%20Tam%20Pharmacist%20Allergy%20CE%20program%206188.jpeg?alt=media&token=193f5341-0100-408a-a400-eeb9b40b8b0f",
  },
  "Jean Eaton": {
    blurb: `Jean Eaton is constructively obsessive about privacy, confidentiality, and security especially when it comes to the handling of personal health information. If you would like to discuss how I can help your practice, you can find more about her <a href="https://informationmanagers.ca/author/jean-eaton-2/" target="_blank" rel="noreferrer">here</a>.`,
    designation: "Privacy and Security Specialist",
    avatar:
      "https://gallery.mailchimp.com/35b6329bd08c0690f0868bb33/images/7915dd22-3b33-4d2b-8ae4-dc2fdcf877f0.png",
  },
  "Michael Do": {
    blurb: `Michael Do is a passionate <strong>pharmacist</strong> and <strong>software engineer</strong> 
    who managed an independent community pharmacy for close to 10 years before co-founding MedEssist, 
    a pharmacy technology company. As the Chief Technology Officer at MedEssist, Michael combines his
     expertise in pharmacy practice and software development to create solutions that empower pharmacists and 
     transform patient care. Connect with him on <a href="https://www.linkedin.com/in/mikedolink/" target="_blank"
      rel="noreferrer">LinkedIn</a>.`,
    designation: "Pharmacist & Software Engineer",
    avatar:
      "https://firebasestorage.googleapis.com/v0/b/education-hub-848d2.appspot.com/o/educationHub%2Fheadshots%2Fmike%20headshot.jpeg?alt=media&token=b07b716a-6b7b-4385-be87-d0ad78168171",
  },
  "Stephanie Hsieh": {
    blurb: `Stephanie Hsieh is a residency-trained <strong>clinical pharmacist</strong> specializing in <strong>antimicrobial stewardship</strong>. She collaborates with Infectious Diseases specialists, pharmacists, and other health professionals to optimize antimicrobial therapies. Stephanie is dedicated to simplifying complex concepts for other pharmacists, sharing her knowledge through her YouTube channel, Instagram, and X accounts. She is also keen on collaborating with community pharmacies and primary care settings to enhance antimicrobial therapy review and stewardship practices. <br/>Connect with her at <a href="mailto:hsiehstephanie4@gmail.com">hsiehstephanie4@gmail.com</a>, <a href="https://www.youtube.com/@shsieh_rx" target="_blank" rel="noreferrer">YouTube</a>, and <a href="https://www.instagram.com/shsieh_rx" target="_blank" rel="noreferrer">Instagram</a>.`,
    designation: "Clinical Pharmacist",
    avatar:
      "https://firebasestorage.googleapis.com/v0/b/education-hub-848d2.appspot.com/o/educationHub%2Fheadshots%2Fsteph.jpeg?alt=media&token=25fedcae-33e8-4522-912e-95081589479f",
    koFiUsername: "shsieh_rx",
  },
  "Max Kisil": {
    blurb: `Max is a Pharmacist and founder of Script Digital Marketing Solutions, where he helps community pharmacy owners enhance their pharmacy's digital presence and improve marketing efforts to grow their practice. He is passionate about small business, using technology to increase accessibility for patients, and modernizing business processes to make more informed business decisions. As a recent graduate and pharmacy class valedictorian, he appreciates connecting with patients and practicing at full scope whenever possible. Outside of work, you can find him at a local coffee shop or out on the golf course trying to improve his game.  Connect with him on <a href="https://www.linkedin.com/in/max-kisil/" target="_blank" rel="noreferrer">LinkedIn</a>.`,
    designation: "PharmD, Rph, Founder of Script Digital Marketing Solutions",
    avatar:
      "https://firebasestorage.googleapis.com/v0/b/education-hub-848d2.appspot.com/o/educationHub%2Fmax%20-%20marketing%2Fimage%20(9).png?alt=media&token=60f95497-6f4a-4a51-a1fc-8c3361aa588c",
  },
  "Anushya Vijayaraghevan": {
    blurb: `<p>
    Anushya is a University of Toronto PharmD graduate and has gained numerous experiences over the years in the
     areas of pharmacy and the cannabis industry. She owns and operates two 
   Wholehealth pharmacies in downtown Toronto and is the co-founder of
      <a href="https://apothecare.ca/">Apothecare</a>, a pharmacist-led cannabis 
      consultation company servicing Canadians seeking guidance on cannabis use for health and wellness 
      purposes. Apothecare combines traditional and alternative medicine, emphasizing evidence-based 
      practices with cannabis for holistic well-being. Anushya is dedicated to advancing the pharmacy 
      profession with innovative solutions and navigating diverse healthcare landscapes. Anushya is also 
      well known in the social media space as a pharmacist-wellness content creator with a social media 
      community of over 75,000 individuals. <br/><br/>Connect with her on
       <a href="https://www.tiktok.com/@brownskinpharmd">TikTok</a>, 
       <a href="https://www.instagram.com/brownskinpharmd">Instagram</a>, and 
       <a href="https://www.linkedin.com/in/anushyav/">LinkedIn</a>. Visit  <a href="https://apothecare.ca/">Apothecare's website</a> for more details
        on the company’s services.
  </p>`,
    designation:
      "Pharmacist, Wholehealth Pharmacy Owner and Co-Founder of Apothecare Inc.",
    avatar:
      "https://firebasestorage.googleapis.com/v0/b/education-hub-848d2.appspot.com/o/educationHub%2Fheadshots%2Fanushya_vijayaraghevan_sphere.png?alt=media&token=5ed461e7-44f3-42a9-b0df-0f45f919406d",
  },
  "Kayla Castonguay": {
    blurb: `Kayla est une pharmacienne clinicienne et possède les certifications de spécialiste en pharmacothérapie et d'éducatrice en diabète. Son dévouement et sa passion de la profession la pousser à la fourniture de soins exceptionnels aux patients. En outre, elle prend plaisir à encadrer les étudiants en pharmacie et à leur transmettre avec enthousiasme son expertise en pharmacothérapie.`,
    designation: "Pharmacienne clinicienne",
    avatar:
      "https://firebasestorage.googleapis.com/v0/b/education-hub-848d2.appspot.com/o/educationHub%2FCCCEP%2FLMC_logo-highres-171-x-172.png?alt=media&token=7a658347-761c-4be7-8be5-2b8d1490883d",
    linkedinUrl: "https://www.linkedin.com/in/kayla-castonguay-383563a4/",
  },
};

export default instructorData;
