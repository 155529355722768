import axios from "axios";

export const fetchMedEssistData = async () => {
  try {
    const response = await axios.get(
      "https://northamerica-northeast1-medessist-ver2.cloudfunctions.net/api/api/education-hub"
    );
    return response.data;
  } catch (error) {
    console.error("Error fetching MedEssist data:", error);
    return [];
  }
};

//POST request with the user UUID and the pharmacy ID
export const linkPharmacyToUser = async (userId, pharmacyId) => {
  try {
    const response = await axios.post(
      "https://northamerica-northeast1-medessist-ver2.cloudfunctions.net/api/api/education-hub",
      {
        userId,
        pharmacyId,
      }
    );
    return response.data;
  } catch (error) {
    console.error("Error linking pharmacy to user:", error);
    return false;
  }
};

//get request with the user UUID and the pharmacy ID
export const testGET = async (userId, pharmacyId) => {
  try {
    const response = await axios.get(
      "https://northamerica-northeast1-education-hub-848d2.cloudfunctions.net/api/courses"
    );
    return response.data;
  } catch (error) {
    console.error("Error fetching MedEssist data:", error);
    return [];
  }
};
