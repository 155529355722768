import {
  updateDoc,
  getDoc,
  getDocs,
  doc,
  collection,
  runTransaction,
  setDoc,
  onSnapshot,
  query,
  where,
  increment,
  arrayUnion,
  deleteDoc,
  writeBatch,
} from "firebase/firestore";
import { v4 as uuidv4 } from "uuid";

import { db } from "../../lib/firebase/config";
import {
  getStorage,
  ref,
  uploadBytesResumable,
  getDownloadURL,
} from "firebase/storage";
import axios from "axios";
import moment from "moment";

export const getCurrentUserObject = async (userId) => {
  const userDocRef = doc(db, "users", userId);
  const userDocSnap = await getDoc(userDocRef);
  if (userDocSnap.exists()) {
    return userDocSnap.data();
  } else {
    console.log("User not found");
    return null;
  }
};
export const getCurrentPIN = async (userId) => {
  const userDocRef = doc(db, "users", userId);
  const userDocSnap = await getDoc(userDocRef);
  if (userDocSnap.exists() && userDocSnap.data().myPin) {
    return userDocSnap.data().myPin;
  } else {
    return null;
  }
};
//Fetch then return array of course objects containing the course ID and data
export const getAllCourses = async () => {
  const querySnapshot = await getDocs(collection(db, "courses"));
  const courses = [];
  querySnapshot.forEach((doc) => {
    courses.push({
      id: doc.id,
      ...doc.data(),
    });
  });
  return courses;
};

//fetch a single course from db using the courseId
export const getCourse = async (courseId) => {
  const courseDocRef = doc(db, "courses", courseId);
  try {
    const docSnap = await getDoc(courseDocRef);
    if (docSnap.exists()) {
      return docSnap.data();
    } else {
      console.log("Course not found");
      return null;
    }
  } catch (error) {
    console.error("Error fetching course:", error);
    return null;
  }
};
export const updateDontShowPopUp = async (userId) => {
  const userDocRef = doc(db, "users", userId);
  await updateDoc(userDocRef, { doNotShowInitPopUp: true });
};
// update the course record for a user in db (users collection)
export const updateCoursesForUser = async ({
  userId,
  courseId,
  courseName,
  score,
  pass,
  enrolled,
  videoWatched,
  instructorName,
  courseLength,
  multipleCreditCourse = false,
  source = "unknown",
}) => {
  const userDocRef = doc(db, "users", userId);
  try {
    const userDocSnap = await getDoc(userDocRef);
    if (userDocSnap.exists()) {
      const userData = userDocSnap.data();
      const courses = userData.courses || {};
      // if the course already exists in the object, update its properties
      if (courses?.hasOwnProperty(courseId)) {
        const updatedCourse = {};
        // enroll user and save the enrollment date
        if (enrolled) {
          updatedCourse.enrolled = true;
          updatedCourse.enrolledDate = new Date();
        }

        // eg: google ads, fb, linkedin, etc
        updatedCourse.source = source;

        if (courseId) {
          updatedCourse.courseId = courseId;
        }

        if (courseName !== undefined) {
          updatedCourse.courseName = courseName;
        }
        if (score !== undefined) {
          updatedCourse.score = score;
        }
        if (videoWatched !== undefined) {
          updatedCourse.videoWatched = videoWatched;
        }
        // when at least one property was defined for the updated course, update course in the courses object
        if (Object.keys(updatedCourse).length > 0) {
          courses[courseId] = { ...courses[courseId], ...updatedCourse };
        }
        if (pass) {
          courses[courseId].completed = new Date();
        }
        if (courseLength) {
          courses[courseId].courseLength = courseLength;
        }

        if (multipleCreditCourse) {
          courses[courseId].multipleCreditCourse = multipleCreditCourse;
        }
      } else {
        // if course doesn't exist in object, add it with default properties (when newly enroll)
        const newCourse = {
          instructorName,
          courseName,
          enrolled,
          videoWatched: false,
          courseId,
          enrolledDate: enrolled ? new Date() : null,
          multipleCreditCourse,
          source,
        };
        courses[courseId] = newCourse;
      }

      await updateDoc(userDocRef, { courses });
      console.log("Course record updated for user");
    } else {
      console.log("User not found");
    }
  } catch (error) {
    console.error("Error updating courses record", error);
  }
};

//for INTERACTIVE COURSES: function to update user's progress in an interactive course, including quiz results
export const updateInteractiveCourseProgress = async ({
  userId,
  courseId,
  progress,
  quizResults,
}) => {
  const userDocRef = doc(db, "users", userId);
  try {
    const userDocSnap = await getDoc(userDocRef);
    if (userDocSnap.exists()) {
      const userData = userDocSnap.data();
      const courses = userData.courses || {};

      const courseProgress = {
        interactive: true,
        progress,
        quizResults, // Save quiz results into the course data
      };

      courses[courseId] = { ...courses[courseId], ...courseProgress };

      await updateDoc(userDocRef, { courses });
      console.log("Updated course progress including quiz results.");
    } else {
      console.log("User document does not exist");
    }
  } catch (error) {
    console.error("Error updating interactive course progress", error);
  }
};

//for INTERACTIVE COURSES: mark an interactive course section as complete
export const markSectionAsComplete = async ({
  userId,
  courseId,
  sectionId,
  credits = 0, // Default credits to 0 if not provided
  multipleCreditCourse = false,
  moduleName = "",
  accreditationNumber = "",
  isThisAModuleThatGetsACredit = false,
}) => {
  const userDocRef = doc(db, "users", userId);

  try {
    const userDocSnap = await getDoc(userDocRef);
    const userData = userDocSnap.data();
    const courses = userData.courses || {};
    const courseData = courses[courseId] || {};
    courseData.completedSections = courseData.completedSections || {};

    if (multipleCreditCourse) {
      // For multi-credit courses, store detailed information including the module name

      courseData.completedSections[sectionId] = {
        completed: true,
        date: new Date().toISOString(),
        creditsEarned: credits,
        moduleName,
        accreditationNumber,
        isThisAModuleThatGetsACredit,
      };
    } else {
      // For single-credit courses, mark the section simply as completed
      courseData.completedSections[sectionId] = true;
    }

    // Update the course data back into the user's course list
    await updateDoc(userDocRef, {
      [`courses.${courseId}`]: courseData,
    });
  } catch (error) {
    console.error("Error marking section as complete ->", error);
  }
};
// saving long answer responses...
export const saveAnswersToDb = async ({
  userId,
  courseId,
  questionId,
  answer,
}) => {
  const userDocRef = doc(db, "users", userId);
  try {
    const userDocSnap = await getDoc(userDocRef);
    if (userDocSnap.exists()) {
      const userData = userDocSnap.data();
      const courses = userData.courses || {};
      const courseData = courses[courseId] || {};

      // get or create the answers object
      const answers = courseData.answers || {};

      // save the user's answer to the specified question
      answers[questionId] = {
        answer: answer,
        date: new Date().toISOString(),
      };

      // update the course data with the new answers
      await updateDoc(userDocRef, {
        [`courses.${courseId}.answers`]: answers,
      });
    }
  } catch (error) {
    console.error("Error saving user answer:", error);
  }
};

//for INTERACTIVE COURSES: track what videos from the course user watched
export const updateVideoProgress = async ({ userId, courseId, videoId }) => {
  const userDocRef = doc(db, "users", userId);

  try {
    const userDocSnap = await getDoc(userDocRef);
    if (userDocSnap.exists()) {
      const userData = userDocSnap.data();
      const courseProgress = userData.courses?.[courseId] || {};
      const videoProgress = courseProgress.videoProgress || {};

      // Mark this video as completed
      videoProgress[videoId] = true;

      // Update the course progress with new video progress data
      await updateDoc(userDocRef, {
        [`courses.${courseId}.videoProgress`]: videoProgress,
      });

      console.log("Video progress updated:", videoId);
    } else {
      console.error("User document does not exist.");
    }
  } catch (error) {
    console.error("Error updating video progress:", error);
  }
};

// link a medessist pharmacy (name, uuid and phone) to user's document under linkedPharmacies key
// export const linkPharmacy = async (userId, pharmacyObject) => {
//   const userDocRef = doc(db, "users", userId);
//   try {
//     const userDocSnap = await getDoc(userDocRef);
//     if (userDocSnap.exists()) {
//       const userData = userDocSnap.data();
//       // grab user's linkedPharmacies array, or create it if it doesn't exist yet
//       const linkedPharmacies = userData.linkedPharmacies || [];

//       // Add the new pharmacy object to the linkedPharmacies array
//       // pharmacyObject should contain pharmacyId, name and phone number
//       linkedPharmacies.push(pharmacyObject);

//       // Update the user's document with the new linkedPharmacies array
//       await updateDoc(userDocRef, { linkedPharmacies });
//       console.log("Pharmacy linked successfully");
//     } else {
//       console.log("User not found");
//     }
//   } catch (error) {
//     console.error("Error linking pharmacy", error);
//   }
// };
export const linkPharmacy = async (userId, pharmacyObject) => {
  const userDocRef = doc(db, "users", userId);
  try {
    const userDocSnap = await getDoc(userDocRef);
    if (userDocSnap.exists()) {
      const userData = userDocSnap.data();
      // Grab user's linkedPharmacies array, or create it if it doesn't exist yet
      const linkedPharmacies = userData.linkedPharmacies || [];

      // Check if pharmacyObject with the same pharmacyId already exists
      const pharmacyExists = linkedPharmacies.some(
        (pharmacy) => pharmacy.pharmacyId === pharmacyObject.pharmacyId
      );

      if (!pharmacyExists) {
        // Add the new pharmacy object to the linkedPharmacies array
        linkedPharmacies.push(pharmacyObject);

        // Update the user's document with the new linkedPharmacies array
        await updateDoc(userDocRef, { linkedPharmacies });
        console.log("Pharmacy linked successfully");
      } else {
        console.log("Pharmacy is already linked");
      }
    } else {
      console.log("User not found");
    }
  } catch (error) {
    console.error("Error linking pharmacy", error);
  }
};
// allow users to delete pharmacy link requests
export const deletePharmacyLinkRequest = async (userId, pharmacyId) => {
  const userDocRef = doc(db, "users", userId);

  try {
    const userDocSnap = await getDoc(userDocRef);
    if (userDocSnap.exists()) {
      const userData = userDocSnap.data();
      // user obj has field "linkedPharmacies", which is an array of pharmacy objects (pharmacyId, phone & name)
      const pendingPharmacyRequests = userData?.pendingPharmacyRequests;

      // filter out the pharmacy to be deleted based on pharmacyId
      const updatedPendingPharmacyRequests = pendingPharmacyRequests.filter(
        (pharmacy) => pharmacy.pharmacyId !== pharmacyId
      );

      // Update the user document with the new linkedPharmacies array, with the target pharmacy removed
      await updateDoc(userDocRef, {
        pendingPharmacyRequests: updatedPendingPharmacyRequests,
      });
      console.log("Pharmacy deleted successfully");
    }
  } catch (error) {
    console.error("Error deleting pharmacy", error);
  }
};
// allow users to unlink pharmacies from their account
export const deleteLinkedPharmacy = async (userId, pharmacyId) => {
  const userDocRef = doc(db, "users", userId);

  try {
    const userDocSnap = await getDoc(userDocRef);
    if (userDocSnap.exists()) {
      const userData = userDocSnap.data();
      // user obj has field "linkedPharmacies", which is an array of pharmacy objects (pharmacyId, phone & name)
      const linkedPharmacies = userData?.linkedPharmacies;

      // filter out the pharmacy to be deleted based on pharmacyId
      const updatedLinkedPharmacies = linkedPharmacies.filter(
        (pharmacy) => pharmacy.pharmacyId !== pharmacyId
      );

      // Update the user document with the new linkedPharmacies array, with the target pharmacy removed
      await updateDoc(userDocRef, {
        linkedPharmacies: updatedLinkedPharmacies,
      });
      console.log("Pharmacy deleted successfully");
    }
  } catch (error) {
    console.error("Error deleting pharmacy", error);
  }
};

//store user's course rating to respective courses document and in user document (in courses object field)
export const rateCourse = async ({ userId, courseId, rating }) => {
  const courseDocRef = doc(db, "courses", courseId);
  const userDocRef = doc(db, "users", userId);
  try {
    const courseDocSnap = await getDoc(courseDocRef);
    const userDocSnap = await getDoc(userDocRef);
    if (courseDocSnap.exists() && userDocSnap.exists()) {
      // Update rating in the course's document
      const courseData = courseDocSnap.data();
      const courseRatings = courseData.ratings || {};
      courseRatings[userId] = rating;
      await updateDoc(courseDocRef, { ratings: courseRatings });

      // Update rating in the user's document
      const userData = userDocSnap.data();
      const userCourses = userData.courses || {};
      if (userCourses[courseId]) {
        userCourses[courseId].rating = rating;
      }
      await updateDoc(userDocRef, { courses: userCourses });
    }
  } catch (error) {
    console.error("Error updating course rating", error);
  }
};
const baseUrl =
  "https://northamerica-northeast1-medessist-ver2.cloudfunctions.net/api";
export const handleSendCustomEmail = (data) => {
  const url = `${baseUrl}/api/custom-email-to-pharmacy`;
  return axios.post(url, data);
};

export const uploadProfilePicture = async (userId, file) => {
  const storage = getStorage();
  // Images will be stored in Firestore, in the profilePictures folder, under a userId-named file
  // This way, we have one image stored only per user
  const storageRef = ref(storage, `profilePictures/${userId}`);

  // uploadBytesResumable takes in a reference to where to upload file and file being uploaded, returns a promise
  try {
    // wait for file to upload to Firebase Storage, then get download URL
    const snapshot = await uploadBytesResumable(storageRef, file);
    const downloadURL = await getDownloadURL(snapshot.ref);

    // Get reference to user's document in Firestore and update profilePic field with download URL
    const userDocRef = doc(db, "users", userId);
    await updateDoc(userDocRef, { profilePicture: downloadURL });

    console.log("Upload completed successfully");
  } catch (error) {
    console.error("Upload failed:", error);
  }
};
export const handleSendNotificationSMS = (mobileNumber, message) => {
  const data = { mobileNumber, message };
  const url = `${baseUrl}/api/notificationSMS/`;
  return axios.post(url, data);
};

// Update profession, practiceType, company record for a user in db (users collection)
export const updatePracticeTypeForUser = async ({
  uid,
  practiceType,
  company,
  profession,
}) => {
  const userDocRef = doc(db, "users", uid);
  try {
    // check if the user's document exists
    const userDocSnap = await getDoc(userDocRef);
    if (userDocSnap.exists()) {
      // only include fields that not undefined (value provided)
      const updateObject = {
        ...(practiceType !== undefined && { practiceType }),
        ...(company !== undefined && { company }),
        ...(profession !== undefined && { profession }),
      };

      // update doc with fields gven
      await updateDoc(userDocRef, updateObject);
      console.log("Updated for user");
    } else {
      console.log("User not found");
    }
  } catch (error) {
    // log any errors to the console
    console.error("Error updating:", error);
  }
};

// Update the user's document to add the started module
export const addModuleToStarted = async ({ userId, courseId, moduleId }) => {
  const userDocRef = doc(db, "users", userId);
  try {
    const userDocSnap = await getDoc(userDocRef);
    if (userDocSnap.exists()) {
      const userData = userDocSnap.data();
      const courses = userData.courses || {};
      const course = courses[courseId] || {};
      course.modulesStarted = course.modulesStarted || {};

      // Add the new module if it hasn't been started yet
      if (!course.modulesStarted[moduleId]) {
        course.modulesStarted[moduleId] = {
          started: true,
          date: new Date().toISOString(),
        };
        courses[courseId] = course;
        await updateDoc(userDocRef, { courses });
        console.log("Module started updated successfully");
      }
    } else {
      console.log("User document does not exist");
    }
  } catch (error) {
    console.error("Error updating module started:", error);
  }
};

// Update the name of a user in the database (users collection)
export const updateUserName = async ({ userId, firstName, lastName }) => {
  const userDocRef = doc(db, "users", userId);
  try {
    const userDocSnap = await getDoc(userDocRef);
    if (userDocSnap.exists()) {
      await updateDoc(userDocRef, {
        firstName: firstName,
        lastName: lastName,
      });
      console.log("User name updated");
    } else {
      console.log("User not found");
      throw new Error("User not found");
    }
  } catch (error) {
    console.error("Error updating user name", error);
  }
};

/* Fetches all completed sections for each course for all users
returns completedSectionsData array as list of objects with email, courseId & completed sections */
export const getSectionsNeeded = async (sectionKey) => {
  try {
    const usersSnapshot = await getDocs(collection(db, "users"));
    const sectionNeededData = [];

    usersSnapshot.forEach((doc) => {
      const userData = doc.data();
      const email = userData.email;
      const courses = userData.courses || {};

      Object.keys(courses).forEach((courseId) => {
        const course = courses[courseId];
        const sections = course[sectionKey] || {};

        const sectionDetails = Object.keys(sections).map((sectionId) => ({
          sectionId,
          ...sections[sectionId],
        }));

        if (sectionDetails.length > 0) {
          sectionNeededData.push({
            email,
            courseId,
            sections: sectionDetails,
          });
        }
      });
    });

    return sectionNeededData;
  } catch (error) {
    console.error(`Error fetching ${sectionKey}:`, error);
    return [];
  }
};

export const actionCounter = async (type, field) => {
  const counterRef = doc(db, "counters", type);
  await updateDoc(counterRef, {
    [field]: increment(1),
  });
};

export const addNewQuery = async (userId, message, pharmacyName) => {
  const data = {
    userId: userId,
    initialQuery: message?.query,
    timestamp: new Date(),
    chat_history: [],
  };
  const newAiRef = doc(collection(db, "aiConversations"));

  try {
    await setDoc(newAiRef, { docId: newAiRef.id, ...data });
    return newAiRef.id;
  } catch (error) {
    console.error("Error adding query", error);
  }
};

export const getAIPosts = async (setAllConversations, userId) => {
  try {
    const q = query(
      collection(db, "aiConversations"),
      where("userId", "==", userId)
    );
    const snapshotRef = onSnapshot(q, (querySnapshot) => {
      const allAIPosts = [];
      querySnapshot.forEach((doc) => {
        if (doc.data().userId === userId) {
          allAIPosts.push({
            id: doc.id,
            ...doc.data(),
          });
        }
      });
      const chronologicalPosts = allAIPosts.sort(function (x, y) {
        return y.timestamp - x.timestamp;
      });
      setAllConversations(chronologicalPosts.slice(0, 10));
    });
  } catch (error) {
    console.error("Error fetching query", error);
  }
};

export const addToAIQuery = async (newValues, docID) => {
  try {
    const aiRef = doc(db, "aiConversations", docID);
    await updateDoc(aiRef, newValues);
    console.log("query updated");
  } catch (error) {
    console.error("Error updating query", error);
  }
};

// Function to update user's PIN in user's document
export const updateUserPin = async ({ userId, pin }) => {
  const userDocRef = doc(db, "users", userId);
  try {
    await updateDoc(userDocRef, { myPin: pin });
  } catch (error) {
    console.error("Error updating user PIN", error);
  }
};

// Function to update user's licenseNumber in user's document
export const updateUserLicenseNumber = async ({ userId, licenseNumber }) => {
  const userDocRef = doc(db, "users", userId);
  try {
    await updateDoc(userDocRef, { licenseNumber });
  } catch (error) {
    console.error("Error updating user license number", error);
  }
};
/* ADMIN PAGE (FOR VIEWING STATISTICS) FUNCTIONS */

const possibleDemoAccounts = (emailAddress) => {
  if (
    emailAddress?.includes("medessist") ||
    emailAddress?.includes("test.com") ||
    emailAddress?.includes("butter.com")
  ) {
    return true;
  }
};

export const getAccountData = async () => {
  const querySnapshot = await getDocs(collection(db, "users"));
  const users = [];
  querySnapshot.forEach((doc) => {
    const data = doc.data();
    if (!possibleDemoAccounts(data.email) && !data?.tester) {
      users.push({
        email: data.email,
        firstName: data.firstName,
        lastName: data.lastName,
        province: data.province,
        registrationDate: data.registrationDate,
        profession: data.profession,
      });
    }
  });
  return users;
};

// get counts of each profession
export const getProfessionCounts = async () => {
  const querySnapshot = await getDocs(collection(db, "users"));
  const professionCounts = {};

  querySnapshot.forEach((doc) => {
    //extact profession from the document, or default to 'Pharmacist' if not set
    const profession = doc.data().profession || "Pharmacist";

    // Skip TESTER accounts
    if (doc.data()?.tester === true || possibleDemoAccounts(doc.data().email)) {
      return;
    }
    // add to count for the profession
    // if profession doesn't exist yet in the object, initialize it with 0, then increase by 1
    professionCounts[profession] = (professionCounts[profession] || 0) + 1;
  });

  // return object containing counts for each profession
  return professionCounts;
};

export const trackExternalCourseClick = async (userId, courseId) => {
  try {
    // "users" collection
    const userDocRef = doc(db, "users", userId);

    // add the courseId to the clickedExternalCourses array
    // use arrayUnion: ensure no duplicates and only adds the courseId if it's not already present
    await updateDoc(userDocRef, {
      clickedExternalCourses: arrayUnion(courseId),
    });
  } catch (error) {
    console.error("Error tracking 'External Course' click:", error);
  }
};

// For admin page: track # of external course navigations
// returns an object with courseId as keys and click counts as values
// external clicks stored in user object using trackExternalCourseClick
export const getExternalCourseClickCounts = async () => {
  const usersSnapshot = await getDocs(collection(db, "users"));
  const courseClicks = {};
  usersSnapshot.forEach((doc) => {
    const userData = doc.data();

    // Skip TESTER accounts
    if (userData?.tester === true) {
      return;
    }

    const clickedExternalCourse = userData.clickedExternalCourses || [];

    // go over each clicked courseId in the user's array
    clickedExternalCourse.forEach((courseId) => {
      // If the courseID is already in the courseClicks object, increment its count
      if (courseClicks[courseId]) {
        courseClicks[courseId] += 1;
      } else {
        // If courseId isnt yet in object, add with a count of 1
        courseClicks[courseId] = 1;
      }
    });
  });

  // return object with courseId as keys and click counts as values
  return courseClicks;
};

// this was used to filter out registrations to set the initial value of registration on the course object, might be worth keeping around for troubleshooting in the future
export const getAllRegistrations = async () => {
  const querySnapshot = await getDocs(collection(db, "users"));
  const registrations = [];
  querySnapshot.forEach((doc) => {
    if (!possibleDemoAccounts(doc.data().email)) {
      registrations.push(doc.data().courses);
    }
  });
  //if you want to test, replace "deprescribing-101" with the course.id you want to filter
  // console.log(emails.filter((x) => x[courseId]));
  return registrations;
};

export const addToTrackRegistration = async (courseId) => {
  const courseRef = doc(db, "courses", courseId);
  const docSnapshot = await getDoc(courseRef);
  if (docSnapshot.exists()) {
    // we use runtransaction to update in case multiple people press it at once
    await runTransaction(db, async (transaction) => {
      const coursesObj = docSnapshot.data();
      let currentCounterValue = coursesObj.registration || 0;
      currentCounterValue++;
      transaction.update(courseRef, { registration: currentCounterValue });
    });

    console.log("Document successfully updated with the counter value!");
  } else {
    console.log("This course does not exist");
  }
};

export const getAllSpecialCourseStats = async (courseId) => {
  const processDate = (date) => {
    return date && date.seconds
      ? moment(date.seconds * 1000).format("MM/DD/YYYY")
      : date
      ? moment(date).format("MM/DD/YYYY")
      : "_____ /_____ /________";
  };

  const usersRef = collection(db, "users");
  const q = query(usersRef, where(`courses.${courseId}.viewed`, "==", true));
  const snapshot = await getDocs(q);

  const stats = {
    quizzesCompleted: {},
    videosWatched: {},
    sectionsCompleted: {},
    modulesStarted: {},
    usersCompleted: [],
    usersEnrolled: 0,
    usersViewed: 0,
    enrolledEmails: [],
    sourceCounts: {},
    enrolledUsersBySource: {},
    longAnswerResponses: [],
  };

  snapshot.forEach((doc) => {
    const userData = doc.data();
    const courseData = userData.courses[courseId];

    // filter out demo accounts
    if (possibleDemoAccounts(userData.email) || userData?.tester) {
      return;
    }

    if (courseData.viewed) {
      stats.usersViewed += 1;
    }

    let longAnswerResponse = "";

    if (
      courseData?.answers?.["long-answer-1"]?.answer &&
      courseData?.answers?.["long-answer-1"]?.answer !== undefined
    ) {
      longAnswerResponse = courseData?.answers?.["long-answer-1"]?.answer;
    }

    //for users who enrolled
    if (courseData.enrolled) {
      stats.usersEnrolled += 1;
      stats.enrolledEmails.push(userData.email);

      const source = courseData.source || "unknown";
      stats.sourceCounts[source] = (stats.sourceCounts[source] || 0) + 1;

      if (!stats.enrolledUsersBySource[source]) {
        stats.enrolledUsersBySource[source] = [];
      }

      stats.enrolledUsersBySource[source].push({
        email: userData.email,
        firstName: userData.firstName,
        lastName: userData.lastName,
        province: userData.province,
        profession: userData.profession,
        registrationDate: userData.registrationDate
          ? userData.registrationDate
          : null, //  hub enrollment date
        courseEnrolledDate: courseData.enrolledDate
          ? processDate(courseData.enrolledDate)
          : null, // course enrollment date
        practiceType: userData.practiceType ? userData.practiceType : null,
        company: userData.company ? userData?.company : null,
        longAnswerResponses: longAnswerResponse,
      });
    }

    Object.entries(courseData.completedSections || {}).forEach(
      ([key, value]) => {
        stats.sectionsCompleted[key] =
          (stats.sectionsCompleted[key] || 0) + (value ? 1 : 0);
      }
    );

    Object.entries(courseData.modulesStarted || {}).forEach(([key, value]) => {
      stats.modulesStarted[key] =
        (stats.modulesStarted[key] || 0) + (value ? 1 : 0);
    });

    if (courseData.completed) {
      stats.usersCompleted.push({
        email: userData.email,
        firstName: userData.firstName,
        lastName: userData.lastName,
        province: userData.province,
        profession: userData.profession,
        completedDate: courseData.completed,
      });
    }
  });

  return stats;
};

//function to update the viewed status for a course for a user
export const markCourseAsViewed = async (userId, courseId) => {
  const userDocRef = doc(db, "users", userId);
  try {
    const userDocSnap = await getDoc(userDocRef);
    if (userDocSnap.exists()) {
      const userData = userDocSnap.data();
      const courses = userData.courses || {};
      const course = courses[courseId] || {};

      // Set viewed to true
      course.viewed = true;

      // Update the course data back into the user's courses object
      courses[courseId] = course;
      await updateDoc(userDocRef, { courses });
      console.log("Course viewed status updated for user");
    } else {
      console.log("User not found");
    }
  } catch (error) {
    console.error("Error updating course viewed status", error);
  }
};

export const addCanadaToEachAcc = async () => {
  try {
    const querySnapshot = await getDocs(collection(db, "users"));
    querySnapshot.forEach(async (doc) => {
      await updateDoc(doc.ref, {
        country: "Canada",
      });
    });
  } catch {
    console.log("didnt work");
  }
};

/*   -------***FORUMS****-------- */
// Fetch all questions for a specific course
export const getQuestions = async (courseId) => {
  try {
    const q = query(collection(db, "forum"), where("courseId", "==", courseId));
    const querySnapshot = await getDocs(q);
    const questions = [];

    for (const doc of querySnapshot.docs) {
      const question = { id: doc.id, ...doc.data() };

      // forum chat content and user info is stored in reply
      question.replies = question.replies.map((reply) => ({
        ...reply,
      }));

      questions.push(question);
    }

    return questions;
  } catch (error) {
    console.error("Error fetching questions:", error);
    return [];
  }
};

// Add new question to FORUMS collection
export const addQuestion = async (
  courseId,
  userId,
  content,
  firstName,
  lastName,
  email,
  sectionId,
  profession = null,
  province = null,
  vimeoTimestamp = null,
  videoId = null
) => {
  try {
    const forumDocRef = doc(collection(db, "forum"), uuidv4()); // Use UUID for the document ID

    await setDoc(forumDocRef, {
      courseId,
      userId, // ID of user who asked the question
      firstName,
      lastName,
      email,
      content, // question content
      sectionId,
      timestamp: new Date(), // current timestamp
      replies: [],
      profession,
      province,
      vimeoTimestamp,
      videoId,
    });
  } catch (error) {
    console.error("Error adding question:", error);
  }
};

// Add reply under specific question
export const addReply = async (
  questionId,
  userId,
  content,
  firstName,
  lastName,
  email,
  isInstructor,
  profession = null,
  province = null
) => {
  const replyId = uuidv4(); // make a UUID for each reply
  const questionDocRef = doc(db, "forum", questionId);

  try {
    await updateDoc(questionDocRef, {
      replies: arrayUnion({
        id: replyId, // giving the reply its own UUID
        userId, // ID of user replying
        firstName,
        lastName,
        email,
        content,
        isInstructor: isInstructor || false, // adding the instructor flag
        timestamp: new Date(), // timestamp for right now
        profession,
        province,
      }),
    });
  } catch (error) {
    console.error("Error adding reply:", error);
  }
};

// Function to fetch user data by userId  ---> not being used anymore? consider deleting
export const getUserData = async (userId) => {
  try {
    const userDocRef = doc(db, "users", userId);
    const userDoc = await getDoc(userDocRef);
    if (userDoc.exists()) {
      return userDoc.data();
    } else {
      console.log("No such user!");
      return null;
    }
  } catch (error) {
    console.error("Error fetching user data:", error);
    return null;
  }
};

//EDIT or deleting existing questions

// Edit an existing question
export const editQuestion = async (questionId, newContent) => {
  try {
    const questionDocRef = doc(db, "forum", questionId);
    await updateDoc(questionDocRef, {
      //replacing the content
      content: newContent,
      //add a "edited at" timestamp
      editedAt: new Date(), // record the time of edit
    });
  } catch (error) {
    console.error("Error editing question:", error);
  }
};

// function to delete a question for firebase
export const deleteQuestion = async (questionId) => {
  try {
    const questionDocRef = doc(db, "forum", questionId);
    await updateDoc(questionDocRef, {
      deleted: true,
      deletedAt: new Date(),
    });
    console.log("Question marked as deleted");
  } catch (error) {
    console.log("Error marking question as deleted", error);
  }
};

// Edit an existing reply
export const editReply = async (questionId, replyId, newContent) => {
  try {
    const questionDocRef = doc(db, "forum", questionId);
    const questionDoc = await getDoc(questionDocRef);

    const questionData = questionDoc.data();
    const updatedReplies = questionData.replies.map((reply) =>
      //if the reply.id matches the id of the reply we're targeting
      reply.id === replyId
        ? //update reply content and add a new editedAt timestamp (just in case)
          { ...reply, content: newContent, editedAt: new Date() }
        : reply
    );
    await updateDoc(questionDocRef, { replies: updatedReplies });
  } catch (error) {
    console.log("Error editing reply", error);
  }
};

// Delete an existing reply
export const deleteReply = async (questionId, replyId) => {
  try {
    const questionDocRef = doc(db, "forum", questionId);
    const questionDoc = await getDoc(questionDocRef);
    const questionData = questionDoc.data();
    //filter out reply that match the reply.id being targeted for deletion
    const updatedReplies = questionData.replies.filter(
      (reply) => reply.id !== replyId
    );

    await updateDoc(questionDocRef, { replies: updatedReplies });
  } catch (error) {
    console.error("Error deleting reply:", error);
  }
};

export const migratePharmacyIds = async () => {
  try {
    const usersSnapshot = await getDocs(collection(db, "users"));

    let batch = writeBatch(db); // Create a batch
    let count = 0;

    for (const userDoc of usersSnapshot.docs) {
      const data = userDoc.data();
      const linkedPharmacies = data.linkedPharmacies || [];

      // Get pharmacyIds from linkedPharmacies
      const pharmacyIds = linkedPharmacies.map(
        (pharmacy) => pharmacy.pharmacyId
      );

      // Reference to the document
      const userRef = userDoc.ref;

      // Update the document in batch
      batch.update(userRef, { pharmacyIds });

      count++;

      // Firestore batch limit is 500 operations, so commit and reset the batch if the limit is hit
      if (count === 500) {
        await batch.commit(); // Commit the current batch
        batch = writeBatch(db); // Start a new batch
        count = 0;
      }
    }

    // Commit any remaining writes after the loop
    if (count > 0) {
      await batch.commit();
    }

    console.log("Migration complete");
  } catch (error) {
    console.error("Error migrating pharmacyIds:", error);
  }
};
