import React, { useState } from "react";
import _ from "lodash";
import { addToAIQuery } from "../components/helpers/source";
import { Box } from "@mui/material";
import ExtraFeedbackModal from "./ExtraFeedbackModal";

export default function FeedbackAI(props) {
  const {
    conversation,
    message,
    index,
    setSelectedConversation,
    selectedConversation,
  } = props;
  const [showExpandFeedBackModal, setShowExpandFeedbackModal] = useState(false);

  return (
    <div>
      <ExtraFeedbackModal
        showExpandFeedBackModal={showExpandFeedBackModal}
        setShowExpandFeedbackModal={setShowExpandFeedbackModal}
        conversation={conversation}
        index={index}
        selectedConversation={selectedConversation}
        setSelectedConversation={setSelectedConversation}
      />
      <Box className="feedback-ai">
        Was this helpful?
        {["Yes", "No"].map((answer) => (
          <button
            className="new-button-style primary"
            style={{
              minWidth: "4rem",
              marginLeft: "10px",
              background: message?.isHelpful === answer ? "#edd178" : "#ebdeb1",
            }}
            key={answer}
            value={answer}
            onClick={(e) => {
              const chatHistoryCopy = _.cloneDeep(conversation?.chat_history);
              chatHistoryCopy[index]["isHelpful"] = e.target.value;

              addToAIQuery(
                { chat_history: chatHistoryCopy },
                conversation?.docId
              );

              setSelectedConversation({
                ...selectedConversation,
                chat_history: chatHistoryCopy,
              });
              if (answer === "No") {
                setShowExpandFeedbackModal(true);
              }
            }}
          >
            {answer}
          </button>
        ))}
      </Box>
    </div>
  );
}
