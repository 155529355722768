import React, { useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import LockIcon from "@mui/icons-material/Lock";
import CheckIcon from "@mui/icons-material/Check";
import {
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { trackExternalCourseClick } from "../helpers/source";

const UnlockQuiz = ({
  videoWatched,
  handleStartQuiz,
  handleUserConfirmation,
  markAsWatchedLoading,
  tutorial,
  handleTutorialCompletion,
  tutorialCompleted,
  setTutorialCompleted,
  enrolled,
  handleCourseEnrollment,
  currentUser,
  isExternal,
  externalLink,
  courseId,
}) => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [enrollmentLoading, setEnrollmentLoading] = useState(false);
  const [openExternalNavigationDialog, setOpenExternalNavigationDialog] =
    useState(false);
  const navigate = useNavigate();
  const [isRedirecting, setIsRedirecting] = useState(false);
  const location = useLocation();

  const handleSignInToEnroll = () => {
    navigate("/login", { state: { from: location } });
  };

  const completeTutorial = () => {
    setLoading(true);
    handleTutorialCompletion();
    setTimeout(() => {
      setLoading(false);
      setTutorialCompleted(true);
    }, 1000);
  };

  const enroll = async () => {
    if (!currentUser) {
      handleSignInToEnroll();
      return;
    }
    setEnrollmentLoading(true);
    await handleCourseEnrollment();
    setEnrollmentLoading(false);
  };

  const openExternalDialog = () => {
    setOpenExternalNavigationDialog(true);
  };

  // Dialog component for navigating to an external course
  const ExternalCourseDialog = () => (
    <Dialog
      open={openExternalNavigationDialog}
      onClose={() => setOpenExternalNavigationDialog(false)}
    >
      <DialogTitle id="external-course-dialog-title">
        {"Enroll in External Course"}
      </DialogTitle>
      <DialogContent>
        {/* message specific to ecortex. If needed, can change to store in db for variable messages */}
        <DialogContentText>
          You are leaving MedEssist Hub. This continuing education lesson is
          available for pharmacists on <strong>eCortex.ca</strong>, the online
          learning home of <strong>CanadianHealthcareNetwork.ca</strong>. If you
          do not have an account, you will need to register with the Canadian
          Healthcare Network to participate.
          <br />
          <br />
          Already a member? Log in and click on the eCortex link in the menu for
          easy access.
          {isRedirecting && (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                marginTop: "20px",
              }}
            >
              <CircularProgress />
              <p style={{ marginLeft: "10px" }}>Redirecting, please wait...</p>
            </div>
          )}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <button
          className="new-button-style secondary"
          onClick={() => setOpenExternalNavigationDialog(false)}
        >
          Cancel
        </button>
        <button
          className="new-button-style primary"
          onClick={() => {
            trackExternalCourseClick(currentUser.uid, courseId);
            setIsRedirecting(true);
            setTimeout(() => {
              // simulate a loading delay before actual redirection
              window.location.href = externalLink;
            }, 500);
          }}
          autoFocus
        >
          Continue
        </button>
      </DialogActions>
    </Dialog>
  );

  //if external course, show Take Course button that opens navigation modal
  if (isExternal) {
    return (
      <>
        <button
          className="new-button-style primary teal-hover take-quiz-button"
          onClick={openExternalDialog}
          disabled={enrollmentLoading || loading}
        >
          Take Free Course
        </button>
        {isExternal && <ExternalCourseDialog />}
      </>
    );
  }

  if (!enrolled) {
    return (
      <>
        <button
          className="new-button-style primary teal-hover take-quiz-button"
          onClick={enroll}
          disabled={enrollmentLoading || loading}
        >
          {enrollmentLoading || loading ? (
            <CircularProgress size={24} />
          ) : currentUser ? (
            <>
              <LockIcon sx={{ mr: 1 }} />
              {"Enroll Now"}
            </>
          ) : (
            <>
              <LockIcon sx={{ mr: 1 }} />
              Sign In to Enroll
            </>
          )}
        </button>
      </>
    );
  }

  return (
    <>
      {
        // Check if we are not in tutorial mode
        !tutorial ? (
          // if not in tutorial, check if video has been watched
          !videoWatched ? (
            // If video not watched, check if mark as watched is in loading state
            markAsWatchedLoading ? (
              // display loading spinner while marking as watched
              <div className="unlocking-container">
                <CircularProgress size={24} />
              </div>
            ) : (
              // if not loading, show button to mark course as watched
              <button
                onClick={handleUserConfirmation}
                className="new-button-style primary teal-hover take-quiz-button"
              >
                <LockIcon sx={{ mr: 1 }} />{" "}
                {t("unlockQuiz.markCourseAsWatched")}
              </button>
            )
          ) : (
            // if video has been watched, show button to start the quiz for certificate
            <button
              className="new-button-style primary teal-hover take-quiz-button"
              onClick={handleStartQuiz}
            >
              <CheckIcon sx={{ mr: 1 }} /> {t("unlockQuiz.takeCertificateQuiz")}
            </button>
          )
        ) : tutorialCompleted ? (
          // if in tutorial mode and tutorial is completed, disable the quiz button
          <button
            disabled
            className="new-button-style primary teal-hover take-quiz-button"
          >
            <CheckIcon sx={{ mr: 1 }} /> {t("unlockQuiz.tutorialCompleted")}
          </button>
        ) : loading ? (
          // if tutorial is not completed and is loading, show loading spinner
          <div style={{ width: "30%" }}>
            <CircularProgress size={24} />
          </div>
        ) : (
          // if tutorial not completed and not loading, show button to mark tutorial as completed
          <button
            onClick={completeTutorial}
            className="new-button-style primary teal-hover take-quiz-button"
            disabled={loading}
          >
            <CheckIcon sx={{ mr: 1 }} />{" "}
            {t("unlockQuiz.markTutorialAsCompleted")}
          </button>
        )
      }
    </>
  );
};

export default UnlockQuiz;
