import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";

function LongAnswerQuestion({
  questionData,
  onAnswerSave,
  savedAnswer,
  handlePreviousStep,
  currentStepIndex,
}) {
  const { t } = useTranslation();
  const [answer, setAnswer] = useState(savedAnswer?.answer || ""); // Extract the answer text here
  const [isSubmitted, setIsSubmitted] = useState(false);

  useEffect(() => {
    if (savedAnswer?.answer) {
      // Check if savedAnswer has an answer property
      setAnswer(savedAnswer.answer);
      setIsSubmitted(true);
    }
  }, [savedAnswer]);

  const handleInputChange = (e) => {
    setAnswer(e.target.value);
  };

  const handleSubmit = () => {
    setIsSubmitted(true);
    onAnswerSave(questionData.questionId, answer);
  };

  const handleEdit = () => {
    setIsSubmitted(false);
  };

  return (
    <div className="centered-container">
      <h1>{questionData.title}</h1>
      <p style={{ marginBottom: "15px", fontWeight: "bold" }}>
        {questionData.question}
      </p>
      <textarea
        value={answer}
        onChange={handleInputChange}
        rows={5}
        cols={50}
        disabled={isSubmitted}
        style={{
          width: "100%",
          padding: "10px",
          fontSize: "16px",
          marginBottom: "20px",
        }}
      />
      <div style={{ display: "flex", justifyContent: "center" }}>
        <button
          onClick={handlePreviousStep}
          className="new-button-style secondary"
          style={{ marginRight: "10px" }}
          disabled={currentStepIndex < 1}
        >
          {t("interactiveCourse.back")}
        </button>

        {!isSubmitted ? (
          <button className="new-button-style primary" onClick={handleSubmit}>
            {t("interactiveCourse.submit")}
          </button>
        ) : (
          <button className="new-button-style primary" onClick={handleEdit}>
            {/*     {t("interactiveCourse.editAnswer")} ADD TRANSLATION LATER*/}
            Continue
          </button>
        )}
      </div>
    </div>
  );
}

export default LongAnswerQuestion;
