import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";

function StepQuizCheckboxes({
  quizData,
  onQuizPass,
  isPassed,
  updateProgress,
  handlePreviousStep,
}) {
  const { t } = useTranslation();
  const [answers, setAnswers] = useState({});
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [showContinue, setShowContinue] = useState(false);

  const arraysEqual = (arr1 = [], arr2 = []) => {
    if (arr1.length !== arr2.length) return false;
    const sortedArr1 = arr1.slice().sort();
    const sortedArr2 = arr2.slice().sort();
    for (let i = 0; i < sortedArr1.length; i++) {
      if (sortedArr1[i] !== sortedArr2[i]) return false;
    }
    return true;
  };

  useEffect(() => {
    if (isPassed) {
      const correctAnswers = quizData.questions.reduce(
        (acc, question) => ({
          ...acc,
          [question.question]: question.answer,
        }),
        {}
      );
      setAnswers(correctAnswers);
      setIsSubmitted(true);
      setShowContinue(true);
    } else {
      setAnswers({});
      setIsSubmitted(false);
      setShowContinue(false);
    }
  }, [quizData.quizId, isPassed, quizData.questions]);

  const handleAnswerChange = (questionId, option, checked) => {
    if (!isSubmitted) {
      setAnswers((prev) => ({
        ...prev,
        [questionId]: checked
          ? [...(prev[questionId] || []), option]
          : (prev[questionId] || []).filter((ans) => ans !== option),
      }));
    }
  };

  const handleSubmit = () => {
    setIsSubmitted(true);
    let passed = true;
    quizData.questions.forEach((question) => {
      if (!arraysEqual(answers[question.question], question.answer)) {
        passed = false;
      }
    });
    setShowContinue(passed);
    if (passed) {
      updateProgress({
        quizId: quizData.quizId,
        passed: true,
      });
    }
  };

  const handleContinue = () => {
    if (showContinue) {
      onQuizPass();
    }
  };

  const handleRetry = () => {
    setIsSubmitted(false);
    setShowContinue(false);
    setAnswers({});
  };

  return (
    <div className="centered-container">
      <h1>{quizData.title}</h1>
      {quizData.questions.map((question, index) => (
        <div
          key={index}
          className="question-block"
          style={{ marginBottom: "15px" }}
        >
          <p style={{ fontWeight: "bold" }}>{question.question}</p>
          {question.options.map((option, optionIndex) => (
            <label
              key={optionIndex}
              style={{ display: "block", margin: "5px 0" }}
            >
              <input
                type="checkbox"
                name={`question-${index}`}
                value={option}
                checked={answers[question.question]?.includes(option) || false}
                onChange={(e) =>
                  handleAnswerChange(
                    question.question,
                    option,
                    e.target.checked
                  )
                }
                disabled={isSubmitted}
              />
              {option}
            </label>
          ))}
        </div>
      ))}

      {/* Sorry did not pass message when user fails */}
      {isSubmitted && !showContinue && (
        <p
          style={{
            color: "red",
            fontSize: "18px",
            fontWeight: "bold",
            textAlign: "center",
            marginBottom: "10px",
          }}
        >
          {t("interactiveCourse.sorryDidNotPass")}
        </p>
      )}

      {/* Congrats message when user passes quiz successfully */}
      {isSubmitted && showContinue && (
        <p
          style={{
            color: "green",
            fontSize: "18px",
            fontWeight: "bold",
            textAlign: "center",
            marginBottom: "10px",
          }}
        >
          {t("interactiveCourse.congratulationsPassedQuiz")}
        </p>
      )}

      {/* Back button */}
      <div style={{ display: "flex", justifyContent: "center" }}>
        <button
          onClick={handlePreviousStep}
          className="new-button-style secondary"
          style={{ marginRight: "10px" }}
        >
          {t("interactiveCourse.back")}
        </button>

        {/* Submit answers button */}
        {!isSubmitted ? (
          <button className="new-button-style primary" onClick={handleSubmit}>
            {t("interactiveCourse.submit")}
          </button>
        ) : showContinue ? (
          /*  Continue Button */
          <button className="new-button-style primary" onClick={handleContinue}>
            {t("interactiveCourse.continue")}
          </button>
        ) : (
          <button className="new-button-style primary" onClick={handleRetry}>
            {t("interactiveCourse.tryAgain")}
          </button>
        )}
      </div>
    </div>
  );
}

export default StepQuizCheckboxes;
