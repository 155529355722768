import React from "react";
import {
  Typography,
  Box,
  Card,
  Grid,
  IconButton,
  CssBaseline,
  Divider,
} from "@mui/material";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import EmailIcon from "@mui/icons-material/Email";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import InstagramIcon from "@mui/icons-material/Instagram";
import FacebookIcon from "@mui/icons-material/Facebook";
import TwitterIcon from "@mui/icons-material/Twitter";
import BackButton from "../navbars/BackButton";
import LanguageIcon from "@mui/icons-material/Language";
import { useTranslation, Trans } from "react-i18next";
import TeachOnMedEssist from "../teach/Teach";
import SuggestCourse from "../suggest-course/SuggestCourse";

const ContactUs = () => {
  const { t } = useTranslation();

  return (
    <>
      <CssBaseline />

      <Card
        style={{
          padding: "3rem",
          borderRadius: "10px",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: { xl: "row", lg: "row", xs: "column" },
          }}
        >
          <div>
            <Typography variant="h4" gutterBottom>
              <Trans i18nKey="contactUs.aboutUs" />
            </Typography>

            <Typography variant="body1" sx={{ marginTop: 2 }}>
              <Trans i18nKey="contactUs.description" />
            </Typography>
            <br />
            <Grid
              container
              spacing={1}
              justifyContent="flex-start"
              sx={{ marginTop: 2 }}
            >
              <Grid item>
                <LocationOnIcon />
              </Grid>
              <Grid item>
                <Typography variant="body1">
                  {t("contactUs.address")}
                </Typography>
              </Grid>
            </Grid>
            <Grid container spacing={1} justifyContent="flex-start">
              <Grid item>
                <EmailIcon />
              </Grid>
              <Grid item>
                <Typography variant="body1">{t("contactUs.email")}</Typography>
              </Grid>
            </Grid>
            <Grid container spacing={1} justifyContent="flex-start">
              <Grid item>
                <LanguageIcon />
              </Grid>
              <Grid item>
                <a
                  href="https://www.medessist.com/"
                  target="_blank"
                  style={{ textDecoration: "none" }}
                >
                  <Typography variant="body1">
                    {t("contactUs.website")}
                  </Typography>
                </a>
              </Grid>
            </Grid>
            {/* Social media icons */}
          </div>
          <Divider
            orientation="vertical"
            variant="inset"
            sx={{ height: "unset", marginLeft: "2em", marginRight: "2em" }}
          />
          <div>
            <Divider
              orientation="horizontal"
              sx={{
                display: { xl: "none", lg: "none", xs: "block" },
                marginTop: "2em",
                marginBottom: "2em",
              }}
            />
            <TeachOnMedEssist />
            <Divider
              orientation="horizontal"
              sx={{ marginTop: "2em", marginBottom: "2em" }}
            />
            <SuggestCourse />
          </div>
        </Box>
      </Card>
    </>
  );
};

export default ContactUs;
