import React from "react";
import { Typography, Box, Card, CssBaseline } from "@mui/material";
import BackButton from "../navbars/BackButton";

function SuggestCourse() {
  return (
    <>
      <CssBaseline />

      <Box
        component="form"
        sx={{
          display: "flex",
          flexDirection: "column",

          margin: "auto",
        }}
      >
        <Typography variant="h5" gutterBottom>
          <b>Suggest a Course</b>
        </Typography>

        <Typography variant="body1" sx={{ marginTop: 2 }}>
          We are always looking to expand our course offerings and value your
          input! If there's a topic or course you would like to see included on
          our platform, let us know. To submit your course suggestion, please
          use our{" "}
          <a
            href="https://docs.google.com/forms/d/e/1FAIpQLSfsNLHr1rioRG6frfcDfLptotY-oLaQ_JB8oD_mgSPHNAkZPA/viewform"
            target="_blank"
          >
            Course Suggestion Form
          </a>
          . <br /> <br />
          We look forward to hearing your ideas!
        </Typography>
      </Box>
    </>
  );
}

export default SuggestCourse;
