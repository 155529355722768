import React from "react";
import {
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContentText,
  DialogContent,
} from "@mui/material";
export default function ConfirmDeleteLinkedPharmacy({
  open,
  setOpenConfirmDeleteLinkedPharmacy,
  handleDeletePharmacy,
  pharmacy,
}) {
  return (
    <Dialog
      open={open}
      onClose={() => setOpenConfirmDeleteLinkedPharmacy(false)}
    >
      <DialogTitle>Confirm Pharmacy Unlink</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          Please confirm you are unlinking your account with{" "}
          {pharmacy?.pharmacyName} ({pharmacy?.pharmacyPhone})
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <button
          className="new-button-style"
          onClick={() => setOpenConfirmDeleteLinkedPharmacy(false)}
        >
          Cancel
        </button>
        <button
          className="new-button-style danger-red"
          onClick={() => handleDeletePharmacy(pharmacy?.pharmacyId)}
        >
          Unlink
        </button>
      </DialogActions>
    </Dialog>
  );
}
