import React, { useState, useEffect } from "react";
import { getCurrentUserObject } from "../helpers/source";

import {
  TextField,
  Card,
  CssBaseline,
  Typography,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Paper,
  Box,
} from "@mui/material";
import {
  handleSendCustomEmail,
  handleSendNotificationSMS,
} from "../helpers/source";
import { useAuth } from "../../contexts/AuthContext";
import BackButton from "../navbars/BackButton";
import { useTranslation } from "react-i18next";

export default function Referral() {
  const { t } = useTranslation();
  const { currentUser } = useAuth();
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [userObject, setUserObject] = useState(null);
  useEffect(() => {
    getCurrentUserObject(currentUser.uid).then((res) => setUserObject(res));
  }, [currentUser]);

  const handlePhoneNumberChange = (event) => {
    setPhone(event.target.value);
  };
  const [selectedValue, setSelectedValue] = useState("optionEmail");

  const handleChange = (event) => {
    setSelectedValue(event.target.value);
  };
  const messagePreview = t("referral.theMessagePreview", {
    firstName: userObject?.firstName,
    lastName: userObject?.lastName,
  });

  const sendText = async (event) => {
    event.preventDefault();
    // Regex to allow digits, spaces, hyphens, and parentheses
    const phoneNumberRegex = /^[0-9\s-()+]+$/;

    if (phone && phone !== "") {
      if (phone.trim() === "") {
        alert(t("referral.validation.enterPhone"));
      } else if (!phone.match(phoneNumberRegex)) {
        alert(t("referral.validation.invalidPhoneCharacters"));
      } else if (phone.length !== 10) {
        alert(t("referral.validation.phoneLength"));
      } else {
        await handleSendNotificationSMS(phone, messagePreview);
        alert(`${t("referral.success.textSent")} ${phone}`);
      }
    }
    setPhone("");
  };

  const sendEmail = async (event) => {
    event.preventDefault();
    if (email && email !== "") {
      // Email validation regex pattern
      const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

      if (!emailPattern.test(email)) {
        alert(t("referral.validation.validEmail"));
        return;
      }
      const data = {
        customMessage: `<div style="text-align: center;"><strong>${messagePreview}</strong></div>`,
        email: email,
        subject: `${t("referral.emailSubject")} - ${userObject?.firstName} ${
          userObject?.lastName
        }`,
        firstName: t("referral.emailFirstName"),
      };

      await handleSendCustomEmail(data);
      alert(`${t("referral.success.emailSent")} ${email}`);
    }
    setEmail("");
  };

  return (
    <>
      <CssBaseline />
      <Card
        className="user-profile-card"
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Box
          component="form"
          sx={{
            display: "flex",
            flexDirection: "column",
            maxWidth: 400,
            margin: "auto",
          }}
        >
          <Typography variant="body1" component="div">
            <Typography variant="h4" component="span">
              {t("referral.shareTitle")}
            </Typography>
            <br />
            <br />

            <Typography variant="body1" component="span">
              {t("referral.shareDescription")}
            </Typography>
            <Paper
              elevation={3}
              sx={{ padding: "1em", marginTop: "1rem", textAlign: "center" }}
            >
              <Typography variant="body2" textAlign="center" fontWeight="bold">
                {t("referral.messagePreview")}
              </Typography>
              <Typography variant="body2" color="textSecondary">
                {messagePreview}
              </Typography>
            </Paper>

            <br />
          </Typography>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <InputLabel sx={{ marginTop: "1rem" }} id="dropdown-label">
              {t("referral.selectOption")}
            </InputLabel>
            <FormControl sx={{ width: "300px" }}>
              <Select
                labelId="dropdown-label"
                id="dropdown"
                value={selectedValue}
                onChange={handleChange}
              >
                <MenuItem value="optionText">
                  {t("referral.textOption")}
                </MenuItem>
                <MenuItem value="optionEmail">
                  {t("referral.emailOption")}
                </MenuItem>
              </Select>
            </FormControl>
            {selectedValue === "optionEmail" && (
              <>
                <TextField
                  onChange={(e) => setEmail(e.target.value)}
                  value={email}
                  label={t("referral.emailAddressLabel")}
                  style={{
                    marginTop: "1rem",
                    marginBottom: "1rem",
                    width: "300px",
                  }}
                />
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-around",
                    width: "300px",
                  }}
                >
                  <button
                    className="new-button-style primary"
                    onClick={sendEmail}
                    disabled={!email}
                  >
                    {t("referral.sendEmail")}
                  </button>
                </div>
              </>
            )}
            {selectedValue === "optionText" && (
              <>
                <TextField
                  onChange={handlePhoneNumberChange}
                  value={phone}
                  label={t("referral.phoneNumberLabel")}
                  style={{
                    marginTop: "1rem",
                    marginBottom: "1rem",
                    width: "300px",
                  }}
                />
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-around",
                    width: "300px",
                  }}
                >
                  <button
                    className="new-button-style primary"
                    onClick={sendText}
                    disabled={!phone}
                  >
                    {t("referral.sendText")}
                  </button>
                </div>
              </>
            )}
          </Box>
        </Box>
      </Card>
    </>
  );
}
