import React from "react";
import { useState, useEffect } from "react";
import { Typography, Box, Button } from "@mui/material";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import { jsPDF } from "jspdf";
import html2canvas from "html2canvas";
import CourseCertificate from "../../certificate/CourseCertificate";
import { getCurrentUserObject } from "../../helpers/source";
import { useTranslation } from "react-i18next";

function ReviewQuiz({ questions, userAnswers, course, currentUser }) {
  const containerRef = React.useRef();
  const { t } = useTranslation();

  //scroll to bottom of review page (used on downward arrow)
  const handleScrollDown = () => {
    if (containerRef.current) {
      containerRef.current.scrollTop += 100;
    }
  };

  const [selectedCourse, setSelectedCourse] = useState(course);
  const [userObject, setUserObject] = useState({});

  useEffect(() => {
    const fetchData = async () => {
      const user = await getCurrentUserObject(currentUser.uid);
      setUserObject(user);
    };

    fetchData();
  }, [currentUser.uid]);

  const downloadCertificate = async () => {
    const certificate = document.getElementById("certificate");

    const canvas = await html2canvas(certificate);
    const imgData = canvas.toDataURL("image/png");
    const pdf = new jsPDF("l", "mm", "a4");
    const width = pdf.internal.pageSize.getWidth();
    const height = pdf.internal.pageSize.getHeight();
    const imgWidth = (canvas.width * height) / canvas.height;
    const imgHeight = height - 20;
    const imgPosX = (width - imgWidth) / 2;
    const imgPosY = 10;

    pdf.addImage(imgData, "PNG", imgPosX, imgPosY, imgWidth, imgHeight);
    pdf.save(selectedCourse.courseName);
  };

  return (
    <div className="parent-container">
      <Box className="review-container" ref={containerRef}>
        <Typography variant="h4" align="center" gutterBottom>
          {t("reviewQuiz.reviewTitle")}
        </Typography>
        <div
          style={{ width: "100%", display: "flex", justifyContent: "center" }}
        >
          <Button
            onClick={downloadCertificate}
            className="new-button-style primary teal-hover"
          >
            {t("reviewQuiz.downloadCertificate")}
          </Button>
        </div>
        <ul style={{ padding: "0 20px 20px 13px", listStyleType: "none" }}>
          <div className="review-list">
            {questions.map((question, index) => {
              // check if answer correct
              const isCorrect = question.correctAnswer === userAnswers[index];
              return (
                <li key={index}>
                  <Typography variant="body1">
                    {index + 1}. {question.question}
                  </Typography>
                  <Typography variant="body2" display="inline">
                    {/* User's answer */}
                    {t("reviewQuiz.yourAnswer")}{" "}
                    <span
                      style={{
                        color: isCorrect ? "green" : "black",
                        fontWeight: isCorrect ? "bold" : "normal",
                      }}
                    >
                      {userAnswers[index]}
                    </span>
                    {isCorrect && (
                      <CheckCircleOutlineIcon
                        sx={{
                          color: "green",
                          verticalAlign: "middle",
                          fontSize: "1.1rem",
                          marginLeft: "5px",
                        }}
                      />
                    )}
                  </Typography>
                  {!isCorrect && (
                    <Typography variant="body2" sx={{ color: "red" }}>
                      {t("reviewQuiz.correctAnswer")} {question.correctAnswer}
                    </Typography>
                  )}
                </li>
              );
            })}
          </div>
        </ul>
        <div style={{ position: "absolute", left: "-9999px" }}>
          <CourseCertificate
            name={`${userObject?.firstName} ${userObject?.lastName}`}
            course={selectedCourse}
            review={true}
            licenseNumber={userObject?.licenseNumber}
          />
        </div>
      </Box>
      {/* pulsing button to scroll user down to bottom of list */}
      <ArrowDownwardIcon
        className="scroll-indicator"
        onClick={handleScrollDown}
      />
    </div>
  );
}

export default ReviewQuiz;
