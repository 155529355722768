const basalInsulinMasterySeriesFr = {
  multipleCreditCourse: true,
  numberOfSections: 3,
  courseId: "basal_insulin_mastery_t2d-fr",
  courseTitle:
    "Série de Maîtrise de l'Insuline Basale pour la Gestion du Diabète de Type 2",
  courseDescription:
    "Bienvenue à cette série interactive de trois modules couvrant le mécanisme, les avantages et les directives de l'insuline à action prolongée, les étapes pratiques pour l’initiation et l'ajustement des insulines basales, et une introduction à la nouvelle insulinothérapie hebdomadaire: Icodec.",
  courseAccreditation: {
    statementOfParticipation:
      "Après avoir complété chaque cours, vous recevrez une attestation de participation.",
  },
  collaborationStatement:
    "Ce cours interactif a été développé en collaboration avec LMC Pharmacy. LMC est le plus grand fournisseur de soins spécialisés en diabète et endocrinologie au Canada. Les pharmaciens de notre équipe spécialisés dans l'éducation au diabète ont créé cette série pour fournir des connaissances pratiques et pertinentes pour vous aider dans votre pratique communautaire.",
  instructors: [
    {
      name: "Kayla Castonguay, RPh, PharmD, CDE, CGP",
      imageUrl: `https://firebasestorage.googleapis.com/v0/b/education-hub-848d2.appspot.com/o/educationHub%2Fheadshots%2FIMG_9399.jpeg?alt=media&token=4109b1a8-a24e-4276-9f88-49c3d4fef6d5`,
      bio: `Kayla est une pharmacienne clinicienne et possède les certifications de spécialiste en pharmacothérapie et d'éducatrice en diabète. Son dévouement et sa passion de la profession la poussent à la fourniture de soins exceptionnels aux patients. En outre, elle prend plaisir à encadrer les étudiants en pharmacie et à leur transmettre avec enthousiasme son expertise en pharmacothérapie.`,
      linkedinUrl: "https://www.linkedin.com/in/kayla-castonguay-383563a4/",
    },
  ],

  credits: "0.5 UFC",
  modules: [
    {
      title:
        "Module 1: Explorer le paysage de l'insulinothérapie basale dans la prise en charge du diabète de type 2: un aperçu complet",
      //subtitle:  "Mécanisme d'action, avantages et lignes directrices dans le diabète de type 2",
      watchTime: "18 minutes",
      ceu: "0.3 UFC",
      accreditationNumber: "15130",
    },
    {
      title:
        "Module 2: Mise en route - Initiation et titrage des insulines basales à action prolongée",
      //subtitle: "Initiation et titrage d’insulines basales à action prolongée",
      watchTime: "30 minutes",
      ceu: "0.50 UFC",
      accreditationNumber: "15160",
    },
    {
      title: "Module 3: La nouvelle thérapie en vente !",
      subtitle:
        "Cours accéléré sur l'insuline injectée une fois par semaine Icodec",
      ceu: "0.5 UFC",
      accreditationNumber: "240073",
    },
  ],
  sections: [
    {
      credits: "0.3 UFC",
      accreditationNumber: "15130",
      id: "serie_maitrise_insuline_basale_dtd2_module_1",
      title:
        "Module 1: Explorer le paysage de l'insulinothérapie basale dans la prise en charge du diabète de type 2: un aperçu complet",
      //subtitle: "Mécanisme d'action, avantages et lignes directrices dans le diabète de type 2",
      progressBarTitle: "Module 1: Revue de l’insuline à action prolongée",
      watchTime: "18 minutes",
      surveyLink:
        "https://docs.google.com/forms/d/e/1FAIpQLScg0N1XXQfPyn1CYBeuG1ee8lpUxKRa01p2OSih-JcqzGCSQA/viewform?usp=sharing",
      certificateId: "serie_maitrise_insuline_basale_dtd2_module_1",
      instructor: "Kayla Castonguay, RPh, PharmD, CDE, CGP",
      learningObjectives: [
        "Décrire le fonctionnement de l'insuline basale et son rôle dans la gestion du diabète de type 2.",
        "Reconnaître les différentes insulines basales disponibles sur le marché canadien et être capable de distinguer les différences entre chaque insuline basale.",
        "Énumérer les avantages et les risques potentiels de l’utilisation des insulines basales.",
      ],
      steps: [
        {
          type: "video",
          src: "https://vimeo.com/955727138/76ff75e82d?share=copy",
          videoId: "mod_1-vid_1",
          title: "Revue de l'insuline à action prolongée",
        },
        {
          type: "quiz",
          quizId: "mod_1-quiz_1",
          questions: [
            {
              question:
                "James Jimmerson est un homme de 58 ans qui vit avec le diabète de type 2 depuis plus de 10 ans. Son traitement actuel contre le diabète comprend la metformine, l'empagliflozine et le sémaglutide oral. Aujourd'hui, il se présente à votre équipe de soins du diabète pour plus d'informations sur une nouvelle insuline basale qui lui a été prescrite - l'insuline dégludec U-100 (Tresiba). Ne sachant pas pourquoi son médecin lui a prescrit un autre médicament contre le diabète, il vous demande votre avis sur les raisons possibles. En regardant son dossier médical, vous remarquez que son taux d'HbA1C est 8,6 %. Que pourriez-vous lui dire?",
              options: [
                "L'HbA1c de James est à la cible et son médecin a pensé que ce serait une bonne idée d'ajouter un autre médicament pour s'assurer qu'il reste à l'objectif.",
                "L'HbA1C de James n'est PAS à la cible malgré l'utilisation d'autres médicaments antihyperglycémiants et son médecin souhaite réduire son risque de développer des complications associées au diabète de type 2 mal géré grâce à l'utilisation d'insuline basale.",
                "L'HbA1c de James est proche de son objectif et vous ne savez pas pourquoi son médecin a prescrit ce médicament.",
                "L'HbA1c de James n'est PAS à la cible malgré l'utilisation d'autres médicaments antihyperglycémiants et son médecin souhaite compliquer son traitement médicamenteux avec l'utilisation d'insuline basale.",
              ],
              answer:
                "L'HbA1C de James n'est PAS à la cible malgré l'utilisation d'autres médicaments antihyperglycémiants et son médecin souhaite réduire son risque de développer des complications associées au diabète de type 2 mal géré grâce à l'utilisation d'insuline basale.",
            },
          ],
        },
        {
          type: "quiz",
          quizId: "mod_1-quiz_2",
          questions: [
            {
              question:
                "James rapporte qu’il n’a entendu parler que de l’insuline auparavant, mais que personne ne lui avait jamais expliqué de quoi il s’agissait. Il vous demande de lui en dire plus sur l'insuline, en particulier sur les «insulines basales», ce qu'il a entendu dire par son endocrinologue. Lequel des points de discussion suivants ci-dessous est FAUX?",
              options: [
                "L'insuline est une hormone que votre pancréas sécrète et qui aide à réguler la glycémie.",
                "L'insuline est sécrétée par le pancréas de manière pulsatile, avec des niveaux plus élevés libérés pendant et après les repas, et des niveaux de fond constants à d'autres moments de la journée.",
                "Le rôle de l'insuline basale est d'imiter la réponse de l'insuline endogène du corps aux pics de sucre élevés après les repas.",
                "Le rôle de l'insuline basale dans la gestion du diabète de type 2 est de fournir un niveau constant d'insuline tout au long de la journée, imitant la sécrétion d'insuline pulsatile de fond observée chez les personnes non diabétiques de type 2.",
              ],
              answer:
                "Le rôle de l'insuline basale est d'imiter la réponse de l'insuline endogène du corps aux pics de sucre élevés après les repas.",
            },
          ],
        },
        {
          type: "quiz",
          quizId: "mod_1-quiz_3",
          questions: [
            {
              question:
                "James est extrêmement impressionné par la quantité d'informations que vous lui avez fournies. Avant de s'asseoir et d'attendre que ses médicaments soient prescrits, il pose encore une question. Il demande quels sont les risques associés à l’utilisation de l’insuline ? Tu lui dis…",
              options: [
                "Hypoglycémie",
                "Gain de poids",
                "Problèmes liés au site d’injection",
                "Tout ce qui précède",
              ],
              answer: "Tout ce qui précède",
            },
          ],
        },
        {
          type: "section-done-multi-ceu",
          sectionIndex: 1,
          sectionId: "serie_maitrise_insuline_basale_dtd2_module_1",
        },
      ],
      status: "available",
    },
    {
      credits: "1.10 UFC",
      id: "serie_maitrise_insuline_basale_dtd2_module_2",
      title:
        "Module 2: Mise en route - Initiation et titrage des insulines basales à action prolongée",
      // subtitle: "Initiation et titrage des insulines basales à action prolongée",
      progressBarTitle: "Module 2: Mise en route",
      watchTime: "30 minutes",
      accreditationNumber: "15160",
      surveyLink:
        "https://docs.google.com/forms/d/e/1FAIpQLSdeEwOKDQsIF1wDrNowVmt3Ez8R2g3zlExX2-uOMTrT9ROhNA/viewform?usp=sharing",
      certificateId: "serie_maitrise_insuline_basale_dtd2_module_2",
      instructor: "Kayla Castonguay, RPh, PharmD, CDE, CGP",
      learningObjectives: [
        "Expliquer comment initier la discussion sur l'utilisation et l'administration de l'insuline.",
        "Titrer et optimiser la dose d'insuline basale.",
        "Comprendre les options pour gérer les effets secondaires de l'insuline.",
        "Évaluer les mesures de glucose pour gérer le régime d'insuline basale, d'autres médicaments et le mode de vie.",
      ],
      steps: [
        {
          type: "video",
          src: "https://vimeo.com/982012207/889d760be4",
          videoId: "mod_2-vid_1",
          title:
            "L’initiation et le titrage d’insulines basales à action prolongée",
        },
        {
          type: "quiz",
          quizId: "mod_2-quiz_1",
          title: "Point de contrôle d’apprentissage n°1",
          questions: [
            {
              question:
                "Lequel des éléments suivants n'est pas considéré comme une technique d’injection d’insuline appropriée?",
              options: [
                "A) Rotation de la zone/des sites d’injection",
                "B) Amorcer uniquement avec chaque nouveau stylo",
                "C) Utiliser une nouvelle aiguille à chaque injection",
                "D) Maintenir le bouton jusqu'à ce que la fenêtre de dose indique « 0 » et relâcher immédiatement",
                "E) B seulement",
                "F) B et D",
              ],
              answer: "F) B et D",
              explanation:
                "Les stylos à insuline doivent être amorcés avant chaque utilisation ET avec chaque nouveau stylo, pour garantir que l’insuline puisse être expulsée par l’aiguille. Une fois que la fenêtre de dose indique « 0 », l'aiguille doit être maintenue insérée pendant au moins 6 secondes supplémentaires pour permettre au médicament de se propager dans la zone environnante et réduire les fuites du site.",
            },
          ],
        },
        {
          type: "free-answer-mcq",
          quizId: "mod_2-quiz_2",
          title: "Point de contrôle d’apprentissage n°2",
          questions: [
            {
              question:
                "À votre écoute ! Selon vous, qu’est-ce qu’il est plus important de gérer en premier: l’hypoglycémie OU l’hyperglycémie ? Pourquoi?",
              options: ["Hypoglycémie", "Hyperglycémie"],
              // answer: "Hypoglycémie",
              explanation:
                "La gestion de l’HYPERglycémie est notre objectif ultime en fin de compte, mais il est également essentiel d’éviter et de minimiser les incidences de l’hypoglycémie. Cela est dû aux conséquences potentiellement graves d’une hypoglycémie non traitée, comme le coma, les convulsions, et même la mort.",
            },
          ],
        },
        {
          type: "quiz",
          quizId: "mod_2-quiz_3",
          title: "Étude de cas, partie A",
          questions: [
            {
              question:
                "FK est un homme de 63 ans qui a récemment reçu un diagnostic de diabète. Son HbA1C le plus récent était de 9,1%. Ses médicaments actuels contre le diabète comprennent la metformine 500 mg deux fois par jour, le gliclazide 30 mg deux fois par jour et la canagliflozine 100 mg une fois par jour. On lui avait précédemment prescrit un AR du GLP-1 car ils présentent un faible risque d'hypoglycémie et n'ont pas d'effet de prise de poids. Malheureusement, il ne pouvait pas se le permettre en raison du manque de couverture de son régime d'assurance médicaments. En raison de son taux élevé d'HbA1C, son endocrinologue recommande de commencer une insulinothérapie pour améliorer le contrôle glycémique. Toutefois, FK travaille en alternance jour et nuit et craint que son horaire de travail ne lui fasse oublier de prendre son insuline. Laquelle des options suivantes seraient-elles les plus appropriées à initier à ce stade?",
              options: [
                "Insuline glargine (Lantus) 10 unités une fois par jour au coucher.",
                "Insuline détémir (Levemir)0,1 à 0,2 unités/kg une fois par jour au coucher.",
                "Insuline dégludec (Tresiba) 10 unités une fois par jour le matin.",
                "Insuline glargine 300U (Toujeo) 0,1-0,2 unités/kg une fois par jour au coucher.",
              ],
              answer:
                "Insuline dégludec (Tresiba) 10 unités une fois par jour le matin.",
              explanation:
                "L'insuline dégludec et l'insuline glargine 300 sont toutes deux recommandées car elles présentent un risque d'hypoglycémie plus faible que les autres insulines basales. Cependant, FK travaille en alternant le travail de jour et de nuit et craint également d'oublier de prendre son insuline. L'insuline dégludec a un profil d'action encore plus long que l'insuline glargine 300U, ce qui en fait le choix le plus approprié car elle donnera à FK plus de flexibilité en termes d'oubli de prendre sa dose d'insuline.",
            },
          ],
        },
        {
          type: "quiz",
          quizId: "mod_2-quiz_4",
          title: "Étude de cas, partie B",
          questions: [
            {
              question:
                "Deux semaines plus tard, vous appelez FK pour faire un suivi et vous renseigner sur son expérience avec l'insulinothérapie. FK ne signale aucune difficulté avec la technique d'injection mais exprime ses inquiétudes concernant son récent taux de sucre dans le sang à jeun (FBG) élevé, mesurant environ 10 mmol/L. Il vous dit qu'il est resté sous 10U d'insuline dégludec depuis le début et qu'il a oublié ce que son endocrinologue lui a dit concernant les titrages. Quel est le conseil le plus approprié à lui donner en ce moment?",
              options: [
                "Augmentez la dose d'insuline dégludec (Tresiba) de 1 unité une fois par jour au coucher jusqu'à ce que la glycémie à jeun soit <7mmol/L.",
                "Augmentez la dose d'insuline dégludec (Tresiba) de 2 unités une fois par jour au coucher jusqu'à ce que la glycémie à jeun soit <7mmol/L.",
                "Augmentez la dose d'insuline dégludec (Tresiba) de 2 unités une fois tous les 3-4 jours jusqu'à ce que la glycémie à jeun soit <7mmol/L.",
                "Ne pas titrer en raison du risque d'hypoglycémie. Suggérer des modifications alimentaires pour optimiser le contrôle de la glycémie, en mettant l’accent sur l’apport en glucides et le moment des repas.",
                "Ajoutez une insuline à courte durée d'action (bolus) au régime de FK pour traiter les niveaux élevés de sucre dans le sang à jeun.",
              ],
              answer:
                "Augmentez la dose d'insuline dégludec (Tresiba) de 2 unités une fois tous les 3-4 jours jusqu'à ce que la glycémie à jeun soit <7mmol/L.",
              explanation:
                "Les stylos à insuline doivent être amorcés avant chaque utilisation ET avec chaque nouveau stylo, pour garantir que l’insuline puisse être expulsée par l’aiguille. Une fois que la fenêtre de dose indique « 0 », l'aiguille doit être maintenue insérée pendant au moins 6 secondes supplémentaires pour permettre au médicament de se propager dans la zone environnante et réduire les fuites du site.",
              // explanationPicture: ""
            },
          ],
        },
        {
          type: "quiz",
          quizId: "mod_2-quiz_5",
          title: "Étude de cas, partie C",
          questions: [
            {
              question:
                "Deux mois plus tard, vous faites un suivi auprès de FK concernant son insulinothérapie. Il rapporte avoir souffert d'hypoglycémie pendant la nuit et occasionnellement tout au long de la journée, survenant 2 à 3 fois par semaine. Interrogé sur les changements récents, FK mentionne qu'il a rejoint un club de marche et qu'il marche dehors avec ses amis pendant une heure par jour. Il a également adhéré à un nouveau plan alimentaire recommandé par sa diététicienne, qui comprend des glucides à faible indice glycémique. Il admet fièrement qu'il a également omis son verre de vin habituel avec son dîner. Il déclare que ses glycémies à jeun sont d'environ 4-5mmol/L. Dose actuelle d'insuline dégludec : 22U une fois par jour (il a arrêté de titrer). Quels sont les facteurs possibles contribuant à ses épisodes hypoglycémiques?",
              options: [
                "A) Augmentation de l'activité physique en marchant 1 heure par jour",
                "B) Consommation d'alcool",
                "C) Modifications alimentaires entraînant une réduction de l'apport en glucides",
                "D) A et C",
              ],
              answer: "D) A et C",
              explanation:
                "L'activité physique, en particulier l'activité aérobique, peut augmenter le risque d'hypoglycémie. Les changements alimentaires qui réduisent l’apport global en glucides peuvent également le faire. Dans cette affaire, la consommation d’alcool n’était pas un facteur étant donné que FK avait arrêté de consommer de l’alcool au dîner. Toutefois, si FK consommait toujours de l’alcool de manière régulière, cela pourrait également être un facteur contribuant à l’hypoglycémie. Lorsqu’un individu consomme de l’alcool, son foie interrompt la libération de glucose dans tout le corps pour maintenir la glycémie et se concentre plutôt sur le métabolisme de l’alcool.",
            },
          ],
        },
        {
          type: "quiz",
          quizId: "mod_2-quiz_6",
          title: "Étude de cas, partie D",
          questions: [
            {
              question:
                "Que conseilleriez-vous à FK en termes de gestion et de prévention des futurs épisodes hypoglycémiques?",
              options: [
                "A) Réduire l'insuline basale de 10 %",
                "B) Réduisez l'exercice à 15 minutes par jour et arrêtez de suivre le plan alimentaire du diététicienne.",
                "C) Avoir accès à tout moment à du sucre à action rapide",
                "D) Apportez une collation lors de sa promenade",
                "E) A, C et D",
              ],
              answer: "E) A, C et D",
            },
          ],
        },
        {
          type: "quiz",
          quizId: "mod_2-quiz_7",
          title: "Étude de cas, partie E",
          questions: [
            {
              question:
                "En plus de sa routine quotidienne, FK dépose et récupère également ses petits-enfants à l'école tous les jours. Avant de partir pour ces voyages, il vérifie sa glycémie, et si son appareil de surveillance continue de la glycémie indique qu'il souffre d'hypoglycémie, il la traite en mangeant des Kit Kats avant de monter dans sa voiture. En raison de contraintes de temps, il n’a souvent pas la possibilité de retester sa glycémie avant de commencer à conduire. Que devriez-vous conseiller à FK concernant la gestion de son hypoglycémie?",
              options: [
                "A) Il n'y a aucun souci. FK traite la gestion de l'hypoglycémie de manière appropriée",
                "B) Conseillez à FK de ne pas traiter l’hypoglycémie avec du chocolat",
                "C) FK doit refaire un test après 15 minutes pour s'assurer que son taux de glucose est supérieur à 4 mmol/L avant de commencer à conduire après avoir traité une hypoglycémie.",
                "D) Informez FK qu'après un épisode d'hypoglycémie, cela peut prendre jusqu'à 40 minutes pour que son cerveau récupère complètement et qu'il puisse à nouveau conduire en toute sécurité.",
                "E) D seulement",
                "F) B et D",
                "G) B, C et D",
              ],
              answer: "F) B et D",
              explanation:
                "Les épisodes hypoglycémiques doivent être traités avec 15 g de sucres à action rapide. Le chocolat n’est pas une bonne option car il contient des graisses qui ralentissent l’absorption des sucres qu’il contient. Après avoir traité une hypoglycémie, une glycémie supérieure à 5mmol/L est nécessaire, avant de conduire",
            },
          ],
        },
        {
          type: "quiz",
          quizId: "mod_2-quiz_8",
          title: "Étude de cas, partie F",
          questions: [
            {
              question:
                "Six mois se sont écoulés et FK est resté stable sous 18U d'insuline dégludec (Tresiba). Il maintient systématiquement une glycémie à jeun entre 5 et 7 mmol/L. Bien que les épisodes hypoglycémiques se soient résolus, FK signale de récents pics postprandiaux tout au long de la journée, ce qui a un impact sur son rapport de profil glycémique ambulatoire, qui reflète désormais une plage de temps d'environ 60 %. Depuis le décès de sa femme il y a deux mois, FK est en deuil et ne se sent pas motivé pour cuisiner ou faire de l'activité physique. FK reconnaît ne pas avoir respecté son plan de repas prescrit, revenant à ses vieilles habitudes consistant à manger au restaurant, à acheter des dîners télévisés et à grignoter fréquemment. Au cours des deux derniers mois seulement, il a remarqué une prise de poids de 10 livres. Il cherche des conseils sur la prochaine étape appropriée, car il aimerait rétablir sa glycémie et perdre du poids. Il note également qu'il a obtenu une promotion au travail et qu'il bénéficie désormais d'une assurance-médicaments étendue pour pratiquement tous les médicaments. Quelle est la meilleure option que vous puissiez recommander en ce moment?",
              options: [
                "Informez FK que la seule option est de modifier son mode de vie",
                "Recommander l'ajout d'un bolus d'insuline à son régime",
                "Recommander l'ajout d'un AR du GLP-1 à son régime",
                "Recommander d'augmenter l'insuline basale",
              ],
              answer: "Recommander l'ajout d'un AR du GLP-1 à son régime",
              explanation:
                "Les AR du GLP-1 ont des effets pléiotropes. Ils sont bénéfiques dans la gestion de la glycémie et ont également un effet amaigrissant. De plus, de nombreux AR du GLP-1 offrent des avantages de protection cardiorénale dont un individu peut bénéficier.",
            },
          ],
        },
        {
          type: "section-done-multi-ceu",
          sectionIndex: 2,
          sectionId: "serie_maitrise_insuline_basale_dtd2_module_2",
        },
      ],
      surveyLink:
        "https://docs.google.com/forms/d/e/1FAIpQLSdkS1TG4obg2vAid0-mI_biSUKhtFfv1LYHCsXrLeUw7eYljQ/viewform?usp=sharing",
      status: "available",
    },

    {
      certificateId: "serie_maitrise_insuline_basale_dtd2_module_3",
      id: "serie_maitrise_insuline_basale_dtd2_module_3",
      progressBarTitle: "Module 3: La nouvelle thérapie en vente !",
      title: "Module 3: La nouvelle thérapie en vente !",
      subtitle:
        "Cours accéléré sur l'insuline injectée une fois par semaine Icodec",
      watchTime: "27 minutes",
      ceu: "0.5 UFC",
      credits: "0.5 UFC",
      accreditationNumber: "240073",
      instructor: "Kayla Castonguay, RPh, PharmD, CDE, CGP",
      learningObjectives: [
        "Décrire le mécanisme et la pharmacocinétique de l'insuline icodec une fois par semaine.",
        "Déterminer à qui convient l'insuline une fois par semaine.",
        "Énumérer les avantages et les risques potentiels de l’utilisation d’insuline une fois par semaine.",
        "Expliquer comment initier une personne à l'insuline icodec une fois par semaine et comment titrer sa dose en fonction des objectifs glycémiques.",
        "Discuter de l'efficacité et de la sécurité de l'insuline icodec chez les personnes naïves à l'insuline et chez les personnes qui prenaient auparavant d'autres analogues de l'insuline basale.",
      ],
      steps: [
        {
          type: "video",
          src: "https://vimeo.com/1008435607/ebd54602df",
          videoId: "mod_3-vid_1",
          title: "La nouvelle thérapie en vente !",
        },
        {
          type: "long-answer",
          questionId: "long-answer-1",
          question:
            "Session de réflexion ! Selon vous, qui pourrait bénéficier d’une injection d’insuline une fois par semaine ? Et pourquoi ?",
        },
        {
          type: "video",
          src: "https://vimeo.com/1008435664/23712cd230",
          videoId: "mod_3-vid_2",
          title: "Suite de la nouvelle thérapie",
        },
        /* quiz 1 */
        {
          type: "quiz",
          quizId: "mod_3-quiz_1",
          title: "Insuline Icodec – Scénario 1",
          questions: [
            {
              question: `George est un homme de 50 ans qui vit avec le diabète de type 2. Il prend actuellement plusieurs médicaments pour gérer son diabète, notamment : Tirzepatide 7,5 mg par semaine, Empagliflozine 25 mg par jour et Metformine 1000 mg deux fois par jour. Le résultat récent de HbA1c de George est revenu plus élevé à 8,7 %. Étant donné la détérioration de sa gestion glycémique, son endocrinologue lui a recommandé d'augmenter encore la dose de Tirzepatide. Malheureusement, George rapporte que 7,5 mg par semaine est la dose maximale qu'il peut tolérer. Son endocrinologue lui recommande plutôt de commencer la nouvelle insuline icodec une fois par semaine et George est d'accord. Quelle est, selon vous, la dose de départ appropriée pour George ? Et pourquoi ?`,
              options: [
                "10 U une fois par jour",
                "70 U une fois par semaine",
                "70 U une fois par jour",
                "100 U une fois par semaine",
              ],
              answer: "70 U une fois par semaine",
              explanation:
                "Étant donné qu'il est naïf à l'insuline, il devrait utiliser 70 U une fois par semaine. La dose de départ typique pour une insuline basale quotidienne est de 10 unités par jour, donc l'équivalent pour une semaine est 70 unités.",
              explanationPicture: "", //fill this in
            },
          ],
        },
        /* quiz 2 */
        {
          type: "quiz",
          quizId: "mod_3-quiz_2",
          title: "Insuline Icodec – Scénario 2",
          questions: [
            {
              question: `George est un homme de 50 ans qui vit avec le diabète de type 2. Il prend actuellement plusieurs médicaments pour gérer son diabète, notamment : Tirzepatide 7,5 mg par semaine, Empagliflozine 25 mg par jour, Metformine 1000 mg deux fois par jour et 25 U d'insuline glargine U100 par jour. Le résultat récent de l'HbA1c de George est revenu plus élevé, à 8,7 %. Étant donné la détérioration de sa gestion glycémique, son endocrinologue lui a recommandé d'augmenter encore la dose de Tirzepatide. Malheureusement, George rapporte que 7,5 mg par semaine est la dose maximale qu'il peut tolérer. Son endocrinologue lui recommande plutôt de commencer la nouvelle insuline icodec une fois par semaine et George est d'accord. En supposant qu'il n'y ait pas de chargement/ajout à la première dose,  quelle est, selon vous, la dose de départ appropriée pour George? Et pourquoi ?`,
              options: [
                "100 U une fois par semaine",
                "170 U une fois par semaine",
                "180 U une fois par semaine",
                "200 U une fois par semaine",
              ],
              answer: "180 U une fois par semaine",
              explanation: `Étant donné que George utilisait auparavant 25 U d'insuline glargine par jour, si nous prenons ce chiffre et le multiplions par 7, nous obtenons 175 U une fois par semaine d'insuline icodec. Si nous arrondissons à 10 unités près, nous obtenons 180 unités une fois par semaine.`,
            },
          ],
        },
        /* quiz 3 */
        {
          type: "quiz",
          quizId: "mod_3-quiz_3",
          title: "Insuline Icodec – Scénario 3",
          questions: [
            {
              question: `George est un homme de 50 ans qui vit avec le diabète de type 2. Il prend actuellement plusieurs médicaments pour gérer son diabète, notamment : Tirzepatide 7,5 mg par semaine, Empagliflozine 25 mg par jour, Metformine 1000 mg deux fois par jour et 25 U d'insuline glargine U100 par jour. Le résultat récent de l'HbA1c de George est revenu plus élevé, à 8,7 %. Étant donné la détérioration de sa gestion glycémique, son endocrinologue lui a recommandé d'augmenter encore la dose de Tirzepatide. Malheureusement, George rapporte que 7,5 mg par semaine est la dose maximale qu'il peut tolérer. Son endocrinologue lui recommande plutôt de commencer la nouvelle insuline icodec une fois par semaine et George est d'accord. En supposant qu’une dose supplémentaire de +50 % était appropriée pour la première dose, qu'elle dose d'insuline icodec George devrait-il prendre pour la semaine 1 et la semaine 2 ?`,
              options: [
                "Semaine 1 : 260 U une fois par semaine, Semaine 2 : 180 U une fois par semaine",
                "Semaine 2 : 270 U une fois par semaine, Semaine 2 : 175 U une fois par semaine",
                "Semaine 3 : 260 U une fois par semaine, Semaine 2 : 150 U une fois par semaine",
                "Semaine 4 : 270 U une fois par semaine, Semaine 2 : 150 U une fois par semaine",
              ],
              answer:
                "Semaine 1 : 260 U une fois par semaine, Semaine 2 : 180 U une fois par semaine",
              explanation: `La dose quotidienne d'insuline basale de George était de 25 U par jour. Si nous prenons ce chiffre et le multiplions par 10,5 pour obtenir la première dose hebdomadaire d'insuline icodec, nous obtenons 262,5. En arrondissant à 10 unités près, nous obtenons 260 U pour la semaine 1. Pour la semaine 2, nous prenons la dose quotidienne d'insuline basale de 25 U et la multiplions par 7, ce qui nous donne 175 U par semaine. En arrondissant au 10e près, nous obtenons 180 U une fois par semaine.`,
              explanationPicture:
                "https://firebasestorage.googleapis.com/v0/b/education-hub-848d2.appspot.com/o/educationHub%2FCCCEP%2Fscenario%203.png?alt=media&token=9dc069c3-d393-4473-b6ae-f45dd7bc220f",
            },
          ],
        },
        /* quiz 4 */
        {
          type: "quiz",
          quizId: "mod_3-quiz_4",
          title: "Insuline Icodec – Scénario 4",
          questions: [
            {
              question: `George est un homme de 50 ans vivant avec le diabète de type 2. Il a récemment commencé à prendre de l'insuline icodec il y a 3 semaines pour une meilleure gestion de la glycémie par son endocrinologue.Sa dernière dose hebdomadaire était de 170 U. Aujourd'hui (3 juillet), il est censé de prendre sa 3ième dose d'insuline icodec. En lisant les feuilles d'instructions qui lui ont été fournies, il est indiqué qu'il doit ajuster sa dose d'insuline icodec en fonction de sa glycémie à jeun moyenne. Ses lectures de glycémie à jeun au cours de la semaine dernière sont les suivantes :
							1er juillet = 7,9 mmol/L, 2 juillet = 8,2 mmol/L, 3 juillet = 7,5 mmol/L. 
							Quelle dose d'insuline icodec George devrait-il prendre aujourd'hui ?`,
              options: [
                "a) 150U une fois par semaine",
                "b) 170U une fois par semaine",
                "c) 180U une fois par semaine",
                "d) 190U une fois par semaine",
              ],
              answer: "d) 190U une fois par semaine",
              explanation: ` La glycémie à jeun moyenne de George au cours de la semaine dernière était de 7,9, ce qui dépasse son objectif de 7,2 mmol/L. Par conséquent, en regardant les instructions de titration tirées des études ONWARDS, nous augmenterions la dose de 20 unités. Étant donné que sa dernière dose hebdomadaire était de 170 unités, sa dose cette semaine sera de 170 + 20, donc 190 unités, réponse d.`,
              explanationPicture:
                "https://firebasestorage.googleapis.com/v0/b/education-hub-848d2.appspot.com/o/educationHub%2FCCCEP%2Fscenario%204.png?alt=media&token=11e1ec98-784d-4432-ab17-d51bd403e4ac",
            },
          ],
        },
        /* quiz 5 */
        {
          type: "quiz",
          quizId: "mod_3-quiz_5",
          title: "Insuline Icodec – Scénario 5",
          questions: [
            {
              question: `Harleen prend normalement sa dose d'insuline icodec une fois par semaine, tous les lundis. La semaine dernière, elle a perdu son dernier stylo à insuline icodec en vacances et ne pourra pas récupérer de nouvelles fournitures de médicaments avant jeudi. Si Harleen récupère sa réserve de médicaments le Jeudi et prend la dose jeudi, elle pourra reprendre sa dose hebdomadaire normale prévue lundi.`,
              options: ["a) Vrai", "b) Faux"],
              answer: "a) Vrai",
              explanation: `Comme il y a au moins 4 jours entre jeudi et lundi, Harleen peut rattraper sa dose oubliée jeudi et reprendre son programme hebdomadaire normal d'insuline icodec lundi.

							Si une personne est en mesure de compenser la dose oubliée sans changer le jour de prise, vous devez lui conseiller de surveiller sa glycémie plus étroitement pendant la semaine suivante, en particulier après sa prochaine dose.
							`,
              explanationPicture:
                "https://firebasestorage.googleapis.com/v0/b/education-hub-848d2.appspot.com/o/educationHub%2FCCCEP%2Fscenario%205.png?alt=media&token=d3e49a76-a044-4cdf-836d-24da46667bb4",
            },
          ],
        },
        /* quiz 6 */
        {
          type: "quiz",
          quizId: "mod_3-quiz_6",
          title: "Insuline Icodec – Scénario 6",
          questions: [
            {
              question: `Sonia prend normalement sa dose d'insuline icodec une fois par semaine, tous les lundis. La semaine dernière, elle a perdu son dernier stylo à insuline icodec en vacances et ne pourra pas récupérer de nouvelles fournitures de médicaments avant vendredi. Si Sonia récupère sa réserve de médicaments le Vendredi et prend la dose vendredi, elle pourra reprendre sa dose hebdomadaire normale prévue lundi`,
              options: ["a) Vrai", "b) Faux"],
              answer: "b) Faux",
              explanation: `C'est faux car il n'y a pas 4 jours entre les doses consécutives, juste 3, donc elle ne devrait pas reprendre la dose du lundi et maintenant utiliser le vendredi comme heuhnouvelle date de dosage hebdomadaire.`,
              explanationPicture:
                "https://firebasestorage.googleapis.com/v0/b/education-hub-848d2.appspot.com/o/educationHub%2FCCCEP%2Fscenario%206.png?alt=media&token=25bd94d7-ed6f-4828-8d38-b5b7fb4be105",
            },
          ],
        },

        {
          type: "section-done-multi-ceu",
          sectionIndex: 3,
          sectionId: "serie_maitrise_insuline_basale_dtd2_module_3",
        },
      ],
      surveyLink:
        "https://docs.google.com/forms/d/e/1FAIpQLSeFRK6ER-x6a-XUEig4OgQD8sEfk8NEdUYKJ-JG37OEEH3eBg/viewform?usp=sharing",
      status: "available",
    },
  ],
  sponsoredBy:
    "Ce cours a été soutenu par une subvention éducative non restreinte de Novo Nordisk Canada.",
};

export default basalInsulinMasterySeriesFr;
