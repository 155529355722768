import React, { createContext, useContext, useState, useEffect } from "react";
import { useAuth } from "./AuthContext";
import { getCurrentUserObject } from "../components/helpers/source";

// This file creates UserContext to share user data across the app
// it fetches user details when the user logs in and makes it accessible everywhere, since userData needed in many files
// in future: REFACTOR CASES USING getCurrentUserObject to USE useUSER INSTEAD

const UserContext = createContext();

export const UserProvider = ({ children }) => {
  const { currentUser } = useAuth();
  const [userObject, setUserObject] = useState(null);

  useEffect(() => {
    if (currentUser?.uid) {
      getCurrentUserObject(currentUser.uid)
        .then(setUserObject)
        .catch((error) => console.error("Failed to fetch user data:", error));
    }
  }, [currentUser]);

  return (
    <UserContext.Provider value={{ userObject, setUserObject }}>
      {children}
    </UserContext.Provider>
  );
};

export const useUser = () => useContext(UserContext);
