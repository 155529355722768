import React from "react";
import { Chip } from "@mui/material";
import CheckIcon from "@mui/icons-material/Check";
import { getAllResources } from "./resourceFunctions";
import { getResourceTypeColour } from "./resourceFunctions";

export default function FilterResourcesButtons(props) {
  const {
    filtersTurnedOn,
    setFiltersTurnedOn,
    searchQuery,
    linksWithSearchItem,
    setLinksWithSearchItem,
    userProfession,
    setLinksToShow,
  } = props;

  const filterBySearchItem = (filteredList) => {
    let postiveSearchList = [];

    filteredList.map((link) => {
      if (
        link?.title?.toLowerCase().includes(searchQuery.toLowerCase()) ||
        link?.fileName?.toLowerCase().includes(searchQuery.toLowerCase()) ||
        link?.otherKeywords?.includes(searchQuery.toLowerCase())
      ) {
        postiveSearchList.push(link);
      }
    });

    return postiveSearchList;
  };
  const removeFromSearchList = (filterItem) => {
    const allResourceArrays = getAllResources(userProfession);
    const currentFilters = filtersTurnedOn.filter(
      (turnedOnFilter) => turnedOnFilter !== filterItem
    );

    if (searchQuery) {
      const filteredListBySearchWord = filterBySearchItem(allResourceArrays);
      setLinksWithSearchItem(
        filteredListBySearchWord.filter((linkGroup) =>
          currentFilters?.includes(linkGroup?.typeOfResource)
        )
      );
      if (currentFilters.length > 0) {
        setLinksWithSearchItem(
          filteredListBySearchWord.filter((linkGroup) =>
            currentFilters?.includes(linkGroup?.typeOfResource)
          )
        );
      } else {
        setLinksWithSearchItem(filteredListBySearchWord);
      }
    } else {
      setLinksWithSearchItem(
        linksWithSearchItem.filter(
          (linkGroup) => linkGroup?.typeOfResource !== filterItem
        )
      );
    }
  };

  const getAllFilteredResources = (filterItem) => {
    const allResourceArrays = getAllResources(userProfession);
    // filters by the filter item
    const filteredList = allResourceArrays.filter((linkItem) =>
      [...filtersTurnedOn, filterItem].includes(linkItem?.typeOfResource)
    );
    // if there is also a seach item, it goes through that too
    if (searchQuery) {
      const filteredListBySearchItem = filterBySearchItem(filteredList);
      setLinksToShow({
        resourceType: "search",
      });
      setLinksWithSearchItem(filteredListBySearchItem);
    } else {
      setLinksToShow({
        resourceType: "search",
      });
      setLinksWithSearchItem(filteredList);
    }
  };

  const handleClick = (filterItem) => {
    if (filtersTurnedOn?.includes(filterItem)) {
      removeFromSearchList(filterItem);
      setFiltersTurnedOn(
        filtersTurnedOn.filter(
          (turnedOnFilter) => turnedOnFilter !== filterItem
        )
      );
    } else {
      setFiltersTurnedOn([...filtersTurnedOn, filterItem]);
      getAllFilteredResources(filterItem);
    }
  };

  const displayChip = (filter, label) => {
    return (
      <Chip
        sx={{ background: getResourceTypeColour(filter), marginRight: "5px" }}
        clickable
        onClick={() => {
          handleClick(filter);
        }}
        label={label}
        icon={filtersTurnedOn?.includes(filter) && <CheckIcon />}
      />
    );
  };

  return (
    <div style={{ margin: "1rem 0" }}>
      {displayChip("patient", "Patient Resource")}
      {displayChip("provider", "Provider Resource")}
    </div>
  );
}
