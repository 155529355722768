import React from "react";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import "./AccountDeleted.scss";
const AccountDeleted = () => {
  return (
    <div className="account-deleted">
      <CheckCircleIcon
        style={{ fontSize: "4rem" }}
        className="account-deleted__icon"
      />
      <h2 className="account-deleted__title">Account Deleted Successfully</h2>
      <p className="account-deleted__message">
        We're sorry to see you go. If you change your mind, you're always
        welcome to join MedEssist Education Hub again anytime!
      </p>
    </div>
  );
};

export default AccountDeleted;
