import { createSlice } from "@reduxjs/toolkit";
import { onSnapshot, doc } from "firebase/firestore";
import { db } from "../../lib/firebase/config";

// this is essentially how userObject would be like as a global state
// data should not be changed from the userSlice however, rather redux
// allows for the usage of slices in which each part of the slice should
// carry out a single responsibility (ie. i have created slices for pendingPharmacyRequests and linkedPharmacies)
// note that createSlice is a method for toolkit, so whenever going through redux docs, make sure its applicable to toolkit as standard redux has a lot more
// customizability but more boilerplate code (official docs also recommends using toolkit for modern uses)
// https://redux.js.org/tutorials/essentials/part-1-overview-concepts
export const userSlice = createSlice({
  name: "user",
  initialState: {
    value: {
      practiceType: "",
      profession: "",
      profilePicture: null,
      company: "",
      courses: {},
    },
  },
  reducers: {
    setUser: (state, action) => {
      // Redux Toolkit allows us to write "mutating" logic in reducers. It
      // doesn't actually mutate the state because it uses the Immer library,
      // which detects changes to a "draft state" and produces a brand new
      // immutable state based off those changes.
      // Also, no return statement is required from these functions.
      state.value = action.payload;
    },
    setUserSingleField: (state, action) => {
      const { fieldName, payload } = action.payload;
      state.value = { ...state.value, [fieldName]: payload };
    },
  },
});

export const getUpdatingUserObject = (userId) => async (dispatch) => {
  const userDocRef = doc(db, "users", userId);
  const unsubscribe = onSnapshot(userDocRef, (doc) => {
    if (doc.exists()) {
      // Document exists, update data
      const updatedUserData = doc.data();
      dispatch(setUser(updatedUserData));
      // Dispatch an action or update local state with updatedUserData
    } else {
      // Document doesn't exist
      console.log("User document no longer exists");
    }
  });
  return unsubscribe;
};

// Action creators are generated for each case reducer function
export const { setUser, setUserSingleField } = userSlice.actions;

export default userSlice.reducer;
